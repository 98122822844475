export default (value) => {
  let formData = new FormData();
  for (const prop in value) {
    let data = value[prop];
    if (data === null) {
      formData.append(prop, '');
    }
    else if (data instanceof Date) {
      data = (new Date(data)).toUTCString();
      formData.append(prop, data);
    } else if (Array.isArray(data) || (typeof data === 'object' && (!(data instanceof File)))) {
      formData.append(prop, JSON.stringify(data));
    } else {
      formData.append(prop, data);
    }
  }
  return formData
};