<template>
  <div class="container">
    <div class="progress-bar-container">
      <label>{{ translate("import.your_progress") }}</label>
      <div class="progress-bar-text">{{ progress }}% to complete</div>
      <div class="progress-bar">
        <div class="progress-bar-value" :style="{ width: `${progress}%` }"></div>
      </div>
    </div>

    <div class="actions">
      <app-button :color="'primary'" @click="cancel">{{
        translate("cancel")
      }}</app-button>
    </div>
  </div>
</template>
<script>
import AppButton from "@/components/shared/Button.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { onMounted, reactive, toRefs } from "vue";
export default {
  components: {
    AppButton,
    IconClose,
  },
  inject: ["translate"],
  emits: ["onCancel", "onComplete"],
  setup(props, { emit }) {
    const state = reactive({
      progress: 0,
    });

    const cancel = () => {
      emit("onCancel");
    };

    const fakeImport = () => {
      return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          state.progress += 10;
          if (state.progress >= 100) {
            clearInterval(interval);
            resolve({
              success: 14,
              failed: 6,
            });
          }
        }, 200);
      });
    };

    const importData = async () => {
      await fakeImport();
      emit("onComplete");
    };

    onMounted(() => importData());

    return {
      cancel,
      importData,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 500px;
  height: 85px;
  padding-bottom: 50px;
  align-items: center;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 1fr 120px;
}

label {
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #a1a9ba;
}
.progress-bar-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 5px;
  color: #2684fe;
}

.progress-bar {
  margin-top: 15px;
  height: 5px;
  background: #f2f5f9;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.progress-bar-value {
  height: 100%;
  background: #2684fe;
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.5s;
}
.actions {
  align-self: end;
}
</style>
