<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.5284 14.2322L19.4545 18.1574L18.1574 19.4545L14.2322 15.5284C12.7717 16.6992 10.9551 17.336 9.08331 17.3333C4.52931 17.3333 0.833313 13.6373 0.833313 9.08331C0.833313 4.52931 4.52931 0.833313 9.08331 0.833313C13.6373 0.833313 17.3333 4.52931 17.3333 9.08331C17.336 10.9551 16.6992 12.7717 15.5284 14.2322ZM13.6896 13.5521C14.8529 12.3557 15.5026 10.752 15.5 9.08331C15.5 5.53765 12.6281 2.66665 9.08331 2.66665C5.53765 2.66665 2.66665 5.53765 2.66665 9.08331C2.66665 12.6281 5.53765 15.5 9.08331 15.5C10.752 15.5026 12.3557 14.8529 13.5521 13.6896L13.6896 13.5521Z"
      fill="black" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
  },
};
</script>