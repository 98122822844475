import { computed } from 'vue';
import { useStore } from 'vuex';

export function useWbsDocument() {
  const store = useStore();
  const projectStatistic = computed(() => {
    const data = store.getters["wbs/nodesModelMeta"];
    if (!data) return {};
    const calcOverlogPercentage = (data) => {
      let result =
        100 - data.projectTimeSpentPercentage < 0
          ? Math.abs(100 - data.projectTimeSpentPercentage)
          : 0;

      return result > 100 ? 100 : result;
    };

    return {
      projectDoneOriginalEstimateInStoryPoints:
        data.projectDoneOriginalEstimateInStoryPoints,
      calcTimePerc: data.projectTimeSpentPercentage,
      spent: data.projectTimeSpent,
      projectProgress: data.projectProgress,
      spentStr: data.projectTimeSpentFormatted,
      calcOverLog: calcOverlogPercentage(data),
      calcRemaining:
        wbsState.value?.estimationType == "story_points"
          ? 0
          : data.projectRemainingEstimatePercentage,
      remaining: data.projectRemainingEstimate,
      remainingStr: data.projectRemainingEstimateFormatted,
      estimateStr: data.projectOriginalEstimateFormatted,
      projectProgressStr: data.projectProgressFormatted,
    };
  });
  const compareNodes = (node1, node2) => {
    const changedProperties = {};
    const compareProperties = ['summary', 'parentId', 'colorNode', 'codeOfAccounts', 'assignee', 'jiraIssueStatus', 'descriptionHtml', 'jiraPriority', 'jiraProject', 'parent', 'originalEstimateFormatted', 'originalEstimateInStoryPoints', 'timeSpentFormatted', 'remainingEstimateFormatted', 'originalEstimateInHours', 'remainingEstimate', 'timeSpent'];
    const excludeProperties = ['id', 'avatarUrls', 'active', 'timeZone', 'accountType', 'assigneeId', 'iconUri', 'accountId', 'createdAt', 'updatedAt', 'self', 'jiraIssueType', 'statusCategory', 'parent', 'children', 'jiraIssueStatusId', 'jiraIssueStatusCategoryId', 'iconUrl', 'description']
    const nodeProperty = Object.keys(node1);

    nodeProperty.filter(k => excludeProperties.includes(k) == false).forEach(k => {
      if (compareProperties.includes(k)) {
        if (node1[k] !== null && typeof node1[k] === 'object' && !Array.isArray(node1[k])) {
          const childKeys = Object.keys(node1[k]);
          childKeys.filter(k => excludeProperties.includes(k) == false).forEach(ck => {
            if (node1?.[k]?.[ck] != node2?.[k]?.[ck]) {
              changedProperties[k] = {
                summary: node1.summary,
                before: node1[k],
                after: node2[k],
              }
            }
          })
        } else {
          if (node1[k] != node2[k]) {            
            changedProperties[k] = {
              summary: node1.summary,
              before: node1[k],
              after: node2[k],
            }
          }
        }
      }
    })
    return changedProperties;
  }
  return {
    projectStatistic,
    compareNodes,
  }
}