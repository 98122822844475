<template>
  <div class="settings-screen">
    <div class="content">
      <app-tabs @change="changeTab">
        <app-tab :title="translate('settings.general')" data-testid="general-tab">
          <app-setting-general />
        </app-tab>
        <app-tab :title="translate('settings.nodes_hierarchy')" data-testid="hierarchy-tab">
          <app-settings-node-hierarchy v-if="isShowHierarchy"/>
        </app-tab>
      </app-tabs>
    </div>
  </div>
</template>
<script>
import { computed, inject, reactive, toRefs } from "vue";
import AppTab from "@/components/shared/tabs/Tab.vue";
import AppTabs from "@/components/shared/tabs/Tabs.vue";
import AppHeader from "@/components/shared/Header.vue";
import AppSettingGeneral from "@/components/settings/SettingsGeneral.vue";
import AppSettingsNodeHierarchy from "@/components/settings/SettingsNodeHierarchy.vue";
import AppSearchPages from "@/components/shared/SearchPages.vue";
export default {
  inject: ["translate"],
  components: {
    AppTab,
    AppTabs,
    AppHeader,
    AppSettingGeneral,
    AppSettingsNodeHierarchy,
    AppSearchPages,
  },
  setup() {
    const translate = inject("translate");
    const state = reactive({
      searchText: "",
      //generalTab: computed(() => translate("settings.general")),
      isShowHierarchy: false,
    });
    const changeTab = (index) => {
      if (index == 1) state.isShowHierarchy = true;
    };
    return {
      ...toRefs(state),
      changeTab,
    };
  },
};
</script>
<style scoped lang="scss">

.settings-screen {
  height: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:

    "Content";

  .header {
    grid-area: Header;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 300px;
    .header-title {
      grid-area: 1 / 1 / 1 / 1;
    }

    .search-control {
      grid-area: 1 / 2 / 1 / 2;
    }

    @media (max-width: 1400px) {
      .header-title {
        ::v-deep(.app-name) {
          font-size: 12px;
          line-height: 125%;
        }
      }
    }
  }

  .content {
    grid-area: Content;
    background: #fff;
    padding: 20px 25px;
    border-radius: 8px;
    position: relative;
    grid-template-rows: minmax(100px, 1fr) 1fr;

    .name {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #363636;
      @media (max-width: 1400px) {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
</style>
