<template>
  <v-pagination
    v-model="page"
    :pages="pages"
    :range-size="1"
    :hideFirstButton="hideFirstButton"
    :hideLastButton="hideFirstButton"
    @update:modelValue="onChangePage"
  />
</template>
<script>
import { computed } from "vue";
import VPagination from "@hennge/vue3-pagination";
export default {
  components: {
    VPagination,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    count: {
      type: Number,
      default: 0,
    },
    countOnPage: {
      type: Number,
      default: 0,
    },
    hideFirstButton: {
      type: Boolean,
      default: true,
    },
    hideLastButton: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["onChangePage"],
  setup(props, { emit }) {
    const pages = computed(() => {
      return Math.ceil(props.count / props.countOnPage);
    });

    const onChangePage = (page) => {
      emit("onChangePage", page);
    };
    return {
      onChangePage,
      pages,
    };
  },
};
</script>
<style lang="scss" scoped>
.Pagination {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  align-items: center;
}
::v-deep() {
  .PaginationControl {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 50%;
    border: 0.5px solid rgba(54, 86, 116, 0.25);
  }

  .Control {
    position: relative;
    display: block;
    width: 23px;
    height: 23px;
    fill: rgba(54, 86, 116, 0.25);
  }

  .Control-active {
    fill: #0085ff;
  }

  .Page {
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 11px;
    line-height: 14px;
    flex-shrink: 0;
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
    outline: 0;
    user-select: none;
  }

  .Page-active {
    border-radius: 50%;
    background-color: #0085ff !important;
    background: #0085ff;
    color: white;
  }

  .DotsHolder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin: 0 2px;
    box-sizing: border-box;
  }

  .Dots {
    width: 8px;
    height: 4px;
    fill: #bbbbbb;
  }
  button {
    -webkit-appearance: none;
    appearance: none;
  }
}
</style>
