<template>
  <svg
    :opacity="opacity"
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33337 16.6666C3.33337 14.1666 6.66671 14.1666 8.33337 12.4999C9.16671 11.6666 6.66671 11.6666 6.66671 7.49992C6.66671 4.72242 7.77754 3.33325 10 3.33325C12.2225 3.33325 13.3334 4.72242 13.3334 7.49992C13.3334 11.6666 10.8334 11.6666 11.6667 12.4999C13.3334 14.1666 16.6667 14.1666 16.6667 16.6666"
      :stroke="stroke"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: String,
      default: "",
    },
    stroke: {
      type: String,
      default: "",
    },
  },
};
</script>
