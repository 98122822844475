import { createApp } from 'vue';
import 'normalize.css';
import App from './App.vue';
import AppWrongToken from './AppWrongToken.vue';
import router from './router';
import axios from 'axios';
import store from './store';
import vClickOutside from './directives/outside';
import { i18n } from './i18n';
import { useLanguageHelper } from './helpers/languageHelper';
import VTooltip from 'v-tooltip';
import Echo from 'laravel-echo';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import * as jose from 'jose'
let jwt = 'jwt=';

window.io = require('socket.io-client');

const axiosSetConfiguration = function (baseUrl, jwt) {
  axios.defaults.baseURL = baseUrl + '/api/v1/';
  axios.defaults.headers.common = {
    Authorization: jwt,
    'Access-Control-Max-Age': 86400
  };
  let numberOfCalls = 0;

  axios.interceptors.request.use((config) => {
    numberOfCalls++;
    if (!config.hideLoader) {
      store.commit('loader/start');
    }
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      numberOfCalls--;
      if (numberOfCalls == 0) {
        store.commit('loader/finish');
      }
      return response;
    },
    (error) => {
      numberOfCalls--;
      if (error?.response?.status) {        
        switch (error.response.status && !error?.config?.skipException) {
          case 500:
            store.commit('app/SET_SERVER_ERROR', error.response.status);
            break;
          case 400:
              store.commit('app/SET_SERVER_ERROR', error.response.status);
              break;            
          case 401:
            store.commit('app/SET_SERVER_ERROR', error.response.status);
            break;
          case 403:
            store.commit('app/SET_SERVER_ERROR', error.response.status);
            break;
        }
      }

      if (numberOfCalls == 0) {
        store.commit('loader/start');
      }
      store.commit('loader/finish');
      return Promise.reject(error);
    }
  );
};

const windowEchoInit = function (jwt) {
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.config.websocketHost ? window.config.websocketHost : window.location.hostname + ':6001',
    auth: {
      headers: {
        Authorization: jwt
      }
    }
  });
};

useLanguageHelper(store);

const vueInitLocal = async () => {
  try {
    const app = createApp(App);
    app.directive('clickOutside', vClickOutside);
    await store.dispatch("profile/getInstance");
    app
      .use(store).
      use(router).
      use(i18n).
      use(VTooltip);
    router.app = app;
    app.store = store;
    app.provide('translate', useLanguageHelper(store).translate);
    app.mount('#app');
  } catch (error) {
    console.log('Instance error =', error);
  }
};

const vueInit = async () => {
  try {
    const app = createApp(App);
    app.directive('clickOutside', vClickOutside);
    const bugsnagVue = Bugsnag.getPlugin('vue');
    await store.dispatch("profile/getInstance");
    app
      .use(store).
      use(router).
      use(i18n).
      use(VTooltip).
      use(bugsnagVue);
    router.app = app;
    app.store = store;
    app.provide('translate', useLanguageHelper(store).translate);
    app.mount('#app');

    Bugsnag.start({
      apiKey: '2af0f05b02b7cad7c8c5850060a4b527',
      plugins: [new BugsnagPluginVue()],
      releaseStage: config.env
    });
  } catch (error) {
    console.log('Instance error =', error);
  }
};

const getToken = async () => {
  let token, isWrongToken = false;
  for (let index = 0; index < 3; index++) {
    token = await AP.context.getToken();
    const content = jose.decodeJwt(config.localJwt);    
    if (!content?.sub) {
      isWrongToken = true;
      continue;
    }
    else {
      isWrongToken = false;
      break;
    }
  }    
  
  if (isWrongToken) {
    const app = createApp(AppWrongToken);
    app.mount('#app');
    return;
  }
  jwt += token;
  axiosSetConfiguration(config.baseURL, jwt);
  windowEchoInit(jwt);
  vueInit();
}

if (config.isLocal) {
  jwt += config.localJwt;
  axiosSetConfiguration(config.baseURL, jwt);
  windowEchoInit(jwt);
  vueInitLocal();
} else {
  getToken()
}
