<template>
  <div class="release-container">
    <div class="content">
      <section class="release">
        <ul>
          <li v-for="(item, index) in releaseList" :key="item" class="release-item"
            :class="{ 'show-detail': item.showDetail }">
            <div class="header">
              <div class="name">
                <a @click.prevent="setCurrentSlide({ item, index: 0 })" href="#">{{ item.numb }}</a>
                - {{ item.name }}
              </div>
              <div class="date">Released {{ item.date }}<div v-if="index == 0" class="badge">Latest Release</div>
              </div>
            </div>
            <ul class="list">
              <li v-for="(feature, index) in item.features" :key="feature"
                :class="{ active: index == item.currentSlide }" @click="setCurrentSlide({ item, index })">
                {{ feature.name }}
              </li>
            </ul>
            <div v-if="item.showDetail" class="release-details">
              <app-release-detail :slides="item.features" :current-slide="item.currentSlide"
                @on-close="closeDetail(item)" @on-change="(slide) => onChangeSlide({ slide, item })" />
            </div>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>
<script>
import AppHeader from "@/components/shared/Header.vue";
import AppSearchPages from "@/components/shared/SearchPages.vue";
import { reactive, toRefs } from "vue";
import ReleaseList from "./releaseList.json";
import AppReleaseDetail from "@/components/release-notes/ReleaseDetail.vue"

export default {
  components: {
    AppHeader,
    AppSearchPages,
    AppReleaseDetail,
  },
  setup() {
    const data = reactive({
      perPageOptions: [10, 20, 30],
      perPage: 10,
      releaseList: ReleaseList.map((item) => {
        return { ...item, ...{ currentSlide: -1, showDetail: false } };
      }),
      releaseMeta: {
        self: "/companies",
        count: 3,
        currentPage: 1,
        from: 1,
        lastPage: 1,
        links: [
          {
            url: null,
            label: "&laquo; Previous",
            active: false,
          },
          {
            url: "http://localhost:8000/api/v1/companies?page=1",
            label: "1",
            active: true,
          },
          {
            url: null,
            label: "Next &raquo;",
            active: false,
          },
        ],
        path: "http://localhost:8000/api/v1/companies",
        perPage: "30",
        to: 3,
        total: 3,
      },
    });
    const perPageChanged = () => { };
    const pageChanged = () => { };
    const onChangeSlide = ({ slide, item }) => {
      item.currentSlide = slide;
    };
    const setCurrentSlide = ({ item, index }) => {      
      item.showDetail = true;
      item.currentSlide = index;
    };
    const closeDetail = (item) => {
      item.showDetail = false;
      item.currentSlide = -1;
    };
    return {
      ...toRefs(data),
      perPageChanged,
      pageChanged,
      onChangeSlide,
      setCurrentSlide,
      closeDetail,
    };
  }
}
</script>
<style lang="scss" scoped>
.release-container {
  height: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;

  grid-template-areas:
    "Content";

  .buttons {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 150px;

    .button {
      height: 40px;
      grid-area: 1 / 2;
    }
  }

  .header {
    grid-area: Header;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 300px;

    .header-title {
      grid-area: 1 / 1 / 1 / 1;
    }

    .search-control {
      grid-area: 1 / 2 / 1 / 2;
    }

    @media (max-width: 1400px) {
      .header-title {
        ::v-deep(.app-name) {
          font-size: 12px;
          line-height: 125%;
        }
      }
    }
  }

  .content {
    grid-area: Content;
    background: #fff;
    padding: 20px 25px;
    border-radius: 8px;
    position: relative;
    grid-template-rows: minmax(100px, 1fr) 1fr;
  }


  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .list {
    padding: 0 50px;
    list-style: initial;
    padding-bottom: 15px;
    display: grid;
    grid-row-gap: 15px;
    align-self: start;
    li {
      font-size: 14px;
      cursor: pointer;

      &.active {
        font-weight: bold;
      }
    }
  }

  .release {
    .release-item+.release-item {
      margin-top: 15px;
    }

    .release-item {
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      background: #fff;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }

    .show-detail {
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      grid-template-rows: 62px 1fr;
      grid-row-gap: 25px;

      .header {
        grid-area: 1 / 1;
      }

      .list {
        grid-area: 2 / 1;
      }

      .release-details {
        grid-area: 1 / 2 / 3 / 4;
      }
    }

    .header {
      border-bottom: 1px solid #e5e7eb;
      display: grid;
      height: 63px;
      align-items: center;
      margin-bottom: 15px;
      padding: 0 25px;
      align-content: center;
      grid-template-columns: 1fr;
      grid-row-gap: 5px;

      .date {
        font-size: 14px;
        color: rgba(107, 114, 128);
      }

      .badge {
        background: rgb(220, 252, 231);
        display: inline-flex;
        align-items: center;
        font-weight: 400;
        font-size: 11px;
        padding: 5px;
        border-radius: 5px;
        margin-left: 5px;
      }
    }

    .name {
      display: flex;
      gap: 5px;
      color: rgb(107, 114, 128);
      align-items: center;
      font-size: 14px;

      a {
        color: rgb(59, 130, 246);
      }
    }
  }
}
</style>
