<template>
  <div class="widget-frame">
    <div class="widget-title">
      <div class="title">
        <slot name="title"></slot>
      </div>
      <div class="descr">
        <slot name="title-descr"></slot>
      </div>
      <div class="icon">
        <slot name="title-icon"></slot>
      </div>
    </div>
    <div class="widget">
      <div class="widget-icon">
        <slot name="icon"></slot>
      </div>
      <div class="widget-value">
        <div class="value">
          <slot name="value"></slot>
        </div>
        <div>
          <small>
            <slot name="descr"></slot>
          </small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.widget-frame {
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}
.widget {
  background: linear-gradient(42.62deg, #2684fe 0%, #2483ff 100%);
  border-radius: 8px;
  padding: 30px;
  display: grid;
  align-items: center;
}

.widget-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #363636;
  display: grid;
  grid-template-areas:
    "Title Icon"
    "Descr Icon";
  align-items: center;
  margin-bottom: 10px;
  .title {
    font-style: normal;
    font-weight: 500;
    grid-area: Title;
  }
  .descr {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #c3cad9;
    grid-area: Descr;
  }
  .icon {
    grid-area: Icon;
    justify-self: end;
  }
}

.widget-icon {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  grid-area: 1 / 1 / 1 / 1;
}

.widget-value {
  grid-area: 1 / 2 / 1 / 2;
  position: relative;
  top: 9px;
  .value {
    font-weight: 500;
    font-size: 42px;
    align-items: center;
    color: #ffffff;
    line-height: 33px;
    margin-bottom: 20px;
  }
  small {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }
}
</style>
