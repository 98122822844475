<template>
  <div class="container">
    <div class="header">
      <div class="close" @click="cancel">
        <icon-close :width="'20px'" :height="'20px'" :fill="'#A0A3BD'" />
      </div>
    </div>
    <div class="text">{{ translate("import.confirm_text") }}</div>
    <div class="actions">
      <app-button :color="'default'" @click="cancel">{{
        translate("import.cancel")
      }}</app-button>
      <app-button :color="'primary'" @click="submit">{{
        translate("import.submit")
      }}</app-button>
    </div>
  </div>
</template>
<script>
import AppButton from "@/components/shared/Button.vue";
import IconClose from "@/components/icons/IconClose.vue";
export default {
  components: {
    AppButton,
    IconClose,
  },
  inject: ["translate"],
  emits: ["onCancel", "onSubmit"],
  setup(props, { emit }) {
    const cancel = () => {
      emit("onCancel");
    };
    const submit = () => {
      emit("onSubmit");
    };
    return {
      cancel,
      submit,
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 500px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .close {
    cursor: pointer;
  }
}
.text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #232b37;
}

.actions {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  justify-content: space-between;
}
</style>
