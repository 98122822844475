<template>
  <svg
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect :width="width" :height="height" rx="4" fill="#0085FF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 17L7 17L7 16L19 16L19 17Z"
      fill="#FCFCFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 11L7 11L7 10L14 10L14 11Z"
      fill="#FCFCFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 13L7 13L7 12L19 12L19 13Z"
      fill="#FCFCFC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 15L7 15L7 14L19 14L19 15Z"
      fill="#FCFCFC"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
