<template>
  <div class="user-access">
    <div class="wrapper">
      <div class="header-wrapper">
        <div class="go-to-welcome" @click="openWelcomeScreen">
          <icon-attention :width="'21px'" :height="'21px'" />
        </div>
      </div>
      <div class="container">
        <h2>{{ translate("user_access.who_can_access") }}:</h2>
        <form class="users-form" @submit.prevent="addUserAccess">
          <div class="add-user">
            <div class="input-field">
              <label>{{ translate("user_access.header") }}</label>
              <base-select-box
                v-model="userId"
                :options="userList"
                :placeholder="translate('user_access.select_user')"
                :search-placeholder="translate('search_selectbox')"
                :selectedText="translate('selected')"
                group-by="isGroup"
                label-by="label"
                value-by="value"
                :clear-on-close="true"
                :close-on-select="true"
                :small-font="true"
                :searchable="true"
                :min="1"
                @input="handleInput"
              >
                <template v-slot:default="item">
                  <div class="image-item" @click="onChangeUser(item)">
                    <img v-if="item.option.iconUri" :src="item.option.iconUri" alt="" />
                    <template v-if="Array.isArray(item.option.value)">
                      {{ translate("all") }}
                    </template>
                    <template v-else>
                      {{ item.option.label }}
                    </template>
                  </div>
                </template>
              </base-select-box>
            </div>
            <app-button :disabled="!userId" type="subtmit" color="purple">{{
              translate("user_access.add")
            }}</app-button>
          </div>
          <div class="access-type-container">
            <div class="acces-type">
              <label>{{ translate("user_access.access_type") }}</label>
              <base-radio v-model="accessType" :options="accessTypeOptions" />
            </div>
            <div class="pagination" v-if="pages">
              <app-pagination
                v-model="page"
                :pages="pages"
                :range-size="1"
                :hideFirstButton="true"
                :hideLastButton="true"
                @update:modelValue="onChangePage"
              />
            </div>
          </div>
          <div class="error-list" v-if="backendErrors.length">
            <ul>
              <li :key="i" v-for="(error, i) in backendErrors">
                {{ error.text }}
              </li>
            </ul>
          </div>
          <div class="table">
            <app-table :header="columns">
              <template v-slot:row>
                <app-row
                  class="user-access-row"
                  :userInfo="user"
                  :key="user.userId + 'data'"
                  v-for="user in userAccessList"
                  @onRemoveRow="onRemoveRow"
                />
              </template>
            </app-table>
            <p class="no-data-found" v-if="!userAccessList.length">
              {{ translate("user_settings.grid.not_found") }}
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, reactive, toRefs, ref, inject } from "vue";
import debounce from "lodash.debounce";
import { useStore } from "vuex";
import router from "@/router";
import AppButton from "@/components/shared/Button.vue";
import BaseRadio from "@/components/forms/elements/BaseRadio.vue";
import BaseSelectBox from "vue-select-box";
import AppPagination from "@/components/shared/Pagination.vue";
import AppTable from "@/components/table/UserAccessTable.vue";
import AppRow from "@/components/table/rows/UserAccessRow.vue";
import { useModalHelper } from "@/helpers/modalHelper";
import { useLanguageHelper } from "@/helpers/languageHelper";
import IconAttention from "@/components/icons/IconAttention.vue";
import errorToList from "@/helpers/errorToList";
import * as types from "@/store/mutation-types";
import AppHeader from "@/components/shared/Header.vue";
import AppSearchPages from "@/components/shared/SearchPages.vue";
export default {
  components: {
    AppButton,
    BaseRadio,
    BaseSelectBox,
    AppPagination,
    AppTable,
    AppRow,
    IconAttention,
    AppHeader,
    AppSearchPages,
  },
  inject: ["translate"],
  setup() {
    const store = useStore(),
      settings = computed(() => store.getters["profile/settings"]),
      translate = inject("translate"),
      state = reactive({
        userId: null,
        accessType: 0,
        userAccess: null,
        countOnPage: 4,
        selectedUser: null,
        meta: computed(() => store.getters["userAccess/meta"]),
        pages: computed(() => {
          return Math.ceil(state.meta.total / state.countOnPage);
        }),
        page: 1,
        backendErrors: [],
      }),
      userAccessList = computed(() => {
        const currentUser = store.getters["profile/user"];
        return store.getters["userAccess/userAccessList"].map((u) => {
          return { ...u, ...{ isCurrent: u.userId === currentUser.id ? true : false } };
        });
      }),
      userList = computed(() => {
        if (state.selectedUser) {
          const arr = [...store.getters["userAccess/userList"], state.selectedUser];
          return arr;
        } else return store.getters["userAccess/userList"];
      }),
      columns = computed(() =>
        store.getters["userAccess/columns"].map((c) => translate(`user_access.${c}`))
      ),
      accessTypeOptions = computed(() =>
        store.getters["userAccess/accessTypeOptions"].map((a) => {
          return {
            id: a.id,
            name: translate(`user_access.${a.name.toLowerCase()}`),
          };
        })
      );

    const getUserList = async (params = { perPage: 5, search: "" }) => {
      await store.dispatch("userAccess/getUserList", params);
    };

    const getUserAccess = async () => {
      await store.dispatch("userAccess/getUserAccess", {
        _page: state.page,
        _limit: state.countOnPage,
      });
    };

    const onChangePage = async (_page) => {
      if (!_page) return;
      state.page = _page;
      getUserAccess();
    };

    const { openModal, storeModal } = useModalHelper();
    const onRemoveRow = (id) => {
      openModal("Confirmation", {
        title: computed(() => translate("modal.confirmation")),
        text: computed(() => translate("modal.remove_persmisson")),
      });
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          try {
            state.backendErrors = [];
            await store.dispatch("userAccess/removeUserAccess", id);
            getUserAccess();
          } catch (error) {
            state.backendErrors = errorToList(error);
          }
        }
        unsubscribe();
      });
    };

    const addUserAccess = async () => {
      const user = userList.value.find((u) => u.value === state.userId);
      if (!user) return;
      const newUserAccess = {
        userId: user.value,
        displayName: user.label,
        iconUri: user.iconUri,
        accessType: state.accessType,
      };
      try {
        state.backendErrors = [];
        await store.dispatch("userAccess/addUserAccess", newUserAccess);
        state.page = 1;
        state.accessType = 0;
        await getUserAccess();
      } catch (error) {
        state.backendErrors = errorToList(error);
      }
    };
    const openWelcomeScreen = async () => {
      store.commit(`profile/${types.SET_SHOW_USER_ACCESS_INTRO}`, true);
      router.push({ name: "user.access.welcome" });
    };

    const loadingSearch = ref(false);
    const debounceSearch = debounce(async (inputEvent) => {
      loadingSearch.value = true;
      await getUserList({
        perPage: 5,
        search: inputEvent.target.value,
      });
      loadingSearch.value = false;
    }, 500);

    const handleInput = async (inputEvent) => {
      debounceSearch(inputEvent);
    };

    const onChangeUser = async (item) => {
      state.selectedUser = item.option;
      await getUserList();
    };

    getUserList();
    getUserAccess();

    return {
      ...toRefs(state),
      columns,
      translate,
      onChangePage,
      addUserAccess,
      onRemoveRow,
      userList,
      userAccessList,
      accessTypeOptions,
      openWelcomeScreen,
      loadingSearch,
      handleInput,
      onChangeUser,
    };
  },
};
</script>
<style lang="scss" scoped>
.user-access {
  display: grid;
  grid-template-areas:   
    "Content";

  .wrapper {
    grid-area: Content;
    background-color: #fff;
    border-radius: 15px;
    min-height: 740px;
    padding: 30px;
    height: 100%;
  }
  .wrapper .header-wrapper {
    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 125%;
    }
    position: relative;
    padding-bottom: 20px;
    .go-to-welcome {
      position: absolute;
      top: -15px;
      right: -11px;
      cursor: pointer;
    }
  }
  .container {
    max-width: 440px;
    margin: 0 auto;
    h2 {
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;

      /* #363636 */

      color: #363636;
    }
  }
  .add-user {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: 15px;
    label {
      font-size: 12px;
      margin-bottom: 10px;
      display: block;
    }
    .button {
      position: relative;
      top: 10px;
      height: 54px;
    }
  }
  .access-type-container {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 150px;
    grid-column-gap: 15px;
    align-items: center;
    label {
      font-size: 12px;
      margin-bottom: 10px;
      display: block;
    }
    .pagination {
      align-self: end;
    }
  }
  .table {
    margin-top: 15px;
    .user-access-row:last-child {
      border-bottom: 0;
    }
  }
  @media (max-width: 1200px) {
    min-height: 640px;
  }
}
.error-list {
  font-size: 12px;
  margin-top: 15px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    color: #eb7571;
  }
}
</style>
