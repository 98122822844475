<template>
  <svg
    :opacity="opacity"
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 0.0626526C10.831 0.0626526 13.9375 3.16915 13.9375 7.00015C13.9375 10.8312 10.831 13.9377 7 13.9377C3.16825 13.9377 0.0625 10.8312 0.0625 7.00015C0.0625 3.16915 3.16825 0.0626526 7 0.0626526Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "#136AFD",
    },
  },
};
</script>
