<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.31958 6.05319H14.6875"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.3315 8.98226H11.3384"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00335 8.98226H8.0103"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.66839 8.98226H4.67534"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.3315 11.8972H11.3384"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00335 11.8972H8.0103"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.66839 11.8972H4.67534"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0327 0.5V2.96809"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.97411 0.5V2.96809"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.1787 1.6844H4.82822C2.6257 1.6844 1.25 2.91135 1.25 5.16667V11.9539C1.25 14.2447 2.6257 15.5 4.82822 15.5H11.1718C13.3812 15.5 14.75 14.266 14.75 12.0106V5.16667C14.7569 2.91135 13.3882 1.6844 11.1787 1.6844Z"
      :stroke="fill"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "#0F8AF9",
    },
  },
};
</script>
