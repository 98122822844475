<template>
  <svg
    :opacity="opacity"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10.0001" r="10" fill="#C3CAD9" />
    <path
      d="M10.0112 7.9268C9.80458 7.9268 9.63125 7.8568 9.49125 7.7168C9.35125 7.5768 9.28125 7.40346 9.28125 7.1968C9.28125 6.99013 9.35125 6.8168 9.49125 6.6768C9.63125 6.5368 9.80458 6.4668 10.0112 6.4668C10.2113 6.4668 10.3813 6.5368 10.5213 6.6768C10.6613 6.8168 10.7313 6.99013 10.7313 7.1968C10.7313 7.40346 10.6613 7.5768 10.5213 7.7168C10.3813 7.8568 10.2113 7.9268 10.0112 7.9268ZM10.5713 8.6568V14.1668H9.43125V8.6568H10.5713Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "#0F8AF9",
    },
  },
};
</script>
