<template>
  <app-modal ref="modal" :isOpen="!!componentName" tabindex="0">
    <component :is="componentName" v-bind="props" />
  </app-modal>
</template>

<script>
import { defineAsyncComponent, computed } from "vue";
import { useStore } from "vuex";
import AppModal from "@/components/modal/ModalComponent";
import newNodeComponent from "@/components/modal/NewNode.vue";
import NewProject from "@/components/modal/NewProject.vue";
import NewTemplate from "@/components/modal/NewTemplate.vue";
import Confirmation from "@/components/modal/Confirmation.vue";
import Message from "@/components/modal/Message.vue";
import SelectParentNode from "@/components/modal/SelectParentNode.vue";
import ServerError from "@/components/modal/ServerError.vue";
import ProjectCreating from "@/components/modal/ProjectCreating.vue";
import ShareWBS from "@/components/modal/ShareWBS.vue";
import Estimation from "@/components/modal/Estimation.vue";
import ImagePreview from "@/components/modal/ImagePreview.vue";
import Import from "@/components/modal/Import.vue";
import SettingsNewNode from "@/components/modal/SettingsNewNode.vue";
import SelectUser from "@/components/modal/SelectUser.vue";
import SelectStatus from "@/components/modal/SelectStatus.vue";
export default {
  components: {
    AppModal,
    Confirmation: Confirmation,
    NewProject: NewProject,
    NewTemplate: NewTemplate,
    SettingsNewNode: SettingsNewNode,
    Message: Message,
    SelectUser: SelectUser,
    SelectStatus: SelectStatus,
    ShareWBS: ShareWBS,
    Estimation: Estimation,
    Import: Import,
    NewNode: newNodeComponent,
    SelectParentNode: SelectParentNode,
    ProjectCreating: ProjectCreating,
    ServerError: ServerError,
    ImagePreview: ImagePreview,
  },
  setup() {
    const store = useStore(),
      componentName = computed(() => store.getters["modal/component"]),
      props = computed(() => store.getters["modal/props"]);

    return {
      componentName,
      props,
    };
  },
};
</script>
