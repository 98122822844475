export default {
  projectsCount: 0,
  templatesCount: 0,
  activeSessions: 0,
  totalSessions: 0,
  totalTemplates: 0,
  activeParticipants: 0,
  allProjectsCount: 0,
  hoursSaved: null,
  hoursCount: 0,
  sessions: [],
  projects: [],
  templates: [],
};
