<template>
  <div class="modal-body">
    <div class="close" @click="closeModal">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">
      <div>
        <strong>{{ translate("select_user") }} </strong>
      </div>
    </div>
    <form @submit.prevent="submit">
      <div class="select-user">
        <label> &nbsp;</label>
        <base-select-box
          v-model="userId"
          :options="userList"
          :placeholder="translate('select_user')"
          :search-placeholder="translate('search_selectbox')"
          :selectedText="translate('selected')"
          label-by="label"
          value-by="value"
          :close-on-select="true"
          :small-font="true"
          :searchable="true"
        >
          <template v-slot:default="item">
            <div class="image-item">
              <img v-if="item.option.iconUri" :src="item.option.iconUri" alt="" />
              <template v-if="Array.isArray(item.option.label)">
                {{ translate("dashboard.all") }}
              </template>
              <template v-else>
                {{ item.option.label }}
              </template>
            </div>
          </template>
        </base-select-box>
      </div>
      <div class="actions">
        <div class="buttons">
          <app-button :color="'default'" type="button" @click="closeModal">
            {{ translate("modal.cancel") }}
          </app-button>
          <app-button :color="'primary'" :disabled="!isFormValid" type="submit">{{
            translate("modal.save")
          }}</app-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, toRefs, computed, onMounted } from "vue";
import { useModalHelper } from "@/helpers/modalHelper";
import AppButton from "@/components/shared/Button";
import IconClose from "@/components/icons/IconClose.vue";
import IconPhoto from "@/components/icons/IconPhoto.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import BaseInput from "@/components/forms/elements/BaseInput.vue";
import BaseTextArea from "@/components/forms/elements/BaseTextArea.vue";
import BaseSelectBox from "vue-select-box";
import IconPerson from "@/components/icons/IconPerson.vue";
import BaseSelectedItems from "@/components/forms/elements/SelectedItems.vue";
import IconWrapper from "@/components/icons/IconWrapper.vue";
import { useStore } from "vuex";

export default {
  components: {
    AppButton,
    IconClose,
    IconPhoto,
    IconPlus,
    BaseInput,
    BaseTextArea,
    BaseSelectBox,
    IconPerson,
    BaseSelectedItems,
    IconWrapper,
  },
  props: {
    userData: {
      type: Object,
      default: {},
    },
  },
  inject: ["translate"],
  setup(props) {
    const store = useStore(),
      { closeModal, submitModal } = useModalHelper(),
      state = reactive({
        userId: null,
        userList: computed(() => store.getters["users/userList"]),
        isFormValid: computed(() => state.user !== null),
      });

    const submit = () => {
      submitModal(state.userList.find((u) => u.value == state.userId));
    };

    onMounted(async () => {
      await store.dispatch("users/getUsers");
      state.userId = props.userData.value;
    });

    return {
      ...toRefs(state),
      submit,
      closeModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #0085ff;
      svg {
        fill: white;
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #363636;
    strong {
      font-weight: 600;
    }
    small {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #a1a9ba;
      margin-top: 5px;
    }
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
    color: #a1a9ba;
    display: block;
  }
  .actions {
    grid-area: Actions;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    margin-top: 15px;
    .button {
      height: 55px;
      width: 100%;
      align-self: end;
    }
    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      justify-self: end;
      width: 200px;
    }
  }
}
</style>
