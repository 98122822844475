import { defineComponent, h, withDirectives } from "vue";
import AppProgressBar from "@/components/wbsProject/estimation/ProgressBar.vue";
import IconNoAssignee from "@/components/icons/IconNoAssignee.vue";
import vOpenJiraIssueDialog from "@/directives/openJiraIssueDialog";
export function useCellComponents() {
  const ProgressBarCell = defineComponent({
    props: {
      row: Object,
    },
    setup(props) {
      return () => {
        return h(
          "div",
          {
            style: { 'font-size': '12px', width: '100px' }
          },
          `${Math.round(props.row.row[props.row.name].calcProgressPerc)}%`,
          [
            h(AppProgressBar, {
              spent: props.row.row[props.row.name].calcTimePerc,
              overlog: props.row.row[props.row.name].calcOverLog,
              remaining: props.row.row[props.row.name].calcRemaining,
            })
          ],
        );
      };
    },
  });
  const StatusCell = defineComponent({
    props: {
      row: Object,
      highlight: Boolean,
    },
    setup(props) {
      return () => {
        return h(
          "div",
          {
            style: { background: props.row.row.statusColor },
            class: props?.highlight ? "highlight-property status" : "status",
          },
          [
            h(
              "span",
              {
                style: { color: props.row.row.statusColorText },
              },
              props.row.row[props.row.name]
            ),
          ]
        );
      };
    },
  });

  const AssigneeCell = defineComponent({
    props: {
      row: Object,
      highlight: Boolean,
    },
    setup(props) {
      return () => {
        return h(
          "div",
          {
            class: props?.highlight ? "highlight-property assignee assignee-cell" : "assignee assignee-cell",
          },
          h(
            !props.row.row.assigneeUri ? h(IconNoAssignee, {
              width: '26px',
              height: '26px',
              fill: '#fff',
              class: 'unassignee-image',
              style: {
                background: '#ccc',
                'border-radius': '50%',
              }
            }) :
              h("img", {
                class: 'assignee-image',
                src: props.row.row.assigneeUri,
                style: {
                  width: '26px',
                  height: '26px',
                  'border-radius': '50%'
                }
              })
          ),
          props.row.row[props.row.name] || 'Unassigned'
        );
      };
    },
  });

  const IssueTypeCell = defineComponent({
    props: {
      row: Object,
      highlight: Boolean,
    },
    setup(props) {
      return () => {
        return h(
          "div",
          {
            class: props?.highlight ? "highlight-property issue-type issue-type-cell" : "issue-type issue-type-cell",
            style: {
              display: 'flex',
              'align-items': 'center',
              'gap': '5px',
            }
          },
          props.row.row?.jiraIssueUri && h(
            "img", {
            class: 'issue-type-image',
            src: props.row.row.jiraIssueUri,
            style: {
              width: '16px',
              height: '16px',
            }
          }
          ),
          props.row.row[props.row.name]
        );
      };
    },
  });

  const SummaryCell = defineComponent({
    props: {
      row: Object,
      highlight: Boolean,
    },
    setup(props) {
      return () => {
        return h(
          "div",
          {
            class: props?.highlight ? "highlight-property summary-cell" : "summary-cell",
            title: props.row.row[props.row.name],
          },
          props.row.row[props.row.name]
        );
      };
    },
  });
  const IssueKeyCell = defineComponent({
    props: {
      row: Object,
      highlight: Boolean,
    },
    setup(props) {
      return () => {
        const color = props.row.row.colorNode?.split(
          ':'
        )[1] ? props.row.row.colorNode?.split(
          ':'
        )[1] : '';
        return h(
          "a",
          {
            target: "_blank",
            href: props.row.row['jiraIssueUrl'],
            style: {
              color: color,
              display: 'flex',
              'align-items': 'center',
              'gap': '5px',
              'text-decoration': 'none',
            }
          },
          props.row.row?.jiraIssueUri && h(
            "img", {
            class: 'issue-type-image',
            src: props.row.row.jiraIssueUri,
            style: {
              width: '16px',
              height: '16px',
            }
          }
          ),
          props.row.row[props.row.name]
        );
      };
    },
  });
  const EstimatesCellComponent = defineComponent({
    props: {
      row: Object,
      columns: Array,
      childrenCount: Number,
      highlight: Boolean,
    },
    setup(props) {
      return () => {
        let highlight = props?.highlight ? "highlight-property" : ""
        return h(
          "div",
          {
            class: props.childrenCount > 0 && props.row.row[props.row.name] !== '0.00h' ? 'cell-bold ' + highlight : ' ' + highlight,
          },
          props.row.row[props.row.name]
        );
      };
    },
  });
  const TimeTrackCellComponent = defineComponent({
    props: {
      row: Object,
      columns: Array,
      childrenCount: Number,
      highlight: Boolean,
    },
    setup(props) {
      return () => {
        let highlight = props?.highlight ? "highlight-property" : ""
        return h(
          "div",
          {
            class: props.childrenCount > 0 && props.row.row[props.row.name] !== '0.00h' ? 'cell-bold ' + highlight : ' ' + highlight,
          },
          props.row.row[props.row.name]
        );
      };
    },
  });
  const CellCOAComponent = defineComponent({
    props: {
      row: Object,
      columns: Array,
      highlight: Boolean,
    },
    setup(props) {
      return () => {
        return h(
          "div",
          { class: props?.highlight ? "highlight-property" : "" },
          props.row.row[props.row.name]
        );
      };
    },
  });

  const CellComponent = defineComponent({
    props: {
      row: Object,
      columns: Array,
      highlight: Boolean,
    },
    setup(props) {
      return () => {
        return h(
          "div",
          { class: props?.highlight ? "highlight-property" : "" },
          props.row.row[props.row.name]
        );
      };
    },
  });

  return {
    ProgressBarCell,
    StatusCell,
    AssigneeCell,
    SummaryCell,
    IssueKeyCell,
    EstimatesCellComponent,
    TimeTrackCellComponent,
    CellCOAComponent,
    CellComponent,
    IssueTypeCell,
  }
}