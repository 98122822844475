<template>
  <svg :width="width" :height="height" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3795 2.58333H18.25C18.4931 2.58333 18.7263 2.67991 18.8982 2.85182C19.0701 3.02373 19.1666 3.25688 19.1666 3.5V16.3333C19.1666 16.5764 19.0701 16.8096 18.8982 16.9815C18.7263 17.1534 18.4931 17.25 18.25 17.25H1.74998C1.50686 17.25 1.27371 17.1534 1.1018 16.9815C0.92989 16.8096 0.833313 16.5764 0.833313 16.3333V1.66667C0.833313 1.42355 0.92989 1.19039 1.1018 1.01849C1.27371 0.846577 1.50686 0.75 1.74998 0.75H8.54615L10.3795 2.58333ZM2.66665 4.41667V15.4167H17.3333V4.41667H2.66665Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
