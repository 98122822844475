<template>
  <div class="input-field" :class="{ 'input-search': searchText }">
    <div class="input-field-wrapper input-field-wrapper-icon icon-right">
      <i>
        <icon-search></icon-search>
      </i>
      <input v-model="searchText" :type="type" name="search" autocomplete="off" :placeholder="placeholder"
        @input="onChange" @keyup.esc="resetSearch" @focus="(e) => emit('focus', e)" @blur="(e) => emit('blur', e)" />
      <button type="button" class="clear-search" @click="resetSearch">
        <i>
          <icon-close></icon-close>
        </i>
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import IconSearch from "@/components/icons/IconSearch";
import IconClose from "@/components/icons/IconClose";
export default {
  name: "SearchComponent",
  components: {
    IconClose,
    IconSearch,
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "Search",
    },
  },
  emits: ["update:modelValue", "input", "blur", "focus"],


  setup(props, { emit }) {
    const state = reactive({
      searchText: props.modelValue,
    });

    watch(
      () => props.modelValue,
      (current) => {
        state.searchText = current;
      }
    );

    const onChange = () => {
      emit("update:modelValue", state.searchText);
      emit("input", state.searchText);
    };

    const resetSearch = () => {
      state.searchText = "";
      onChange();
    };

    return {
      ...toRefs(state),
      onChange,
      resetSearch,
      emit,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin input-style($borderColor: #c3cad9) {
  background: transparent;

  &.error {
    ::placeholder {
      color: #e47676;
    }

    border: 1px solid #e47676;
  }

  border: 1px solid $borderColor;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
}

.input-field {
  .input-field-wrapper {
    display: flex;
    align-items: center;
    height: 45px;
    position: relative;

    &-icon {
      input {
        height: 100%;
        padding-left: 45px;
        padding-right: 15px;
      }
    }

    &.icon-right {
      input {
        padding-left: 15px;
      }

      i {
        right: 15px;
        left: auto;
      }
    }

    @media (max-width: 1200px) {
      height: 45px;
    }
  }

  .clear-search {
    display: none;
  }

  &.input-search {
    .input-field-wrapper {
      * {
        outline: none;
      }

      .clear-search {
        display: block;
      }

      input {
        padding-right: 58px;
      }

      .clear-search {
        display: block;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        background: transparent;
        border: 0;
        position: absolute;
        right: 45px;

        i {
          position: relative;
          right: auto;
          left: auto;

          svg {
            stroke: #c3cad9;
          }
        }
      }
    }
  }

  &.error {
    input {
      border: 1px solid #e47676;

      &:focus {
        box-shadow: 0px 0px 10px #e47676;
        border: 1px solid #e47676;
      }

      color: #e47676;
    }

    label {
      color: #e47676;
    }
  }

  label {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 12px;
    display: block;
    margin-left: 22px;
  }

  textarea,
  input {
    height: 100%;
    width: 100%;
    border: 0;
    outline: 0;
    background: transparent;
    padding-left: 15px;
    font-size: 12px;
    line-height: 18px;
    @include input-style();

    &.no-border {
      @include input-style(transparent);
    }

    transition: 0.5s;

    &:focus {
      border: 1px solid #0f8af9;
      box-shadow: 0px 0px 10px #0f8af9;
    }
  }

  textarea {
    padding-top: 20px;
  }

  i {
    display: block;
    position: absolute;
    left: 16px;
    width: 22px;
    height: 24px;
    position: absolute;
    left: 16px;
    margin-top: 0px;
  }
}
</style>
