export default {
  activeUsers: state => state.session?.active || [],
  inactiveUsers: state => state.session?.inactive || [],
  meta: (state) => {
    return state.meta || {};
  },
  count: (state) => {
    return state.count;
  },
  countOnPage: (state) => {
    return state.countOnPage;
  },
};
