//users module
export const SET_USERS = 'SET_USERS';
export const SET_USERS_WITH_ACCESS = 'SET_USERS_WITH_ACCESS';
export const SET_PROFILE_INSTANCE = 'SET_PROFILE_INSTANCE';
export const SET_SHOW_USER_ACCESS_INTRO = 'SET_SHOW_USER_ACCESS_INTRO';
export const SET_SHOW_MAIN_INTRO = 'SET_SHOW_MAIN_INTRO';
export const SET_USER_CAPTURE_SETTINGS = 'SET_USER_CAPTURE_SETTINGS';
export const SET_SETTINGS = 'SET_SETTINGS';
export const SET_PROFILE_INSTANCE_LOADING = 'SET_PROFILE_INSTANCE_LOADING';
export const SET_CONVERSION_SCREEN_USED = 'SET_CONVERSION_SCREEN_USED';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const SET_USER_ACCESS = 'SET_USER_ACCESS';
export const REMOVE_USER_ACCESS = 'REMOVE_USER_ACCESS';
export const SET_USER_ACCESS_USERS = 'SET_USER_ACCESS_USERS';
export const SET_USER_ACCESS_LIST = 'SET_USER_ACCESS_LIST';
export const ADD_USER_ACCESS = 'ADD_USER_ACCESS';
export const SET_REPORT = 'SET_REPORT';
export const APP_SHOW_SIDEBAR = 'APP_SHOW_SIDEBAR';
export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_PROJECT_MANAGERS = 'SET_PROJECT_MANAGERS';
export const SET_PROJECT_ACCESS = 'SET_PROJECT_ACCESS';
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_HIERARCHY = 'SET_HIERARCHY';
export const SET_ISSUE_TYPES = 'SET_ISSUE_TYPES';
export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS';
export const SET_STATUS_LIST = 'SET_STATUS_LIST';
export const SET_NODES = 'SET_NODES';
export const SET_CACHED_NODES = 'SET_CACHED_NODES';
export const RESET_CACHED_NODES = 'RESET_CACHED_NODES';
export const PUSH_NODE = 'PUSH_NODE';
export const UPDATE_NODES = 'UPDATE_NODES';
export const UPDATE_NODE_LIST_COLLAPSE = 'UPDATE_NODE_LIST_COLLAPSE';
export const UPDATE_NODE = 'UPDATE_NODE';
export const UPDATE_LINKS = 'UPDATE_LINKS';
export const SET_NODES_META = 'SET_NODES_META';
export const SET_SESSION = 'SET_SESSION';
export const SET_IMPORT_LIST = 'SET_IMPORT_LIST';
export const SET_FILTER_LIST = 'SET_FILTER_LIST';
export const SET_WBS_STATE = 'SET_WBS_STATE';
export const RESET_WBS_STATE = 'RESET_WBS_STATE';
export const SET_JIRA_PROJECTS = 'SET_JIRA_PROJECTS';
export const SET_JIRA_STATUSES = 'SET_JIRA_STATUSES';
export const SET_JIRA_CATEGORIES = 'SET_JIRA_CATEGORIES';
export const SET_PROJECT_FILTERS = 'SET_PROJECT_FILTERS';
export const SET_JIRA_PRIORITIES = 'SET_JIRA_PRIORITIES';
export const SET_WBS_SOURCE = 'SET_WBS_SOURCE';
export const SET_SEARCH_TEMPLATES = 'SET_SEARCH_TEMPLATES';
export const SET_SEARCH_INSTANCE = 'SET_SEARCH_INSTANCE';
export const SET_LAST_ISSUE_TYPE = 'SET_LAST_ISSUE_TYPE';
export const SET_IMPORT_RUN = 'SET_IMPORT_RUN';
export const SET_EXISTS_IMPORT = 'SET_EXISTS_IMPORT';
export const SET_PRINT_RUN = 'SET_PRINT_RUN';
export const SET_PROJECT_PARTICIPANTS = 'SET_PROJECT_PARTICIPANTS';
export const ADD_CACHED_NODE_STATUS = 'ADD_CACHED_NODE_STATUS';
export const ADD_ERROR_NODE = 'ADD_ERROR_NODE';
export const CLEAR_ERROR_NODES = 'CLEAR_ERROR_NODES';
export const DELETE_ERROR_NODE = 'DELETE_ERROR_NODE';
export const SET_NAVBAR_WIDTH = 'SET_NAVBAR_WIDTH';
export const CLEAR_CACHED_STATUS = 'CLEAR_CACHED_STATUS';
export const CLEAR_CACHED_NODE_STATUS = 'CLEAR_CACHED_NODE_STATUS';
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const CLEAR_PROJECT_STATE = 'CLEAR_PROJECT_STATE';
export const UPDATE_LAST_UPDATE_MILLESECONDS = 'UPDATE_LAST_UPDATE_MILLESECONDS';
export const SET_CURRENT_JIRA_TICKET = 'SET_CURRENT_JIRA_TICKET';
export const SET_ISSUETYPES_BY_PROJECT = 'SET_ISSUETYPES_BY_PROJECT';
export const SET_GANTT_LINKS = 'SET_GANTT_LINKS';
export const SET_NODES_LOADING = 'SET_NODES_LOADING';