<template>
  <div class="content">
    <template v-if="tabIndex == 0">
      <template v-if="estimationType == 'pert'">
        <div class="tab-content">
          <app-pert-esimation :pertBestCase="node.pertBestCase" :pertProbableCase="node.pertProbableCase"
            :pertWorstCase="node.pertWorstCase" @onFormValid="setPertFormValid" @onChange="changeForm" />
        </div>
      </template>
      <template v-else-if="estimationType == 'in_hours'">
        <div class="tab-content">
          <app-original-estimation :originalEstimateInHours="node.originalEstimateFormatted"
            @onFormValid="setOriginalFormValid" @onChange="changeForm" />
        </div>
      </template>
      <template v-else-if="estimationType == 'story_points'">
        <div class="tab-content">
          <app-story-point-estimation :storyPoint="node.originalEstimateInStoryPoints"
            @onFormValid="setHistoryFormValid" @onChange="changeForm" />
        </div>
      </template>
    </template>
    <template v-if="tabIndex == 1">
      <div class="tab-content">        
        <app-logging-hours-estimation :estimate="freshEstimate" :spent="node.timeSpent"
          :remaining="node.remainingEstimate" :date="node.date" :time="node.time"
          :focusElementName="focusElementName" @onFormValid="setLogginFormValid"
          @onChange="changeForm" />
      </div>
    </template>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from "vue";
import AppPertEsimation from "@/components/wbsProject/estimation/PertEstimation.vue";
import AppOriginalEstimation from "@/components/wbsProject/estimation/OriginalEstimation.vue";
import AppStoryPointEstimation from "@/components/wbsProject/estimation/StoryPointEstimation.vue";
import AppLoggingHoursEstimation from "@/components/wbsProject/estimation/LoggingHoursEstimation.vue";

export default {
  components: {
    AppPertEsimation,
    AppOriginalEstimation,
    AppStoryPointEstimation,
    AppLoggingHoursEstimation,
  },
  inject: ["translate"],
  props: {
    node: {
      type: Object,
      default: () => { },
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    estimationType: {
      type: String,
      required: true,
    },
    focusElementName: {
      type: String,
      default: null,
    },      
  },
  emits: ["onFormValid", "onChange"],
  setup(props, { emit }) {
    const state = reactive({
      isPertEstimationValid: false,
      isOriginalEstimationValid: false,
      isHistoryEstimationValid: false,
      isLogginValid: false,
      estimationType: props.estimationType,
      freshEstimate: props.node.originalEstimateInHours,
      isFormValid: computed(() => {
        if (props.tabIndex === 0) {
          switch (state.estimationType) {
            case "pert":
              return state.isPertEstimationValid;
            case "in_hours":
              return state.isOriginalEstimationValid;
            default:
              return state.isHistoryEstimationValid;
          }
        } else {
          return state.isLogginValid
        }
      }),
    });

    const setPertFormValid = (value) => {
      state.isPertEstimationValid = value;
    };

    const setOriginalFormValid = (value) => {
      state.isOriginalEstimationValid = value;
    };

    const setHistoryFormValid = (value) => {
      state.isHistoryEstimationValid = value;
    };

    const setLogginFormValid = (value) => {
      state.isLogginValid = value;
    };


    const changeForm = (data) => {
      if (data.estimate) state.freshEstimate = data.estimate;
      emit("onChange", data);
    };

    watch(
      () => state.isFormValid,
      (current) => {
        emit("onFormValid", current);
      },
      {
        immediate: true,
      }
    );

    return {
      ...toRefs(state),
      setPertFormValid,
      setOriginalFormValid,
      setHistoryFormValid,
      setLogginFormValid,
      changeForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  margin-top: 15px;
}
</style>
