<template>
    <div
        ref="container"
        class="container"
        :class="{
            'drag-mode': dragMode,
            'animate-off': showAnimate == false,
            'is-loading': isLoading,
        }"
        @mousemove="handleMove"
        @mouseup="handleUp"
        @mousedown="handleDown"
    >
        <p v-if="isLoading" class="please-wait">
            {{ translate("wbs.moving_nodes") }}
        </p>
        <svg
            class="canvas"
            rel="canvas"
            :height="height"
            :width="width"
            @click="unselectNodes"
        >
            <app-link-component
                class="link"
                v-bind="link"
                :scale="scene.scale"
                :centerX="scene.centerX"
                :centerY="scene.centerY"
                v-for="(link, index) in lines"
                :key="`link${index}`"
                :class="{
                    hidden: !visibleNodes.includes(link.to),
                    'drag-mode': dragMode,
                }"
                :style="{
                    //opacity: hoverNodes.length ? (!hoverNodes.includes(link.from) ? 0.5 : 1) : 1,
                }"
                @deleteLink="linkDelete(link.id)"
            ></app-link-component>
        </svg>
        <div class="float-orphaned-container">
            <app-orphaned-component
                :orphanedNodesContainerLeft="orphanedNodesContainerLeft"
                :nodes="scene.nodes"
                :scale="scene.scale"
                :nodeOptions="nodeOptions"
                :compactMode="isOrphanedNodesViewCompact"
                @onChange="setOrphanedNodes"
                @onChangeView="changeOrphanedNodesView"
                @onDragstart="onDragstart"
                @onDeleteNode="({ id, e }) => beforeNodeDelete(id, e, true)"
            >
                <template v-slot:default="{ node }">
                    <slot :node="node"></slot>
                </template>
            </app-orphaned-component>
        </div>
        <div
            v-if="isShowPlaceHolder"
            class="placeholder-area"
            :style="{
                top: `${defaultLocationY}px`,
                transform: `scale(${scene.scale})`,
            }"
        >
            <div
                class="drag-node-placeholder"
                :class="{ 'is-hover': isPlaceHolderHover }"
                :style="{
                    width: `${nodeWidth}px`,
                    height: `${nodeHeight}px`,
                }"                
                @click="addNewNode"
                @dragenter.prevent="dragEnterPlaceHolder"
                @dragover.prevent
                @drop="dropPlaceHolder"
                @dragleave="dragLeavePlaceHolder"
            >
                <icon-add-node
                    :stroke="isPlaceHolderHover ? '#0f8af9' : '#A1A9BA'"
                    :width="'24px'"
                    :height="'24px'"
                />
            </div>
        </div>
        <app-node-component
            v-for="node in nodes"
            :id="node.id"
            :key="node.id"
            :childrenCount="node.childrenCount"
            :isRoot="node.topNode"
            :isParent="node.parent ? true : false"
            :centerX="nodeOptions.centerX"
            :centerY="nodeOptions.centerY"
            :scale="nodeOptions.scale"
            :isUpdate="node.isUpdate"
            :x="node.x"
            :y="node.y"
            :isSelected="selectedNodes.includes(node.id)"
            :style="{
                width: `${nodeWidth}px`,
                height: `${nodeHeight}px`,
                zIndex: selectedNodes.includes(node.id) ? 100 : 0,
            }"
            class="node"
            :class="{
                hidden: !visibleNodes.includes(node.id),
                'drag-mode': dragMode,
                edited: node.edited,
            }"
            @linkingStart="linkingStart(node.id)"
            @linkingStop="linkingStop(node.id)"
            @mousedown="nodeMouseDown(node.id, $event)"
            @mouseup="nodeMouseUp(node.id, $event)"
            @onDragEnter="onDragEnter"
            @onDrop="onDrop"
            @onDragleave="onDragleave"
        >
            <slot :node="node"></slot>
        </app-node-component>
        <!--clone nodes-->

        <app-node-component
            :childrenCount="node.childrenCount"
            :isParent="node.parent ? true : false"
            :centerX="nodeOptions.centerX"
            :centerY="nodeOptions.centerY"
            :scale="nodeOptions.scale"
            :x="node.x"
            :y="node.y"
            class="clone-node"
            v-for="(node, index) in cloneNodes"
            :key="`node${index}`"
            :id="node.id"
            v-show="isMouseMove"
            :style="{
                width: `${nodeWidth}px`,
                height: `${nodeHeight}px`,
                zIndex: 999,
                marginTop: `${15 * index}px`,
                marginLeft: `${15 * index}px`,
                opacity: nodes.some((n) => n.isChangeOrder == true) ? 0.8 : 1,
            }"
            :options="nodeOptions"
        >
            <slot :node="node"></slot>
        </app-node-component>
    </div>
</template>
<script>
import {
    reactive,
    computed,
    onMounted,
    ref,
    toRefs,
    nextTick,
    onUnmounted,
    watch,
    provide,
} from "vue";
import { usePositionHelper } from "@/helpers/positionHelper";
import AppNodeComponent from "@/components/treeView/NodeComponent.vue";
import AppLinkComponent from "@/components/treeView/LinkComponent.vue";
import IconArrowDown from "@/components/icons/IconArrowDown.vue";
import IconAddNode from "@/components/icons/IconAddNode.vue";
import * as wbsConst from "@/store/modules/wbs/const";
import AppOrphanedComponent from "@/components/wbsProject/orphaned/OrphanedComponent.vue";
export default {
    components: {
        AppNodeComponent,
        AppLinkComponent,
        IconArrowDown,
        IconAddNode,
        AppOrphanedComponent,
    },
    inject: ["translate"],
    props: {
        scene: {
            type: Object,
            default() {
                return {
                    centerX: 1024,
                    scale: 1,
                    centerY: 140,
                    nodes: [],
                    links: [],
                };
            },
        },
        nodeWidth: {
            type: Number,
            default: wbsConst.nodeWidth,
        },
        nodeHeight: {
            type: Number,
            default: wbsConst.nodeHeight,
        },
        moveNodes: {
            type: Boolean,
            default: false,
        },
        isOrphanedCollapsed: {
            type: Boolean,
            default: false,
        },
        filteredNodes: {
            type: Array,
            default: [],
        },
        invisibleFilteredNodes: {
            type: Array,
            default: [],
        },
        treeView: {
            type: String,
            default: "horizontal",
            validator: function (value) {
                return ["horizontal", "vertical"].includes(value);
            },
        },
        zoom: {
            type: Number,
            default: 8,
        },
        allowedDragNodes: {
            type: Array,
            default: [],
        },
        defaultLocationX: {
            type: Number,
            default: 10,
        },
        defaultLocationY: {
            type: Number,
            default: 40,
        },
        disabledMoveClassList: {
            type: Array,
            default: ["title", "status", "ava", "total", "show-children"],
        },
        allowNewParentNode: {
            type: Number,
            default: null,
        },
    },
    emits: [
        "onCanvasClick",
        "onNodeClick",
        "onBeforeNodeDelete",
        "onBeforeNodeDeleteOrphaned",
        "onNodeDelete",
        "onChangeZoom",
        "onChangeStructure",
        "onChangeOrder",
        "onScrollingStart",
        "onScrollingEnd",
        "onMoveNode",
        "onCollapsedOrphaned",
        "onAddNewNode",
        "setOrphanedNodes",
    ],
    setup(props, { emit }) {
        const { getMousePosition } = usePositionHelper();
        const container = ref(null),
            orphanedNodesContainerLeft = ref(null),
            isOrphanedNodesViewCompact = ref(!props.isOrphanedCollapsed),
            orphanedNodesList = ref(),
            ctrlMode = ref(false),
            shiftMode = ref(false),
            data = reactive({
                ...props,
                action: {
                    linking: false,
                    dragging: false,
                    scrolling: false,
                    selected: 0,
                },
                mouse: {
                    x: 0,
                    y: 0,
                    lastX: 0,
                    lastY: 0,
                },
                draggingLink: null,
                rootDivOffset: {
                    top: 0,
                    left: 0,
                },
                isMouseDownNode: false,
                cloneNodes: [],
                hoverOnNode: null,
                selectedNode: null,
            });
        const showAnimate = ref(false),
            isLoading = ref(false);

        data.treeView = ref(props.treeView);
        provide(
            "treeConfig",
            reactive({ treeView: computed(() => data.treeView) })
        );
        let disableMove = false;

        const selectedNodes = ref([]),
            isPlaceHolderHover = ref(false),
            isShowPlaceHolder = computed(() => {                
                return !nodes.value.length;
            });

        let targetNode = null;

        const hoverNodes = computed(() => {
            const result = [];
            function recursive(id) {
                for (let i = 0; i < props.scene.links.length; i++) {
                    const link = props.scene.links[i];
                    if (link.from === id) {
                        result.push(link.to);
                        recursive(link.to);
                    }
                }
            }
            if (data.isMouseDownNode) {
                recursive(data.isMouseDownNode);
                result.unshift(data.isMouseDownNode);
                return result;
            }
            return [];
        });

        const visibleNodes = computed(() => {
            return props.scene.nodes
                .filter((n) => n.visible === true)
                .map((n) => n.id);
        });

        const orphanedNodes = ref([]);

        const nodes = computed(() => {
            const getNodes = () => {
                if (Object.keys(data.scene.links).length > 0) {
                    const rootNode = data.scene.nodes.find(
                        (n) => n.topNode == true
                    );
                    if (rootNode) {
                        rootNode.isRoot = true;
                        rootNode.isOrphanedChild = false;
                        const children = getChildren(rootNode.id);
                        const childrenNodes = [];
                        for (let i = 0; i < children.length; i++) {
                            const child = children[i];
                            const node = data.scene.nodes.find(
                                (n) => n.id == child.id
                            );
                            if (node) {
                                node.isOrphanedChild = false;
                                childrenNodes.push(node);
                            }
                        }
                        childrenNodes.unshift(rootNode);
                        return childrenNodes;
                    } else return [];
                } else {
                    const list = data.scene.nodes.filter((n) => n.parentId);
                    return list.length
                        ? list
                        : data.scene.nodes.filter((n) => n.topNode == true);
                }
            };
            return getNodes().filter(
                (n) => !props.invisibleFilteredNodes.includes(n.id)
            );
        });

        const nodeOptions = computed(() => {
            return {
                centerY: data.scene.centerY,
                centerX: data.scene.centerX,
                scale: data.scene.scale,
                offsetTop: data.rootDivOffset.top,
                offsetLeft: data.rootDivOffset.left,
                selected: data.action.selected,
            };
        });

        const lines = computed(() => {
            let result = data.scene.links.reduce((acc, link) => {
                const fromNode = findNodeWithID(link.from);
                const toNode = findNodeWithID(link.to);
                acc = acc || [];

                if (fromNode && toNode) {
                    let x, y, cy, cx, ex, ey;
                    x = data.scene.centerX + fromNode.x * data.scene.scale;
                    y = data.scene.centerY + fromNode.y * data.scene.scale;
                    [cx, cy] = getPortPosition("output", x, y, fromNode);
                    x = data.scene.centerX + toNode.x * data.scene.scale;
                    y = data.scene.centerY + toNode.y * data.scene.scale;
                    [ex, ey] = getPortPosition("input", x, y, toNode);
                    let isLast = false;
                    if (toNode.parent && toNode.parent.children) {
                        const lastNode =
                            toNode.parent.children[
                                toNode.parent.children.length - 1
                            ];
                        if (lastNode && lastNode.id == toNode.id) isLast = true;
                    }

                    if (data.treeView == "horizontal") {
                        if (fromNode.y == toNode.y) {
                            acc.push({
                                start: [cx, cy],
                                end: [ex, ey],
                                id: link.id,
                                from: fromNode.id,
                                fromY: fromNode.y,
                                toY: toNode.y,
                                to: toNode.id,
                                codeOfAccounts: toNode.codeOfAccounts,
                                isShiftedParent: fromNode?.isShifted,
                            });
                        } else {
                            acc.push({
                                start: [ex - 35 * data.scene.scale, ey],
                                end: [ex, ey],
                                id: link.id,
                                from: fromNode.id,
                                fromY: fromNode.y,
                                toY: toNode.y,
                                to: toNode.id,
                                codeOfAccounts: toNode.codeOfAccounts,
                                isCurved: true,
                                isLine: false,
                                isLast,
                            });
                        }
                    } else {
                        if (fromNode.x == toNode.x) {
                            acc.push({
                                start: [cx, cy],
                                end: [ex, ey],
                                id: link.id,
                                from: fromNode.id,
                                fromY: fromNode.y,
                                toY: toNode.y,
                                codeOfAccounts: toNode.codeOfAccounts,
                                to: toNode.id,
                            });
                        } else {
                            acc.push({
                                start: [ex, ey - 35 * data.scene.scale],
                                end: [ex, ey],
                                id: link.id,
                                from: fromNode.id,
                                fromY: fromNode.y,
                                toY: toNode.y,
                                to: toNode.id,
                                codeOfAccounts: toNode.codeOfAccounts,
                                isCurved: true,
                                isLast,
                            });
                        }
                    }
                }
                return acc;
            }, []);

            // add line
            const groupedCoords = result.reduce((acc, value) => {
                if (!acc[value.from]) {
                    acc[value.from] = [];
                }
                acc[value.from].push(value);

                return acc;
            }, {});

            Object.keys(groupedCoords).forEach((k) => {
                groupedCoords[k].sort(function (a, b) {
                    let codeAccountA = a.codeOfAccounts
                        ? a.codeOfAccounts.toString()
                        : "";
                    let codeAccountB = b.codeOfAccounts
                        ? b.codeOfAccounts.toString()
                        : "";
                    if (codeAccountA && codeAccountB) {
                        codeAccountA =
                            codeAccountA.indexOf(".") !== -1
                                ? codeAccountA.split(".").slice(-1)[0]
                                : codeAccountA;

                        codeAccountB =
                            codeAccountB.indexOf(".") !== -1
                                ? codeAccountB.split(".").slice(-1)[0]
                                : codeAccountB;
                        return codeAccountA - codeAccountB;
                    }
                });

                const coords = JSON.parse(
                    JSON.stringify(
                        groupedCoords[k][groupedCoords[k].length - 1]
                    )
                );

                let fromNode = findNodeWithID(coords.from);
                let toNode = findNodeWithID(coords.to);

                const children = getChildren(fromNode.id, false).filter(
                    (c) => !props.invisibleFilteredNodes.includes(c.id)
                );

                if (children && children.length > 1) {
                    let x, y, cy, cx, ex, ey;

                    x = data.scene.centerX + fromNode.x * data.scene.scale;
                    y = data.scene.centerY + fromNode.y * data.scene.scale;
                    [cx, cy] = getPortPosition("output", x, y, fromNode);

                    x = data.scene.centerX + toNode.x * data.scene.scale;
                    y = data.scene.centerY + toNode.y * data.scene.scale;
                    [ex, ey] = getPortPosition("input", x, y, toNode);

                    if (data.treeView == "horizontal") {
                        coords.start = [cx + 35 * data.scene.scale, cy];
                        coords.end = [ex, ey - cy - 35 * data.scene.scale];
                    } else {
                        coords.start = [cx, cy + 35 * data.scene.scale];
                        coords.end = [ey, ex - cx - 35 * data.scene.scale];
                    }
                    coords.isLine = true;
                    coords.isCurved = false;
                    coords.isLast = false;
                    result.push(coords);
                }
            });

            return result;
        });

        const findNodeWithID = (id) => {
            return nodes.value.find((item) => {
                return id === item.id;
            });
        };

        const getPortPosition = (type, x, y, fromNode) => {
            switch (data.treeView) {
                case "horizontal":
                    if (type === "input") {
                        return [
                            x,
                            y + (props.nodeHeight * props.scene.scale) / 2,
                        ];
                    } else if (type === "output") {
                        return [
                            x + props.nodeWidth * props.scene.scale,
                            y + (props.nodeHeight * props.scene.scale) / 2,
                        ];
                    }
                    break;
                case "vertical":
                    if (type === "input") {
                        return [
                            x + (props.nodeWidth * props.scene.scale) / 2,
                            y,
                        ];
                    } else if (type === "output") {
                        return [
                            x + (props.nodeWidth * props.scene.scale) / 2,
                            y + props.nodeHeight * props.scene.scale,
                        ];
                    }
                    break;
            }
        };

        const createTree = (dataset) => {
            const hashTable = Object.create(null);
            dataset.forEach(
                (aData) => (hashTable[aData.id] = { ...aData, children: [] })
            );
            const dataTree = [];

            if (Object.keys(hashTable).length) {
                dataset.forEach((aData) => {
                    if (aData.parent && hashTable[aData.parent])
                        hashTable[aData.parent].children.push(
                            hashTable[aData.id]
                        );
                    else dataTree.push(hashTable[aData.id]);
                });
            }
            return dataTree;
        };

        const arrangeNodes = (updateOrder = false) => {
            if (!nodes.value) return;
            const rootNode = nodes.value.find((n) => n.topNode == true);
            if (!rootNode) return;
            let childrenNodes = [];
            const children = getChildren(rootNode.id);

            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                const node = data.scene.nodes.find((n) => n.id == child.id);
                node.parent = data.scene.nodes.find(
                    (n) => n.id == child.parent
                );

                if (node.visible && node.parent.showChildren) {
                    node.x = 0;
                    node.y = 0;
                    childrenNodes.push(node);
                } else {
                    node.visible = false;
                    hideChildren(node.id);
                }
            }

            childrenNodes = childrenNodes.filter(
                (n) => !props.invisibleFilteredNodes.includes(n.id)
            );

            const list = children.filter(
                (n) => !props.invisibleFilteredNodes.includes(n.id)
            );

            list.unshift({ id: rootNode.id });
            list.forEach((l) => {
                const node = data.scene.nodes.find((n) => n.id == l.id);
                l.codeOfAccounts = node.codeOfAccounts;
            });

            const tree = createTree(list);

            const shiftPosition = (node) => {
                for (let j = 0; j < childrenNodes.length; j++) {
                    const child = childrenNodes[j];

                    switch (data.treeView) {
                        case "horizontal":
                            if (
                                child.id !== node.id &&
                                child.x == node.x &&
                                (child.y == node.y ||
                                    child.y + props.nodeHeight > node.y)
                            ) {
                                node.y += props.nodeHeight + 22;
                                node.isShifted = true;
                                shiftPosition(node);
                            }
                            break;
                        case "vertical":
                            if (
                                child.id !== node.id &&
                                child.y == node.y &&
                                (child.x == node.x ||
                                    child.x + props.nodeWidth > node.x)
                            ) {
                                node.x += props.nodeWidth + 22;
                                shiftPosition(node);
                            }
                            break;
                    }
                }
            };

            const setPosition = (treeList) => {
                for (let i = 0; i < treeList.length; i++) {
                    const n = treeList[i];

                    const node = childrenNodes.find((fn) => fn.id == n.id);
                    if (node && node.id) {
                        const parent = node.parent;

                        switch (data.treeView) {
                            case "horizontal":
                                node.x = parent.x + props.nodeWidth + 70;
                                node.y = parent.y + props.nodeHeight * i * 1.1;
                                break;

                            case "vertical":
                                node.y = parent.y + props.nodeHeight + 70;
                                node.x = props.nodeWidth * i * 1.1 + parent.x;
                                break;
                        }
                        if (updateOrder && parent.codeOfAccounts) {
                            const parentCodeOfAccounts = parent.codeOfAccounts;
                            node.codeOfAccounts =
                                parentCodeOfAccounts != 0
                                    ? parentCodeOfAccounts + "." + (i + 1)
                                    : i + 1;
                        }

                        shiftPosition(node);
                    }
                    setPosition(n.children);
                }
            };

            setPosition(tree);
            calcHeight();
        };

        const getChildren = (id, recursion = true) => {
            const children = [];
            function rec(id) {
                for (let i = 0; i < props.scene.links.length; i++) {
                    const link = props.scene.links[i];
                    if (link.from === id) {
                        children.push({
                            id: link.to,
                            parent: id,
                        });

                        if (recursion) rec(link.to);
                    }
                }
            }
            rec(id);
            return children;
        };

        const linkingStart = (index) => {
            data.action.linking = true;
            data.draggingLink = {
                from: index,
                mx: 0,
                my: 0,
            };
        };

        const linkingStop = (index) => {
            // add new Link
            if (data.draggingLink && data.draggingLink.from !== index) {
                // check link existence
                const existed = data.scene.links.find((link) => {
                    return (
                        link.from === data.draggingLink.from &&
                        link.to === index
                    );
                });
                if (!existed) {
                    let maxID = Math.max(
                        0,
                        ...data.scene.links.map((link) => {
                            return link.id;
                        })
                    );
                    const newLink = {
                        id: maxID + 1,
                        from: data.draggingLink.from,
                        to: index,
                    };
                    data.scene.links.push(newLink);
                }
            }
            data.draggingLink = null;
        };

        const linkDelete = (id) => {
            const deletedLink = data.scene.links.find((item) => {
                return item.id === id;
            });
            if (deletedLink) {
                data.scene.links = data.scene.links.filter((item) => {
                    return item.id !== id;
                });
            }
        };

        const nodeMouseDown = (id, e) => {
            if (e.button !== 0) return;

            const path = e.path || (e.composedPath && e.composedPath());
            for (let i = 0; i < props.disabledMoveClassList.length; i++) {
                const item = props.disabledMoveClassList[i];
                if (
                    path.some((p) => {
                        return p.classList && p.classList.contains(item);
                    })
                ) {
                    disableMove = true;
                    return;
                }
            }

            data.action.dragging = id;
            data.action.selected = id;

            if (
                !ctrlMode.value &&
                !shiftMode.value &&
                (selectedNodes.value.length == 0 ||
                    selectedNodes.value.length == 1)
            ) {
                selectedNodes.value = [];
                selectedNodes.value.push(id);
            } else if (
                !ctrlMode.value &&
                !shiftMode.value &&
                !selectedNodes.value.some((r) => r == id)
            ) {
                selectedNodes.value.length = 0;
                selectedNodes.value.push(id);
            }

            data.mouse.lastX =
                e.pageX || e.clientX + document.documentElement.scrollLeft;
            data.mouse.lastY =
                e.pageY || e.clientY + document.documentElement.scrollTop;
            data.isMouseDownNode = id;
            const { left: containerLeft } =
                container.value.getBoundingClientRect();
            const { left: orphanedContainerLeft } = orphanedNodesContainer.value
                ? orphanedNodesContainer.value.getBoundingClientRect()
                : 0;

            data.cloneNodes = JSON.parse(
                JSON.stringify(
                    [...nodes.value, ...orphanedNodes.value]
                        .filter((n) => selectedNodes.value.indexOf(n.id) !== -1)
                        .map((n) => {
                            let left = n.isOrphaned
                                ? orphanedContainerLeft -
                                  containerLeft -
                                  data.scene.centerX
                                : n.x;
                            let top = n.y - data.scene.centerY;
                            return {
                                ...n,
                                ...{
                                    x: n.isOrphaned
                                        ? (left /= data.scene.scale)
                                        : n.x,
                                    y: n.isOrphaned
                                        ? (top /= data.scene.scale) -
                                          orphanedNodesList.value.scrollTop /
                                              data.scene.scale
                                        : n.y,
                                },
                            };
                        })
                )
            );

            emit("onNodeClick", id);
        };

        const nodeMouseUp = (id, e) => {
            if (!ctrlMode.value && selectedNodes.value.length > 0) {
                selectedNodes.value = [];
                selectedNodes.value.push(id);
            }
            return;
            // multi selected node
            if (selectedNodes.value.indexOf(id) !== -1 && ctrlMode.value) {
                selectedNodes.value.splice(selectedNodes.value.indexOf(id), 1);
            } else if (
                selectedNodes.value.indexOf(id) !== -1 &&
                !ctrlMode.value &&
                !shiftMode.value
            ) {
                selectedNodes.value.length = 0;
                selectedNodes.value.push(id);
            } else if (ctrlMode.value && !shiftMode.value) {
                const firstNode = selectedNodes.value.length
                    ? nodes.value.find(({ id }) => id == selectedNodes.value[0])
                    : null;
                selectedNodes.value.push(id);
                if (firstNode) {
                    selectedNodes.value = selectedNodes.value.filter(
                        (selId) =>
                            nodes.value.find(({ id }) => id == selId)
                                .parentId == firstNode.parentId
                    );
                }
            } else if (!ctrlMode.value && shiftMode.value) {
                const firstNode = nodes.value.find(
                    ({ id }) => id == selectedNodes.value[0]
                );
                const startIndex = nodes.value
                        .filter((n) => n.parentId == firstNode.parentId)
                        .findIndex(({ id }) => id == selectedNodes.value[0]),
                    sourceId = id,
                    endIndex = nodes.value
                        .filter((n) => n.parentId == firstNode.parentId)
                        .findIndex(({ id }) => id == sourceId);

                selectedNodes.value = nodes.value
                    .filter((n) => n.parentId == firstNode.parentId)
                    .filter(
                        (r, i) =>
                            (i >= startIndex && i <= endIndex) ||
                            (i <= startIndex && i >= endIndex)
                    )
                    .map(({ id }) => id);
            }
        };

        const dragMode = ref(null);
        const isMouseMove = ref(false);
        const height = ref("100vh");
        const width = ref("100vw");
        const calcHeight = () => {
            setTimeout(() => {
                height.value = 0;
                nextTick(() => {
                    height.value = document.body.scrollHeight + "px";
                });
            }, 1000);
            calcWidth();
        };

        const calcWidth = () => {
            setTimeout(() => {
                width.value = 0;
                nextTick(() => {
                    width.value = document.body.scrollWidth + "px";
                });
            }, 1000);
        };

        const handleMove = (e) => {
            if (data.action.linking) {
                [data.mouse.x, data.mouse.y] = getMousePosition(
                    container.value,
                    e
                );
                [data.draggingLink.mx, data.draggingLink.my] = [
                    data.mouse.x,
                    data.mouse.y,
                ];
            }

            if (data.action.dragging) {
                if (disableMove) return;
                const node = data.scene.nodes.find(
                    (n) => n.id == data.action.selected
                );
                if (!node) return;
                if (node?.isOrphanedChild) return;

                data.mouse.x =
                    e.pageX || e.clientX + document.documentElement.scrollLeft;
                if (data.action.isOrphaned) {
                    data.mouse.y = e.pageY + document.documentElement.scrollTop;
                } else {
                    data.mouse.y =
                        e.pageY ||
                        e.clientY + document.documentElement.scrollTop;
                }

                let diffX = data.mouse.x - data.mouse.lastX;
                let diffY = data.mouse.y - data.mouse.lastY;
                data.mouse.lastX = data.mouse.x;
                data.mouse.lastY = data.mouse.y;
                isMouseMove.value = true;

                moveSelectedNode(diffX, diffY);
            }

            if (data.action.scrolling) {
                [data.mouse.x, data.mouse.y] = getMousePosition(
                    container.value,
                    e
                );
                let diffX = data.mouse.x - data.mouse.lastX;
                let diffY = data.mouse.y - data.mouse.lastY;
                data.mouse.lastX = data.mouse.x;
                data.mouse.lastY = data.mouse.y;
                data.scene.centerX += diffX;
                data.scene.centerY += diffY;
                dragMode.value = true;
                calcHeight();
            }
        };

        const handleUp = (e) => {
            isMouseMove.value = false;
            dragMode.value = false;
            disableMove = false;
            const target = e.target || e.srcElement;
            if (container.value.contains(target)) {
                if (
                    typeof target.className !== "string" ||
                    target.className.indexOf("node-input") < 0
                ) {
                    data.draggingLink = null;
                }
                if (
                    typeof target.className === "string" &&
                    target.className.indexOf("node-delete") > -1
                ) {
                    beforeNodeDelete(data.action.dragging, e);
                }
            }

            // create link after drag & drop
            if (
                targetNode &&
                targetNode.visible &&
                data.action.dragging &&
                targetNode.id !== data.action.dragging
            ) {
                const oldLinks = JSON.parse(JSON.stringify(data.scene.links));
                // change order
                if (targetNode.isChangeOrder) {
                    function moveElement(array, initialIndex, finalIndex) {
                        array.splice(
                            finalIndex,
                            0,
                            array.splice(initialIndex, 1)[0]
                        );
                        return array;
                    }

                    data.cloneNodes.forEach((cloneNode) => {
                        const fromIndex = data.scene.links.findIndex(
                            (n) => n.to == cloneNode.id
                        );
                        const toIndex = data.scene.links.findIndex(
                            (n) => n.to == targetNode.id
                        );
                        moveElement(data.scene.links, fromIndex, toIndex);

                        arrangeNodes(true);

                        const movedNode = data.scene.nodes.find(
                            (n) => n.id == cloneNode.id
                        );
                        emit("onChangeOrder", {
                            node: movedNode,
                        });
                    });
                } else {
                    let isAccepNewParent = false;
                    // drag & drop
                    data.cloneNodes.forEach((node) => {
                        const link = data.scene.links.find(
                            (l) => l.to == node.id
                        );
                        if (!link) {
                            const maxID = Math.max(
                                0,
                                ...data.scene.links.map((link) => {
                                    return link.id;
                                })
                            );
                            if (targetNode.isAccepNewParent) {
                                isAccepNewParent = true;
                                data.scene.links.push({
                                    id: maxID + 1,
                                    to: targetNode.id,
                                    from: node.id,
                                });
                            } else {
                                data.scene.links.push({
                                    id: maxID + 1,
                                    from: targetNode.id,
                                    to: node.id,
                                });
                            }
                        } else {
                            link.from = targetNode.id;
                        }
                    });
                    arrangeNodes();
                    if (isAccepNewParent) {
                        const node = data.scene.nodes.find(
                            (n) => n.id == data.cloneNodes[0].id
                        );

                        node.topNode = true;
                        node.isOrphaned = false;
                        node.codeOfAccounts = 0;
                        node.visible = true;

                        targetNode.topNode = false;
                        if (
                            targetNode.parentId &&
                            targetNode.parentId == node.id
                        )
                            targetNode.parentId = node.id;

                        targetNode.topNodeId = node.id;

                        emit("onChangeStructure", {
                            oldLinks: null,
                            newLinks: null,
                            rootNode: targetNode,
                        });
                    } else {
                        emit("onChangeStructure", {
                            oldLinks: oldLinks,
                            newLinks: data.scene.links,
                        });
                    }
                }
            }

            data.action.linking = false;
            data.action.dragging = null;
            data.action.scrolling = false;
            data.action.isOrphaned = false;
            data.cloneNodes = [];
            data.hoverOnNode = null;
            data.isMouseDownNode = false;
            targetNode = null;

            data.scene.nodes = data.scene.nodes.map((n) => {
                return {
                    ...n,
                    ...{
                        isSuccessHover: null,
                        isChangeOrder: null,
                        dragDirection: null,
                        isAccepNewParent: false,
                    },
                };
            });
        };

        const handleDown = (e) => {
            const target = e.target || e.srcElement;
            if (
                (target === container.value ||
                    target.classList.contains("canvas")) &&
                e.which === 1
            ) {
                data.action.scrolling = true;
                [data.mouse.lastX, data.mouse.lastY] = getMousePosition(
                    container.value,
                    e
                );
                data.action.selected = null; // deselectAll
            }
            const path = e.path || (e.composedPath && e.composedPath());
            if (
                path.some((p) => {
                    return (
                        p.classList && p.classList.contains("orphaned-nodes")
                    );
                })
            ) {
                data.action.isOrphaned = true;
            }

            emit("onCanvasClick", e);
        };
        const orphanedNodesContainer = ref(null);

        const moveSelectedNode = (dx, dy) => {
            const index = data.cloneNodes.findIndex((item) => {
                return item.id === data.action.dragging;
            });

            let moveNode = data.cloneNodes[index];

            if (!moveNode || moveNode.edited) return;

            let left = moveNode.x + dx / data.scene.scale;
            let top = moveNode.y + dy / data.scene.scale;

            for (let i = 0; i < data.cloneNodes.length; i++) {
                const node = data.cloneNodes[i];
                if (node.isDragBlocked && !node.isOrphaned) continue;
                node.x = left;
                node.y = top;
            }

            data.hoverOnNode = null;
            data.scene.nodes.forEach((n) => {
                n.isSuccessHover = null;
                n.isChangeOrder = null;
                n.isAccepNewParent = false;
            });

            targetNode = null;

            for (const node of nodes.value) {
                if (
                    node.visible &&
                    left + props.nodeWidth / 2 > node.x &&
                    left + props.nodeWidth / 2 < node.x + props.nodeWidth &&
                    top + props.nodeHeight / 2 > node.y &&
                    top + props.nodeHeight / 2 < node.y + props.nodeHeight
                ) {
                    targetNode = false;
                    data.hoverOnNode = node.id;
                    node.isSuccessHover = false;
                    node.isChangeOrder = false;
                    node.isAccepNewParent = false;

                    if (node.edited) node.isSuccessHover = false;
                    for (let j = 0; j < data.cloneNodes.length; j++) {
                        const cloneNode = data.cloneNodes[j];
                        if (cloneNode.id === node.id) {
                            node.isSuccessHover = false;
                        } else {
                            targetNode = node;
                            targetNode.isSuccessHover = true;

                            if (props.allowNewParentNode === targetNode.id)
                                targetNode.isAccepNewParent = true;
                            if (data.treeView == "vertical") {
                                if (left < targetNode.x) {
                                    targetNode.dragDirection = "left";
                                }
                                if (left > targetNode.x) {
                                    targetNode.dragDirection = "right";
                                }
                            } else {
                                if (top < targetNode.y) {
                                    targetNode.dragDirection = "top";
                                }
                                if (top > targetNode.y) {
                                    targetNode.dragDirection = "bottom";
                                }
                            }

                            if (
                                targetNode.parentId == cloneNode.parentId &&
                                !cloneNode?.isOrphaned
                            ) {
                                targetNode.isSuccessHover = null;
                                node.isAccepNewParent = false;
                                node.isChangeOrder = true;
                            }
                        }
                    }
                    emit("onMoveNode", targetNode);
                    if (!props.allowedDragNodes.some((n) => n == moveNode.id)) {
                        node.isSuccessHover = false;
                        targetNode = false;
                        break;
                    }
                    if (node.isSuccessHover == false) {
                        targetNode = false;
                        break;
                    }
                }
            }
        };

        const beforeNodeDelete = (id, e, isOrphaned = false) => {
            if (isOrphaned) {
                emit("onBeforeNodeDeleteOrphaned", id, e);
            } else {
                emit("onBeforeNodeDelete", id, e);
            }
        };

        const nodeDelete = (id) => {
            data.scene.nodes = data.scene.nodes.filter((node) => {
                return node.id !== id;
            });

            const children = getChildren(id);
            children.forEach((c) => {
                const node = data.scene.nodes.find((n) => n.id === c.id);
                node.parent = null;
                data.scene.links = data.scene.links.filter((link) => {
                    return link.from !== node.id && link.to !== node.id;
                });
            });

            data.scene.links = data.scene.links.filter((link) => {
                return link.from !== id && link.to !== id;
            });

            nextTick(() => arrangeNodes());
            emit("onNodeDelete", id);
        };

        const hideChildren = (id) => {
            const children = getChildren(id);
            children.forEach((child) => {
                const node = data.scene.nodes.find((cn) => cn.id == child.id);
                if (node.parent) {
                    node.x = node.parent?.x || 0;
                    node.y = node.parent?.y || 0;
                }
                node.visible = false;
            });
        };

        const showChildren = (id, visible, showAnimate = true) => {
            if (showAnimate) setShowAnimateInterval(200);

            const node = data.scene.nodes.find((n) => n.id === id);
            if (!node) return;

            if (visible == true) {
                const sameLevelNodes = data.scene.nodes.filter(
                    (n) => n.parentId == node.parentId
                );

                sameLevelNodes.forEach((n) => {
                    n.showChildren = false;
                    const children = getChildren(n.id);
                    children.forEach((child) => {
                        const node = data.scene.nodes.find(
                            (cn) => cn.id == child.id
                        );
                        if (node && node.parent) {
                            node.x = node.parent?.x || 0;
                            node.y = node.parent?.y || 0;
                        }
                        if (node) node.visible = false;
                    });
                });
            }

            node.showChildren = visible;
            arrangeNodes();

            const children = getChildren(node.id);
            children.forEach((child) => {
                const node = data.scene.nodes.find((n) => n.id == child.id);
                node.x = node.parent.x;
                node.y = node.parent.y;

                if (
                    node.parent.showChildren &&
                    visible &&
                    node.parent.visible
                ) {
                    node.visible = true;
                } else {
                    node.visible = false;
                }
            });
            arrangeNodes();
        };

        const zoomList = [
            0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.3, 1.4, 1.5,
            1.6,
        ];

        const setShowAnimateInterval = (interval = 300) => {
            showAnimate.value = true;
            setTimeout(() => {
                showAnimate.value = false;
            }, interval);
        };

        let zoomInd = ref(props.zoom);
        const zoomIn = () => {
            if (zoomInd.value < zoomList.length - 1) zoomInd.value++;
            setShowAnimateInterval();
            data.scene.scale = zoomList[zoomInd.value];
            emit("onChangeZoom", zoomInd.value);
        };

        const setZoom = (value) => {
            setShowAnimateInterval();
            zoomInd.value = value;
            data.scene.scale = zoomList[zoomInd.value];
        };

        const zoomReset = () => {
            setShowAnimateInterval();
            zoomInd.value = 8;
            data.scene.scale = 1;
            emit("onChangeZoom", zoomInd.value);
        };

        const zoomOut = () => {
            if (zoomInd.value > 0) zoomInd.value--;
            setShowAnimateInterval();
            data.scene.scale = zoomList[zoomInd.value];
            emit("onChangeZoom", zoomInd.value);
        };

        watch(
            () => zoomInd.value,
            () => {
                const { width } = container.value.getBoundingClientRect();
                orphanedNodesContainerLeft.value =
                    width - props.nodeWidth * data.scene.scale;
            }
        );

        watch(
            () => props.invisibleFilteredNodes,
            () => arrangeNodes()
        );

        watch(
            () => data.action.scrolling,
            (current) => {
                if (current == true) emit("onScrollingStart", current);
                else emit("onScrollingEnd", current);
            }
        );

        const addKeyDownEventListener = (e) => {
            if (e.keyCode == 32 && e.target == document.body) {
                e.preventDefault();
                return false;
            }
            const isOSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
            if (isOSX && e.metaKey) {
                ctrlMode.value = true;
            }
            if (!isOSX && e.key == "Control") {
                ctrlMode.value = true;
            }
            if (e.key == "Shift") {
                shiftMode.value = true;
            }
        };

        const addKeyUpEventListener = () => {
            ctrlMode.value = false;
            shiftMode.value = false;
        };

        const addDocumentBlurListener = () => {
            ctrlMode.value = false;
            shiftMode.value = false;
        };

        const addMouseWheelListener = (e) => {
            if (ctrlMode.value) {
                e.preventDefault();
                let delta = 0;
                if (e.deltaY !== 0) {
                    delta = e.deltaY > 0 ? 100 : -100;
                }
                if (delta < 0) zoomIn();
                else zoomOut();
            }
        };

        const changeOrphanedNodesView = () => {
            isOrphanedNodesViewCompact.value =
                !isOrphanedNodesViewCompact.value;
            emit("onCollapsedOrphaned", !isOrphanedNodesViewCompact.value);
        };

        const unselectNodes = () => {
            selectedNodes.value = [];
        };

        const bringToCenter = (
            x = props.defaultLocationX,
            y = props.defaultLocationY
        ) => {
            setShowAnimateInterval();
            data.scene.centerX = x;
            data.scene.centerY = y;
            calcHeight();
        };

        const changeView = (mode) => {
            return new Promise((resolve) => {
                data.treeView = mode;
                setShowAnimateInterval(1000);
                arrangeNodes();
                setTimeout(() => {
                    resolve(true);
                }, 1000);
            });
        };

        const addNewNode = () => {
            emit("onAddNewNode");
        };

        const setOrphanedNodes = (nodes) => {
            orphanedNodes.value = nodes;
            emit("setOrphanedNodes", nodes);
        };

        /**
         * This is for orphaned nodes (drag & drop)
         */
        let sourceDragNodeId = null;
        let targetDragNodeId = null;
        const onDragstart = (id) => {
            sourceDragNodeId = id;
        };

        const onDragEnter = (e) => {
            const nodeTo = nodes.value.find((n) => n.id == parseInt(e));
            const nodeFrom = props.scene.nodes.find(
                (n) => n.id == parseInt(sourceDragNodeId)
            );
            if (!nodeFrom || !nodeTo) return;
            if (nodeTo) targetDragNodeId = nodeTo.id;
            const targetNode = data.scene.nodes.find(
                (n) => n.id == targetDragNodeId
            );
            if (props.allowNewParentNode === targetNode.id)
                targetNode.isAccepNewParent = true;
            emit("onNodeClick", nodeFrom.id);
            emit("onMoveNode", nodeTo);
            nextTick(() => {
                props.scene.nodes.forEach((n) => {
                    n.isSuccessHover = null;
                });
                if (!props.allowedDragNodes.some((n) => n == nodeFrom.id)) {
                    nodeTo.isSuccessHover = false;
                } else {
                    nodeTo.isSuccessHover = true;
                }
            });
        };

        const onDragleave = (e) => {
            if (e.relatedTarget.classList.contains("canvas")) {
                props.scene.nodes.forEach((n) => {
                    n.isSuccessHover = null;
                    n.isAccepNewParent = null;
                });
            }
        };

        const onDrop = (e) => {
            let isAccepNewParent = false;
            // drag & drop
            const node = data.scene.nodes.find((n) => n.id == sourceDragNodeId);
            const targetNode = data.scene.nodes.find(
                (n) => n.id == targetDragNodeId
            );
            if (!node || !targetNode || !targetNode.isSuccessHover) {
                data.scene.nodes.forEach((n) => {
                    n.isSuccessHover = null;
                });
                return;
            }

            const link = data.scene.links.find((l) => l.to == node.id);
            const oldLinks = JSON.parse(JSON.stringify(data.scene.links));
            if (!link) {
                const maxID = Math.max(
                    0,
                    ...data.scene.links.map((link) => {
                        return link.id;
                    })
                );
                if (targetNode.isAccepNewParent) {
                    isAccepNewParent = true;

                    data.scene.links.push({
                        id: maxID + 1,
                        to: targetNode.id,
                        from: node.id,
                    });
                } else {
                    data.scene.links.push({
                        id: maxID + 1,
                        from: targetNode.id,
                        to: node.id,
                    });
                }
            } else {
                link.from = targetNode.id;
            }

            arrangeNodes();
            if (isAccepNewParent) {
                isLoading.value = true;
                setTimeout(() => {
                    node.topNode = true;
                    node.isOrphaned = false;
                    node.codeOfAccounts = 0;
                    node.visible = true;

                    targetNode.topNode = false;
                    targetNode.isAccepNewParent = false;
                    if (targetNode.parentId && targetNode.parentId == node.id)
                        targetNode.parentId = node.id;

                    targetNode.topNodeId = node.id;

                    emit("onChangeStructure", {
                        oldLinks: null,
                        newLinks: null,
                        rootNode: targetNode,
                    });
                    isLoading.value = false;
                }, 100);
            } else {
                emit("onChangeStructure", {
                    oldLinks: oldLinks,
                    newLinks: data.scene.links,
                });
            }

            sourceDragNodeId = null;
            props.scene.nodes.forEach((n) => {
                n.isSuccessHover = null;
            });
        };

        /**
         * Create new root node from orphaned to placeholder
         */

        const dragStartPlaceHolder = (ev) => {
            ev.dataTransfer.setData("id", ev.target.id);
        };

        const dragEnterPlaceHolder = (e) => {
            isPlaceHolderHover.value = true;
        };

        const dragLeavePlaceHolder = (e) => {
            isPlaceHolderHover.value = false;
        };

        const dropPlaceHolder = (e) => {
            isPlaceHolderHover.value = false;
            const rootNode = data.scene.nodes.find(
                (n) => n.id == sourceDragNodeId
            );
            if (rootNode) {
                rootNode.topNode = true;
                rootNode.visible = true;
                emit("onChangeStructure", {
                    oldLinks: [],
                    newLinks: [],
                    rootNode: rootNode,
                });
            }
        };

        onMounted(() => {
            data.rootDivOffset.top = container.value
                ? container.value.offsetTop
                : 0;
            data.rootDivOffset.left = container.value
                ? container.value.offsetLeft
                : 0;
            document.addEventListener("keydown", addKeyDownEventListener);
            document.addEventListener("keyup", addKeyUpEventListener);
            document.addEventListener("wheel", addMouseWheelListener);
            window.addEventListener("blur", addDocumentBlurListener);
            setZoom(zoomInd.value);
            arrangeNodes();
        });

        onUnmounted(() => {
            document.removeEventListener("keydown", addKeyDownEventListener);
            document.removeEventListener("keyup", addKeyUpEventListener);
            document.removeEventListener("wheel", addMouseWheelListener);
            window.removeEventListener("blur", addDocumentBlurListener);
        });

        return {
            ...toRefs(data),
            nodeOptions,
            linkingStart,
            linkingStop,
            container,
            lines,
            linkDelete,
            handleMove,
            handleUp,
            handleDown,
            nodeDelete,
            hoverNodes,
            visibleNodes,
            showChildren,
            zoomIn,
            zoomOut,
            zoomReset,
            setZoom,
            dragMode,
            getChildren,
            selectedNodes,
            nodeMouseDown,
            nodeMouseUp,
            isMouseMove,
            arrangeNodes,
            beforeNodeDelete,
            orphanedNodes,
            nodes,
            orphanedNodesContainer,
            orphanedNodesContainerLeft,
            changeOrphanedNodesView,
            isOrphanedNodesViewCompact,
            orphanedNodesList,
            unselectNodes,
            bringToCenter,
            changeView,
            height,
            width,
            calcHeight,
            isPlaceHolderHover,
            isShowPlaceHolder,
            addNewNode,
            showAnimate,
            setOrphanedNodes,
            onDragstart,
            onDragEnter,
            onDrop,
            onDragleave,
            dragEnterPlaceHolder,
            dragLeavePlaceHolder,
            dropPlaceHolder,
            isLoading,
        };
    },
};
</script>
<style lang="scss" scoped>
.placeholder-area {
    position: absolute;
    left: 0;
    top: 0;
}

.drag-node-placeholder {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #ccc;
    border-radius: 5px;
    position: absolute;
    transition: 0.3s;
    &.is-hover {
        border: 2px dashed #0f8af9;
    }
    svg {
        z-index: -1;
    }
}
.container {
    background: #f2f5f9;
    margin: 0;
    position: relative;
    &.is-loading {
        &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgb(255 255 255 / 84%);
            z-index: 2;
        }
    }
}
svg {
    cursor: grab;
    &.drag-mode {
        cursor: grab;
    }
}

.node {
    transition: 0.3s;
    &.drag-mode {
        transition: none;
    }
}
.animate-off {
    .node {
        transition: none;
    }
}
.node {
    &.hidden {
        visibility: hidden;
        opacity: 0 !important;
        overflow: hidden;
    }
    &.edited {
        cursor: default;
    }
}
.link {
    &.hidden {
        display: none;
    }
}

.float-orphaned-container {
    position: fixed;
    right: 0;
    top: 110px;
    z-index: 1;
}
.orphaned-nodes {
    overflow: hidden;
    &-header {
        background: #ffffff;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
        color: #363636;
        display: flex;
        height: 30px;
        align-items: center;
        justify-content: space-between;
        padding: 8px 15px;
        width: 285px;
        position: absolute;
        top: 0;
        z-index: 998;
        right: 0;
        transform-origin: top left;
        transition: 0.3s;
        .icon {
            display: grid;
            grid-auto-flow: column;
            grid-column-gap: 10px;
            svg {
                transition: 0.3s;
                transform: rotate(0deg);
                cursor: pointer;
            }
        }
        span {
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #2684fe;
        }
    }
    &.compact-view &-header {
        .icon {
            svg {
                transform: rotate(90deg);
            }
        }
    }

    ::v-deep(.container-node) {
        box-shadow: 0px -2px 7px #0000000f;
    }
    .orphaned-nodes-list {
        position: relative;
        height: calc(100vh - 110px);
        overflow: auto;
        overflow-x: hidden;
        margin-top: -15px;
    }
}
.clone-node {
    ::v-deep(.node-port) {
        &:after,
        &:before {
            display: none;
        }
    }
}
.please-wait {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    transform: translate(-315px, -70px);
    justify-content: center;
    align-items: center;
    z-index: 99;
}
</style>
