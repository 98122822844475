export default {
  accessTypeOptions: [
    { id: 0, name: 'Normal' },
    { id: 1, name: 'Admin' },
  ],
  meta: {},
  userList: [],
  userAccessList: [],
  columns: ['user_name', 'type', 'delete'],
};
