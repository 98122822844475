<template>
  <div class="input-field">
    <label>Story point</label>
    <app-base-input data-testid="estimate-storypoint-input" ref="el" :type="'text'" @input="onChange" v-model="storyValue" />
    <div class="error-text">
      {{ isEstimateValid.errorText }}
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed, onMounted, watch, ref } from "vue";
import AppBaseInput from "@/components/forms/elements/BaseInput.vue";

export default {
  components: {
    AppBaseInput,
  },
  props: {
    storyPoint: {
      type: [Number, String],
      default: "",
    },
  },
  emits: ["onFormValid", "onChange"],
  setup(props, { emit }) {
    const el = ref(),
      state = reactive({
        storyValue: props.storyPoint.toString(),
        isEstimateValid: computed(() => {
          let errorText = "";
          let result = true;

          if (isNaN(state.storyValue)) {
            errorText = "Specified format is not valid.";
          }
          if (errorText) {
            result = false;
          }

          return {
            errorText,
            result,
          };
        }),
        isFormValid: computed(() => state.isEstimateValid.result),
      });

    watch(
      () => state.isFormValid,
      (current) => {
        emit("onFormValid", current);
      }
    );

    const onChange = () => {
      if (state.isFormValid)
        emit("onChange", {
          storyPoint: state.storyValue,
        });
    };

    onMounted(() => {
      emit("onFormValid", state.isFormValid);
      el.value.focus();
      el.value.el.select();
    });

    return {
      ...toRefs(state),
      onChange,
      el,
    };
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #363636;
    margin-bottom: 5px;
    display: block;
    span {
      color: red;
    }
  }
  small {
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 13px;
    color: #c3cad9;
  }
  .error-text {
    color: #de350b;
    font-size: 12px;
    margin-top: 5px;
  }
}
</style>
