<template>
    <div class="common-container">
        <!-- Header -->
        <div class="dashboard-header">
            <div class="title">Help Center</div>
        </div>
        <div class="content-wrapper common-container-body help-center">
            <div class="contact block">
                <div class="header">Phone numbers</div>
                <ul>
                    <li><a href="tel:+18552866088">+18552866088</a></li>
                    <li><a href="tel:6468461979">6468461979</a></li>
                </ul>
            </div>
            <div class="phone block">
                <div class="header">Email</div>
                <ul>
                    <li><a href="mailto:help@aneto.io">help@aneto.io</a></li>
                </ul>
            </div>
            <div class="video">
                <a href="https://aneto.io/anetos-work-breakdown-structure-tutorial/" target="_blank">
                    <img src="/images/video_placeholder.png?dcdd8f2a6f17736abcf7f65e3cb5b700">
                </a>
            </div>
            <div class="map block">
                <div class="header">
                    <small>Address</small>
                    8108 Old Hixon Road, Suite 105, Tampa, FL 33626, Aneto
                    Software LLC
                </div>
                <div id="map" class="map"></div>
            </div>

            <a class="helpdesk block" target="_blank" href="https://aneto.atlassian.net/servicedesk/customer/portal/1">
                <div class="icon-chat">
                    <icon-help-desk width="30px" height="30px" />
                </div>
                <div class="icon-arrow">
                    <icon-arrow-top-right width="20px" height="20px" />
                </div>
                <div class="header">Helpdesk</div>
            </a>
            <div class="live-chat block" @click="openChat">
                <div class="icon-chat">
                    <icon-live-chat width="33px" height="33px" />
                </div>
                <div class="icon-arrow">
                    <icon-arrow-top-right width="20px" height="20px" :fill="'white'" />
                </div>
                <div class="header">Live chat</div>
            </div>
            <div class="faq block">
                <div class="header">
                    FAQ
                    <app-search-input v-model="searchText" placeholder="Search..." />
                </div>
                <p v-if="searchText && filteredList.length == 0">No data found</p>
                <ul>
                    <li :key="item" :class="{ 'is-open': item.isOpen }" v-for="item in filteredList">
                        <div class="list-header" @click="item.isOpen = !item.isOpen">
                            {{  item.name  }}
                            <div class="icon">
                                <icon-close-faq v-if="item.isOpen" width="21px" height="21px" />
                                <icon-open-faq v-else width="20px" height="20px" />
                            </div>
                        </div>
                        <div class="list-details">
                            <template v-if="item?.component">
                                <component :is="item.component" />
                            </template>
                            <template v-else>
                                {{  item.details  }}
                            </template>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { computed, onMounted, reactive, toRefs } from "vue";
import IconHelpDesk from "@/components/icons/IconHelpDesk.vue";
import IconLiveChat from "@/components/icons/IconLiveChat.vue";
import IconArrowTopRight from "@/components/icons/IconArrowTopRight.vue";
import IconOpenFaq from "@/components/icons/IconOpenFaq.vue";
import IconCloseFaq from "@/components/icons/IconCloseFaq.vue";
import AppChangeStyle from "@/components/help-center/ChangeStyle.vue";
import AppSearchInput from "@/components/shared/SearchInput.vue";
export default {
    components: {
        IconHelpDesk,
        IconLiveChat,
        IconArrowTopRight,
        IconOpenFaq,
        IconCloseFaq,
        AppChangeStyle,
        AppSearchInput,
    },
    setup() {
        const data = reactive({
            searchText: null,
            faqList: [
                {
                    name: "How many nodes can my plan have?",
                    details: "You can have an unlimited number of nodes in any of your projects ",
                    isOpen: false,
                },
                {
                    name: "Can I synchronize the WBS plan multiple times?",
                    details: "Yes. There is no limit on the number of imports that you can make ",
                    isOpen: false,
                },
                {
                    name: "How can I change from the tree view to a list view?",
                    component: "AppChangeStyle",
                    isOpen: false,
                },
                {
                    name: "How can I add a higher hierarchy node to my projects?",
                    details: `You can add as many hierarchy nodes to your projects as you want by simply going to: General Settings-> Nodes hierarchy and adding new nodes to the top of the hierarchy. If you do not find the Jira issue type that you like, it's probably because it is already selected under “Standard nodes''. Just unselect it from Standard nodes and it will be made available to select as a higher hierarchy node`,
                    isOpen: false,
                },
                {
                    name: "What can I do with templates?",
                    details: "Templates help you set up the project base with the initial nodes so that you can save a lot of time when creating new initiatives. You can create as many templates as you want and just select them when creating a new project",
                    isOpen: false,
                },
                {
                    name: "Why can’t I move a subtask from a story to another? ",
                    details: "The Jira API limits the moves of subtasks for now. We will implement this as soon as the APIs are available",
                    isOpen: false,
                },
            ],
            filteredList: computed(() =>
                data.faqList.filter(({ name }) => name.toLowerCase().indexOf(data.searchText?.toLowerCase()) !== -1 || !data.searchText)
            ),
        });

        const initGoogleMap = () => {
            if (window?.initMap) return;
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src =
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyDnX8uFZZ4Q2S1kZcW1j8rg6Udlx-m2tCw";
            script.onload = () => {
                const uluru = {
                    lat: 28.07690875417432,
                    lng: -82.57971772324744,
                };
                const map = new google.maps.Map(
                    document.getElementById("map"),
                    {
                        zoom: 18,
                        center: uluru,
                    }
                );
                const marker = new google.maps.Marker({
                    position: uluru,
                    map: map,
                });

                window.initMap = true;
            };
            document.head.appendChild(script);
        };
        const openChat = () => {
            Chatra("openChat", true);
        };
        const search = (value) => {
            data.searchText = value;
        };
        onMounted(() => {
            initGoogleMap();
        });
        return {
            ...toRefs(data),
            search,
            openChat,
        };
    },
};
</script>
<style lang="scss" scoped>
.help-center {
    display: grid;
    grid-template-columns: repeat(2, 285px) 1fr;
    // grid-template-rows: 145px 340px 160px 160px;
    grid-template-rows: 145px 260px 160px 160px;
    gap: 12px;
    margin-top: 25px;
    grid-template-areas:
        "Email Phone FAQ"
        "Video Video FAQ"
        "Map Map FAQ"
        "Helpdesk LiveChat FAQ";

    .phone {
        grid-area: Phone;
    }

    .email {
        grid-area: Email;
    }

    .video {
        grid-area: Video;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .map {
        padding: 0 !important;

        #map {
            // height: 258px;
            height: 160px;
            // margin-left: -21px;
            // margin-right: -21px;
            border-radius: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        grid-area: Map;

        .header {
            font-size: 16px;
            line-height: 19px;
            /* identical to box height */
            display: none;
            color: #3e5571;
        }

        small {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;

            color: #3e5571;
            margin-bottom: 5px;
            opacity: 0.5;
        }
    }

    .block {
        background: #ffffff;
        box-shadow: 0px 1px 2px rgba(128, 128, 128, 0.25);
        border-radius: 10px;
        padding: 24px;
    }

    .faq {
        grid-area: FAQ;
        overflow: auto;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .phone a,
    .contact a {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */

        color: #3e5571;
    }

    .helpdesk {
        grid-area: Helpdesk;
        display: grid;
        grid-template-columns: 30px 1fr 30px;
        row-gap: 15px;
        cursor: pointer;

        .header {
            grid-area: 3 / 1 / 3 / 3;
            font-weight: 400;
        }

        .icon-arrow {
            grid-area: 1 / 3 / 1 / 3;
        }

        .icon-chat {
            grid-area: 2 / 1 / 2 / 1;
        }
    }

    .live-chat {
        grid-area: LiveChat;
        background: #1780ff;
        display: grid;
        grid-template-columns: 30px 1fr 30px;
        row-gap: 15px;
        cursor: pointer;

        .header {
            grid-area: 3 / 1 / 3 / 3;
            font-weight: 400;
            color: white;
        }

        .icon-arrow {
            grid-area: 1 / 3 / 1 / 3;
        }

        .icon-chat {
            grid-area: 2 / 1 / 2 / 1;
        }
    }

    .header {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;

        color: #3e5571;
        margin-bottom: 15px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            /* identical to box height */

            color: #3e5571;
        }

        li+li {
            margin-top: 15px;
        }
    }

    .faq {
        .list-header {
            display: grid;
            grid-template-columns: 1fr 22px;
            align-items: center;
            cursor: pointer;
        }

        .list-details {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #3e5571;
            opacity: 0.8;
            margin-top: 10px;
        }

        li {
            max-height: 30px;
            overflow: hidden;

            &.is-open {
                max-height: 100%;
            }
        }

        li {
            border-bottom: 1px solid #3e55711b;
            padding-bottom: 15px;
        }
    }
}

::v-deep(.input-field .input-field-wrapper) {
    height: 45px;
}
</style>
