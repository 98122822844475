<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.39 1.8974C6.77783 1.43389 7.47499 1.36593 7.94811 1.74587C7.97427 1.76648 8.81474 2.41939 8.81474 2.41939C9.33449 2.7336 9.49599 3.40155 9.1747 3.91129C9.15764 3.93859 4.40597 9.88224 4.40597 9.88224C4.24789 10.0794 4.00792 10.1959 3.75145 10.1987L1.93176 10.2215L1.52177 8.48616C1.46433 8.24215 1.52177 7.98589 1.67985 7.78867L6.39 1.8974Z"
      stroke="#A1A9BA"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.50977 3.00037L8.23589 5.09393"
      stroke="#A1A9BA"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="7.5" cy="10.5" r="0.5" fill="#A1A9BA" />
    <circle cx="9.5" cy="10.5" r="0.5" fill="#A1A9BA" />
    <circle cx="11.5" cy="10.5" r="0.5" fill="#A1A9BA" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "#0F8AF9",
    },
  },
};
</script>
