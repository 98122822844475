<template>
  <div
    class="issue-panel"
    :class="{ active: isOpenMenu }"
    ref="el"
    @click="openMenu"
    v-click-outside="(e) => closeMenu(e, el.id)"
  >
    <div class="dotts">
      <icon-dotts :width="'2px'" :height="'12px'" />
    </div>
    <slot />

    <div class="content" v-if="isOpenMenu">
      <app-issue-menu
        :customList="customList"
        :list="list"
        @save="(value) => save(value)"
        @click.stop
        @cancel="cancel"
      />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, onMounted } from "vue";
import IconDotts from "@/components/icons/IconDottsMenu.vue";
import AppIssueMenu from "@/components/settings/IssueMenu.vue";
export default {
  components: {
    IconDotts,
    AppIssueMenu,
  },
  props: {
    customList: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["save", "cancel"],
  setup(props, { emit }) {
    const state = reactive({
      isOpenMenu: false,
      uniqId: new Date().getTime(),
    });

    const el = ref();

    const closeMenu = (e, id) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return p.classList && p.classList.contains("issue-panel") && p.id === id;
        })
      )
        return;
      state.isOpenMenu = false;
    };

    const openMenu = () => {
      state.isOpenMenu = !state.isOpenMenu;
    };

    const cancel = () => {
      emit("cancel");
      state.isOpenMenu = false;
    };

    const save = (data) => {
      emit("save", data);
      state.isOpenMenu = false;
    };

    onMounted(() => {
      el.value.id = '_' + Math.random().toString(36).substr(2, 9);
    });

    return {
      ...toRefs(state),
      openMenu,
      closeMenu,
      emit,
      save,
      cancel,
      el,
    };
  },
};
</script>
<style lang="scss" scoped>
.issue-panel {
  position: relative;
  background: rgba(195, 202, 217, 0.15);
  border: 1px solid rgba(195, 202, 217, 0.15);
  box-sizing: border-box;
  border-radius: 2px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  cursor: pointer;
  &.active {
    border: 1px solid #2684fe;
    box-shadow: 0 1px 4px 1px #2684fe;
    box-sizing: border-box;
    border-radius: 2px;
  }
  img {
    width: 16px;
    height: 16px;
  }
  .content {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>
