import axios from 'axios';
import * as types from '@/store/mutation-types';

export default {
  getUsers({ commit }, params = { perPage: 10, search: '', hideLoader: false }) {    
    return new Promise((resolve, reject) => {
      axios
        .get(`/users?perPage=${params.perPage || 10}&search=${params.search || ''}`, {
          hideLoader: params.hideLoader
        })
        .then((response) => {
          commit(types.SET_USERS, { data: response.data.data, meta: response.data.meta });
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
