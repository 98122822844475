<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 25 21"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 5.25V18.75C25 19.9922 23.9502 21 22.6562 21H2.34375C1.0498 21 0 19.9922 0 18.75V5.25C0 4.00781 1.0498 3 2.34375 3H6.64062L7.24121 1.45781C7.58301 0.58125 8.45703 0 9.43359 0H15.5615C16.5381 0 17.4121 0.58125 17.7539 1.45781L18.3594 3H22.6562C23.9502 3 25 4.00781 25 5.25ZM18.3594 12C18.3594 8.89688 15.7324 6.375 12.5 6.375C9.26758 6.375 6.64062 8.89688 6.64062 12C6.64062 15.1031 9.26758 17.625 12.5 17.625C15.7324 17.625 18.3594 15.1031 18.3594 12ZM16.7969 12C16.7969 14.2734 14.8682 16.125 12.5 16.125C10.1318 16.125 8.20312 14.2734 8.20312 12C8.20312 9.72656 10.1318 7.875 12.5 7.875C14.8682 7.875 16.7969 9.72656 16.7969 12Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "",
    },
  },
};
</script>
