<template>
  <svg :opacity="opacity" :width="width" :height="height" viewBox="0 0 14 14" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.796 5.2645L8.7355 4.204L1.75 11.1895V12.25H2.8105L9.796 5.2645ZM10.8565 4.204L11.917 3.1435L10.8565 2.083L9.796 3.1435L10.8565 4.204ZM3.4315 13.75H0.25V10.5678L10.3262 0.491502C10.4669 0.350898 10.6576 0.271912 10.8565 0.271912C11.0554 0.271912 11.2461 0.350898 11.3867 0.491502L13.5085 2.61325C13.6491 2.7539 13.7281 2.94463 13.7281 3.1435C13.7281 3.34237 13.6491 3.53311 13.5085 3.67375L3.43225 13.75H3.4315Z"
      fill="#A0A5B0" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: String,
      default: "",
    },
    stroke: {
      type: String,
      default: "",
    },
  },
};
</script>
