<template>
  <div>
    <base-select-box 
      v-model="countOnPage" 
      :options="pagesSizes" 
      :close-on-select="true" 
      :min="1" 
      :small-font="true"
      @change="changeCountOnPage"
    />
  </div>
</template>
<script>
import { watch, reactive, toRefs } from "vue";
import BaseSelectBox from "vue-select-box";
export default {
  components: {
    BaseSelectBox,
  },
  inject: ["translate"],
  props: {
    modelValue: {
      type: [String, Number],
      default: 10,
    },
  },
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {

    const data = reactive({
      countOnPage: props.modelValue,
      pagesSizes:[10, 20, 40, 50]
    })

    const changeCountOnPage = (value) => {
      emit("update:modelValue", value);
      emit("change", value)
    }

    watch(() => props.modelValue, (current)=> {
      data.countOnPage = current
    })

    return {
      ...toRefs(data),
      changeCountOnPage
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep(.vue-select) {
  .vue-select-header,
  .vue-dropdown .vue-dropdown-item {
    height: 35px;
  }
  .icon {
    width: 31px;
  }
  .vue-input input {
    padding-left: 15px;
  }
}
</style>
