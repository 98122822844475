<template>
  <div v-click-outside="(e) => clickOutSide(e)" class="skill-dropdown-component" data-testid="skills-dropdown">
    <div class="skill-label" :title="skillName" :data-testid="`skill-label-${id}-${skillName}`" @mouseup.stop @click="openList">{{ skillName }}</div>
    <teleport :to="'.gantt-component-container .rows'">
      <div v-show="isListOpen" :style="{ ...position }" class="skill-list" :data-testid="`skill-list-${id}`"
        @keydown.esc="isListOpen = false">
        <Multiselect ref="select$" v-model="skillId" :options="skillList" :searchable="true"
          :noResultsText="'No data found'" :hideSelected="false" :canDeselect="false" @select="onChange" @open="onOpen">
          <template #option="{ option }">
            <div class="option-container" :data-testid="`skill-item-${id}-${option.label}`">
              <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4" r="4" fill="#FFA72B" />
              </svg>
              {{ option.label }}
              <input v-if="multipleSkills.length > 1 && multipleSkills.includes(option.value)" type="checkbox" checked
                disabled>
            </div>
          </template>
          <template v-if="showApplyChildren" #afterlist>
            <div class="apply-children">
              <label class="checkbox big">
                Apply children
                <input v-model="applyChildren" type="checkbox">
                <div class="icon" />
              </label>
            </div>
          </template>
        </Multiselect>
      </div>
    </teleport>
  </div>
</template>
<script>
import { ref, watch, nextTick, computed } from 'vue';
import Multiselect from '@vueform/multiselect';
import vClickOutside from '@/directives/outside';

export default {
  components: { Multiselect },
  directives: {
    clickOutside: vClickOutside
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    selected: {
      type: [Number, String],
      default: 0,
    },
    showApplyChildren: {
      type: Boolean,
      default: false,
    },
    scrollTop: {
      type: Number,
      default: 0,
    }
  },
  emits: ["onChange"],
  setup(props, { emit }) {
    const
      skillId = ref(props.selected),
      applyChildren = ref(true),
      skillName = ref(null),
      position = ref({}),
      isListOpen = ref(false),
      select$ = ref(null),
      skillList = ref([
        { value: 0, label: 'VueJs' },
        { value: 1, label: 'ReacJs' },
        { value: 2, label: 'Angular' },
        { value: 3, label: 'PHP' },
        { value: 4, label: 'Laravel' },
        { value: 5, label: 'MySQL' },
        { value: 6, label: 'Java Script' },
        { value: 7, label: 'CSS' },
        { value: -1, label: 'Muitiple skills', disabled: true, },
      ]),
      multipleSkills = computed(() => {
        return props.selected.toString().split(',').map(id => {
          return skillList.value.find(s => s.value == id).value
        })
      });

    const openList = (e) => {
      const { left, width, top, height } = e.target.getBoundingClientRect();
      position.value = { left: `${left}px`, top: `${top + height}px` };
      isListOpen.value = !isListOpen.value;
      select$.value.open();
    }

    const clickOutSide = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("skill-list"))
          );
        })
      ) {
        return;
      }
      isListOpen.value = false;
    }

    const onChange = (e) => {
      isListOpen.value = false;
      select$.value.close();
      emit('onChange', { data: skillList.value.find(s => s.value == e), applyChildren: applyChildren.value });
    }

    const onOpen = () => {
      nextTick(() => {
        select$.value.focus();
      })
    }

    watch(() => props.selected, (current) => {
      if (current.toString().split(',').length > 1) {
        skillId.value = -1;
        skillName.value = skillList.value.find(s => s.value == -1)?.label
      } else {
        skillId.value = current;
        skillName.value = skillList.value.find(s => s.value == current)?.label
      }
    }, {
      immediate: true,
    });

    watch(() => props.scrollTop, () => {
      isListOpen.value = false;
      select$.value.close();
    })

    return {
      select$, skillId, multipleSkills, applyChildren, skillName, skillList, isListOpen, openList, position, clickOutSide, onChange, onOpen
    }
  }
}
</script>
<style>
.skill-list {
  --ms-max-height: 15rem;
  --ms-line-height: 1;
  --ms-ring-color: none;
  --ms-border-color: none;
  --ms-font-size: 14px;
  --ms-line-height: 1.375;
}
</style>
<style src="@vueform/multiselect/themes/default.css">

</style>
<style lang="scss" scoped>
.skill-dropdown-component {
  position: relative;
}

.skill-list {
  position: fixed;
  width: 200px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

::v-deep(.multiselect-dropdown) {
  box-shadow: none;
  border-top: 0;
}

::v-deep(.is-disabled) {
  display: none;
}

.skill-label {
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  color: #444;
  background: #fff8ee;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
}

.apply-children {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-top: 1px solid #0b19311a;

  label {
    display: flex;
    gap: 2px;
    margin: 10px 0;
  }

  .checkbox {

    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #363636;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 15px;


    input {
      display: none;
    }

    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #363636;

    .icon {
      border-radius: 100px;
      width: 22px;
      height: 14px;

      background: #0f8af9;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: 0.5s;
      background: #b9bcc0;

      &:before {
        content: "";
        transition: 0.5s;
        position: absolute;
        width: 12px;
        height: 12px;
        transform: translateX(-4px);
        right: auto;
        border-radius: 50%;
        background: #f7f7fa;
      }
    }

    input:checked~.icon {
      background: #21AD64;

      &:before {
        transition: 0.5s;
        content: "";
        left: auto;
        background: white;
        transform: translateX(4px);
      }
    }

    &.big {
      .icon {
        width: 32px;
        height: 18px;

        &:before {
          width: 12px;
          height: 12px;
          left: 8px;
        }
      }

      input:checked~.icon {
        &:before {
          right: 8px;
        }
      }
    }
  }
}

.option-container {
  width: 100%;
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  align-items: center;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
</style>