<template>
  <div class="error-node-container" :style="position">
    <div class="close" @click="close">
      <icon-close :width="'20px'" :height="'20px'" :fill="'#0085FF'" />
    </div>
    <div class="popup-info">
      <ul>
        <li :key="i" v-for="(error, i) in errorList">
          {{ error.text }}
        </li>
      </ul>
      <div class="actions">
        <app-button class="button-action" :color="'primary'" @click="retry(id)">
          {{ translate("wbs.retry") }}
        </app-button>
        <app-button class="button-action" :color="'default'" @click="dismiss(id)">
          {{ translate("wbs.dismiss") }}
        </app-button>
      </div>
    </div>
  </div>
</template>
<script>
import AppButton from "@/components/shared/Button.vue";
import { computed } from "vue";
import errorToList from "@/helpers/errorToList";
import IconClose from "@/components/icons/IconClose.vue";
export default {
  components: { AppButton, IconClose },
  inject: ["translate"],
  props: {
    nodeErrors: {
      type: Object,
      default: null,
    },
    position: {
      type: Object,
      default: () => { },
    },
  },
  emits: ["onRetry", "onClose", "onDismiss"],
  setup(props, { emit }) {
    const errorList = computed(() => {
      if (props.nodeErrors) {
        return errorToList(props.nodeErrors.error);
      } else {
        return [];
      }
    });

    const retry = () => {
      emit("onRetry");
    };

    const dismiss = () => {
      emit("onDismiss");
    }

    const close = () => {
      emit("onClose");
    };

    return {
      errorList,
      close,
      retry,
      dismiss,
    };
  },
};
</script>
<style lang="scss" scoped>
.error-node-container {
  min-width: 150px;
  max-width: 210px;
  display: none;
  position: absolute;
  z-index: 999;
  background: #fff;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 15px;
  color: white;
  font-size: 14px;
  box-shadow: 0px 1px 3px 1px #0000003d;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 4px;
}

.popup-info {
  margin-top: 15px;

  ul {
    padding: 0;
    list-style: none;
    margin: 0;
    color: #444;
    line-height: 18px;
  }

  .button-action {
    font-size: 13px;
    cursor: pointer;
    margin-top: 10px;
    height: 30px;
    width: 90px;
  }

  &:before {
    content: "";
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 10px 5px;
    border-color: transparent transparent #fff transparent;
    top: -7px;
    left: 5px;
  }
}

.actions {
  display: flex;
  gap: 5px;
}
</style>
