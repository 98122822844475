<template>
  <div class="notification-list">
    <div
      class="list-item"
      :class="{ error: item.type == 'error' }"
      :key="i"
      v-for="(item, i) in list"
    >
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.notification-list {
  position: fixed;
  top: 85px;
  right: 20px;
  z-index: 9999;
  .list-item {
    padding: 15px;
    list-style: none;
    background: green;
    color: white;
    &.error {
      background: red;
      color: white;
    }
    border-radius: 5px;
  }
  .list-item + .list-item {
    margin-top: 5px;
  }
}
</style>
