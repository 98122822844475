<template>
  <div class="input-field">
    <label>{{ translate("wbs.original_estimate") }}</label>
    <app-base-input ref="el" v-model="estimateTime" data-testid="estimate-input" @input="onChange" :placeholder="translate('wbs.estimate_placeholder')"
      />
    <div class="error-text">
      {{ isEstimateValid.errorText }}
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed, onMounted, watch, ref } from "vue";
import AppBaseInput from "@/components/forms/elements/BaseInput.vue";
import { useDateHelper } from "@/helpers/dateHelper";
export default {
  components: {
    AppBaseInput,
  },
  inject: ["translate"],
  props: {
    originalEstimateInHours: {
      type: String,
      default: "",
    },
  },
  emits: ["onFormValid", "onChange"],
  setup(props, { emit }) {
    const { checkValidationStr } = useDateHelper(),
      el = ref(),
      state = reactive({
        estimateTime: props.originalEstimateInHours,
        isEstimateValid: computed(() => {
          let errorText = "";
          let result = true;
          if (state.estimateTime && !checkValidationStr(state.estimateTime)) {
            errorText = "Specified format is not valid.";
          }

          if (errorText) {
            result = false;
          }

          return {
            errorText,
            result,
          };
        }),
        isFormValid: computed(() => state.estimateTime && state.isEstimateValid.result),
      });

    const onChange = () => {
      if (state.isFormValid)
        emit("onChange", {
          estimate: state.estimateTime,
        });
    };

    onMounted(() => {
      el.value.focus();
      el.value.el.select();
    });

    watch(
      () => state.isFormValid,
      (current) => {
        emit("onFormValid", current);
      },
      {
        immediate: true,
      }
    );

    return {
      ...toRefs(state),
      onChange,
      el,
    };
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #363636;
    margin-bottom: 5px;
    display: block;

    span {
      color: red;
    }
  }

  small {
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 13px;
    color: #c3cad9;
  }

  .error-text {
    color: #de350b;
    font-size: 12px;
    margin-top: 5px;
  }
}
</style>
