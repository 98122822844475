import { computed, watch } from 'vue';
import { useI18n } from "vue-i18n";
export function useLanguageHelper(store) {
  //store = store || useStore();
  const language = computed(() => store?.getters['profile/language']);  
  
  watch(
    () => language.value,
    (current) => {            
      setLanguage(current)
    }
  );
  const setLanguage = (locale = 'en') => {      
    store.dispatch('profile/setLanguage', locale);    
    if (locale === 'ru') {      
      document.documentElement.style.setProperty("--font-family", "Montserrat")
    } else {
      document.documentElement.style.setProperty("--font-family", "Poppins")
    }
  }
  const translate = (key, params = {}) => {
    const { t } = useI18n();    
    return t(key, params, { locale: language.value })
  }
  return { setLanguage, language, translate }
}