<template>
  <svg
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13" cy="13" r="12.5" stroke="#A0A3BD" />
    <path
      d="M12.8991 8.116C13.8231 8.116 14.5698 8.37733 15.1391 8.9C15.7084 9.42267 15.9931 10.1227 15.9931 11C15.9931 11.98 15.6898 12.708 15.0831 13.184C14.4764 13.6507 13.6551 13.884 12.6191 13.884L12.5771 15.214H11.4711L11.4151 13.002H11.8211C12.7451 13.002 13.4684 12.862 13.9911 12.582C14.5138 12.302 14.7751 11.7747 14.7751 11C14.7751 10.44 14.6071 9.99667 14.2711 9.67C13.9351 9.34333 13.4824 9.18 12.9131 9.18C12.3344 9.18 11.8771 9.33867 11.5411 9.656C11.2144 9.964 11.0511 10.3887 11.0511 10.93H9.8471C9.8471 10.37 9.9731 9.88 10.2251 9.46C10.4771 9.03067 10.8318 8.69933 11.2891 8.466C11.7558 8.23267 12.2924 8.116 12.8991 8.116ZM12.0171 18.084C11.7744 18.084 11.5691 18 11.4011 17.832C11.2331 17.664 11.1491 17.4587 11.1491 17.216C11.1491 16.9733 11.2331 16.768 11.4011 16.6C11.5691 16.432 11.7744 16.348 12.0171 16.348C12.2504 16.348 12.4464 16.432 12.6051 16.6C12.7731 16.768 12.8571 16.9733 12.8571 17.216C12.8571 17.4587 12.7731 17.664 12.6051 17.832C12.4464 18 12.2504 18.084 12.0171 18.084Z"
      fill="#A0A3BD"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
      default: "none",
    },
  },
};
</script>
