<template>
  <div ref="wbsContainer" class="wbs-container" data-testid="wbs-schedule">
    <app-top-panel :isShowFilter="false" :projectStatistic="projectStatistic" :isImportError="isImportError"
      :isImportRun="isImportRun" :viewMode="viewMode" @onAction="onPanelAction">
      <app-project-indicator :estimate="projectStatistic.estimateStr" :value="projectStatistic.projectProgress">
        <app-progress-bar
          :projectDoneOriginalEstimateInStoryPoints="projectStatistic.projectDoneOriginalEstimateInStoryPoints"
          :spent="projectStatistic.projectProgress" :overlog="projectStatistic.calcOverLog"
          :remaining="projectStatistic.calcRemaining" :isShowInfo="true"
          :projectProgressStr="projectStatistic.projectProgressStr" :spentStr="projectStatistic.spentStr"
          :remainingStr="projectStatistic.remainingStr" :estimateStr="projectStatistic.estimateStr"
          :background="'#ffffff'" />
      </app-project-indicator>
    </app-top-panel>
    <app-notification :list="notificationList" />
    <app-select-user 
      v-if="currentDropDown?.name == 'assigned'" 
      ref="selectUser" 
      :userData="currentDropDown.value"
      :position="dropDownPosition" 
      data-testid="select-issue-assignee"
      @onChange="onChangeDropDown" 
    />

    <app-select-status 
      v-if="currentDropDown?.name == 'status'" 
      :status="currentNode?.jiraIssueStatusId"
      :position="dropDownPosition" 
      :projectId="currentNode?.jiraProject?.id"
      :issueTypeId="currentNode?.jiraIssueType?.id" 
      :nodeId="currentNode?.id" 
      data-testid="select-issue-status"
      @onChange="onChangeDropDown"
      @onClickOutSide="hideDropDowns" />
    <div class="gantt-component-wrapper" data-testid="gantt-component">
      <app-gantt-component ref="ganttComponent" :dimension="dimension" :startDate="startDate" :dueDate="dueDate"
        :filter="searchStr" :columns="columns" :rows="rows" :allowDragAndDrop="false" :collapseChildren="false"
        :expandAll="expandAll" :height="calcHeight" :allowMultipleSelect="true"
        :displayCriticalPath="displayCriticalPath" 
        :linksProp="links"
        @onLoadRows="onLoadRows" @onShowChildren="onShowChildren">
        <template #header>
          <div class="swith-mode">
            <div class="swith-mode-buttons">
              <div class="swith-button active">
                Tasks
              </div>
              <div class="swith-button">
                Resources
              </div>
            </div>
          </div>
        </template>
        <template #row="{ row, getChildrenAll }">
          <div class="cell" :title="row.row[row.name]" @click="hideDropDowns" :data-testid="row.row?.jiraIssueKey">
            {{ row.row[row.name].length > 50 ? row.row[row.name].substr(0, 20) + '...' : row.row[row.name] }}
            <div class="skill-dropdown">
              <app-skill-drop-down :id="row.row?.jiraIssueKey" :selected="row.row.skillId" :scrollTop="scrollTop"
                :show-apply-children="!row.row?.parentId"
                @onChange="e => onChangeSkill({ e, row: row.row, getChildrenAll })" />
            </div>
          </div>
        </template>
        <template #ticket-data="{ row }">
          <app-issue-component :issue="row" 
            @onStatusClick="e => onCellClick({ name: 'status', id: row.id, event: e })" 
            @onAssigneeClick="e => onCellClick({ name: 'assignee', id: row.id, event: e })"
          />
        </template>
      </app-gantt-component>
    </div>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  computed,
  onMounted,
  inject,
  watch,
  onUnmounted,
  toRefs,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import { useCookies } from "@/helpers/cookies";
import router from "@/router";
import { useModalHelper } from "@/helpers/modalHelper";
import { useCalcProgress } from "@/helpers/calcProgress";
import { useStatusColor } from "@/helpers/statusColor";
import AppTopPanel from "@/components/wbsProject/TopPanel.vue";
import AppProjectIndicator from "@/components/wbsProject/ProjectIndicator.vue";
import AppProgressBar from "@/components/wbsProject/estimation/ProgressBar.vue";
import AppNotification from "@/components/wbsProject/Notification.vue";
import errorToList from "@/helpers/errorToList";
import debounce from "lodash.debounce";
import moment from "moment";
import { useStatistic } from "@/components/wbsProject/modules/statistic";
import { createToaster } from "@meforma/vue-toaster";
import { YEAR_DIMENSION, MONTH_DIMENSION, WEEK_DIMENSION } from '@/components/wbsProject/schedule/const';
const toaster = createToaster({ position: "top-right" });
import vOpenJiraIssueDialog from "@/directives/openJiraIssueDialog.js";
import { useWbsDocument } from "@/components/wbsProject/wbsDocument";
import AppGanttComponent from 'gantt-component';
import AppSkillDropDown from './SkillDropDown.vue';
import AppIssueComponent from './IssueComponent.vue';
import AppSelectStatus from "@/components/wbsProject/SelectStatus.vue";
import AppSelectUser from "@/components/wbsProject/SelectUser.vue";
import { usePositionHelper } from "@/helpers/positionHelper";
export default {
  components: {
    AppTopPanel,
    AppGanttComponent,
    AppProjectIndicator,
    AppProgressBar,
    AppNotification,
    AppSelectStatus,
    AppSelectUser,
    AppSkillDropDown,
    AppIssueComponent,
  },
  directives: {
    "open-jira-issue-dialog": vOpenJiraIssueDialog,
  },
  inject: ["translate"],
  setup() {
    const { openModal, storeModal } = useModalHelper(),
      translate = inject("translate"),
      store = useStore(),
      state = reactive({
        searchStr: "",
        expandAll: null,
        isLoading: false,
        rows: [],
        calcHeight: null,
        projectId: null,
        source: computed(() => store.getters["wbs/source"]),
        searchStr: "",
        expandAll: true,
        columns: [{
          name: 'summary',
          title: 'Summary'
        }],
        startDate: moment('01 12 2021', 'DD MM YYYY'),
        dueDate: moment('01 09 2022', 'DD MM YYYY'),
      }),
      backendErrors = ref([]),
      isImportError = computed(() => {
        return store.getters["wbs/nodesModelMeta"]
          ?.lastImportErrorMessage
          ? true
          : false;
      }),
      notifications = ref([]),
      notificationList = computed(() => {
        backendErrors.value.forEach((e) => {
          e.type = "error";
        });

        return [...backendErrors.value, ...notifications.value];
      }),
      wbsState = computed(() => {
        return store.getters["wbs/wbsState"];
      }),
      projectStatistic = computed(() => {
        return useStatistic().projectStatistic.value;
      }),
      isImportRun = computed(() => store.getters["wbs/isImportRun"]),
      isExistsImport = computed(
        () => store.getters["wbs/isExistsImport"]
      ),
      links = computed(()=> {
        return store.getters['wbs/ganttLinks']
      }),
      notUpdateFromDelta = ref(false),
      { calcElementPosition } = usePositionHelper();
    const dimension = ref(WEEK_DIMENSION),
      dimensionList = computed(() => {
        return [{
          name: 'Week',
          value: WEEK_DIMENSION
        },
        {
          name: 'Month',
          value: MONTH_DIMENSION
        },
        {
          name: 'Year',
          value: YEAR_DIMENSION
        }]
      }),
      displayCriticalPath = ref(false),
      scrollTop = ref(0);
    const wbsContainer = ref(null),
      ganttComponent = ref(null),
      getStatusColorText = useStatusColor().getStatusColorText,
      viewMode = ref('schedule'),
      currentDropDown = ref(null),
      currentNode = ref(null),
      dropDownPosition = ref({
        position: "absolute",
        display: "none",
      });

    const setCurrentNode = (id) => {
      const node = state.rows.find((n) => n.id == id);
      currentNode.value = node;
      closeDropDown();
    };

    const openShareModal = () => {
      openModal("ShareWBS", {
        source: state.source,
        id: parseInt(state.projectId),
        viewStyle: "displayAsList",
      });
    };

		const openAssignDropDown = (node, e) => {
			setCurrentNode(node.id);
			currentDropDown.value = { name: "assigned", value: node.assignee };         
			nextTick(() => {
				dropDownPosition.value = calcElementPosition({
					e,
					popupWidth: 250,
					container: wbsContainer.value,
					popupHeight: 380,
				});
			});
		};

    const openStatusDropDown = (node, e) => {
      setCurrentNode(node.id)        
      currentDropDown.value = {
        name: "status",
        value: node.jiraIssueStatus.id,
      };
      nextTick(() => {
        dropDownPosition.value = calcElementPosition({
          e,
          popupWidth: 250,
          container: wbsContainer.value,
          popupHeight: 240,
        });
      });
    };

    const launchImport = async () => {
      try {
        notUpdateFromDelta.value = true;
        store.commit("wbs/SET_EXISTS_IMPORT", true);
        await store.dispatch("wbs/launchImport", {
          projectId: state.projectId,
        });
        store.commit("wbs/SET_IMPORT_RUN", true);
      } catch (error) {
        store.commit("wbs/SET_IMPORT_RUN", false);
        notUpdateFromDelta.value = false;
        backendErrors.value = errorToList(error);
        setTimeout(() => {
          backendErrors.value = [];
        }, 3000);
      }
    };

    const getWbsState = async (projectId) => {
      if (Object.keys(store.getters["wbs/wbsState"]).length) return;
      try {
        backendErrors.value = [];
        store.commit("wbs/SET_IMPORT_RUN", false);
        await store.dispatch("wbs/getWbsState", projectId);
        store.commit("wbs/SET_IMPORT_RUN", wbsState.value.activeImport);
      } catch (error) {
        backendErrors.value = errorToList(error);
        setTimeout(() => {
          backendErrors.value = [];
        }, 3000);
      }
    };

    const debouncesetWbsState = debounce(async (data) => {
      try {
        await store.dispatch("wbs/setWbsState", {
          ...wbsState.value,
          ...data,
          ...{
            projectId: state.projectId,
            zoomLevel: zoomInd.value,
            locationX: state.centerX,
            locationY: state.centerY,
            displayTreeOption: wbsState.value?.displayTreeOption,
          },
        });
      } catch (error) {
        const list = errorToList(error);
        list.forEach((item) => {
          backendErrors.value.push(item);
        });

        setTimeout(() => {
          backendErrors.value = backendErrors.value.filter(
            (e) => !list.some((l) => l.name == e.name)
          );
        }, 3000);
      }
    }, 500);

    const setWbsState = async (data = {}) => {
      await debouncesetWbsState(data);
    };

    const openEditProject = async () => {
      openModal("NewProject", {
        template: wbsState.value.template,
        project: {
          ...wbsState.value,
          ...{ lastImportErrorMessage: isImportError.value },
        },
        countNodes: state.rows.length,
        title: wbsState.value.name,
      });
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          store.commit("wbs/RESET_CACHED_NODES");
          store.commit("wbs/RESET_WBS_STATE");
          loadInstance(router.currentRoute.value.params.id);
          getNodes();
          //runAutoImport();
          unsubscribe();
        }
        if (data.type === "modal/closeModal") {
          unsubscribe();
        }
      });
    };

    const openEditTemplate = async () => {
      openModal("NewTemplate", {
        template: wbsState.value,
        title: wbsState.value.name,
      });
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          store.commit("wbs/RESET_CACHED_NODES");
          store.commit("wbs/RESET_WBS_STATE");
          loadInstance(router.currentRoute.value.params.id);
          getNodes();
          unsubscribe();
        }
        if (data.type === "modal/closeModal") {
          unsubscribe();
        }
      });
    };

    const onPanelAction = (action) => {
      const { saveCookie } = useCookies();
      switch (action) {
        case "launchImport":
          launchImport();
          break;
        case "openShareModal":
          openShareModal();
          break;
        case "openFilter":
          openFilter();
          break;
        case "openNewNodeModal":
          openNewNodeModal();
          break;
        case "launch-synch":
          launchSync();
          break;
        case "createProject":
          createProject();
          break;
        case "editProject":
          openEditProject();
          break;
        case "editTemplate":
          openEditTemplate();
          break;
        case "displayAsList":
          router.push({
            name: state.source.includes("project")
              ? "wbs-project-list-view"
              : "wbs-template-list-view",
            params: {
              id:
                state.projectId ||
                parseInt(router.currentRoute.value.params.id),
            },
          });
          saveCookie(`view-style-${state.projectId}`, "displayAsList");
          break;
        case "displayAsTree":
          router.push({
            name: state.source.includes("project")
              ? "wbs-project"
              : "wbs-template",
            params: {
              id:
                state.projectId ||
                parseInt(router.currentRoute.value.params.id),
            },
          });
          saveCookie(
            `view-style-${state.projectId}`,
            "displayAsTree"
          );
          break;
        case "displayTreeAsHorizontal":
          router.push({
            name: state.source.includes("project")
              ? "wbs-project"
              : "wbs-template",
            params: {
              id:
                state.projectId ||
                parseInt(router.currentRoute.value.params.id),
              treeStyle: "horizontal",
            },
          });
          saveCookie(
            `view-style-${state.projectId}`,
            "displayAsTree"
          );
          break;
        case "displayTreeAsVertical":
          router.push({
            name: state.source.includes("project")
              ? "wbs-project"
              : "wbs-template",
            params: {
              id:
                state.projectId ||
                parseInt(router.currentRoute.value.params.id),
              treeStyle: "vertical",
            },
          });

          saveCookie(
            `view-style-${state.projectId}`,
            "displayAsTree"
          );
          break;
        case "resetFilter":
          filterComponent.value.resetFilter();
          break;
        case "displaySchedule":
          router.push({
            name: "wbs-schedule-management",
            params: {
              id:
                state.projectId ||
                parseInt(router.currentRoute.value.params.id),
            },
          });
          saveCookie(
            `view-style-${state.projectId}`,
            "displaySchedule"
          );
          break;
        case "displayScheduleCriticalPath":
          displayCriticalPath.value = !displayCriticalPath.value;
          if (displayCriticalPath.value) {
            viewMode.value = 'schedule-critical-path';
          } else {
            viewMode.value = 'schedule';
          }
          break;
      }
    };

    const socketsOff = () => {
      if (window.isTestRun) {
        return;
      }
      const tenantId = store.getters["profile/tenantId"];
      const projectId = state.projectId;
      Echo.leave("addon." + tenantId + "." + projectId);
      Echo.leave("addon." + tenantId + ".template." + projectId);
      Echo.leave("addon." + tenantId + ".project." + projectId);
      Echo.leave(
        "addon." + tenantId + ".template." + projectId + ".participants"
      );
      Echo.leave(
        "addon." + tenantId + ".project." + projectId + ".participants"
      );
    };

    const socketsOn = (tenantId, projectId) => {
      if (window.isTestRun) {
        return;
      }

      Echo.private("addon." + tenantId + "." + projectId)
        .listen("ImportStarted", (response) => {
          notifications.value = [];
          store.commit("wbs/SET_IMPORT_RUN", true);
        })
        .listen("ImportCompleted", async (response) => {
          store.commit("wbs/SET_IMPORT_RUN", false);
          store.commit("wbs/RESET_WBS_STATE");
          loadInstance(projectId);
          notifications.value.push({
            name: "Import",
            text: "Import completed successfully",
            type: "success",
          });
          setTimeout(() => {
            notifications.value = notifications.value.filter(
              (e) => e.name !== "Import"
            );
          }, 3000);
        });

      const channelKey = ["wbs-project",
        "wbs-project-list-view",
        "wbs-schedule-management",].includes(router.currentRoute.value.name)
        ? "project"
        : "template";

      Echo.private(
        "addon." + tenantId + "." + channelKey + "." + projectId
      ).listen("FetchLatestData", async (response) => {
        updateNodes(channelKey);
      });

      if (channelKey === "project") {
        Echo.private(
          "addon." +
          tenantId +
          "." +
          channelKey +
          "." +
          projectId +
          ".participants"
        ).listen("FetchProjectParticipants", (response) => {
          store.dispatch(
            "wbs/getProjectParticipants",
            state.projectId
          );
        });
      }
    };

    const updateNodesProperties = () => {
      updatedProperties.value = tempUpdateProperties;
      updatesNodes.forEach(item => {
        const node = state.rows.find(i => i.id == item.id);
        if (node) {
          node[item.keyName] = item.value;
        }
      });

      updateNodesStore();

      setTimeout(() => {
        updatedProperties.value = [];
        updatesNodes = [];
        state.rows.forEach(node => {
          node.isUpdate = false;
          node.isNew = false;
        })
        updateNodesStore();
      }, 2000);
    }

    let updatedProperties = ref([]),
      isUserInteraction = false,
      tempUpdateProperties = [],
      { compareNodes } = useWbsDocument(),
      updatesNodes = [],
      debounceUpdateNodesProperties = debounce(() => {
        updateNodesProperties();
        isUserInteraction = false;
      }, 10000);

    const updateNodes = async (channelKey) => {
      tempUpdateProperties = [];
      const fieldsMap = {
        jiraIssueStatus: "status",
      };

      let lastUpdateDate = store.getters["wbs/nodesMeta"]?.lastUpdateDate;
      if (!lastUpdateDate)
        lastUpdateDate = moment(new Date()).format("Y-MM-d H:m:s");
      const updatingList = await store.dispatch(
        "wbs/latestNodesChanges",
        {
          channelKey: channelKey,
          modelId: state.projectId,
          lastUpdateDate: lastUpdateDate,
        }
      );

      if (!updatingList.data) return;

      store.commit('wbs/SET_NODES_META', updatingList.meta)

      for (let i = 0; i < updatingList.data.length; i++) {
        const updateNode = updatingList.data[i];
        const targetNode = state.rows.find(
          (n) => n.id == updateNode.id
        );
        if (targetNode) {
          // delete node from document
          if (updateNode?.isDeleted == true) {
            state.rows = state.rows.filter(
              (n) => n.id !== updateNode.id
            );
            state.rows.forEach((n) => {
              if (n.parentId == updateNode.id) n.parentId = null;
            });
          } else {
            // update properties
            const detectedChanges = compareNodes(
              targetNode,
              updateNode
            );

            targetNode.x = 0;
            targetNode.y = 0;
            targetNode.visible = true;

            Object.keys(detectedChanges).forEach((k) => {
              tempUpdateProperties.push({
                id: targetNode.id,
                name: fieldsMap[k] ? fieldsMap[k] : k,
              });
            });

            Object.keys(updateNode).forEach((k) => {
              if (!['listChildrenCollapsed', 'wbsChildrenCollapsed'].includes(k)) {
                // targetNode[k] = updateNode[k];
                updatesNodes.push({ id: updateNode.id, keyName: k, value: updateNode[k] })
              }
            });

            if (updateNode.syncError) {
              store.commit("wbs/ADD_ERROR_NODE", {
                id: updateNode.id,
                createdNode: updateNode,
                action: "create",
                error: updateNode.syncErrorMessage,
              });
            } else {
              store.commit(
                "wbs/DELETE_ERROR_NODE",
                updateNode.id
              );
            }
          }
        } else if (!updateNode?.isDeleted) {
          // add new node
          updateNode.x = 0;
          updateNode.y = 0;
          updateNode.visible = true;
          updateNode.isNew = true;
          state.rows.push(updateNode);
          if (updateNode.syncError) {
            store.commit("wbs/ADD_ERROR_NODE", {
              id: updateNode.id,
              createdNode: updateNode,
              action: "create",
              error: updateNode.syncErrorMessage,
            });
          } else {
            store.commit("wbs/DELETE_ERROR_NODE", updateNode.id);
          }
        }
      }

      if (isUserInteraction)
        debounceUpdateNodesProperties()
      else
        updateNodesProperties()
    };

    const makeRows = () => {
      let result = store.getters["wbs/nodes"].map((node) => {
        let childrenCollapsed = node.listChildrenCollapsed;
        return {
          ...node,
          id: node.id,
          codeOfAccounts: node.codeOfAccounts,
          parentId: node.parentId,
          summary: node.summary,
          parentKey: "",
          path: node.path,
          jiraIssueKey: node.jiraIssueKey,
          jiraIssueUrl: node.jiraIssueUrl,
          jiraIssueType: node.jiraIssueType,
          subtask: node.jiraIssueType?.subtask,
          isSubtask: node.jiraIssueType?.subtask,
          jiraIssueUri: node.jiraIssueType?.iconUri || node.jiraIssueType?.iconUrl,
          assignee: node.assignee,
          status: node.jiraIssueStatus?.name,
          jiraIssueStatus: node.jiraIssueStatus,
          isUpdate: node.isUpdate,
          isNew: node.isNew,
          syncError: node.syncError,
          syncErrorMessage: node.syncErrorMessage,
          colorNode: node.colorNode,
          color: node.colorNode?.split(":")[0],
          aggregateProgress: node.aggregateProgress,
          timeSpentFormatted: node.timeSpentFormatted,
          remainingEstimateFormatted:
            node.remainingEstimateFormatted,
          originalEstimateFormatted:
            node.originalEstimateFormatted,
          originalEstimateInStoryPoints:
            node.originalEstimateInStoryPoints,
          progress: useCalcProgress(
            node.originalEstimateFormatted,
            node.timeSpentFormatted,
            node.remainingEstimateFormatted
          ),
          childrenDisplayOption: node.childrenDisplayOption,
          childrenCount: node?.children?.length,
          childrenCollapsed: childrenCollapsed,
          displayChildren:
            childrenCollapsed == true ? false : true,
          statusColor: node?.jiraIssueStatus?.categoryColor,
          statusColorText: getStatusColorText(
            node?.jiraIssueStatus?.categoryColor
          ),
          topNode: node.topNode,
          topNodeId: node.topNodeId,
          isOrphaned: node.isOrphaned,
          visible: true,
        };
      }).map(r => {
        return {
          ...r, ...{
            skillId: 0,
            overview: {
              startDate:
                '10/Dec/22', dueDate: '20/Dec/22',
              originalEstimate: r.originalEstimateFormatted,
              timeSpent: r.timeSpentFormatted,
            }
          }
        }
      })
      state.rows = result;
    };

    const updateNodesStore = () => {
      store.commit("wbs/UPDATE_NODES", state.rows);
    };

    const setData = () => {
      setTimeout(() => {
        makeRows();
        updateNodesStore();
      }, 100);
    };

    const getNodes = async (hideLoader = false) => {
      if (!store.getters["wbs/nodes"].length) {
        const response = await store.dispatch("wbs/getNodes", {
          projectId: state.projectId,
          hideLoader,
        });
        return response;
      } else {
        store.commit(
          "wbs/UPDATE_NODES",
          store.getters["wbs/cachedNodes"][state.projectId]
        );
        setData();
      }
    };

    const getHierarchy = async () => {
      if (!store.getters["settings/hierarchy"].length)
        await store.dispatch("settings/getNodesHierarchy");
    };

    const getIssueTypes = async () => {
      if (!store.getters["settings/issueTypes"].length)
        await store.dispatch("settings/getIssueTypes");
    };

    const loadInstance = async (id) => {
      state.projectId = parseInt(id);
      store.commit("wbs/CLEAR_CACHED_STATUS");
      await getWbsState(state.projectId);

      socketsOff();
      socketsOn(store.getters["profile/tenantId"], state.projectId);

      getHierarchy();
      getIssueTypes();
    };

    const setSessionState = async (value) => {
      if (state.source !== "projects") return;
      if (value == true) {
        if (!store.getters["wbs/projectParticipants"].length)
          await store.dispatch(
            "wbs/addProjectParticipants",
            state.projectId
          );
      } else {
        if (
          store.getters["wbs/projectParticipants"].length &&
          state.projectId
        )
          await store.dispatch(
            "wbs/deleteProjectParticipant",
            state.projectId
          );
      }
    };

    const setCalcHeight = () => {
      state.calcHeight =
        wbsContainer.value && wbsContainer.value.clientHeight - 180;
    };

    let unsubscribeAction;
    onMounted(async () => {
      setCalcHeight();
      const projectId = parseInt(router.currentRoute.value?.params?.id);
      const { readCookie } = useCookies();
      let source;
      switch (router.currentRoute.value.name) {
        case "wbs-project":
          store.commit("wbs/SET_WBS_SOURCE", "projects");
          source = "projects";
          break;
        case "wbs-schedule-management":
          store.commit("wbs/SET_WBS_SOURCE", "projects");
          source = "projects";
          break;
        case "wbs-project-list-view":
          store.commit("wbs/SET_WBS_SOURCE", "projects");
          source = "projects";
          break;
        default:
          store.commit("wbs/SET_WBS_SOURCE", "templates");
          source = "templates";
          break;
      }

      unsubscribeAction = store.subscribe((action, state) => {
        if (action.type == "wbs/UPDATE_LAST_UPDATE_MILLESECONDS") {
          isUserInteraction = true;
        }

        if (["wbs/SET_NODES", "wbs/UPDATE_NODE"].includes(action.type)) {
          setData();
        }

        if (action.type == "wbs/UPDATE_NODE") {
          if (action.payload.createdNode) {
            const createdNode = action.payload.createdNode;
            if (source == 'templates') {
              toaster.success(
                `Issue has been successfuly created`,
                {
                  position: "top-right",
                }
              );
            } else {
              toaster.success(
                `Issue ${createdNode?.jiraIssueKey} - ${createdNode.summary} has been successfuly created`,
                {
                  position: "top-right",
                }
              );
            }
          }
        }
      });

      const viewStyle = await readCookie(`view-style-${projectId}`);
      if (viewStyle == "displayAsTree") {
        onPanelAction("displayAsTree");
        return;
      }

      await loadInstance(projectId);
      await getNodes();

      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "hidden";

      setSessionState(true);
      window.addEventListener("beforeunload", () => {
        socketsOff();
        setSessionState(false);
      });

      window.addEventListener("resize", setCalcHeight);
    });

    onUnmounted(() => {
      if (!window?.isTestRun)
        window?.source?.cancel("Operation canceled by the user.");
        
      window.removeEventListener("resize", setCalcHeight);
      store.commit("wbs/SET_CACHED_NODES", {
        projectId: state.projectId,
        nodes: state.rows,
      });

      const exists = [
        "wbs-project",
        "wbs-template",
        "wbs-template-list-view",
        "wbs-project-list-view",
        "wbs-schedule-management",
      ].some((i) => i == router?.currentRoute?.value?.name);

      if (router?.currentRoute?.value.name && !exists) {
        store.commit("wbs/RESET_CACHED_NODES");
        store.commit("wbs/RESET_WBS_STATE");
        store.commit("wbs/SET_EXISTS_IMPORT", false);
        setSessionState(false);
      } else {
        if (router.currentRoute.value.params.id != state.projectId) {
          store.commit("wbs/RESET_CACHED_NODES");
          store.commit("wbs/RESET_WBS_STATE");
          setSessionState(false);
        }
      }

      document.body.style.overflow = "initial";
      unsubscribeAction();
      socketsOff();

      const channelKey = router.currentRoute.value?.name?.includes(
        "wbs-project",
        "wbs-project-list-view"
      )
        ? "project"
        : "template";
      if (channelKey === "project") {
        window.removeEventListener("beforeunload", setSessionState);
      }
    });

    const setExpand = (value) => {
      state.expandAll = null;
      nextTick(() => {
        state.expandAll = value;
        state.rows.forEach((n) => {
          n.listChildrenCollapsed = state.expandAll ? false : true;
        });
        store.dispatch("wbs/setChildrenCollapsedAll", {
          projectId: state.projectId,
          viewOption: "list",
          childrenCollapsed: !value,
        });
      });
    };
    const onDragEnd = (e) => {
      console.log('onDragEnd=', e)
    };
    const closeDropDown = () => {
      if (!currentDropDown.value) return;

      dropDownPosition.value = {
        display: "none",
      };
    };

    const hideDropDowns = () => {
      closeDropDown();
    };

    const onChangeDropDown = async (item) => {
      if (!item) {
        closeDropDown();
        return;
      }
      
			if (currentDropDown.value.name == "assigned") {
				const node = state.rows.find(
					(n) => n.id == currentNode.value.id
				);
				node.assigneeId = item.value == -1 ? null : item.value;

				node.assignee =
					item.value == -1
						? null
						: {
							id: item.value,
							displayName: item.displayName,
							iconUri: item.iconUri,
						};

				store.dispatch("wbs/editNode", {
					projectId: state.projectId,
					nodeId: node.id,
					node,
				});
				closeDropDown();
			}

      if (currentDropDown.value.name == "status") {
        const node = state.rows.find(
          (n) => n.id == currentNode.value.id
        );

        if (!item) return;

        node.jiraIssueStatusId = item.id;
        node.jiraIssueStatus = {
          ...node.jiraIssueStatus, ...{
            id: item.id,
            name: item.name,
            categoryColor: item.categoryColor,
            jiraIssueStatusId: item.id,
          }
        };

        store.dispatch("wbs/editNode", {
          projectId: state.projectId,
          nodeId: node.id,
          node,
        });
        closeDropDown();
      }
    };

    const onCellClick = (e) => {
      const node = state.rows.find((n) => n.id == e.id);
   
      switch (e.name) {
				case "assignee":
					openAssignDropDown(node, e.event);
					break;        
        case "status":
          openStatusDropDown(node, e.event);
          break;
        default:
          closeDropDown();
          break;
      }
    };

    const onShowChildren = async ({ value, row }) => {
      const foundRow = state.rows.find((n) => n.id == row.id);
      foundRow.listChildrenCollapsed = !value;
      await store.dispatch("wbs/setChildrenCollapsed", {
        projectId: state.projectId,
        nodeId: foundRow.id,
        viewOption: "list",
        childrenCollapsed: foundRow.listChildrenCollapsed,
      });

      store.commit("wbs/UPDATE_NODE_LIST_COLLAPSE", {
        id: foundRow.id,
        childrenCollapsed: foundRow.listChildrenCollapsed,
      });
    };


    const onChangeSkill = ({ e, row, getChildrenAll }) => {
      ganttComponent.value.listView.selectedRows.forEach(id => {
        let index = state.rows.findIndex(r => r.id == id);
        state.rows[index].skillId = e.data.value;
        const children = getChildrenAll(state.rows[index].id);
        if (e.applyChildren) {
          children.forEach((r, i) => {
            r.skillId = e.data.value;
          });
        }

        state.rows[index].path.split("-").forEach(parentId => {
          const muitipleSkills = state.rows.filter(r => r.parentId == parentId).reduce((acc, row) => {
            if (!acc.includes(row.skillId))
              acc.push(row.skillId)
            return acc;
          }, []);
          const r = state.rows.find(r => r.id == parentId)
          r.skillId = state.rows[index].skillId;
          if (muitipleSkills.length > 1) {
            r.skillId = muitipleSkills.join(',');
          }
        })
      })

    }

    // make state rows as reference of issue list rows
    const onLoadRows = (rows) => {
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        state.rows[i] = row;
      }
    }

    watch(() => ganttComponent.value?.listViewScroll, (current) => {
      scrollTop.value = current;
    })

    return {      
      state,
      ...toRefs(state),
      links,
      wbsContainer,
      projectStatistic,
      isImportRun,
      isImportError,
      notificationList,
      viewMode,
      openStatusDropDown,
      currentDropDown,
      dropDownPosition,
      setWbsState,
      setExpand,
      displayCriticalPath,
      dimension,
      dimensionList,
      ganttComponent,
      scrollTop,
      currentNode,
      getStatusColorText,
      onChangeDropDown,
      hideDropDowns,
      onPanelAction,
      onDragEnd,
      onCellClick,
      onShowChildren,
      onChangeSkill,
      onLoadRows,
    };
  },
};
</script>
<style>
.grid.full-screen {
  height: 100vh !important;
}
</style>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;600&display=swap');

* {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.wbs-container {
  padding-top: 81px;
  height: 100vh;
}

.swith-mode {
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  background: #ffff;
  border-radius: 4px;
  text-transform: none;
  color: #A0A5B0;
  display: grid;
  grid-template-columns: 225px 1fr;
  gap: 5px;
  height: 49px;
  align-items: center;
  padding: 0 5px;
  border: 1px solid #EBEEF4;

  .swith-mode-buttons {
    background: #F3F6FB;
    padding: 3px;
    align-items: center;
    display: flex;
    gap: 5px;
  }

  .swith-button {
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    background: #F3F6FB;
  }

  .swith-button.active {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(119, 121, 126, 0.19);
    border-radius: 2px;
    color: #23252A;

  }
}

::v-deep(.cell-value) {
  width: 100%;
}

.cell {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #A0A5B0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 53px;
  gap: 5px;

  &.is-root {
    display: grid;
    grid-template-columns: 1fr 53px;
    gap: 5px;
    align-items: center;
  }

  &.is-parent {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    color: #23252A;
  }

  .cell-status {
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #006644;
    background: #E3FCEF;
    border-radius: 4px;
    padding: 5px;
    text-align: center;
  }

  .skill-dropdown {
    position: relative;
  }
}
</style>
