<template>
  <div class="info-content">
    <app-baloon :position="'top'">
      <template v-slot:title>
        <strong class="title">
          <icon-error :width="'20px'" :height="'20px'" :fill="'#d81d1d'" />
          {{ translate("wbs.import_error") }}
          <div class="close" @click="onClose">
            <icon-close :width="'20px'" :height="'20px'" :fill="'#444'" />
          </div>
        </strong>
      </template>
      <template v-slot:content>
        <p>
          {{ translate("wbs.import_error_description") }}
        </p>
      </template>
    </app-baloon>
  </div>
</template>
<script>
import AppBaloon from "@/components/shared/baloon/Baloon";
import IconAttention from "@/components/icons/IconAttention.vue";
import IconImport from "@/components/icons/IconImport.vue";
import IconError from "@/components/icons/IconError.vue";
import IconClose from "@/components/icons/IconClose.vue";
export default {
  components: {
    AppBaloon,
    IconAttention,
    IconImport,
    IconError,
    IconClose,
  },
  emits: ["onClose"],
  inject: ["translate"],
  setup(props, { emit }) {
    const onClose = () => {
      emit("onClose");
    };
    return { onClose };
  },
};
</script>

<style lang="scss" scoped>
.header {
  text-decoration: underline;
  display: block;
  margin-bottom: 5px;
}
p {
  font-size: 14px;
  line-height: 21px;
}
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #363636;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  justify-content: space-between;
  align-items: center;
  strong {
    font-weight: 600;
  }
  .close {
    cursor: pointer;
    justify-self: end;
  }
}
::v-deep(.baloon-container) {
  .content {
    height: auto;
    overflow: auto;
  }
}
.icon-import {
  background: #0f8af9;
  color: #ffffff;
  width: 62px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: relative;
  top: 5px;
  svg {
    position: absolute;
  }
}
</style>
