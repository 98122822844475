<template>
  <div
    class="baloon-container"
    v-bind:class="[position, theme]"
    :style="{ width: width }"
  >
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    position: {
      type: String,
      default: "left",
      validator: function (value) {
        return ["left", "right", "top", "bottom"].indexOf(value) !== -1;
      },
    },
    theme: {
      type: String,
      default: "white",
      validator: function (value) {
        return ["white", "black"].indexOf(value) !== -1;
      },
    },
    width: {
      type: String,
      default: "400px",
    },
    height: {
      type: String,
      default: "200px",
    },
  },
};
</script>
<style scoped lang="scss">
.baloon-container {
  z-index: 99999;
  display: flex;
  flex-direction: column;
  background: rgba(54, 54, 54, 0.85);
  backdrop-filter: blur(4px);
  border-radius: 4px;
  padding: 15px;
  position: relative;
  box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.15);
  img {
    grid-area: Preview;
    position: relative;
    z-index: 1;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: white;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;

    top: -11px;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 17.5px 35px 17.5px;
    border-color: transparent transparent #545454 transparent;
  }

  &.white {
    background: white;
    color: #444;
    p {
      color: #444;
    }

    &:before {
      border-color: transparent transparent #fff transparent;
    }
    &.left {
      &:before {
        border-color: transparent transparent #fff transparent;
      }
    }
    &.top {
      &:before {
        border-color: transparent transparent #fff transparent;
      }
    }
  }

  &.left {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 16px;
      left: -13px;
      transform: rotate(150deg);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10.5px 18px 10.5px;
      border-color: transparent transparent #545454 transparent;
    }
  }
  &.top {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: -11px;
      left: 0;
      transform: rotate(240deg);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10.5px 18px 10.5px;
      border-color: transparent transparent #545454 transparent;
    }
  }
}
</style>
