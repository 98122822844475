<template>
  <div class="node-content">
    <div class="icon">
      <slot name="icon" />
    </div>
    <div class="split"></div>
    <div class="name">
      <slot name="name" />
    </div>
    <div class="add-cutom-node">
      <slot name="add-custom-node"></slot>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import IconArrowDown from "@/components/icons/IconArrowDown.vue";
export default {
  components: { IconArrowDown },
  setup() {
    const state = reactive({});

    return {
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.node-content {
  position: relative;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  display: grid;
  grid-template-columns: minmax(151px, max-content) 20px minmax(min-content, 120px) 1fr 120px;

  align-items: center;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #c3cad9;
  border-radius: 4px;
  padding-right: 30px;

  .icon {
    display: flex;
    justify-content: center;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .split {
    height: 40px;
    width: 1px;
    background: #c3cad9;
    justify-self: start;
  }
  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #363636;
  }

  .add-cutom-node {
    justify-self: center;
  }
}
</style>
