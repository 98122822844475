<template>
  <svg :width="width" :height="height" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.00776 4.06357C6.67111 2.62226 8.79908 1.83025 11 1.83332C16.0628 1.83332 20.1667 5.93724 20.1667 11C20.1667 12.958 19.5525 14.773 18.5075 16.2617L15.5833 11H18.3333C18.3335 9.56231 17.911 8.15631 17.1185 6.95681C16.3259 5.7573 15.1983 4.81722 13.8758 4.25344C12.5532 3.68965 11.0942 3.52705 9.67997 3.78584C8.26578 4.04463 6.95884 4.7134 5.92168 5.70899L5.00776 4.06357ZM16.9923 17.9364C15.3289 19.3777 13.2009 20.1697 11 20.1667C5.93726 20.1667 1.83334 16.0627 1.83334 11C1.83334 9.04199 2.44751 7.22699 3.49251 5.73832L6.41668 11H3.66668C3.66656 12.4377 4.08903 13.8437 4.88157 15.0432C5.6741 16.2427 6.80174 17.1828 8.12426 17.7465C9.44679 18.3103 10.9059 18.4729 12.3201 18.2141C13.7342 17.9553 15.0412 17.2866 16.0783 16.291L16.9923 17.9364Z"
      fill="#23252A" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "transparent",
    },
    stroke: {
      type: [String],
      default: "white",
    },
  },
};
</script>
