import * as types from '@/store/mutation-types';
export default {
  [types.SET_DASHBOARD](state, payload) {
    state.projectsCount = payload.allProjectsCount;
    state.templatesCount = payload.templates.meta.total;
    state.activeParticipants = payload.activeParticipants;
    state.sessions = payload?.sessions?.data || [];
    state.templates = payload.templates.data;
    state.projects = payload.projects.data;
    state.hoursSaved = payload.hoursSaved;
    state.activeProjects = payload.projects.meta.total;
  },
};
