<template>
  <div class="form-group" :class="{ 'form-check-inline': inlineGroup }">
    <div class="form-check" v-for="item in options" :key="item.id">
      <label class="radio-button" :class="{ disabled: !!item.disabled }">
        <input
          type="radio"
          @change="onChange(item.id)"
          :name="item.id"
          :checked="checked === item.id"
          :disabled="item.disabled"
        />
        {{ item.name }}
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from "vue";
export default {
  props: {
    inlineGroup: {
      default: true,
    },
    options: {
      type: Array,
      required: true,
      validator(opts) {
        return !opts.find((opt) => typeof opt !== "object");
      },
    },
    modelValue: [Number, String],
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = reactive({
      checked: props.modelValue,
    });
    watch(
      () => props.modelValue,
      (current) => {
        state.checked = current;
      }
    );
    const onChange = (id) => {
      state.checked = id;
      emit("update:modelValue", state.checked);
    };
    return {
      ...toRefs(state),
      onChange,
    };
  },
};
</script>
<style lang="scss" scoped>
.form-check-inline {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15px;
  align-items: center;
  justify-content: start;
}
.form-check {
  padding-left: 0px;
}
.radio-button {
  position: relative;
  padding-left: 25px;
  padding-top: 2px;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
  &.disabled {
    cursor: not-allowed;
    color: #b0bcc9;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid #0085ff;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #d7d7d7;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #0085ff;
  }
}

label {
  font-weight: normal;
  font-size: 12px;
  color: #365674;
}
</style>
