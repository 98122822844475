<template>
  <div class="project-indicator">
    <div class="name">
      {{ translate('wbs.project_progress') }}
      <div class="value">{{ value }}%</div>
    </div>
    <div class="progress-bar">
      <slot />
    </div>
    <div class="total">{{ estimate }}</div>
  </div>
</template>
<script>
export default {
  inject: ['translate'],
  props: {
    estimate: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "0",
    },
  },
  setup(props) { },
};
</script>
<style lang="scss" scoped>
.name {
  grid-area: Name;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #A0A5B0;
}

.value {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-right: 15px;
  color: #23252A;
}

.project-indicator {
  height: 20px;
  border-radius: 2px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "Name Progress Total";
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  padding-left: 23px;
  position: relative;
  z-index: -1;
}

.progress-bar {
  background: #fff;
  border-radius: 4px;
  position: relative;
  width: 100%;
  grid-area: Progress;

  :v-deep(.progress-bar) {
    background: #fff;
  }
}

.total {
  grid-area: Total;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  white-space: nowrap;
  justify-self: end;

}
</style>
