import * as types from '@/store/mutation-types';
export default {
  [types.APP_SHOW_SIDEBAR](state, payload) {
    state.showSideBar = payload;
  },
  [types.SET_SEARCH_INSTANCE](state, payload) {      
    state.searchList = payload;
  },
  [types.SET_NAVBAR_WIDTH](state, payload) {      
    state.navBarWidth = payload;
  },
  [types.SET_SERVER_ERROR](state, payload) {
    state.serverError = payload;
  },
}
