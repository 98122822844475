<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5833 2.74984H18.25C18.4931 2.74984 18.7263 2.84641 18.8982 3.01832C19.0701 3.19023 19.1667 3.42339 19.1667 3.6665V18.3332C19.1667 18.5763 19.0701 18.8094 18.8982 18.9814C18.7263 19.1533 18.4931 19.2498 18.25 19.2498H1.74999C1.50688 19.2498 1.27372 19.1533 1.10181 18.9814C0.929905 18.8094 0.833328 18.5763 0.833328 18.3332V3.6665C0.833328 3.42339 0.929905 3.19023 1.10181 3.01832C1.27372 2.84641 1.50688 2.74984 1.74999 2.74984H5.41666V0.916504H7.25V2.74984H12.75V0.916504H14.5833V2.74984ZM17.3333 10.0832H2.66666V17.4165H17.3333V10.0832ZM12.75 4.58317H7.25V6.4165H5.41666V4.58317H2.66666V8.24984H17.3333V4.58317H14.5833V6.4165H12.75V4.58317ZM4.49999 11.9165H6.33333V13.7498H4.49999V11.9165ZM9.08333 11.9165H10.9167V13.7498H9.08333V11.9165ZM13.6667 11.9165H15.5V13.7498H13.6667V11.9165Z"
      fill="#23252A" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
