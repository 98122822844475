<template>
  <div class="row" ref="rowEl">
    <div class="ava">
      <img v-if="row.iconUri" :src="row.iconUri" alt="" />
      <icon-default v-else :width="'35px'" :height="'35px'" />
      {{ row.name }}
    </div>
    <div class="cell members">
      <ul>
        <li :style="{ zIndex: countMembers - i }" :key="i" v-for="(access, i) in members">
          <img :src="access.iconUri" />
        </li>
        <li class="plus-one" v-if="countMembers > 4">
          +{{ countMembers - members.length }}
        </li>
      </ul>
    </div>
    <div class="cell">
      <strong>{{ row.nodesCount }}</strong>
    </div>
    <div class="cell">
      <strong>{{ row.updatedAt }}</strong>
    </div>
    <div class="cell">
      <strong>{{ row.status }}</strong>
    </div>
    <div class="cell open-template">
      <slot />
      <icon-pencil
        @click="edit(row)"
        :fill="'transparent'"
        :stroke="'#7B818E'"
        :width="'19px'"
        :height="'19px'"
      />
      <icon-trash @click="remove(row)" :fill="'none'" :width="'19px'" :height="'19px'" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, watch, computed } from "vue";
import IconNextRound from "@/components/icons/IconNextRound.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconPencil from "@/components/icons/IconPencil.vue";
import IconDefault from "@/components/icons/IconDefault.vue";
export default {
  components: { IconNextRound, IconTrash, IconPencil, IconDefault },
  props: {
    row: {
      type: Object,
      default: {},
    },
  },
  emits: ["onRemove", "onEdit"],
  setup(props, { emit }) {
    const state = reactive({});
    const setState = () => {
      const copyRow = JSON.parse(JSON.stringify(props.row));
      state.row = copyRow;
      state.countMembers = copyRow.members.length;
      state.members = computed(() =>
        copyRow.members.length > 4 ? copyRow.members.splice(0, 4) : copyRow.members
      );
    };
    watch(
      () => props.row,
      (current) => {
        setState();
      }
    );

    const remove = (row) => {
      emit("onRemove", row.id);
    };

    const edit = (row) => {
      emit("onEdit", row);
    };

    setState();

    return {
      ...toRefs(state),
      remove,
      edit,
    };
  },
};
</script>

<style lang="scss" scoped>
.row {
  border-bottom: 1px solid #c3cad93d;
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-template-columns: minmax(328px, 400px) 164px 77px 181px minmax(100px, 1fr) 200px;
  padding: 0 25px;
  &:hover {
    background: #f5f8fe;
  }
}

.cell {
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #363636;
  position: relative;
  strong {
    font-weight: 500;
  }
}

.ava {
  font-weight: 500;
  display: flex;
  align-items: center;

  svg,
  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
    flex-shrink: 0;
  }
}

.members {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  ul li + li {
    margin-left: -10px;
  }
  .plus-one {
    margin-left: 0;
    font-size: 14px;
    line-height: 21px;
    color: #2684fe;
  }
  img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 2px solid #ffffff;
  }
}
.cell-remove {
  cursor: pointer;
  justify-self: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15px;
}
.cell-join {
  width: 80px;
  justify-self: start;
}
::v-deep(.button) {
  height: 30px;
}
.open-template {
  display: flex;
  gap: 15px;
  justify-self: end;
  * {
    cursor: pointer;
  }
  ::v-deep(*) {
    cursor: pointer;
  }
}
</style>
