<template>
  <div class="modal-body">
    <div class="close" @click="closeModal">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div class="text">
      {{ text }}
    </div>
    <div class="actions">
      <app-button color="default" @click="closeModal">
        {{ translate("modal.submit") }}
      </app-button>
    </div>
  </div>
</template>

<script>
import { useModalHelper } from "@/helpers/modalHelper";
import AppButton from "@/components/shared/Button";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  components: { AppButton, IconClose },
  props: {
    title: {
      type: String,
      default: "Confirmation",
      required: true,
    },
    text: {
      type: String,
      default: {},
      required: true,
    },
  },
  inject: ["translate"],
  setup(props) {
    const { closeModal, submitModal } = useModalHelper();

    const submit = () => {
      submitModal();
    };

    return {
      submit,
      closeModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #0085ff;
      svg {
        fill: white;
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .text {
    margin: 25px;
    font-weight: normal;
    font-size: 12px;
    line-height: 200%;
    text-align: center;
    color: #363636;
  }
  .actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;
    justify-content: center;
    .button {
      height: 55px;
      max-width: 250px;
      justify-self: center;
    }
  }
}
</style>
