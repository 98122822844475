export default {
  statusList: [],
  nodes: [],
  nodesMeta: {},
  links: [],
  importList: [],
  filterList: [],
  wbsState: {},
  activeImport: false,
  jiraProjects: [],
  jiraStatuses: [],
  jiraCategories: [],
  jiraPriorities: [],  
  source: 'projects',
  projectParticipants: [],
  lastIssueTypeId: null,
  isPrintRun: false,
  cachedNodeStatuses: [],
  errorNodes: [],
  cachedNodes: {},
  existsImport: false,
  currentJiraTicket: null,
  issueTypesByProject: [],
  ganttLinks: [],
  isNodesLoading: false,
};
