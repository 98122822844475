<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99998 0.833313C15.0627 0.833313 19.1666 4.93723 19.1666 9.99998C19.1666 15.0627 15.0627 19.1666 9.99998 19.1666C4.93723 19.1666 0.833313 15.0627 0.833313 9.99998C0.833313 4.93723 4.93723 0.833313 9.99998 0.833313ZM9.99998 2.66665C5.95015 2.66665 2.66665 5.95015 2.66665 9.99998C2.66665 14.0498 5.95015 17.3333 9.99998 17.3333C14.0498 17.3333 17.3333 14.0498 17.3333 9.99998C17.3333 5.95015 14.0498 2.66665 9.99998 2.66665ZM13.5136 5.72556C13.7308 5.5734 14.026 5.59906 14.213 5.78698C14.4 5.97398 14.4238 6.26915 14.2707 6.48365C12.2724 9.27581 11.1724 10.7718 10.9726 10.9726C10.4354 11.5088 9.56456 11.5088 9.0274 10.9726C8.49115 10.4354 8.49115 9.56456 9.0274 9.0274C9.37023 8.68548 10.8653 7.58456 13.5136 5.72556ZM15.0416 9.08331C15.5476 9.08331 15.9583 9.49398 15.9583 9.99998C15.9583 10.506 15.5476 10.9166 15.0416 10.9166C14.5356 10.9166 14.125 10.506 14.125 9.99998C14.125 9.49398 14.5356 9.08331 15.0416 9.08331ZM4.95831 9.08331C5.46431 9.08331 5.87498 9.49398 5.87498 9.99998C5.87498 10.506 5.46431 10.9166 4.95831 10.9166C4.45231 10.9166 4.04165 10.506 4.04165 9.99998C4.04165 9.49398 4.45231 9.08331 4.95831 9.08331ZM7.08315 5.78698C7.44065 6.14448 7.44065 6.72473 7.08315 7.08315C6.72565 7.44065 6.14448 7.44065 5.78698 7.08315C5.42948 6.72565 5.42948 6.14448 5.78698 5.78698C6.14448 5.42948 6.72473 5.42948 7.08315 5.78698ZM9.99998 4.04165C10.506 4.04165 10.9166 4.45231 10.9166 4.95831C10.9166 5.46431 10.506 5.87498 9.99998 5.87498C9.49398 5.87498 9.08331 5.46431 9.08331 4.95831C9.08331 4.45231 9.49398 4.04165 9.99998 4.04165Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "",
    },
  },
};
</script>
