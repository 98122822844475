export default {
  statusList: state => state.statusList || [],
  nodes: state => state.nodes || [],
  nodesMeta: state => state.nodesMeta || [],
  links: state => state.links || [],
  filterList: state => state.filterList || [],
  importList: state => state.importList || [],
  project: state => state.project || {},
  wbsState: state => state.wbsState || {},
  jiraProjects: state => state.jiraProjects || [],
  jiraStatuses: state => state.jiraStatuses || [],
  jiraCategories: state => state.jiraCategories || [],
  jiraPriorities: state => state.jiraPriorities || [],
  filters: state => state.wbsState.filters || {},
  source: state => state.source,
  lastIssueTypeId: state => state.lastIssueTypeId || null,
  isImportRun: state => state.activeImport,
  isPrintRun: state => state.isPrintRun || false,
  projectParticipants: state => state.projectParticipants || [],
  cachedNodeStatuses: state => state.cachedNodeStatuses || [],
  errorNodes: state => state.errorNodes || [],
  nodesModelMeta: state => state.nodesMeta?.modelMeta,
  cachedNodes: state => state.cachedNodes || {},
  isExistsImport: state => state.existsImport || false,
  currentJiraTicket: state => state.currentJiraTicket,
  issueTypesByProject: state => state.issueTypesByProject || {},
  ganttLinks: state => state.ganttLinks,
  isNodesLoading: state => state.isNodesLoading,
};
