<template>
  <div class="modal-body">
    <div class="close" @click="close">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="text">
      <span v-if="isLoading">{{ translate('please_wait') }}</span>
      <img :src="src" @load="loadedComplete" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useModalHelper } from "@/helpers/modalHelper";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  components: { IconClose },
  inject: ['translate'],
  props: {
    src: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const isLoading = ref(true),
     { closeModal } = useModalHelper();
    const loadedComplete = () => {
      isLoading.value = false;
    };

    const close = () => {
      closeModal();
    };

    return {
      loadedComplete,
      isLoading,
      close,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
  max-width: 750px;
  padding-top: 15px;
  img {
    width: 100%;
  }
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background: #0085ff;

      svg {
        fill: white;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }

  .text {
    margin: 15 px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #363636;
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;
    justify-content: center;

    .button {
      height: 55px;
      max-width: 250px;
      justify-self: center;
    }
  }
}
</style>
