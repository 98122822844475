<template>
  <div class="welcome-screen">
    <img class="flower" src="@/assets/images/flower.svg" alt="" />
    <img class="man" src="@/assets/images/man.svg" alt="" />
    <img class="clock" src="@/assets/images/clock.svg" alt="" />
    <div class="wrapper">
      <div class="header-text text-center">
        <h1>{{ translate("user_access.no_access") }}</h1>
        <p>{{ translate("user_access.request") }}</p>
      </div>
      <app-button color="purple" @click="openDashboard">{{
        translate("user_access.close")
      }}</app-button>
    </div>
  </div>
</template>
<script>
import router from "@/router";
import IconAnetoLogo from "@/components/icons/IconAnetoLogo.vue";
import AppButton from "@/components/shared/Button";

export default {
  inject: ["translate"],
  components: {
    IconAnetoLogo,
    AppButton,
  },
  setup() {
    const openDashboard = () => {
      router.push({ name: "dashboard" });
    };

    return {
      openDashboard,
    };
  },
};
</script>
<style lang="scss" scoped>
.welcome-screen {
  background: white;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.clock,
.man,
.flower {
  position: absolute;
}

.flower {
  left: 0;
  top: 0;
}

.man {
  bottom: 45px;
  left: 25px;
}
.clock {
  right: 0;
  bottom: 0;
}

.wrapper {
  max-width: 620px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 740px;
  justify-content: center;
  @media (max-width: 1200px) {
    min-height: 650px;
  }
}
.header-text {
  margin-top: 32px;
  margin-bottom: 32px;
}



.button {
  max-width: 160px;
}
.preview {
  img {
    margin-bottom: 35px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1200px) {
  .flower {
    left: -78px;
    top: -45px;
    display: none;
  }
  .man {
    width: 150px;
  }
  .clock {
    width: 260px;
    height: 230px;
    bottom: 35px;
  }
}
</style>
