import { computed, toRefs, reactive } from "vue";
import { useDateHelper } from "@/helpers/dateHelper";
import { result } from "lodash";
export function useCalcProgress(originalEstimateInHours, timeSpent, remainingEstimate) {
  const { parseInput } = useDateHelper(),
    state = reactive({
      calcEstimate: computed(() => {
        return parseInput(originalEstimateInHours);
      }),
      calcSpent: computed(() => {
        return parseInput(timeSpent);
      }),
      calcTimePerc: computed(() => {
        return (state.calcSpent * 100) / state.calcEstimate;
      }),
      calcOverLog: computed(() => {
        return 100 - state.calcTimePerc < 0 ? Math.abs(100 - state.calcTimePerc) : 0;
      }),
      calcRemaining: computed(() => {
        if (isNaN(parseInput(remainingEstimate))) return 0;
        return (
          (parseInput(remainingEstimate) /
            (state.calcSpent + state.calcEstimate)) *
          100
        );
      }),
      calcProgressPerc: computed(() =>{
        const timeSpent = state.calcSpent;
        const estimate = state.calcEstimate;
        if (!timeSpent || !estimate) return 0;
        const result =  parseFloat((timeSpent / estimate ) * 100).toFixed(2);
        return result;
      })
    })
  return {
    calcEstimate: state.calcEstimate || 0,
    calcSpent: state.calcSpent || 0,
    calcTimePerc: state.calcTimePerc || 0,
    calcOverLog: state.calcOverLog || 0,
    calcRemaining: state.calcRemaining || 0,
    calcProgressPerc: state.calcProgressPerc && state.calcProgressPerc > 0  ? state.calcProgressPerc : 0,
  }
}