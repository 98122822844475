<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3333 19.1668H1.66667C1.42355 19.1668 1.19039 19.0703 1.01849 18.8983C0.846577 18.7264 0.75 18.4933 0.75 18.2502V1.75016C0.75 1.50705 0.846577 1.27389 1.01849 1.10198C1.19039 0.930073 1.42355 0.833496 1.66667 0.833496H16.3333C16.5764 0.833496 16.8096 0.930073 16.9815 1.10198C17.1534 1.27389 17.25 1.50705 17.25 1.75016V18.2502C17.25 18.4933 17.1534 18.7264 16.9815 18.8983C16.8096 19.0703 16.5764 19.1668 16.3333 19.1668ZM15.4167 17.3335V2.66683H2.58333V17.3335H15.4167ZM5.33333 5.41683H12.6667V7.25016H5.33333V5.41683ZM5.33333 9.0835H12.6667V10.9168H5.33333V9.0835ZM5.33333 12.7502H9.91667V14.5835H5.33333V12.7502Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "#fff",
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>