<template>
  <div class="table-container">
    <div ref="tableEl" class="table">
      <!-- Table Header -->
      <div class="table-header">
        <slot name="header">
          <div class="columns">
            <div class="column" :class="{ 'is-not-sort': item.isSort == false }" v-for="(item, index) in header"
              :key="index" @click="changeSort(item)">
              <span v-html="item.title" />
              <div class="sort" v-if="orderBy.column == item.name">
                <icon-sort-desc v-if="orderBy.value == 'desc'" :width="'10px'" :fill="'transparent'" />
                <icon-sort-asc v-if="orderBy.value == 'asc'" :width="'10px'" :fill="'transparent'" />
              </div>
            </div>
          </div>
        </slot>
      </div>
      <!-- Table Body -->
      <div class="table-body">
        <!-- Slot for the row -->
        <slot name="row" />
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import IconSortDesc from "@/components/icons/IconSortDesc.vue";
import IconSortAsc from "@/components/icons/IconSortAsc.vue";

export default {
  props: {
    header: {
      type: [Array],
      default: [],
    },
    orderBy: {
      type: Object,
      default: {
        column: null,
        value: null,
      },
    },
  },
  emits: ["onChangeFilter", "onChangeOrder"],
  components: { IconSortDesc, IconSortAsc },
  setup(props, { emit }) {
    const tableEl = ref();
    const orderBy = ref(props.orderBy);
    const changeFilter = (filterName) => {
      emit("onChangeFilter", filterName);
    };
    const resetFilters = (e) => {
      emit("onChangeFilter", "");
      e.preventDefault();
    };

    const changeSort = (column) => {
      if (!column.isSort) return;
      if (orderBy.value.column == column.name) {
        if (orderBy.value.value == "asc") {
          orderBy.value.value = "desc";
        } else {
          orderBy.value.value = "asc";
        }
      } else {
        orderBy.value.column = column.name;
        orderBy.value.value = "asc";
      }

      emit("onChangeOrder", orderBy.value);
    };

    return {
      changeFilter,
      resetFilters,
      changeSort,
      tableEl,
      orderBy,
    };
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  position: relative;
}

.table-header {
  display: flex;
  align-items: center;
  border-top: 1px solid #c3cad93d;

  background: #fafafb;
  color: #a9afba;
  display: grid;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  gap: 15px;
  height: 40px;
  letter-spacing: -.02em;
  line-height: 14px;
  padding: 0 25px;
  .columns {
    width: 100%;
    display: grid;
    align-items: center;
    
    grid-template-columns: minmax(328px, 400px) 164px 77px 181px minmax(100px, 1fr) 200px;
    padding-left: 0;
    padding-right: 0;
  }

  .column {

    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #a9afba;
    display: flex;
    align-items: center;
    cursor: pointer;

    span ::v-deep(strong) {
      font-weight: 500;
      display: block;
      width: 100%;
      clear: both;
      color: #363636;
    }

    .sort {
      margin-left: 10px;
    }

    &.is-not-sort {
      cursor: default;
    }
  }
}
.table-body {
  overflow: auto;
  max-height: calc(100vh - 230px);
}
</style>
