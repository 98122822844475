<template>
  <div class="issue-component-container" :class="{
    'is-parent': issue.childrenCount > 0,
    'compact': issue.width < 400,
    'compact-medium': issue.width < 300,
    'compact-small': issue.width < 200,
  }">
    <div class="assignee" :data-testid="`assignee-button-${issue.jiraIssueKey}`" @click="onAssigneeClick">
      <img v-if="issue.assignee" :src="issue.assignee.iconUri" :title="issue.assignee.displayName" :data-testid="`assignee-${issue.jiraIssueKey}`">
      <icon-no-assignee v-else width="16px" height="16px" fill="#fff" class="unassignee-image" :data-testid="`assignee-${issue.jiraIssueKey}`" />
    </div>
    <div class="progress-bar" :data-testid="`progress-${issue.jiraIssueKey}`">
      <div :style="{ width: '50%' }" class="progress-bar-value"></div>
    </div>
    <div class="issue-data">
      <app-issue-popup-component :issue="issue" :issue-id="popupIssueId">
        <div class="issue-type" @click="popupIssueId = issue.id" :data-testid="`issue-type-${issue.jiraIssueKey}`">
          <img :src="issue.jiraIssueUri" alt="">
        </div>
        <div class="issue-key" @click="popupIssueId = null" :data-testid="`issue-key-${issue.jiraIssueKey}`">
          {{ issue.jiraIssueKey }}
        </div>
      </app-issue-popup-component>
      <div class="estimation" :data-testid="`estimation-${issue.jiraIssueKey}`">
        {{ issue.overview.originalEstimate }}
      </div>
    </div>
    <div class="status" :style="{ background: issue.jiraIssueStatus.categoryColor }" :data-testid="`status-button-${issue.jiraIssueKey}`" @click="onStatusClick">
      <span :style="{
        color: getStatusColorText(
          issue.jiraIssueStatus.categoryColor
        )
      }">{{ issue.jiraIssueStatus?.name }}</span>
    </div>
    <div class="completed" :data-testid="`completed-${issue.jiraIssueKey}`">
      50%
    </div>
    <div class="period" :data-testid="`period-${issue.jiraIssueKey}`">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.16667 11.3333V16.3333H0.5V0.5H8.31833C8.47305 0.500083 8.62469 0.543236 8.75627 0.624627C8.88785 0.706018 8.99417 0.822433 9.06333 0.960833L9.66667 2.16667H14.6667C14.8877 2.16667 15.0996 2.25446 15.2559 2.41074C15.4122 2.56702 15.5 2.77899 15.5 3V12.1667C15.5 12.3877 15.4122 12.5996 15.2559 12.7559C15.0996 12.9122 14.8877 13 14.6667 13H9.34833C9.19361 12.9999 9.04197 12.9568 8.91039 12.8754C8.77882 12.794 8.67249 12.6776 8.60333 12.5392L8 11.3333H2.16667ZM2.16667 2.16667V9.66667H9.03L9.86333 11.3333H13.8333V3.83333H8.63667L7.80333 2.16667H2.16667Z"
          fill="#A0A5B0" />
      </svg>
      {{ issue?.overview?.startDate }} - {{ issue?.overview?.dueDate }}
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import AppIssuePopupComponent from './IssuePopupComponent.vue';
import IconNoAssignee from "@/components/icons/IconNoAssignee.vue";
import { useStatusColor } from "@/helpers/statusColor";
export default {
  components: { AppIssuePopupComponent, IconNoAssignee },
  props: {
    issue: {
      type: Object,
      default: () => null
    }
  },
  emits: ['onStatusClick', 'onAssigneeClick'],
  setup(props, { emit }) {
    const popupIssueId = ref(null),
      getStatusColorText = useStatusColor().getStatusColorText;
    const onStatusClick = (e) => {
      emit('onStatusClick', e);
    }
    const onAssigneeClick = (e) => {
      emit('onAssigneeClick', e);
    }
    return {
      popupIssueId,
      getStatusColorText,
      onStatusClick,
      onAssigneeClick,
    }
  }
}
</script>
<style lang="scss" scoped>
.issue-component-container {
  display: grid;
  grid-template-columns: 140px 1fr auto auto auto;
  gap: 5px;
  align-items: center;
}

.issue-type {
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
    display: block;
  }
}

.assignee {
  position: relative;
  grid-area: 1 / 1 / 1 / 1;
  cursor: pointer;
  svg,
  img {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: -15px;
    left: -70px;
  }

  svg {
    background: rgb(204, 204, 204);
    border-radius: 50%;
  }
}

.period {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #A0A5B0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  gap: 10px;
  grid-area: 1 / 2 / 1 / 2;
}

.issue-data {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  grid-area: 1 / 1 / 1 / 1;

  .issue-key {
    color: #0F8AF9;
  }

  .estimation {
    color: #A0A5B0;
  }

}

.completed {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  margin-right: 5px;
  color: #FFA72B;
  grid-area: 1 / 4 / 1 / 4;
}

.status {
  font-size: 12px;
  line-height: 14px;
  grid-area: 1 / 3 / 1 / 3;
  cursor: pointer;
  display: block;
  margin-right: 5px;
  color: #006644;
  background: #E3FCEF;
  border-radius: 4px;
  padding: 2px 5px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.progress-bar {
  position: absolute;
  left: 5px;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 0;
  grid-area: 1 / 1 / 1 / 4;

  .progress-bar-value {
    position: absolute;
    left: 0;
    height: 100%;
    background: #FFF8EE;
  }
}

.compact {
  .period {
    display: none;
  }
}

.compact-medium {

  .status,
  .completed {
    display: none;
  }
}

.compact-small {

  .estimation,
  .issue-key {
    display: none;
  }

  .issue-type {
    position: relative;
    left: -3px;
  }
}

.issue-popup-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>