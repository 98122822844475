<template>
  <div v-if="show" class="preload-container" data-testid="loader">
    <div class="box">
      <div class="loader"></div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore(),
      show = computed(() => {
        return store.state.loader.show
      });

    return {
      show,
    };
  },
};
</script>
<style lang="scss" scoped>
.preload-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0f8af9;
  .loader {    
    transform: scale(1.2);
    font-size: 70px;
    line-height: 200px;
    border-right: 0.1em solid currentcolor;
    border-radius: 100%;
    width: 1em;
    height: 1em;
    animation: loader 800ms linear infinite;
    &:before,
    &:after {
      content: "";
      width: 0.8em;
      height: 0.8em;
      display: block;
      position: absolute;
      top: calc(50% - 0.4em);
      left: calc(50% - 0.4em);
      border-left: 0.08em solid currentcolor;
      border-radius: 100%;
      animation: loader 400ms linear infinite reverse;
    }
    &:after {
      width: 0.6em;
      height: 0.6em;
      top: calc(50% - 0.3em);
      left: calc(50% - 0.3em);
      border: 0;
      border-right: 0.05em solid currentcolor;
      animation: none;
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
}
</style>
