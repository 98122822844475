<template>
  <div class="modal-body">
    <div class="close" @click="closeModal">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title" :title="node.summary">
      <icon-story :width="'15px'" :height="'15px'" />
      <div class="title-text">{{ node.summary }}</div>
    </div>
    <form @submit.prevent="submit">
      <app-estimation :node="node" :estimationType="estimationType" :focusElementName="focusElementName"
        :tabIndex="tabIndex" @onFormValid="onFormValid" @onChange="changeForm" />
      <div class="actions">
        <div class="buttons">
          <app-button :color="'default'" type="button" @click="closeModal">
            {{ translate("modal.cancel") }}
          </app-button>
          <app-button data-testid="submit-estimation" :color="'primary'" :disabled="!isFormValid" type="submit">{{
              translate("modal.save")
          }}</app-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useModalHelper } from "@/helpers/modalHelper";
import AppButton from "@/components/shared/Button";
import IconClose from "@/components/icons/IconClose.vue";
import AppEstimation from "@/components/wbsProject/estimation/Estimation.vue";
import IconStory from "@/components/icons/IconStory.vue";
export default {
  components: {
    AppButton,
    IconClose,
    AppEstimation,
    IconStory,
  },
  inject: ["translate"],
  props: {
    node: {
      type: Object,
      default: () => { },
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
    estimationType: {
      type: String,
      required: true,
    },
    focusElementName: {
      type: String,
      default: null,
    }
  },
  setup(props) {
    const { closeModal, submitModal } = useModalHelper(),
      state = reactive({
        isFormValid: true,
        formData: {},
      });

    const submit = () => {
      submitModal(state.formData);
    };

    const onFormValid = (value) => {
      state.isFormValid = value;
    };

    const changeForm = (data) => {
      state.formData = { ...state.formData, ...data };
    };

    return {
      ...toRefs(state),
      submit,
      closeModal,
      onFormValid,
      changeForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;

  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      background: #0085ff;

      svg {
        fill: white;
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #363636;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    justify-content: start;
    align-items: center;
    max-width: 300px;
  }

  .title-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
  }

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
    color: #a1a9ba;
    display: block;
  }

  .actions {
    grid-area: Actions;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    margin-top: 15px;

    .button {
      height: 55px;
      width: 100%;
      align-self: end;
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      justify-self: end;
      width: 200px;
    }
  }
}
</style>
