<template>
  <div class="progress-bar-container">
    <label>Time</label>
    <app-progress-bar :spent="calcTimePerc" :overlog="calcOverLog" :remaining="calcRemaining" />
    <label>{{ logged }} Logged</label>
    <br />
    <br />
    <label v-if="estimateTime">The original estimate for this issue was
      <span class="hour">{{ estimateTime }}</span></label>
  </div>
  <br />
  <div class="time-fields">
    <div class="input-field">
      <label>Time spent</label>
      <app-base-input v-model="spentTime" @input="onChange" :placeholder="'eg. 3d 4h 10m'" ref="timeSpentEl" />
      <div class="error-text">
        {{ isSpentValid.errorText }}
      </div>
    </div>
    <div class="input-field">
      <label>Time remaining</label>
      <app-base-input :ref="el => fieldsElement['remaining-estimate'] = el" v-model="remainingTime" :disable="true"
        :placeholder="'eg. 3d 4h 10m'" @input="onChange" @focus="isRemaingFocus = true"
        @blur="isRemaingFocus = false" />
      <div class="error-text">
        {{ isRemainingValid.errorText }}
      </div>
    </div>
  </div>
  <br />
  <div class="date">
    <div class="input-field">
      <label>Date started <span>*</span> </label>
    </div>
    <div class="date-time" tabindex="1" v-click-outside="closeDropBox">
      <div class="date-picker">
        <app-date-picker :placeholder="'Choose date'" :options="pickerOptions" :dateValue="date" :showNavigate="false"
          @change="onChangeDate" />
      </div>
    </div>
  </div>
  <br />
  <div class="description">
    <div class="input-field">
      <label>Work description</label>
    </div>
    <textarea cols="30" rows="10" @input="onChange" v-model="descriptionText"></textarea>
  </div>
</template>
<script>
import { toRefs, reactive, ref, computed, onMounted, watch, nextTick } from "vue";
import moment from "moment";
import AppBaseInput from "@/components/forms/elements/BaseInput.vue";
import { useDateHelper } from "@/helpers/dateHelper";
import AppDatePicker from "@/components/shared/DateRangePicker.vue";
import AppProgressBar from "@/components/wbsProject/estimation/ProgressBar.vue";
import { useStore } from "vuex";
export default {
  components: {
    AppBaseInput,
    AppDatePicker,
    AppProgressBar,
  },
  props: {
    estimate: {
      type: [String, Number],
      default: "",
    },
    spent: {
      type: [String, Number],
      default: "",
    },
    remaining: {
      type: [String, Number],
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    date: {
      type: Number,
      default: new Date().getTime(),
    },
    time: {
      type: String,
      default: moment().format("HH:mm A"),
    },
    focusElementName: {
      type: String,
      default: null,
    },
  },
  emits: ["onFormValid", "onChange"],
  setup(props, { emit }) {

    const { getTimeRanges, parseInput, humanTime, checkValidationStr } =
      useDateHelper(),
      timeSpentEl = ref(),
      fieldsElement = ref({}),
      isRemaingFocus = ref(false),
      store = useStore(),
      currentJiraTiket = computed(() => store.getters['wbs/currentJiraTicket']),
      state = reactive({
        dateValue: props.date,
        timeValue: 0,
        timeStr: props.time,
        descriptionText: props.description,
        estimateTime: null,
        spentTimeExists: computed(() => currentJiraTiket.value?.fields?.timetracking?.timeSpentSeconds / 60 / 60),
        spentTime: "",
        remainingTime: null,
        pickerOptions: {
          singleDatePicker: true,
          autoApply: true,
          locale: {
            format: "MMM Do YYYY",
          },
        },
        calcEstimate: computed(() => {
          return parseInput(state.estimateTime);
        }),
        calcSpent: computed(() => {
          return (parseInput(state.spentTime) || 0) + (state.spentTimeExists || 0);
        }),
        calcTimePerc: computed(() => {
          return (state.calcSpent * 100) / state.calcEstimate;
        }),
        calcOverLog: computed(() => {
          return 100 - state.calcTimePerc < 0
            ? Math.abs(100 - state.calcTimePerc)
            : 0;
        }),
        calcRemaining: computed(() => {
          if (isNaN(parseInput(state.remainingTime))) return null;
          if (state.remainingTime == 0) return null;

          return (
            (parseInput(state.remainingTime) /
              (state.calcSpent + state.calcEstimate)) *
            100
          );
        }),
        logged: computed(() => {
          return humanTime(state.calcSpent);
        }),
        loggedDecimal: computed(() => {
          return parseFloat(state.calcSpent).toFixed(2)
        }),
        remainingDecimal: computed(() => {          
          return state.remainingTime ? parseFloat(parseInput(state.remainingTime)).toFixed(2) : '';
        }),
        isOpenDropBox: false,
        timeList: getTimeRanges(10, "en"),
        isSpentValid: computed(() => {
          let errorText = "";
          let result = true;

          if (state.spentTime && !checkValidationStr(state.spentTime)) {
            errorText = "Specified format is not valid.";
          }
          if (!state.spentTime) {
            result = false;
          }
          if (errorText) {
            result = false;
          }

          return {
            errorText,
            result,
          };
        }),
        isRemainingValid: computed(() => {
          let errorText = "";
          let result = true;

          if (state.remainingTime && !checkValidationStr(state.remainingTime)) {
            errorText = "Specified format is not valid.";
          }

          if (errorText) {
            result = false;
          }

          return {
            errorText,
            result,
          };
        }),
        isFormValid: computed(
          () => ((state.spentTime && state.isSpentValid.result) || state.spentTime == '') && state.isRemainingValid.result
          //&& state.calcEstimate
        ),
      });

    const openDropBox = () => {
      state.isOpenDropBox = true;
    };
    const closeDropBox = () => {
      state.isOpenDropBox = false;
    };
    const selectTime = (item) => {
      state.timeValue = item.timestamp;
      state.timeStr = item.value;
      onChange();
      closeDropBox();
    };
    const onChangeDate = (value) => {
      state.dateValue = value[2];
      onChange();
    };
    const clear = () => {
      state.timeValue = null;
      state.timeStr = null;
      state.dateValue = null;
    };

    let remainingEstimateOriginal;
    const recalcRemainingEstimate = () => {
      if (remainingEstimateOriginal == '0m') return;
      if (isRemaingFocus.value === true) return;
      
      if (state.isSpentValid.result == false) {
        state.remainingTime = remainingEstimateOriginal;
        return;
      }
      const diffValue = parseInput(remainingEstimateOriginal) - parseInput(state.spentTime);

      const result = diffValue < 0 ? 0 : diffValue;
      if (isNaN(result) == false) {
        if (result == 0) {
          state.remainingTime = '0m';
        } else
          state.remainingTime = humanTime(result);
      }
    }

    const onChange = () => {
      recalcRemainingEstimate()
      if (state.isFormValid) {                
        emit("onChange", {
          estimate: state.estimateTime,
          spent: state.spentTime.trim(),
          loggedDecimal: state.loggedDecimal+'h',
          remaining: state.remainingTime,
          remainingDecimal: state.remainingDecimal ? state.remainingDecimal+'h' : '0h',
          date: moment(state.dateValue).format('YYYY-MM-DD'),
          time: state.timeStr.trim(),
          description: state.descriptionText,
        });
      }
    };

    onMounted(() => {
      timeSpentEl.value.focus();
      if (props.focusElementName) {
        fieldsElement.value[props.focusElementName].focus()
      }
    });

    watch(
      () => state.isFormValid,
      (current) => {
        emit("onFormValid", current);
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.estimate,
      (current) => {
        state.estimateTime = current;
      }
    );


    watch(() => currentJiraTiket.value, current => {
      state.remainingTime = current?.fields?.timetracking?.remainingEstimate;
      state.estimateTime = current?.fields?.timetracking?.originalEstimate;
      remainingEstimateOriginal = state.remainingTime;
      nextTick(()=>{
        onChange()
      })
    }, {
      immediate: true,
    });


    return {
      ...toRefs(state),
      getTimeRanges,
      closeDropBox,
      openDropBox,
      selectTime,
      onChangeDate,
      clear,
      timeSpentEl,
      onChange,
      isRemaingFocus,
      fieldsElement,
    };
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #363636;
    margin-bottom: 5px;
    display: block;

    span {
      color: red;
    }
  }

  small {
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 13px;
    color: #c3cad9;
  }
}

.progress-bar-container {
  label {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */

    /* #363636 */

    color: #363636;

    .hour {
      color: #363636;

      /* Inside Auto Layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 10px;
      min-width: 25px;
      padding-left: 5px;
      padding-right: 5px;
      height: 15px;
      left: 208px;
      top: 0px;

      background: #f2f5f9;
      border-radius: 4px;
      display: inline-flex;
      justify-content: center;
    }
  }

  .progress-bar {
    height: 7px;
    position: relative;
    overflow: hidden;
    background: #f2f5f9;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;

    .progress-bar-value {
      transition: 0.5s;
      position: relative;
      height: 100%;
      width: 0%;
      background: #2684fe;

      .progress-bar-value-overlog {
        position: absolute;
        right: 0;
        height: 100%;
        background: rgb(255, 171, 0);
      }
    }

    .progress-bar-value-remaining {
      transition: 0.5s;
      position: relative;
      height: 100%;
      width: 0%;
      background: #f2f5f9;
    }
  }
}

.time-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.date-time {
  background: #f2f5f9;
  border-radius: 4px;
  height: 50px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  position: relative;

  .date-picker {
    height: 50px;
    position: relative;

    ::v-deep(.date-picker-input) {
      height: 50px;
      border: 0;

      .icon {
        border: 0;
      }

      input {
        background-color: transparent;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        padding-left: 15px;
      }
    }
  }

  .clear {
    cursor: pointer;
    position: absolute;
    right: 0;
    width: 35px;
    z-index: 1;
  }
}

.time-select {
  position: relative;

  input {
    cursor: pointer;
    padding-left: 25px;
  }

  .input-field-wrapper {
    border: 0;
    height: 50px;

    &.focus {
      border: 0;
      box-shadow: none;
    }
  }

  &.focus ul {
    opacity: 1;
    height: auto;
    z-index: 1;
  }

  i {
    transform: rotate(-90deg);
    margin-top: -10px;
    right: 15px;
    left: auto;
  }

  ul {
    opacity: 0;
    z-index: -1;
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    background: #fff;
    width: 100%;
    overflow: hidden;
    border-radius: 4px;
    margin-top: 2px;
    top: 100%;
    overflow: auto;
    max-height: 200px;
    box-shadow: 0px 4px 4px 0px #00000038;

    li {
      padding: 5px;
      padding-left: 17px;
      font-size: 12px;
      line-height: 18px;
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #0f8af9;
      }
    }

    li+li {
      border-top: 1px solid #ccc;
    }
  }
}

textarea {
  width: 100%;
  resize: none;
  height: 165px;
  border: 1px solid #c3cad9;
  box-sizing: border-box;
  border-radius: 4px;
  outline: 0;
  padding: 10px;
}

.error-text {
  color: #de350b;
  font-size: 12px;
  margin-top: 5px;
}
</style>
