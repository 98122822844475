<template>
  <svg
    :opacity="opacity"
    :width="width"
    :height="height"
    viewBox="0 0 2 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1" cy="0.999878" r="1" fill="#363636" />
    <circle cx="1" cy="5.99988" r="1" fill="#363636" />
    <circle cx="1" cy="10.9999" r="1" fill="#363636" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: String,
      default: "",
    },
  },
};
</script>
