import users from './users/index';
import userAccess from './userAccess/index';
import profile from './profile/index';
import modal from './modal/index';
import loader from './loader/index';
import dashboard from './dashboard/index';
import app from "./app/index";
import projects from "./projects/index";
import templates from "./templates/index";
import settings from "./settings/index";
import wbs from "./wbs/index";
import session from "./session/index";
export default {
	users,
	userAccess,
	profile,
	modal,
	loader,
	dashboard,
	app,
	projects,
	templates,
	settings,
	wbs,
	session,
};
