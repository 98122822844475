import * as types from '@/store/mutation-types';
export default {
  [types.SET_USERS](state, payload = { data: [], meta: { count: 0 } }) { 
    state.userList = payload.data;
    state.userList = state.userList.map(u => {
      return {
        label: u.displayNameWithId,
        value: u.id,
        iconUri: u.iconUri,
        displayName: u.displayName,
      }
    })
    state.meta = payload.meta;
  },
};