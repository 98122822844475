<template>
  <div class="issue-list">
    <div class="custom-list">
      <div class="header">{{ translate("settings.custom_issue") }}</div>
      <ul>
        <li :key="item.id" v-for="item in customLocalList">
          <label>
            <img :src="item.iconUri" alt="" />
            {{ item.name }}
            <input type="checkbox" v-model="item.isSelected" />
            <div class="checkbox"></div>
          </label>
        </li>
      </ul>
    </div>
    <div class="system-list" v-show="false">
      <div class="header">System Issues</div>
      <ul>
        <li :key="c.id" v-for="c in localList">
          <img :src="c.iconUri" alt="" />
          {{ c.name }}
        </li>
      </ul>
    </div>
    <div class="actions">
      <div class="btn save" @click="save">{{ translate("save") }}</div>
      <div class="btn cancel" @click="cancel">{{ translate("cancel") }}</div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed, watch } from "vue";
export default {
  inject: ["translate"],
  props: {
    customList: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["save", "cancel"],
  setup(props, { emit }) {
    const state = reactive({
      customLocalList: computed(() => {
        return props.customList.map((l) => {
          return { ...{ selected: l?.selected === true ? true : false }, ...l };
        });
      }),
      localList: props.list,
    });

    const save = () => {
      emit("save", { items: state.customLocalList });
    };
    const cancel = () => {
      emit("cancel");
    };
    return {
      ...toRefs(state),
      save,
      cancel,
    };
  },
};
</script>
<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.issue-list {
  width: 235px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  top: calc(100% + 6px);
  left: 0;
  .header {
    font-size: 12px;
    line-height: 18px;
    color: #a1a9ba;
    height: 30px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(195, 202, 217, 0.25);
    padding: 0 10px;
    margin-bottom: 10px;
  }
  ul {
    max-height: 300px;
    overflow: auto;
  }

  li {
    min-height: 35px;
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    justify-content: start;
    font-size: 14px;
    line-height: 21px;
    color: #363636;
    padding: 0 10px;
    position: relative;
    align-items: center;

    img {
      width: 15px;
      height: 15px;
    }
    input {
      display: none;
    }
  }
  .custom-list {
    li label {
      cursor: pointer;
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      grid-column-gap: 8px;
      justify-content: start;
      padding-right: 15px;
      overflow: hidden;
    }
    .checkbox {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 14px;
      height: 14px;
      border: 1px solid #c3cad9;
      border-radius: 2px;
      background: #fff;
    }
    li input:checked ~ .checkbox {
      &:before {
        content: "";
        position: absolute;
        transform: rotate(45deg);
        height: 7px;
        width: 3px;
        margin-left: 60%;
        left: -4px;
        border-bottom: 3px solid #0f8af9;
        border-right: 3px solid #0f8af9;
      }
    }
  }
  .actions {
    padding: 0 5px;
    margin-top: 0;
    .btn {
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #363636;
      cursor: pointer;
    }
    .btn.save {
      background: #2684fe;
      border-radius: 2px;
      color: #ffffff;
    }
  }
}
</style>
