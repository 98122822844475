import moment from "moment"

export function useDateHelper() {
  const formatDate = (date, format = 'MMM DD, YYYY') => {
    return moment(date).format(format);
  }
  const getMomentInstance = (date) => {
    return new moment(date);
  }
  const months = () => ["january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"];
  const getDiffDays = (date1, date2) => {
    let a = moment(date2);
    let b = moment(date1);
    return a.diff(b, 'days')
  }
  const getDiffHours = (date1, date2) => {
    return moment
      .duration(moment(date2, 'YYYY/MM/DD HH:mm')
        .diff(moment(date1, 'YYYY/MM/DD HH:mm'))
      ).asHours();
  }
  const hoursToTime = (hours) => {
    return Math.floor(hours) + ':' + Math.round((hours % 1) * 60);
  }
  const getTimeRanges = (interval = 10, language = "en-GB") => {
    const ranges = [];
    const date = new Date();
    const format = {
      hour: "numeric",
      minute: "numeric",
    };

    for (let minutes = 0; minutes < 24 * 60; minutes = minutes + interval) {
      date.setHours(0);
      date.setMinutes(minutes);
      const prefix = language == 'en-GB' ? (date.getHours() < 12 ? ' AM' : ' PM') : '';
      ranges.push({
        timestamp: date.getTime(),
        value: date.toLocaleTimeString(language, format) + prefix,
      });
    }

    return ranges;
  };

  //calculate raw value in hours
  const parseInput = (val) => {
    if (!val) return '';    
    val = val.toString();
    val = val?.toLowerCase();
    let result = val;
    if ((new RegExp("w|h|d|m").test(val))) {
      let weekPattern = new RegExp('(?:([0-9.]+)w)');
      let daysPattern = new RegExp('(?:([0-9.]+)d)');
      let hoursPattern = new RegExp('(?:([0-9.]+)h)');
      let minutesPattern = new RegExp('(?:([0-9.]+)m)');

      result = 0;

      let workingDaysPerWeek = getWorkingDaysPerWeek(),
        workingHoursPerDay = getWorkingHoursPerDay();

      if (weekPattern.test(val)) {
        let weeks = weekPattern.exec(val);
        result += parseFloat(weeks[1]) * workingDaysPerWeek * workingHoursPerDay;
      }
      if (daysPattern.test(val)) {
        let days = daysPattern.exec(val);
        result += parseFloat(days[1]) * workingHoursPerDay;
      }

      if (hoursPattern.test(val)) {
        let hours = hoursPattern.exec(val);
        result += parseFloat(hours[1]);
      }

      if (minutesPattern.test(val)) {
        let minutes = minutesPattern.exec(val);
        minutes = (minutes[1]) / 60;
        result += parseFloat(minutes);

      }
    }
    return parseFloat(result);
  }

  const checkValidationStr = (str) => {
    let result = true;
    str = str.toString();
    const list = ["w", "d", "h", "m"];
    // 1. format validation (numb + w/d/h/m)
    let arr = str.split(" ");
    for (let i = 0; i < arr.length; i++) {
      const el = arr[i];
      if (!new RegExp(/^[0-9|.0-9]+(w{1}|d{1}|h{1}|m{1})$/g).test(el)) {
        result = false;
        break;
      }
    }

    if (!new RegExp(/^\d+/g).test(str)) {
      result = false;
    }

    // 2. format validation (check double values)
    list.forEach((e) => {
      var regExPattern = "\\b[0-9]+" + e + "\\b";
      const found = str.match(new RegExp(regExPattern, "g"));
      if (found && found.length > 1) {
        result = false;
        return;
      }
    });

    // 2. format validation (check order)
    const orderList = ["w d h m", "w d", "w d h", "w d m", "d h m", "w h", "w m", "d h", "d m", "h m", "w", "d", "h", "m"];
    let order = str.match(new RegExp("[0-9]w|[0-9]d|[0-9]h|[0-9]m", "g"));
    const countNumbers = str.match(new RegExp(/[0-9|0.-9]+/g));
    if (!countNumbers) return false;
    for (let i = 0; i < countNumbers.length; i++) {
      const s = countNumbers[i];
      if (s[0] == '.')
        result = false;
    }

    if (countNumbers && order && countNumbers.length !== order.length) result = false;

    if (order && order.length > 0) {
      order = order.map(a => a.substr(-1)).join(' ');

      if (orderList.indexOf(order) == -1)
        result = false;

      if (order.length > 1 && str.indexOf(' ') == -1) {
        result = false;
      }
    }

    return result;
  }

  /**
 * Get Working Days Per Week from backend (timeTrackingConfiguration object) or default value
 *
 * @returns {number}
 */
  const getWorkingDaysPerWeek = () => {
    let workingDaysPerWeek = window?.timeTrackingConfiguration?.workingDaysPerWeek;

    if (!workingDaysPerWeek) {
      workingDaysPerWeek = 5;
    }

    return workingDaysPerWeek;
  }

  /**
 * Get Working Hours Per Day from backend (timeTrackingConfiguration object) or default value
 *
 * @returns {number}
 */
  const getWorkingHoursPerDay = () => {
    let workingHoursPerDay = window?.timeTrackingConfiguration?.workingHoursPerDay;

    if (!workingHoursPerDay) {
      workingHoursPerDay = 8;
    }

    return workingHoursPerDay;
  }

  //building back the string for pert and deviation results
  const humanTime = (data) => {
    let seconds = data * 3600,
      result = '',
      workingDaysPerWeek = getWorkingDaysPerWeek(),
      workingHoursPerDay = getWorkingHoursPerDay();

    let weeks = Math.floor(seconds / 3600 / workingHoursPerDay / workingDaysPerWeek);

    if (weeks) {
      result += weeks + 'w ';
      seconds -= weeks * workingDaysPerWeek * workingHoursPerDay * 3600;
    }

    let days = Math.floor(seconds / 3600 / workingHoursPerDay);

    if (days) {
      result += days + 'd ';
      seconds -= days * workingHoursPerDay * 3600;
    }

    let hours = Math.floor(seconds / 3600);

    if (hours) {
      result += hours + 'h ';
      seconds -= hours * 3600;
    }

    let minutes = (seconds / 60).toFixed(0);

    if (minutes) {
      result += minutes + 'm';
    }

    return result;
  }

  //recalculate pert and deviation bases on input changes
  const pertCalculation = (worst, probably, best, isDeviationEtimation = false) => {
    //pert
    const solution = (worst + (probably * 4) + best) / 6;
    //deviation
    const deviation = (worst - best) / 6;    
    const pertHuman = humanTime(solution + (isDeviationEtimation == true ? deviation : 0));
    const devHuman = humanTime(deviation);

    return {
      pert: pertHuman,
      dev: devHuman
    }
  }

  return { formatDate, getDiffDays, getDiffHours, getTimeRanges, hoursToTime, months, getMomentInstance, humanTime, pertCalculation, parseInput, checkValidationStr }
}