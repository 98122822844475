<template>
  <div
    ref="orphanedNodesContainer"
    class="orphaned-nodes"
    :class="{ 'compact-view': isCompactView }"
    :style="{ left: orphanedNodesContainerLeft + 'px' }"
    v-if="orphanedNodes.length"
  >
    <div
      class="orphaned-nodes-header"
      :style="{ left: orphanedNodes[0].x + 'px', transform: `scale(${scale})` }"
    >
      {{ translate("wbs.orphaned_issues") }}
      <div class="icon" @click="changeOrphanedNodesView">
        <span>{{ orphanedNodes.length }}</span>
        <icon-arrow-down :width="'11px'" />
      </div>
    </div>
    <div
      class="orphaned-nodes-list"
      ref="orphanedNodesList"
      :style="{ width: (nodeWidth + 30 * scale) * scale + 'px' }"
    >
      <app-node-component
        v-for="(node, index) in orphanedNodes"
        :key="`node${index}`"
        :id="node.id"
        :childrenCount="node.childrenCount"
        :isParent="node.parent ? true : false"
        :centerX="nodeOptions?.centerX"
        :centerY="nodeOptions?.centerY"
        :scale="nodeOptions?.scale"
        :subTask="node?.subtask"
        :x="node.x"
        :y="node.y"
        :isOrphaned="true"
        :style="{
          left: node.x + 'px',
          top: node.y + 'px',
          width: `${nodeWidth}px`,
          height: `${nodeHeight}px`,
        }"
        class="node"
        @dragstart="onDragstart(node.id)"
        @onDrop="onDrop"
        @onDragEnter="onDragEnter"
        @onDeleteNode="onDeleteNode"
      >
        <slot :node="node"></slot>
      </app-node-component>
    </div>
  </div>
</template>
<script>
import AppNodeComponent from "@/components/treeView/NodeComponent.vue";
import AppNodeContent from "@/components/wbsProject/NodeContent.vue";
import IconArrowDown from "@/components/icons/IconArrowDown.vue";
import { computed, nextTick, reactive, toRefs, watch } from "vue";
import * as wbsConst from "@/store/modules/wbs/const";
import { useOrphaned } from "@/components/wbsProject/modules/orphaned";
export default {
  components: { AppNodeComponent, AppNodeContent, IconArrowDown },
  props: {
    nodes: {
      type: Array,
      default: [],
    },
    orphanedNodesContainerLeft: {
      type: Number,
      default: null,
    },
    scale: {
      type: Number,
      default: 1,
    },
    nodeOptions: {
      type: Object,
      default: null,
    },
    compactMode: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "mousedown",
    "mouseup",
    "onChange",
    "onChangeView",
    "onDragstart",
    "onDragEnter",
    "onDrop",
    "onDeleteNode",
  ],
  inject: ["translate"],
  setup(props, { emit }) {
    const state = reactive({
        nodeWidth: wbsConst.nodeWidth,
        nodeHeight: wbsConst.nodeHeight,
        isCompactView: props.compactMode,
        oldNodes: [],
      }),
      orphanedNodes = computed(() => {
        const { orphanedNodes } = useOrphaned({
          nodes: props.nodes,
          scale: props.scale,
          isCompactView: state.isCompactView,
        });
        const orphaned = orphanedNodes.value.map((n) => n.id);
        if (
          orphaned.length === state.oldNodes.length &&
          orphaned.every((value, index) => value === state.oldNodes[index])
        ) {
          return orphanedNodes.value;
        } else {
          state.oldNodes = orphanedNodes.value.map((n) => n.id);
          emit("onChange", orphanedNodes.value);
          return orphanedNodes.value;
        }
      });

    watch(
      () => props.compactMode,
      (current) => {
        state.isCompactView = current;
      }
    );

    const changeOrphanedNodesView = () => {
      state.isCompactView = !state.isCompactView;
      emit("onChangeView", state.isCompactView);
    };

    const onDragstart = (id) => {
      emit("onDragstart", id);
    };

    const onDragEnter = (e) => {
      emit("onDragEnter", e);
    };

    const onDrop = (e) => {
      emit("onDrop", e);
    };

    const onDeleteNode = ({ id, e }) => {
      emit("onDeleteNode", { id, e });
    };

    const dragstart = (e) => {};

    return {
      orphanedNodes,
      onDragEnter,
      onDragstart,
      onDrop,
      changeOrphanedNodesView,
      dragstart,
      onDeleteNode,
      ...toRefs(state),
    };
  },
};
</script>
<style lang="scss" scoped>
.orphaned-nodes {
  overflow: hidden;
  &-header {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #363636;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    width: 285px;
    position: absolute;
    top: 0;
    z-index: 998;
    right: 0;
    transform-origin: top left;
    transition: 0.3s;
    .icon {
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 10px;
      svg {
        transition: 0.3s;
        transform: rotate(0deg);
        cursor: pointer;
      }
    }
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #2684fe;
    }
  }
  &.compact-view &-header {
    .icon {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  ::v-deep(.container-node) {
    box-shadow: 0px -2px 7px #0000000f;
  }
  .orphaned-nodes-list {
    position: relative;
    height: calc(100vh - 110px);
    overflow: auto;
    overflow-x: hidden;
    margin-top: -15px;
  }
}
</style>
