<template>
  <div>
    <carousel ref="carousel" v-model="activeSlide" :items-to-show="1">
      <slide v-for="slide in slides" :key="slide">
        <div class="slide-content" :class="{ 'no-image': !slide.image }">
          <div class="close" @click="$emit('onClose')">
            <icon-close width="18px" :stroke="'#3E5571'" />
          </div>
          <div class="description">
            {{ slide.description }}
          </div>
          <img v-if="slide.image" :src="`../../images/${slide.image}`" alt="" />
        </div>
      </slide>
      <template #addons>
        <Pagination></Pagination>
        <div class="prev" @click="prev">
          <icon-arrow-left width="7px" height="12px" />
        </div>
        <div class="next" @click="next">
          <icon-arrow-left width="7px" height="12px" />
        </div>
      </template>
    </carousel>
  </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import { nextTick, onMounted, ref, watch } from "vue";
import IconClose from "@/components/icons/close";
import IconArrowLeft from "@/components/icons/arrowLeft.vue";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    IconClose,
    IconArrowLeft,
  },
  props: {
    currentSlide: {
      type: Number,
      default: 0,
    },
    slides: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["onChange", "onClose"],
  setup(props, { emit }) {
    const carousel = ref(null),
      // activeSlide = computed(() => {
      //   return carousel.value?.data?.currentSlide?.value;
      // }),
      activeSlide = ref(0);

    watch(
      () => activeSlide.value,
      (slide) => {
        emit("onChange", slide);
      }
    );

    watch(
      () => props.currentSlide,
      (current) => {
        nextTick(() => {
          carousel.value.slideTo(current);
        });
      },
      {
        immediate: true,
      }
    );

    const prev = () => {
      carousel.value.prev();
    };

    const next = () => {
      carousel.value.next();
    };

    onMounted(() => {
      activeSlide.value = carousel.value?.data?.currentSlide?.value;
    })

    return {
      carousel,
      activeSlide,
      prev,
      next,
    };
  },
};
</script>

<style lang="scss" scoped>
.slide-content {
  display: grid;
  border-left: 1px solid #e5e7eb;
  height: 100%;
  min-height: 200px;
  width: 100%;
  align-items: center;
  grid-template-columns: 45px 1fr 45px;
  grid-template-rows: auto 1fr;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    justify-self: center;
  }
}

.description {
  font-size: 14px;
  padding: 10px;
  grid-area: 1/2/1/2;
}

img {
  grid-area: 2/1/2/4;
}

.close {
  position: relative;
  top: 6px;
  align-self: start;
  cursor: pointer;
  grid-area: 1 / 3 / 1 / 3;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 2px rgb(128 128 128 / 25%);
  border-radius: 50%;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
  transform: rotate(180deg);
  margin-top: -20px;
}
</style>

<style>
.carousel__viewport {
  position: relative;
}

.carousel__pagination {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}
</style>