<template>
  <div class="modal-body">
    <div class="close" @click="close">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">
      {{ title || translate("errors.error") }}
    </div>
    <div class="text">
      {{ serverError }}
    </div>
    <div class="actions">
      <app-button color="default" @click="close"> Ok </app-button>
    </div>
  </div>
</template>

<script>
import { useModalHelper } from "@/helpers/modalHelper";
import AppButton from "@/components/shared/Button";
import IconClose from "@/components/icons/IconClose.vue";
import { useStore } from "vuex";
import { computed, inject, watch } from "vue";
export default {
  components: { AppButton, IconClose },
  props: {
    title: {
      type: String,
    },
  },
  inject: ["translate"],
  setup(props) {
    const { closeModal, submitModal } = useModalHelper();
    const store = useStore();
    const translate = inject("translate");
    const serverError = computed(() =>
      translate(`errors.${store.getters["app/serverError"]}`)
    );

    const submit = () => {
      submitModal();
    };

    const close = () => {
      store.commit("app/SET_SERVER_ERROR", null);
      closeModal();
    };

    return {
      submit,
      close,
      serverError,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
  max-width: 350px;
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #0085ff;
      svg {
        fill: white;
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .text {
    margin: 15 px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #363636;
  }
  .actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;
    justify-content: center;
    .button {
      height: 55px;
      max-width: 250px;
      justify-self: center;
    }
  }
}
</style>
