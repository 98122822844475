<template>
  <svg :width="width" :height="height" :fill="fill" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_114_77)">
      <path
        d="M17.7803 16.7198L13.3035 12.243C14.5235 10.751 15.1233 8.84709 14.9789 6.92519C14.8345 5.00329 13.9569 3.21042 12.5277 1.91741C11.0985 0.624402 9.22694 -0.0698112 7.30023 -0.0216374C5.37352 0.0265365 3.53903 0.813412 2.17621 2.17623C0.813393 3.53905 0.0265169 5.37354 -0.0216569 7.30025C-0.0698308 9.22696 0.624383 11.0985 1.91739 12.5277C3.2104 13.9569 5.00327 14.8345 6.92517 14.9789C8.84707 15.1233 10.7509 14.5235 12.243 13.3035L16.7197 17.7803C16.8612 17.9169 17.0507 17.9925 17.2473 17.9908C17.4439 17.9891 17.6321 17.9102 17.7711 17.7711C17.9102 17.6321 17.989 17.444 17.9908 17.2473C17.9925 17.0507 17.9169 16.8612 17.7803 16.7198ZM7.5 13.5C6.31331 13.5 5.15327 13.1481 4.16658 12.4888C3.17988 11.8295 2.41085 10.8925 1.95672 9.79612C1.5026 8.69976 1.38378 7.49336 1.61529 6.32948C1.8468 5.16559 2.41824 4.09649 3.25736 3.25738C4.09647 2.41826 5.16557 1.84682 6.32946 1.61531C7.49334 1.3838 8.69974 1.50262 9.7961 1.95674C10.8925 2.41087 11.8295 3.1799 12.4888 4.1666C13.1481 5.15329 13.5 6.31333 13.5 7.50002C13.4982 9.09077 12.8655 10.6159 11.7407 11.7407C10.6158 12.8655 9.09075 13.4982 7.5 13.5Z"
        fill="#23252A" />
      <path
        d="M9.75 6.75H5.25C5.05109 6.75 4.86032 6.82902 4.71967 6.96967C4.57902 7.11032 4.5 7.30109 4.5 7.5C4.5 7.69891 4.57902 7.88968 4.71967 8.03033C4.86032 8.17098 5.05109 8.25 5.25 8.25H9.75C9.94891 8.25 10.1397 8.17098 10.2803 8.03033C10.421 7.88968 10.5 7.69891 10.5 7.5C10.5 7.30109 10.421 7.11032 10.2803 6.96967C10.1397 6.82902 9.94891 6.75 9.75 6.75Z"
        fill="#23252A" />
    </g>
    <defs>
      <clipPath id="clip0_114_77">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
      default: 'transparent'
    },
  },
};
</script>