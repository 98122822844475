<template>
  <div class="row">
    <div class="cell">
      <div class="user-ava" v-tooltip.top-center="{ content: user.displayName }">
        <img v-if="user.iconUri" :src="user.iconUri" alt="" />
        {{ user.displayName }}
      </div>
    </div>
    <div class="cell">
      {{
        user.accessType === 1
          ? translate("user_access.admin")
          : translate("user_access.normal")
      }}
    </div>
    <div class="cell cell-remove" v-if="!user.isCurrent">
      <div class="remove" @click="removeRow(user.userId)">
        <icon-close :fill="'rgb(235, 117, 113)'" :width="'12px'" :height="'12px'" />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from "vue";
import IconClose from "@/components/icons/IconClose.vue";
export default {
  props: {
    userInfo: {
      type: Object,
    },
  },
  inject: ["translate"],
  emits: ["onRemoveRow"],
  components: {
    IconClose,
  },

  setup(props, { emit }) {
    const state = reactive({
      user: computed(() => JSON.parse(JSON.stringify(props.userInfo))),
    });

    const removeRow = (id) => {
      emit("onRemoveRow", id);
    };

    return {
      ...toRefs(state),
      removeRow,
    };
  },
};
</script>

<style lang="scss" scoped>
.row {
  border-bottom: 1px solid #e6e6e6;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr repeat(2, 100px);
  grid-column-gap: 15px;
  height: 80px;
}

.cell {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cell-remove {
  margin-left: 15px;
}

.user-ava {
  font-weight: 500;
  display: flex;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 15px;
  }
}
.remove {
  width: 24px;
  height: 24px;
  border: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(235, 117, 113);
  border-radius: 50%;
  cursor: pointer;
  outline: none;
}

</style>
