<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 62 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4549 36.5287L24.0729 22.9054L21.179 17.4588L7.9043 41.0559H34.4529L31.9065 36.5287H16.4549Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M31.0005 0L23.3488 13.7626L26.2937 19.0393L30.9887 10.6935L48.0727 41.0559H54.0958L31.0005 0Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M44.5576 41.0559L31.0625 17.066L28.0737 22.4659L38.5345 41.0559H44.5576Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M10.1227 56.3716H3.69874L1.11315 63.6667H0L6.91112 44.1854L13.8105 63.6667H12.7083L10.1227 56.3716ZM9.73989 55.3008L6.91112 47.3157L4.08156 55.3008H9.73989Z"
      fill="#363636"
    />
    <path
      d="M17.1727 54.5867V63.6667H16.1293V51.9805H17.1727V52.7301C18.1234 51.7623 19.2287 51.2784 20.4886 51.2784C21.8339 51.2784 22.9275 51.7108 23.77 52.5755C24.6125 53.4402 25.0455 54.5311 25.0691 55.8483V63.6667H24.0257V55.9433C24.0257 54.9514 23.6759 54.1044 22.9761 53.4023C22.2764 52.7003 21.4472 52.3492 20.4886 52.3492C19.53 52.3492 18.7032 52.6946 18.0073 53.3846C17.6284 53.7735 17.3507 54.1744 17.1727 54.5867Z"
      fill="#363636"
    />
    <path
      d="M30.7164 61.4777C31.6978 62.398 32.8651 62.8585 34.2183 62.8585C35.6711 62.8585 36.9121 62.3312 37.9406 61.2757C38.0716 61.1412 38.1955 61.0019 38.3116 60.8594L39.413 61.2644C39.2122 61.5502 38.9839 61.8239 38.7289 62.0856C37.484 63.3633 35.981 64.0018 34.2183 64.0018C32.4634 64.0018 30.9635 63.3633 29.7194 62.0856C28.4744 60.8087 27.8524 59.2653 27.8524 57.4562C27.8524 55.6552 28.4744 54.1166 29.7194 52.8389C30.9635 51.5612 32.4634 50.9227 34.2183 50.9227C35.726 50.9227 37.0431 51.3864 38.172 52.3147C38.3579 52.4734 38.543 52.6481 38.7289 52.8381C38.7988 52.9097 38.8639 52.9806 38.9258 53.0522L38.1375 53.8614L30.7164 61.4777ZM37.3718 53.1351C36.4673 52.421 35.4162 52.0643 34.2183 52.0643C32.7725 52.0643 31.5354 52.5901 30.5078 53.6408C29.4793 54.6922 28.9655 55.9635 28.9655 57.4546C28.9655 58.684 29.313 59.7636 30.0088 60.6911L37.3718 53.1351Z"
      fill="#363636"
    />
    <path
      d="M43.8185 51.9805V47.0057H44.8618V51.9805H47.1807V53.0513H44.8618V63.6667H43.8185V53.0513H41.4996V51.9805H43.8185Z"
      fill="#363636"
    />
    <path
      d="M55.6348 50.9218C57.3975 50.9218 58.899 51.5603 60.14 52.838C61.381 54.1157 62.0007 55.6583 62.0007 57.4674C62.0007 59.2684 61.3802 60.8078 60.14 62.0847C58.899 63.3624 57.3975 64.0009 55.6348 64.0009C53.88 64.0009 52.3801 63.3624 51.1359 62.0847C49.891 60.8078 49.2689 59.2684 49.2689 57.4674C49.2689 55.6583 49.891 54.1157 51.1359 52.838C52.3801 51.5603 53.8792 50.9218 55.6348 50.9218ZM51.9126 53.6463C50.8841 54.7018 50.3703 55.9747 50.3703 57.4666C50.3703 58.9585 50.8841 60.2297 51.9126 61.2804C52.9402 62.3319 54.1812 62.8568 55.6348 62.8568C57.0877 62.8568 58.3287 62.3311 59.3571 61.2804C60.3848 60.2289 60.8994 58.9576 60.8994 57.4666C60.8994 55.9747 60.3855 54.7018 59.3571 53.6463C58.3287 52.5916 57.0884 52.0635 55.6348 52.0635C54.1812 52.0643 52.9402 52.5916 51.9126 53.6463Z"
      fill="#363636"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.61574"
        y1="37.6096"
        x2="29.9057"
        y2="28.8987"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0042CA" />
        <stop offset="0.2671" stop-color="#0057D8" />
        <stop offset="1" stop-color="#008FFE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="24.7709"
        y1="7.35462"
        x2="58.467"
        y2="35.8949"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#008FFE" />
        <stop offset="1" stop-color="#0042CA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="26.8835"
        y1="21.9996"
        x2="46.5729"
        y2="38.6763"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#008FFE" />
        <stop offset="1" stop-color="#0042CA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
  },
};
</script>
