import axios from 'axios';
import * as types from '@/store/mutation-types';
import objectToFormData from '@/helpers/objectToFormData';
import errorToObject from "@/helpers/errorToObject";
export default {
  getProjects({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/projects?${params._filter || ''}&sort=${params._sort || ''}&search=${params.phrase || ''}&page=${params?._page || 1}&perPage=${params?._limit || 7}`)
        .then((response) => {
          const data = response.data;
          commit(types.SET_PROJECTS, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  removeProject({ commit }, { projectId }) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/projects/${projectId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getUserList({ commit, dispatch }, params = { perPage: 5, search: '' }) {
    return new Promise((resolve, reject) => {
      dispatch('users/getUsers', params, { root: true })
        .then((response) => {
          const data = response.data;
          commit(types.SET_USER_LIST, data);
          resolve(response);
        })
        .catch((error) => {          
          reject(errorToObject(error));
        });
    });
  },
  addProject({ commit }, payload) {
    const formData = objectToFormData(payload);
    return new Promise((resolve, reject) => {
      axios
        .post(`/projects`, formData, {
          'Content-Type': 'multipart/form-data'
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  editProject({ commit }, payload) {
    const formData = objectToFormData(payload);
    formData.append('_method', 'PUT');
    return new Promise((resolve, reject) => {
      axios
        .post(`/projects/${payload.id}`, formData, {
          'Content-Type': 'multipart/form-data'
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
};
