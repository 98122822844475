import { createI18n } from 'vue-i18n';
import en from '@/i18n/en.json';
import ru from '@/i18n/ru.json';
import es from '@/i18n/es.json';
const messages = {
  en,
  ru,
  es,
};

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  legacy: false,
  messages,
});

export {
  i18n
}