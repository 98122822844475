<template>
  <div class="modal-mask" v-show="isOpen" :class="{ open: isOpen }">
    <div class="modal-wrapper">
      <div class="modal-main" ref="modal" tabindex="0" @keydown="keyPress">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useModalHelper } from "@/helpers/modalHelper";
export default {
  props: {
    isOpen: Boolean,
  },
  setup(props) {
    const modal = ref(),
      { closeModal } = useModalHelper();
    const keyPress = (e) => {
      if (e.key == "Escape") {
        closeModal();
      }
    };
    watch(
      () => props.isOpen,
      () => {        
        setTimeout(() => {
          modal.value.focus();
        }, 100);
      }
    );
    return {
      keyPress,
      modal,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  overflow: auto;

  &.confirm {
    padding-top: 10%;
  }
}

.modal-wrapper {
  border-radius: 8px;
  max-width: 1000px;
  min-width: 400px;
  background: #fff;
  padding: 1.5rem 2rem;
  position: relative;
}
.modal-main {
  outline: none;
}
.modal-title {
  font-weight: 500;
  font-size: 16px;
  color: #365674;
}
</style>
