<template>
  <svg :opacity="opacity" :width="width" :height="height" viewBox="0 0 22 22" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.33333 3.66665H19.25V5.49998H7.33333V3.66665ZM2.75 3.20831H5.5V5.95831H2.75V3.20831ZM2.75 9.62498H5.5V12.375H2.75V9.62498ZM2.75 16.0416H5.5V18.7916H2.75V16.0416ZM7.33333 10.0833H19.25V11.9166H7.33333V10.0833ZM7.33333 16.5H19.25V18.3333H7.33333V16.5Z"
      fill="#23252A" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "#0F8AF9",
    },
  },
};
</script>
