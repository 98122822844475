<template>
  <div class="container">
    <div class="header">
      <div class="close" @click="submit">
        <icon-close :width="'20px'" :height="'20px'" :fill="'#A0A3BD'" />
      </div>
    </div>
    <div class="text">
      {{ translate("import.error_import") }}
    </div>
    <div class="columns">
      <div class="col col-success">
        <div class="icon">
          <icon-check :width="'16px'" :height="'16px'" />
        </div>
        <strong>{{ success }}</strong>
        <p>
          {{ translate("import.imported") }}
        </p>
      </div>
      <div class="col col-error">
        <div class="icon">
          <icon-close :width="'20px'" :height="'20px'" :fill="'white'" />
        </div>
        <strong>{{ fails }}</strong>
        <p>
          {{ translate("import.fails") }}
        </p>
      </div>
    </div>
    <div class="actions">
      <app-button :color="'primary'" @click="submit">{{ translate("close") }}</app-button>
    </div>
  </div>
</template>
<script>
import AppButton from "@/components/shared/Button.vue";
import IconClose from "@/components/icons/IconClose.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
export default {
  components: {
    AppButton,
    IconClose,
    IconCheck,
  },
  inject: ["translate"],
  emits: ["onSubmit"],
  props: {
    success: {
      type: Number,
      default: 16,
    },
    fails: {
      type: Number,
      default: 6,
    },
  },
  setup(props, { emit }) {
    const submit = () => {
      emit("onSubmit");
    };
    return {
      submit,
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 500px;
}
.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .close {
    cursor: pointer;
  }
}
.text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #232b37;
}

.columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  .col {
    border: 1px solid #2684fe;
    border-radius: 8px;
    position: relative;
    height: 100px;
    display: flex;
    justify-content: center;
    padding: 30px 20px;
    flex-direction: column;
    strong {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height */
      display: block;
      /* #363636 */

      color: #363636;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #a1a9ba;
      margin: 0;
    }
    .icon {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #2684fe;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.col-error {
      border: 1px solid #ff7e7e;
      .icon {
        background: #ff7e7e;
      }
    }
  }
}

.actions {
  max-width: 110px;
  margin: 0 auto;
  margin-top: 30px;
}
</style>
