<template>
  <svg
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 9 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75935 1.19406V12.5422"
      stroke="#C3CAD9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.36133 4.60749L4.75948 1.1936L8.15762 4.60749"
      stroke="#C3CAD9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
