<template>
  <svg
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 17 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.23073 16.8462C3.77227 16.8462 0.153809 13.2358 0.153809 8.76931C0.153809 4.31084 3.77227 0.692383 8.23073 0.692383C12.6973 0.692383 16.3077 4.31084 16.3077 8.76931C16.3077 13.2358 12.6973 16.8462 8.23073 16.8462ZM10.8073 11.7659C10.9042 11.8224 11.0092 11.8547 11.1223 11.8547C11.3242 11.8547 11.5261 11.7497 11.6392 11.5559C11.8088 11.2732 11.72 10.9016 11.4292 10.7239L8.55382 9.01163V5.28009C8.55382 4.94086 8.2792 4.67432 7.94805 4.67432C7.61689 4.67432 7.34228 4.94086 7.34228 5.28009V9.35894C7.34228 9.56894 7.45536 9.76278 7.64112 9.87586L10.8073 11.7659Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
