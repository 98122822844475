<template>
	<div ref="wbsContainer" class="wbs-container" data-testid="wbs-document">
		<app-top-panel :isShowFilter="isShowFilter" :isShowNewNode="!isHasRootNode" :projectStatistic="projectStatistic"
			:isImportError="isImportError" :isImportRun="isImportRun" :viewMode="'list'" @onAction="onPanelAction">
			<app-project-indicator :estimate="projectStatistic.estimateStr" :value="projectStatistic.projectProgress">
				<app-progress-bar
					:projectDoneOriginalEstimateInStoryPoints="projectStatistic.projectDoneOriginalEstimateInStoryPoints"
					:spent="projectStatistic.projectProgress" :overlog="projectStatistic.calcOverLog"
					:remaining="projectStatistic.calcRemaining" :isShowInfo="true"
					:projectProgressStr="projectStatistic.projectProgressStr" :spentStr="projectStatistic.spentStr"
					:remainingStr="projectStatistic.remainingStr" :estimateStr="projectStatistic.estimateStr"
					:background="'#ffffff'" />
			</app-project-indicator>
			<app-filter ref="filterComponent" :isShow="isShowFilter" :isFiltered="isFiltered" :total="foundCount"
				@onClose="onCloseFilter" @onSumbit="onSubmitFilter" />
		</app-top-panel>
		<app-notification :list="notificationList" />
		<div class="list-view-component" :class="{ template: state.source == 'templates' }">
			<app-color-picker v-if="currentDropDown?.name == 'color-picker'" :color="currentNode?.colorNode"
				:isOpenPicker="isOpenPicker" :currentNode="currentNode" :centerX="state.centerX" :centerY="state.centerY"
				:position="dropDownPosition" :dependingColor="currentNode?.applyColorToChildren" @onCancel="cancelColorPicker"
				@onSubmit="submitColorPicker" @onClickOutSide="hideDropDowns" />
			<app-select-status v-if="currentDropDown?.name == 'status'" :status="currentNode?.jiraIssueStatusId"
				:position="dropDownPosition" :projectId="currentNode?.jiraProject?.id"
				:issueTypeId="currentNode?.jiraIssueType?.id" :nodeId="currentNode?.id" @onChange="onChangeDropDown"
				@onClickOutSide="hideDropDowns" />
			<app-select-user v-if="currentDropDown?.name == 'assigned'" ref="selectUser" :userData="currentDropDown.value"
				:position="dropDownPosition" @onChange="onChangeDropDown" />
			<app-confirmation :position="confirmationPosition" :showDeleteFromJira="state.source == 'projects' ? true : false"
				@onSubmit="nodeDelete" @onCancel="closeConfirmation" @onClickOutSide="closeConfirmation" />
			<app-error-node v-if="currentNodeErrors" :position="nodeErrorPosition" :nodeErrors="currentNodeErrors"
				@onRetry="retryNodeAction" @onDismiss="dismissNodeError" @onClose="closeNodeErrorPopup" />
			<div class="issue-list-container">
				<div class="issue-list" :class="{ 'is-loading': isLoading }">
					<div v-if="!isShowPlaceHolder" class="filter-input">
						<app-search-input v-model="searchStr" :placeholder="translate('search_issue')" />
						<div class="project-filter">
							<div v-if="state.source == 'projects'" v-tooltip="{
								content: translate('wbs.filter'),
								placement: 'bottom',
							}" :class="{ active: isShowFilter, 'is-filtered': isFiltered }" class="panel-btn open-filter"
								data-testid="open-filter" @click="onPanelAction('openFilter')">
								<span v-if="isFiltered" class="badge"></span>
								<icon-filter :width="'42px'" :height="'16px'" :fill="isShowFilter || isFiltered ? '#fff' : '#23252A'" />
							</div>
						</div>
						<div :disabled="isHasRootNode || isImportRun" data-testid="new-node" class="add-root-node"
							@click="onPanelAction('openNewNodeModal')">
							<icon-add-new-node width="18px" />
							{{ translate("wbs.create_top_node") }}
						</div>
						<div v-if="source == 'templates' && nodesCount > 0" v-tooltip="{
							content: translate('create_project'),
							placement: 'bottom',
						}" class="add-root-node" @click="onPanelAction('createProject')">
							{{ translate("create_project") }}
						</div>
						<div class="nodes-count">
							{{ translate("wbs.issues") }}:
							<span>{{ nodesCount }}</span>
						</div>
					</div>
					<div v-if="isShowPlaceHolder" class="drag-node-placeholder" :class="{ 'is-hover': isPlaceHolderHover }"
						:style="{
							width: `100%`,
							height: `50px`,
						}" @click="addNewNode" @dragenter.prevent="dragEnterPlaceHolder" @dragover.prevent @drop="dropPlaceHolder"
						@dragleave="dragLeavePlaceHolder">
						<icon-add-node :stroke="isPlaceHolderHover ? '#0f8af9' : '#A1A9BA'" :width="'24px'" :height="'24px'" />
					</div>
					<p v-if="isLoading" class="please-wait">
						{{ translate("please_wait") }}
					</p>
					<app-jira-issues-custom-list v-if="!isShowPlaceHolder" data-testid="jira-issues-custom-list"
						:filter="searchStr" :columnsDefault="[]" :columns="columns" :rows="renderRows" :height="calcHeight"
						:rowStyle="rowStyle" :cellStyle="[]" :allowMultipleSelect="false" :allowToDrop="allowToDrop"
						:allowedDragRows="allowedDragNodes" :keepOpenOneLevel="false" :selected="selectedRows"
						:blockedRows="blockedRows" :collapseChildren="false" :expandAll="expandAll" @onShowChildren="onShowChildren"
						@onDragstart="onDragstart" @onDragEnd="onDragEnd" @onSelect="setCurrentNode" @onDragHover="onDragHover"
						@onCellClick="onCellClick" @onDragEnter="onDragEnter" @onDrop="onDrop" @onDragleave="onDragleave
						">
						<template #expand-collapse>
							<div :title="translate('wbs.expand_all')" data-testid="expand-all" @click="setExpand(true)">
								<icon-expand-all width="16px" height="100%" />
							</div>
							<div :title="translate('wbs.collapse_all')" data-testid="collapse-all" @click="setExpand(false)">
								<icon-collapse-all width="16px" height="100%" />
							</div>
						</template>
						<template #column="{ column }">
							<div class="column">
								<div v-if="
									Object.keys(totals).includes(
										column.name
									)
								" class="total">
									{{
											totals[column.name]
												? totals[column.name]
												: "0.00"
									
									}}
								</div>
								<div>
									{{ column.title }}
								</div>
							</div>
						</template>
						<template #row="{ row }">
							<div ref="rowElement" class="cell">
								<span :style="{
									color: row.row.colorNode?.split(
										':'
									)[1],
								}">
									<template v-if="CellComponentList[row.name]">
										<component :is="CellComponentList[row.name]" :row="row" :columns="columns"
											:highlight="updatedProperties.some((p) => p.name == row.name && p.id == row.row.id)"
											:children-count="childrenCount.get(row.row.id)" />
									</template>
									<cell-component v-else :row="row" :columns="columns"
										:highlight="updatedProperties.some((p) => p.name == row.name && p.id == row.row.id)"
										:children-count="childrenCount.get(row.row.id)" />
								</span>
							</div>
						</template>
						<template #actions="{ row }">
							<div class="actions-row" @mousedown="addBlockedRow(row.row.id)" @mouseup="removeBlockedRow">
								<div class="action-btn">
									<icon-error v-if="row.row.syncError" width="15px" height="15px" fill="red" @click="
										(e) =>
											showNodeErrorPopup({
												event: e,
												nodeError: row.row.id,
											})
									" />
								</div>
								<div class="action-btn" @click="
									(e) =>
										openColorPicker({
											id: row.row.id,
											e,
										})
								">
									<icon-circle :width="'12px'" :height="'12px'" />
								</div>
								<div v-if="state.source !== 'projects'" class="action-btn">
									<icon-pencil :width="'20px'" :height="'20px'" @click="editNode(row.row.id)" />
								</div>
								<div class="action-btn" :class="{ disabled: row.row?.subtask }">
									<icon-add-node :stroke="'#A1A9BA'" :width="'20px'" :height="'20px'" @click="addNewNode(row.row.id)" />
								</div>
								<div class="action-btn">
									<icon-trash :width="'20px'" :fill="'none'" :height="'20px'" @click="
	(e) =>
		onBeforeNodeDelete(
			row.row.id,
			e
		)
									" />
								</div>
							</div>
						</template>
					</app-jira-issues-custom-list>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	ref,
	reactive,
	computed,
	onMounted,
	inject,
	watch,
	onUnmounted,
	toRefs,
	nextTick,
} from "vue";
import { useStore } from "vuex";
import { useCookies } from "@/helpers/cookies";
import router from "@/router";
import { useModalHelper } from "@/helpers/modalHelper";
import { usePositionHelper } from "@/helpers/positionHelper";
import { useDateHelper } from "@/helpers/dateHelper";
import { useCalcProgress } from "@/helpers/calcProgress";
import { useAllowedIssueTypes } from "@/helpers/allowedIssueTypes";
import { useStatusColor } from "@/helpers/statusColor";
import IconError from "@/components/icons/IconError.vue";
import IconPencil from "@/components/icons/IconPencil.vue";
import IconCircle from "@/components/icons/IconCircle.vue";
import IconAddNode from "@/components/icons/IconAddNode.vue";
import IconTrash from "@/components/icons/IconTrash.vue";
import IconFilter from "@/components/icons/IconFilter.vue";
import AppJiraIssuesCustomList from "jira-issue-custom-list";
import AppNodeContent from "@/components/wbsProject/NodeContent.vue";
import AppColorPicker from "@/components/wbsProject/ColorPicker.vue";
import AppSelectStatus from "@/components/wbsProject/SelectStatus.vue";
import AppSelectUser from "@/components/wbsProject/SelectUser.vue";
import AppConfirmation from "@/components/wbsProject/Confirmation.vue";
import AppErrorNode from "@/components/wbsProject/ErrorNode.vue";
import AppTopPanel from "@/components/wbsProject/TopPanel.vue";
import AppFilter from "@/components/wbsProject/Filter.vue";
import AppProjectIndicator from "@/components/wbsProject/ProjectIndicator.vue";
import AppProgressBar from "@/components/wbsProject/estimation/ProgressBar.vue";
import AppNotification from "@/components/wbsProject/Notification.vue";
import errorToList from "@/helpers/errorToList";
import debounce from "lodash.debounce";
import moment from "moment";
import AppSearchInput from "@/components/shared/SearchInput.vue";
import AppOrphanedComponent from "@/components/wbsProject/orphaned/OrphanedComponent.vue";
import { useStatistic } from "@/components/wbsProject/modules/statistic";
import { useSubtaskNodes } from "@/components/wbsProject/modules/subtaskNodes";
import IconCollapseAll from "@/components/icons/IconCollapseAll.vue";
import IconExpandAll from "@/components/icons/IconExpandAll.vue";
import IconDefault from "@/components/icons/IconDefault.vue";
import IconAddNewNode from "@/components/icons/IconAddNewNode.vue";
import { createToaster } from "@meforma/vue-toaster";
import AppSummary from "@/components/wbsProject/Summary.vue";
const toaster = createToaster({ position: "top-right" });
import vOpenJiraIssueDialog from "@/directives/openJiraIssueDialog.js";
import AppSearchPages from "@/components/shared/SearchPages.vue";
import AppTopMenu from "@/components/dashboard/TopMenu.vue";
import { useWbsDocument } from "./wbsDocument";
import { useCellComponents } from "@/components/wbsProject/cellComponents.js";
export default {
	components: {
		IconPencil,
		IconCircle,
		IconAddNode,
		IconTrash,
		IconFilter,
		IconError,
		IconCollapseAll,
		IconExpandAll,
		IconDefault,
		IconAddNewNode,
		AppTopPanel,
		AppJiraIssuesCustomList,
		AppNodeContent,
		AppColorPicker,
		AppSelectStatus,
		AppSelectUser,
		AppConfirmation,
		AppErrorNode,
		AppFilter,
		AppProjectIndicator,
		AppProgressBar,
		AppNotification,
		AppSearchInput,
		AppOrphanedComponent,
		AppSummary,
		AppTopMenu,
		AppSearchPages,
		...useCellComponents(),
	},
	directives: {
		"open-jira-issue-dialog": vOpenJiraIssueDialog,
	},
	inject: ["translate"],
	setup() {
		const { openModal, storeModal } = useModalHelper(),
			CellComponentList = {
				jiraIssueKey: "IssueKeyCell",
				status: "StatusCell",
				assignee: "AssigneeCell",
				progress: "ProgressBarCell",
				timeSpentFormatted: "EstimatesCellComponent",
				remainingEstimateFormatted: "EstimatesCellComponent",
				originalEstimateInStoryPoints: "EstimatesCellComponent",
				originalEstimateFormatted: "EstimatesCellComponent",
				summary: "SummaryCell",
				codeOfAccounts: "CellCOAComponent",
				jiraIssueType: "IssueTypeCell",
			},
			{ calcElementPosition } = usePositionHelper(),
			translate = inject("translate"),
			store = useStore(),
			selectUser = ref(),
			currentNode = ref(null),
			isShowFilter = ref(false),
			printInfo = ref(null),
			blockedRow = ref(null),
			state = reactive({
				searchStr: "",
				expandAll: null,
				isLoading: false,
				columns: computed(() => {
					const replaceKeys = {
						codeOfAccounts: "code_of_accounts",
						jiraIssueKey: "issue_key",
						jiraIssueType: "issue_type",
						originalEstimateFormatted: "oe",
						timeSpentFormatted: "ts",
						remainingEstimateFormatted: "re",
					};

					if (store.getters["wbs/source"] == "templates") {
						return [
							{
								name: "codeOfAccounts",
								title: "codeOfAccounts",
							},
							{
								name: "jiraIssueType",
								title: "issue_type",
							},
							{
								name: "summary",
								title: "Summary",
							},
							{
								name: "assignee",
								title: "Assignee",
							},
							{
								name: "originalEstimateFormatted",
								title: "OE",
							},
							{
								name: "timeSpentFormatted",
								title: "TS",
							},
							{
								name: "remainingEstimateFormatted",
								title: "RE",
							},
							{
								name: "progress",
								title: "Progress",
							},
						].map((c) => {
							return {
								...c,
								title: translate(
									`wbs.${replaceKeys[c.name]
										? replaceKeys[c.name]
										: c.name
									}`
								),
							};
						});
					} else
						return [
							{
								name: "codeOfAccounts",
								title: "codeOfAccounts",
							},
							{
								name: "jiraIssueKey",
								title: "issue_key",
							},
							{
								name: "summary",
								title: "Summary",
							},
							{
								name: "assignee",
								title: "Assignee",
								clickable: true,
							},
							{
								name: "status",
								title: "Status",
								clickable: true,
							},
							{
								name: "originalEstimateFormatted",
								title: "OE",
								clickable: true,
							},
							{
								name: "timeSpentFormatted",
								title: "TS",
								clickable: true,
							},
							{
								name: "remainingEstimateFormatted",
								title: "RE",
								clickable: true,
							},
							{
								name: "progress",
								title: "Progress",
							},
						].map((c) => {
							return {
								...c,
								title: translate(
									`wbs.${replaceKeys[c.name]
										? replaceKeys[c.name]
										: c.name
									}`
								),
							};
						});
				}),
				rows: [],
				totals: computed(() => {
					return {
						originalEstimateFormatted:
							projectStatistic.value["estimate"],
						timeSpentFormatted: projectStatistic.value["spent"],
						remainingEstimateFormatted:
							projectStatistic.value["remaining"],
					};
				}),
				calcHeight: null,
				nodes: [],
				links: [],
				projectId: null,
				source: computed(() => store.getters["wbs/source"]),
			}),
			backendErrors = ref([]),
			notifications = ref([]),
			notificationList = computed(() => {
				backendErrors.value.forEach((e) => {
					e.type = "error";
				});

				return [...backendErrors.value, ...notifications.value];
			}),
			isImportError = computed(() => {
				return store.getters["wbs/nodesModelMeta"]
					?.lastImportErrorMessage
					? true
					: false;
			}),
			wbsState = computed(() => {
				return store.getters["wbs/wbsState"];
			}),
			errorNodes = computed(() => {
				store.getters["wbs/errorNodes"].forEach((item) => {
					const node = state.nodes.find((n) => n.id == item.id);
					if (node) node.isUpdate = false;
				});
				return store.getters["wbs/errorNodes"];
			}),
			nodesCount = computed(() => store.getters["wbs/nodes"].length),
			currentNodeErrors = ref(null),
			filterComponent = ref("filterComponent"),
			isOrphanedCollapsed = ref(false),
			{ parseInput, humanTime } = useDateHelper(),
			{ allowedIssueTypes, allowedIssueTypesBefore } =
				useAllowedIssueTypes(),
			isFiltered = computed(() => {
				let result = false;
				if (wbsState.value.filters) {
					Object.keys(wbsState.value.filters).forEach((k) => {
						if (
							wbsState.value.filters[k] &&
							wbsState.value.filters[k].length
						) {
							result = true;
						}
					});
				}
				return result;
			}),
			isHasRootNode = computed(() => {
				const hierarchy = store.getters["settings/hierarchy"];
				if (hierarchy.length)
					return state.nodes.some(
						(n) =>
							n.topNode &&
							n.jiraIssueType.name == hierarchy[0].name
					);
				return false;
			}),
			isHasOrphaned = computed(
				() =>
					state.nodes.filter((n) => !n.parentId && !n.topNode)
						.length > 0
			),
			subtaskNodes = ref([]),
			blockedRows = computed(() => {
				return []
			}),
			projectStatistic = computed(() => {
				return useStatistic().projectStatistic.value;
			}),
			orphanedNodes = computed(() => state.rows.filter(r => r.isOrphaned == true).map(node => node.id)),
			renderRows = computed(() => {
				return state.rows;
			}),
			isPlaceHolderHover = ref(false),
			isShowPlaceHolder = computed(() => {
				return state.nodes.length == 0;
			}),
			allowNewParentNode = ref(null),
			allowedDragNodes = computed(() => {
				if (currentNode.value && targetMoveNode.value) {
					allowNewParentNode.value = null;
					if (
						targetMoveNode.value.topNode &&
						!currentNode.value.parentId
					) {
						const allowedTypes = allowedIssueTypesBefore(
							targetMoveNode.value.jiraIssueType.name
						);

						if (
							allowedTypes.length &&
							allowedTypes.some(
								(t) =>
									t.name ==
									currentNode.value.jiraIssueType.name
							)
						) {
							allowNewParentNode.value = targetMoveNode.value.id;
							return [currentNode.value.id];
						}
					}

					if (currentNode.value.isOrphaned &&
						targetMoveNode.value.isOrphaned &&
						!currentNode.value.parentId &&
						!targetMoveNode.value.parentId &&
						currentNode.value.jiraIssueType.jiraIssueTypeId ==
						targetMoveNode.value.jiraIssueType.jiraIssueTypeId) {
						return [-1];
					}

					if (
						targetMoveNode.value.topNode &&
						currentNode.value.jiraIssueType.jiraIssueTypeId ==
						targetMoveNode.value.jiraIssueType.jiraIssueTypeId
					) {
						allowNewParentNode.value = targetMoveNode.value.id;
						return [currentNode.value.id];
					}

					if (currentNode.value.parentId == targetMoveNode.value.parentId &&
						!currentNode.value.isOrphaned &&
						!targetMoveNode.value.isOrphaned
					) {
						return [currentNode.value.id];
					}

					if (currentNode.value.parentId == targetMoveNode.value.id) {
						return [-1];
					}
					if (
						currentNode.value?.isSubtask == 1 ||
						targetMoveNode.value?.isSubtask == 1
					) {
						return [-1];
					}
					if (!currentNode.value?.isSubtask) {
						const allowedTypes = allowedIssueTypes(
							parseInt(
								targetMoveNode.value.jiraIssueType
									.jiraIssueTypeId
							),
							parseInt(targetMoveNode.value.id)
						).map((n) => n.id);
						let ids = [];
						ids = state.nodes
							.filter((n) =>
								allowedTypes.some((t) => t == n.jiraIssueTypeId)
							)
							.map((n) => n.id)
						return !ids?.length ? [-1] : ids;
					}
				}
				return [-1];
			}),
			customFields = computed(() => {
				const customFileds =
					store.getters["wbs/nodesMeta"].customFields || [];
				return customFileds.map((f) => {
					return {
						id: f.jiraCustomFieldId,
						name: f.jiraCustomFieldName,
					};
				});
			}),
			filteredNodes = ref([]),
			childrenCount = computed(() => {
				return (
					state.nodes.reduce((acc, node) => {
						acc = acc || new Map();
						let count = 0;
						if (isFiltered.value) {
							const children = getChildren(node.id);
							children.forEach((c) => {
								const node = state.nodes.find(
									(n) => n.id == c.id
								);
								if (filteredNodes.value.includes(node.id)) {
									count++;
								}
							});
						} else {
							const children = getChildren(node.id, false);
							children.forEach((c) => {
								count++;
							});
						}

						acc.set(node.id, count);
						return acc;
					}, new Map()) || new Map()
				);
			}),
			isImportRun = computed(() => {
				return store.getters["wbs/isImportRun"]
			}),
			isExistsImport = computed(
				() => store.getters["wbs/isExistsImport"]
			),
			rowElement = ref(null),
			notUpdateFromDelta = ref(false),
			isJsLoading = computed(() => {
				const result = state.nodes?.length && !rowElement.value;
				return result;
			});

		const rowStyle = ref(AppJiraIssuesCustomList.props.rowStyle.default);
		rowStyle.value.push({
			condition: new Function("row", "return row.isUpdate == true"),
			style: { background: "#ff98003d" },
		});
		rowStyle.value.push({
			condition: new Function("row", "return row.isNew == true"),
			style: { background: "#2684fe42" },
		});
		const targetMoveNode = ref();
		const onDragHover = (id) => {
			const node = state.nodes.find((n) => n.id == id);
			targetMoveNode.value = node;
		};
		const onCellClick = (e) => {
			const node = state.nodes.find((n) => n.id == e.row.id);
			switch (e.name) {
				case "assignee":
					openAssignDropDown(node, e.event);
					break;
				case "status":
					openStatusDropDown(node, e.event);
					break;
				case "originalEstimateFormatted":
					openEstimationPopup(node.id, 0);
					break;
				case "timeSpentFormatted":
					openEstimationPopup(node.id, 1);
					break;
				case "remainingEstimateFormatted":
					openEstimationPopup(node.id, 1, 'remaining-estimate');
					break;
				case "jiraIssueKey":
					e.event.preventDefault();
					editNode(node.id)
					break;
				default:
					closeDropDown();
					break;
			}
		};
		const currentDropDown = ref(null),
			dropDownPosition = ref({
				position: "absolute",
				display: "none",
			}),
			confirmationPosition = ref({
				position: "absolute",
				display: "none",
			}),
			nodeErrorPosition = ref({
				position: "absolute",
				display: "none",
			}),
			wbsContainer = ref(),
			zoomInd = ref(8),
			isOpenPicker = ref(false);

		const onShowChildren = async ({ value, row }) => {
			const node = state.nodes.find((n) => n.id == row.id);
			node.listChildrenCollapsed = !value;
			await store.dispatch("wbs/setChildrenCollapsed", {
				projectId: state.projectId,
				nodeId: node.id,
				viewOption: "list",
				childrenCollapsed: node.listChildrenCollapsed,
			});

			store.commit("wbs/UPDATE_NODE_LIST_COLLAPSE", {
				id: node.id,
				childrenCollapsed: node.listChildrenCollapsed,
			});
		};

		const getChildren = (id, recursion = true, rows = state.rows) => {
			const children = [];
			function rec(id) {
				for (let i = 0; i < rows.length; i++) {
					const row = rows[i];
					if (row.parentId == id) {
						children.push({
							id: row.id,
							parent: id,
						});

						if (recursion) rec(row.id);
					}
				}
			}
			rec(id);
			return children;
		};
		let oldNodesColor = [];
		const openColorPicker = ({ id, e }) => {
			isOpenPicker.value = true;
			oldNodesColor = [];
			const node = state.nodes.find((n) => n.id == id);
			//currentNode.value = node;
			setCurrentNode(node.id)
			oldNodesColor.push({ id: node.id, color: node.colorNode });
			const children = getChildren(currentNode.value.id);
			children.forEach((child) => {
				const node = state.nodes.find((n) => n.id === child.id);
				oldNodesColor.push({ id: node.id, color: node.colorNode });
			});
			currentDropDown.value = { name: "color-picker" };
			nextTick(() => {
				dropDownPosition.value = calcElementPosition({
					e,
					popupWidth: 250,
					container: wbsContainer.value,
					popupHeight: 270,
				});
			});
		};

		const cancelColorPicker = () => {
			isOpenPicker.value = false;
			if (!currentNode.value || !oldNodesColor.length) return;

			currentNode.value.colorNode = oldNodesColor.find(
				(c) => (c.id = currentNode.value.id)
			).color;

			const children = getChildren(currentNode.value.id);
			children.forEach((child) => {
				const node = state.nodes.find((n) => n.id === child.id);
				node.colorNode = oldNodesColor.find(
					(c) => c.id === child.id
				).color;
			});
			oldNodesColor.values = [];
			makeRows();
		};

		const changeColorPicker = ({ color, backgroundColor, depending }) => {
			if (currentNode.value) {
				currentNode.value.colorNode = backgroundColor + ":" + color;
				currentNode.value.depending = depending;
				currentNode.value.applyColorToChildren = depending;
				if (depending) {
					const children = getChildren(currentNode.value.id);
					children.forEach((child) => {
						const node = state.nodes.find((n) => n.id === child.id);
						node.colorNode = backgroundColor + ":" + color;
					});
				} else {
					const children = getChildren(currentNode.value.id);
					children.forEach((child) => {
						const node = state.nodes.find((n) => n.id === child.id);
						node.colorNode = oldNodesColor.find(
							(c) => c.id === child.id
						).color;
					});
				}
				makeRows();
			}
		};

		const closeConfirmation = () => {
			confirmationPosition.value = {
				display: "none",
			};
		};

		const closeDropDown = () => {
			if (!currentDropDown.value) return;

			dropDownPosition.value = {
				display: "none",
			};
		};

		const hideDropDowns = () => {
			isOpenPicker.value = false;
			closeDropDown();
			closeNodeErrorPopup();
		};

		const submitColorPicker = async ({
			color,
			backgroundColor,
			depending,
		}) => {
			isOpenPicker.value = false;
			changeColorPicker({ color, backgroundColor, depending });
			await store.dispatch("wbs/editNode", {
				projectId: state.projectId,
				nodeId: currentNode.value.id,
				node: currentNode.value,
			});
		};

		const setCurrentNode = (id) => {
			const node = state.nodes.find((n) => n.id == id);
			currentNode.value = node;
			closeDropDown();
		};

		const openFilter = () => {
			isShowFilter.value = !isShowFilter.value;
		};

		const onCloseFilter = () => {
			isShowFilter.value = false;
		};

		const onSubmitFilter = () => {
			makeTree();
		};

		const openAssignDropDown = (node, e) => {
			setCurrentNode(node.id);
			currentDropDown.value = { name: "assigned", value: node.assignee };
			nextTick(() => {
				dropDownPosition.value = calcElementPosition({
					e,
					popupWidth: 250,
					container: wbsContainer.value,
					popupHeight: 380,
				});
			});

			// if (selectUser.value) {
			// 	selectUser.value.resetUserId();
			// }
		};

		const openStatusDropDown = (node, e) => {
			setCurrentNode(node.id)
			currentDropDown.value = {
				name: "status",
				value: node.jiraIssueStatus.id,
			};
			nextTick(() => {
				dropDownPosition.value = calcElementPosition({
					e,
					popupWidth: 250,
					container: wbsContainer.value,
					popupHeight: 240,
				});
			});
		};

		const onChangeDropDown = async (item) => {

			if (!item) {
				closeDropDown();
				return;
			}

			if (currentDropDown.value.name == "assigned") {
				const node = state.nodes.find(
					(n) => n.id == currentNode.value.id
				);
				node.assigneeId = item.value == -1 ? null : item.value;

				node.assignee =
					item.value == -1
						? null
						: {
							id: item.value,
							displayName: item.displayName,
							iconUri: item.iconUri,
						};

				setTimeout(() => {
					makeRows();
				});

				store.dispatch("wbs/editNode", {
					projectId: state.projectId,
					nodeId: node.id,
					node,
				});
				closeDropDown();
			}

			if (currentDropDown.value.name == "status") {
				const node = state.nodes.find(
					(n) => n.id == currentNode.value.id
				);
				if (!item) return;

				node.jiraIssueStatusId = item.id;
				node.jiraIssueStatus = {
					...node.jiraIssueStatus, ...{
						id: item.id,
						name: item.name,
						categoryColor: item.categoryColor,
						jiraIssueStatusId: item.id,
					}
				};

				setTimeout(() => {
					makeRows();
				});

				store.dispatch("wbs/editNode", {
					projectId: state.projectId,
					nodeId: node.id,
					node,
				});
				closeDropDown();
			}
		};
		const onChangeSummary = (id, value) => {
			const node = state.nodes.find((n) => n.id == id);
			node.summary = value;
			store.dispatch("wbs/editNode", {
				projectId: state.projectId,
				nodeId: node.id,
				node,
			});
			makeRows();
		};

		const getPrevCodeOfAccount = (code) => {
			let codeOfAccounts = code;
			let arr = codeOfAccounts.toString().split(".");
			if (arr.length) {
				let lastNumber = arr.pop();
				if (lastNumber > 1) {
					lastNumber = parseInt(lastNumber) - 1;
				}
				arr[arr.length] = lastNumber;
				codeOfAccounts = arr.join(".");
			} else if (codeOfAccounts > 1) {
				codeOfAccounts = parseInt(codeOfAccounts) - 1;
			}
			return codeOfAccounts;
		};

		const getLastCodeOfAccount = (id) => {
			let code = 0;
			const foundNode = state.nodes.find((n) => n.id == id);
			if (foundNode.isOrphaned == false) {
				const children = getChildren(foundNode.id, false);
				if (children.length) {
					const last = children.pop()
					code = state.nodes.find(r => r.id == last.id)?.codeOfAccounts;
					const arr = code.toString().split('.');
					let numb = arr.pop();
					numb = parseInt(numb) + 1;
					arr[arr.length] = numb;
					code = arr.join('.')
				} else {
					code = foundNode.codeOfAccounts + '.1';
				}
			}

			return code;
		};

		const recalcCodeOfAccounts = (id, updateRows = true) => {
			const recursion = (ids) => {
				for (let index = 0; index < ids.length; index++) {
					let node = state.nodes.find((n) => n.id == ids[index].id);
					let codeOfAccounts;
					let parent = state.nodes.find((n) => n.id == node.parentId);
					if (!parent)
						codeOfAccounts = '0';
					else if (parent.codeOfAccounts == 0) {
						codeOfAccounts = parseInt(index + 1);
					} else {
						codeOfAccounts =
							parent.codeOfAccounts + "." + parseInt(index + 1);
					}

					node.codeOfAccounts = codeOfAccounts;
					const children = getChildren(node.id, false);
					if (children.length) {
						recursion(children);
					}
				}
			};

			const childrenIds = getChildren(id, false);
			recursion(childrenIds);
			if (updateRows)
				updateTree();
		};

		const onChangeStructure = async ({ from, to, rootNode = false }) => {
			const moveNode = async (nodeId, node) => {
				await store.dispatch("wbs/updateCoa", {
					projectId: state.projectId,
					nodeId,
					node,
				});
			};

			// it means create new root node from orphaned
			if (rootNode) {
				moveNode(rootNode.id, rootNode);
				rootNode.topNodeId = null;
				return;
			}

			if (!to) return;

			const node = state.nodes.find((n) => n.id == from[0]);
			if (!node) return;
			node.visible = true;
			node.parentId = to;
			node.codeOfAccounts = getLastCodeOfAccount(to);
			moveNode(node.id, node);
			updateTree();
		};

		const onBeforeNodeDelete = (id, e) => {
			const node = state.nodes.find((n) => n.id == id);
			if (!node) return;
			//currentNode.value = node;
			setCurrentNode(node.id)
			confirmationPosition.value = calcElementPosition({
				e,
				popupWidth: 250,
				container: wbsContainer.value,
				popupHeight: 200,
			});
		};

		const nodeDelete = async (deleteFromJira = false) => {
			if (!currentNode.value) return;

			closeConfirmation();

			const nodeList = [];
			let offerNodes = [];
			orphanedNodes.value.forEach((c) => {
				const node = state.nodes.find((n) => n.id == c);
				if (node) nodeList.push(node);
			});
			if (nodeList.length == 1) {
				offerNodes = nodeList;
			} else {
				const hierarchy = store.getters["settings/hierarchy"];
				const issueTypesList = store.getters["settings/issueTypes"];

				const custom = hierarchy
					.filter((n) => n.default == false).map(item => {
						const foundIssue = issueTypesList.find(l => l.name == item.name);
						return foundIssue
					});

				const epic = hierarchy
					.filter((n) => n.default == true && n.name == 'Epic').map(item => {
						const foundIssue = issueTypesList.find(l => l.name == item.name);
						return foundIssue
					});

				const standarts = hierarchy
					.filter((n) => n.default == true)
					.map((n) => {
						return n.issueTypes.filter((i) => i.subtask == 0 && i?.isSelected == true);
					})
					.find((i) => i.length > 0)
					.map((item) => {
						return item;
					});

				offerNodes = nodeList.filter(n => custom.some(c => c.jiraIssueTypeId == n?.jiraIssueType?.jiraIssueTypeId));

				if (offerNodes.length == 0) {
					offerNodes = nodeList.filter(n => epic.some(c => c.jiraIssueTypeId == n?.jiraIssueType?.jiraIssueTypeId));
				}

				if (offerNodes.length == 0) {
					offerNodes = nodeList.filter(n => standarts.some(c => c.jiraIssueTypeId == n?.jiraIssueType?.jiraIssueTypeId));
				}
			}
			if (currentNode.value.topNode && offerNodes.length == 1) {
				let node = state.nodes.find(
					(n) => n.id == currentNode.value.id
				);

				if (node) {
					state.nodes = state.nodes.filter(
						(n) => n.id !== node.id
					);
					getChildren(node.id, true).forEach((c) => {
						state.nodes = state.nodes.filter((deleteNode) => deleteNode.id !== c.id);
					});
					const newRoot = state.nodes.find(n => n.id == offerNodes[0].id);
					newRoot.topNode = true;
					newRoot.codeOfAccounts = '0';
					makeRows();
					await store.dispatch("wbs/deleteNode", {
						projectId: state.projectId,
						nodeId: currentNode.value.id,
						deleteFromJira: deleteFromJira,
						nextParent: newRoot.id,
					});
				}
			} else if (currentNode.value.topNode && offerNodes.length > 1) {
				openModal("SelectParentNode", {
					childrenList: offerNodes,
				});
				const unsubscribe = storeModal.subscribe(async (data) => {
					if (!data.type.startsWith("modal")) return;
					if (data.type === "modal/setData") {
						closeConfirmation();

						let node = state.nodes.find(
							(n) => n.id == currentNode.value.id
						);

						if (node) {
							state.nodes = state.nodes.filter(
								(n) => n.id !== node.id
							);
							getChildren(node.id, true).forEach((c) => {
								state.nodes = state.nodes.filter((deleteNode) => deleteNode.id !== c.id);
							});
							const newRoot = state.nodes.find(n => n.id == data.payload.nodeId);
							newRoot.topNode = true;
							newRoot.codeOfAccounts = '0';
							makeRows();
						}

						await store.dispatch("wbs/deleteNode", {
							projectId: state.projectId,
							nodeId: currentNode.value.id,
							deleteFromJira: deleteFromJira,
							nextParent: data.payload.nodeId,
						});
					}
					unsubscribe();
				});
			} else {

				closeConfirmation();
				let node = state.nodes.find(
					(n) => n.id == currentNode.value.id
				);
				if (node) {
					state.nodes = state.nodes.filter(
						(n) => n.id !== node.id
					);
					getChildren(node.id, true).forEach((c) => {
						state.nodes = state.nodes.filter((deleteNode) => deleteNode.id !== c.id);
					});

					makeRows();
				}

				try {
					await store.dispatch("wbs/deleteNode", {
						projectId: state.projectId,
						nodeId: currentNode.value.id,
						deleteFromJira: deleteFromJira,
					});
				} catch (error) {
					backendErrors.value = errorToList(error);
					setTimeout(() => {
						backendErrors.value = [];
					}, 3000);
				}
			}
		};

		const updateTree = () => {
			filterNodes(state.nodes);
			store.commit("wbs/UPDATE_NODES", state.nodes);
			makeRows();
		};

    const getMaxCodeOfAccount = (parentId) => {
      const incCode = (codeOfAccounts) => {
        if (codeOfAccounts.indexOf(".") !== -1) {
          const arr = codeOfAccounts.split(".");
          let inc = parseInt(arr[arr.length - 1]) + 1;
          arr[arr.length - 1] = inc;
          return arr.join(".");
        } else {
          return parseInt(codeOfAccounts) + 1;
        }
      };

      if (!parentId) return 0;

      const nodes = store.getters["wbs/nodes"].filter(
        (n) => n.parentId == parentId
      );

      if (!nodes.length) {
        const node = store.getters["wbs/nodes"].find(
          (n) => n.id == parentId
        );
        if (node.codeOfAccounts == '---')
          return '---';

        if (node.codeOfAccounts != "0")
          return node.codeOfAccounts + ".1";
        else
          return "1";
      }

      const lastNode = nodes.sort(function (a, b) {
        let codeAccountA = a.codeOfAccounts?.toString();
        let codeAccountB = b.codeOfAccounts?.toString();

        if (codeAccountA && codeAccountB) {
          if (codeAccountA.split('.').length) {
            codeAccountA = codeAccountA.split(".").pop()
          } else {
            if (a.isOrphaned) {
              codeAccountA = "0"
            }
          }

          if (codeAccountB.split('.').length) {
            codeAccountB = codeAccountB.split(".").pop()
          } else {
            if (b.isOrphaned) {
              codeAccountB = "0"
            }
          }

          return parseInt(codeAccountA) - parseInt(codeAccountB);
        }
      })[nodes.length - 1];

      let codeOfAccounts = lastNode.codeOfAccounts;
      return incCode(codeOfAccounts.toString());
    };

		const addNewNode = async (id) => {
			const node = state.nodes.find((n) => n.id == id);
			if (node) {
				// openModal("NewNode", {
				// 	projectId: parseInt(state.projectId),
				// 	jiraIssueTypeId: parseInt(
				// 		node.jiraIssueType.jiraIssueTypeId
				// 	),
				// 	jiraProject: node.jiraProject,
				// 	parentNode: node,
				// 	parentId: id ? parseInt(id) : null,
				// });				
				  const issueTypes = allowedIssueTypes(node.jiraIssueType.jiraIssueTypeId, node.id);

					AP.jira.openCreateIssueDialog(async function(issues){
						const issue = issues?.[0]
						if (!issue) return;
						console.log('issue=', issue)
						let response = await store.dispatch('wbs/addTicketToJira', { 
							projectId: state.projectId, 
							jiraProjectId: issue.fields.project.id, 
							jiraIssueTypeId: issue.fields.issuetype.id, 
							jiraIssueStatusId: issue.fields.status.id, 
							jiraPriorityId: issue.fields.priority.id,
							assigneeId: issue.fields?.assignee?.accountId,
							parentId: id,
							summary: issue.fields.summary,
							codeOfAccounts: getMaxCodeOfAccount(id),
						})
						response = response.data;
						console.log('response=', response);

						if (response.topNodeId) {
							const firstNode = state.nodes.length
								? state.nodes.find((n) => n.topNode == true)
								: null;
								if (firstNode) {
									firstNode.topNode = false;
									firstNode.parentId = response.id;
									firstNode.visible = true;
									firstNode.codeOfAccounts = '1';
									recalcCodeOfAccounts(firstNode.id);
								}
						}

						state.nodes.push(response);
						if (node) {
							node.childrenCollapsed = false;
						}
						updateTree();
						setTimeout(() => {
							const target = document.getElementById(response.id);
							if (target) target.scrollIntoView({ block: "end" });
						}, 100);
						
					}, {
						pid: node.jiraProject.jiraProjectId,
						issueType: issueTypes?.[0]?.jiraIssueTypeId,
					});				
			} else {
				// const firstNode = state.nodes.find((n) => n.topNode == true);
				// openModal("NewNode", {
				// 	projectId: parseInt(state.projectId),
				// 	isShowOnlyRootIssueType: !isHasRootNode.value,
				// 	firstNode: isHasRootNode.value == false ? firstNode : null,
				// });

				AP.jira.openCreateIssueDialog(async function(issues){
					const issue = issues?.[0]
					if (!issue) return;
					console.log('issue=', issue)
					let response = await store.dispatch('wbs/addTicketToJira', { 
						projectId: state.projectId, 
						jiraProjectId: issue.fields.project.id, 
						jiraIssueTypeId: issue.fields.issuetype.id, 
						jiraIssueStatusId: issue.fields.status.id, 
						jiraPriorityId: issue.fields.priority.id,
						assigneeId: issue.fields?.assignee?.accountId,
						topNode: true,
						summary: issue.fields.summary,
						codeOfAccounts: '0',
					})
					response = response.data;
					console.log('response=', response);

					if (response.topNodeId) {
						const firstNode = state.nodes.length
							? state.nodes.find((n) => n.topNode == true)
							: null;
							if (firstNode) {
								firstNode.topNode = false;
								firstNode.parentId = response.id;
								firstNode.visible = true;
								firstNode.codeOfAccounts = '1';
								recalcCodeOfAccounts(firstNode.id);
							}
					}

					state.nodes.push(response);
					if (node) {
						node.childrenCollapsed = false;
					}
					updateTree();
					setTimeout(() => {
						const target = document.getElementById(response.id);
						if (target) target.scrollIntoView({ block: "end" });
					}, 100);
					
				});						
			}

			const unsubscribe = storeModal.subscribe(async (data) => {
				if (!data.type.startsWith("modal")) return;
				if (data.type === "modal/setData") {
					if (data.payload.topNodeId) {
						const firstNode = state.nodes.length
							? state.nodes.find((n) => n.topNode == true)
							: null;
						if (firstNode) {
							firstNode.topNode = false;
							firstNode.parentId = data.payload.id;
							firstNode.visible = true;
							firstNode.codeOfAccounts = '1';
							recalcCodeOfAccounts(firstNode.id);
						}
					}

					state.nodes.push(data.payload);
					if (node) {
						node.childrenCollapsed = false;
					}
					updateTree();
					setTimeout(() => {
						const target = document.getElementById(data.payload.id);
						if (target) target.scrollIntoView({ block: "end" });
					}, 100);
				}
				unsubscribe();
			});
		};

		const editNode = async (id) => {
			let node = state.nodes.find((n) => n.id == id);
			const parentNode = state.nodes.find((n) => n.id == node.parentId);

			if (node.jiraIssueKey && state.source == "projects" && !config.isLocal) {
				AP.jira.openIssueDialog(
					`${node.jiraIssueKey}`,
					async (jiraIssueKey) => {
						const response = await AP.request(
							`/rest/api/3/issue/${jiraIssueKey}`
						);
						const parsedResponse = JSON.parse(response.body);
						let stotyPointEstimationJira = 0;
						const fieldName = customFields.value.find(
							(f) => f.name == "Story Points"
						);
						if (fieldName) {
							stotyPointEstimationJira =
								parsedResponse.fields[fieldName.id] || 0;
						}
						let originalEstimateJira = parseInput(
							parsedResponse.fields?.timetracking?.originalEstimate
						);
						let originalEstimateNode = parseFloat(
							node?.originalEstimateInHours
						);
						originalEstimateJira = originalEstimateJira
							? originalEstimateJira.toFixed(2)
							: null;
						originalEstimateNode = originalEstimateNode
							? originalEstimateNode.toFixed(2)
							: null;

						let remainingEstimateJira = parseInput(
							parsedResponse.fields?.timetracking?.remainingEstimate
						);
						let remainingEstimateNode = parseFloat(
							node?.remainingEstimate
						);
						remainingEstimateJira = remainingEstimateJira
							? remainingEstimateJira.toFixed(2)
							: null;
						remainingEstimateNode = remainingEstimateNode
							? remainingEstimateNode.toFixed(2)
							: null;

						let timeSpentNode = parseFloat(node?.timeSpent) ? parseFloat(node.timeSpent) : null,
							timeSpentJira = parsedResponse.fields?.timetracking?.timeSpentSeconds,
							remainingEstimate = parsedResponse.fields?.timetracking?.remainingEstimateSeconds,
							timeSpentJiraFormatted = parsedResponse.fields?.timetracking?.timeSpent,
							remainingEstimateFormatted = parsedResponse.fields?.timetracking?.remainingEstimate;

						if (
							parsedResponse.fields.summary != node.summary ||
							parsedResponse.fields.status.name != node.jiraIssueStatus.name ||
							parsedResponse.fields.issuetype.name != node.jiraIssueType.name ||
							parsedResponse.fields.assignee?.displayName !=
							node.assignee?.displayName ||
							originalEstimateJira != originalEstimateNode ||
							stotyPointEstimationJira != node.originalEstimateInStoryPoints ||
							timeSpentNode != timeSpentJira ||
							remainingEstimateJira != remainingEstimateNode
						) {
							let assignee = null;
							if (parsedResponse.fields.assignee) {
								assignee = {
									...parsedResponse.fields.assignee,
									...{
										iconUri: parsedResponse.fields.assignee.avatarUrls["24x24"],
									},
								};
							}
							const nodeData = {
								projectId: state.projectId,
								nodeId: node.id,
								node: {
									...node,
									...{
										descriptionObject: parsedResponse.fields.description,
										assignee: assignee,
										originalEstimateFormatted: parsedResponse.fields
											.timetracking
											? parsedResponse.fields.timetracking.originalEstimate
											: "",
										assigneeId: parsedResponse.fields.assignee
											? parsedResponse.fields.assignee.accountId
											: null,
										originalEstimateInHours: parseInput(
											parsedResponse.fields?.timetracking?.originalEstimate
										),
										jiraIssueStatus: parsedResponse.fields.status,
										jiraIssueStatusId: parseInt(
											parsedResponse.fields.status.id
										),
										jiraProjectId: parseInt(parsedResponse.fields.project.id),
										jiraIssueType: parsedResponse.fields.issuetype,
										jiraIssueUri: parsedResponse.fields.issuetype?.iconUrl,
										jiraIssueTypeId: parseInt(
											parsedResponse.fields.issuetype.id
										),
										projectId: state.projectId,
										jiraPriorityId: parsedResponse.fields.priority.id,
										originalEstimateInStoryPoints: stotyPointEstimationJira,
										summary: parsedResponse.fields.summary,
										timeSpent: timeSpentJira,
										timeSpentFormatted: timeSpentJiraFormatted,
										remainingEstimateInHours: parseInput(
											parsedResponse.fields?.timetracking?.remainingEstimate
										),
										remainingEstimate: remainingEstimate,
										remainingEstimateFormatted: remainingEstimateFormatted,
										childrenDisplayOption: "wbs",
									},
								},
							};

							Object.keys(node).forEach((k) => {
								if (nodeData.node[k]) {
									node[k] = nodeData.node[k];
								}
							});

							makeRows();
							try {								
								await store.dispatch("wbs/editNodeWithJiraIds", nodeData);
							} catch (error) {
								const errors = errorToList(error);
								toaster.error(errors?.[0]?.text || `Error edit issue`,{position: "top-right",});
							}
						}
					}
				);
				return;
			}

			openModal("NewNode", {
				title: node.jiraIssueType.name + "-" + node.id,
				projectId: parseInt(state.projectId),
				jiraIssueTypeId: parseInt(node.jiraIssueType.jiraIssueTypeId),
				jiraProject: node.jiraProject,
				parentId: null,
				currentNode: node,
				parentNode: parentNode,
			});

			const unsubscribe = storeModal.subscribe(async (data) => {
				if (!data.type.startsWith("modal")) return;
				if (data.type === "modal/setData") {
					Object.keys(node).forEach((k) => {
						node[k] = data.payload.node[k];
					});
					node.isUpdate = true;
					makeRows();
				}
				unsubscribe();
			});
		};

		const openShareModal = () => {
			openModal("ShareWBS", {
				source: state.source,
				id: parseInt(state.projectId),
				viewStyle: "displayAsList",
			});
		};

		const openNewNodeModal = () => {
			addNewNode();
		};

		const launchImport = async () => {
			try {
				notUpdateFromDelta.value = true;
				store.commit("wbs/SET_EXISTS_IMPORT", true);
				store.commit("wbs/SET_IMPORT_RUN", true);
				await store.dispatch("wbs/launchImport", {
					projectId: state.projectId,
				});
			} catch (error) {
				store.commit("wbs/SET_IMPORT_RUN", false);
				notUpdateFromDelta.value = false;
				backendErrors.value = errorToList(error);
				setTimeout(() => {
					backendErrors.value = [];
				}, 3000);
			}
		};

		const openEstimationPopup = (id, tabIndex = 0, focusElementName) => {

			if (tabIndex == 1 && !state.source.includes("project")) return;

			const node = state.nodes.find((n) => n.id == id);
			if (state.source.includes("project")) {
				AP.request(
					`/rest/api/3/issue/${node.jiraIssueKey}`
				).then((response) => {
					const parsedResponse = JSON.parse(response.body);
					store.commit('wbs/SET_CURRENT_JIRA_TICKET', parsedResponse);
				});
			}

			openModal("Estimation", {
				node,
				tabIndex,
				estimationType:
					wbsState.value.estimationType ||
					store.getters["profile/estimationType"],
				focusElementName,
			});

			const unsubscribe = storeModal.subscribe(async (data) => {
				if (!data.type.startsWith("modal")) return;
				if (data.type === "modal/setData") {
					if (tabIndex == 0) {
						node.originalEstimateInHours = data.payload?.estimate
							? parseInput(data.payload.estimate)
							: "";
						node.originalEstimateFormatted = data.payload?.estimate;
						node.remainingEstimate = data.payload?.remaining;
						node.timeSpent = data.payload?.spent;
						node.pertBestCase = data.payload?.pertBestCase;
						node.pertDeviation = data.payload?.pertDeviation;
						node.pertProbableCase = data.payload?.pertProbableCase;
						node.pertWorstCase = data.payload?.pertWorstCase;
						node.originalEstimateInStoryPoints =
							data.payload?.storyPoint;
						makeRows();
						await store.dispatch("wbs/editNode", {
							projectId: state.projectId,
							nodeId: node.id,
							node,
						});
					}
					if (tabIndex == 1) {
						node.timeSpentFormatted = data.payload?.loggedDecimal;
						node.remainingEstimateFormatted = data.payload?.remainingDecimal;
						makeRows();
						await store.dispatch("wbs/addWorkLog", {
							projectId: state.projectId,
							nodeId: node.id,
							timeSpent: data.payload?.spent,
							remainingEstimate: data.payload?.remaining,
							dateStarted: data.payload?.date,
							comment: data.payload?.description,
						});
					}
				}

				unsubscribe();
			});
		};

		const getWbsState = async (projectId) => {
			if (Object.keys(store.getters["wbs/wbsState"]).length) return;

			backendErrors.value = [];
			store.dispatch("wbs/getWbsState", projectId).then(() => {
				if (isImportRun.value == false)
					store.commit("wbs/SET_IMPORT_RUN", wbsState.value.activeImport);
			}).catch(error => {
				backendErrors.value = errorToList(error);
				setTimeout(() => {
					backendErrors.value = [];
				}, 3000);
			})
		};

		const debouncesetWbsState = debounce(async (data) => {
			try {
				await store.dispatch("wbs/setWbsState", {
					...wbsState.value,
					...data,
					...{
						projectId: state.projectId,
						zoomLevel: zoomInd.value,
						locationX: state.centerX,
						locationY: state.centerY,
						displayTreeOption: wbsState.value?.displayTreeOption,
					},
				});
			} catch (error) {
				const list = errorToList(error);
				list.forEach((item) => {
					backendErrors.value.push(item);
				});

				setTimeout(() => {
					backendErrors.value = backendErrors.value.filter(
						(e) => !list.some((l) => l.name == e.name)
					);
				}, 3000);
			}
		}, 500);

		const setWbsState = async (data = {}) => {
			await debouncesetWbsState(data);
		};

		const launchSync = async () => {
			try {
				backendErrors.value = [];
				await store.dispatch("wbs/launchSync", wbsState.value.id);
			} catch (error) {
				backendErrors.value = errorToList(error);
				setTimeout(() => {
					backendErrors.value = [];
				}, 3000);
			}
		};

		const createProject = async () => {
			openModal("NewProject", {
				template: wbsState.value
			});
			const unsubscribe = storeModal.subscribe(async (data) => {
				if (!data.type.startsWith("modal")) return;
				if (data.type === "modal/setData") {
					unsubscribe();
					if (data.payload.templateId) {
						router.push({
							name: "wbs-project",
							params: { id: data.payload.id },
						});
					}
				}
				if (data.type === "modal/closeModal") {
					unsubscribe();
				}
			});
		};

		const openEditProject = async () => {
			openModal("NewProject", {
				template: wbsState.value?.createOption == 'template' ? wbsState.value.template : null,
				project: {
					...wbsState.value,
					...{ lastImportErrorMessage: isImportError.value },
				},
				countNodes: state.nodes.length,
				title: wbsState.value.name,
			});
			const unsubscribe = storeModal.subscribe(async (data) => {
				if (!data.type.startsWith("modal")) return;
				if (data.type === "modal/setData") {
					store.commit("wbs/RESET_CACHED_NODES");
					store.commit("wbs/RESET_WBS_STATE");
					loadInstance(router.currentRoute.value.params.id);
					getNodes(true);
					//runAutoImport();
					unsubscribe();
				}
				if (data.type === "modal/closeModal") {
					unsubscribe();
				}
			});
		};

		const openEditTemplate = async () => {
			openModal("NewTemplate", {
				template: wbsState.value,
				title: wbsState.value.name,
			});
			const unsubscribe = storeModal.subscribe(async (data) => {
				if (!data.type.startsWith("modal")) return;
				if (data.type === "modal/setData") {
					store.commit("wbs/RESET_CACHED_NODES");
					store.commit("wbs/RESET_WBS_STATE");
					loadInstance(router.currentRoute.value.params.id);
					getNodes();
					unsubscribe();
				}
				if (data.type === "modal/closeModal") {
					unsubscribe();
				}
			});
		};

		const onPanelAction = (action) => {
			const { saveCookie } = useCookies();
			switch (action) {
				case "launchImport":
					launchImport();
					break;
				case "openShareModal":
					openShareModal();
					break;
				case "openFilter":
					openFilter();
					break;
				case "openNewNodeModal":
					openNewNodeModal();
					break;
				case "launch-synch":
					launchSync();
					break;
				case "createProject":
					createProject();
					break;
				case "editProject":
					openEditProject();
					break;
				case "editTemplate":
					openEditTemplate();
					break;
				case "displayAsTree":
					router.push({
						name: state.source.includes("project")
							? "wbs-project"
							: "wbs-template",
						params: {
							id:
								state.projectId ||
								parseInt(router.currentRoute.value.params.id),
						},
					});
					saveCookie(
						`view-style-${state.projectId}`,
						"displayAsTree"
					);
					break;
				case "displayTreeAsHorizontal":
					router.push({
						name: state.source.includes("project")
							? "wbs-project"
							: "wbs-template",
						params: {
							id:
								state.projectId ||
								parseInt(router.currentRoute.value.params.id),
							treeStyle: "horizontal",
						},
					});
					saveCookie(
						`view-style-${state.projectId}`,
						"displayAsTree"
					);
					break;
				case "displayTreeAsVertical":
					router.push({
						name: state.source.includes("project")
							? "wbs-project"
							: "wbs-template",
						params: {
							id:
								state.projectId ||
								parseInt(router.currentRoute.value.params.id),
							treeStyle: "vertical",
						},
					});

					saveCookie(
						`view-style-${state.projectId}`,
						"displayAsTree"
					);
					break;
				case "resetFilter":
					filterComponent.value.resetFilter();
					break;
				case "displaySchedule":
					router.push({
						name: "wbs-schedule-management",
						params: {
							id:
								state.projectId ||
								parseInt(router.currentRoute.value.params.id),
						},
					});
					saveCookie(
						`view-style-${state.projectId}`,
						"displaySchedule"
					);
					break;
			}
		};

		const socketsOff = () => {
			if (window.isTestRun) {
				return;
			}
			const tenantId = store.getters["profile/tenantId"];
			const projectId = state.projectId;
			Echo.leave("addon." + tenantId + "." + projectId);
			Echo.leave("addon." + tenantId + ".template." + projectId);
			Echo.leave("addon." + tenantId + ".project." + projectId);
			Echo.leave(
				"addon." + tenantId + ".template." + projectId + ".participants"
			);
			Echo.leave(
				"addon." + tenantId + ".project." + projectId + ".participants"
			);
		};

		const socketsOn = (tenantId, projectId) => {
			if (window.isTestRun) {
				return;
			}
			Echo.private("addon." + tenantId + "." + projectId)
				.listen("ImportStarted", (response) => {
					notifications.value = [];
					store.commit("wbs/SET_IMPORT_RUN", true);
				})
				.listen("ImportCompleted", async (response) => {
					// store.commit("wbs/SET_IMPORT_RUN", false);
					store.commit("wbs/RESET_WBS_STATE");
					loadInstance(projectId);
				});

			const channelKey = router.currentRoute.value?.name?.includes(
				"wbs-project",
				"wbs-project-list-view"
			)
				? "project"
				: "template";

			Echo.private(
				"addon." + tenantId + "." + channelKey + "." + projectId
			).listen("FetchLatestData", async (response) => {
				updateNodes(channelKey);
			});

			if (channelKey === "project") {
				Echo.private(
					"addon." +
					tenantId +
					"." +
					channelKey +
					"." +
					projectId +
					".participants"
				).listen("FetchProjectParticipants", (response) => {
					store.dispatch(
						"wbs/getProjectParticipants",
						state.projectId
					);
				});
			}
		};

		const updateNodesProperties = () => {
			updatedProperties.value = tempUpdateProperties;
			updatesNodes.forEach(item => {
				const node = state.nodes.find(i => i.id == item.id);
				if (node) {
					node[item.keyName] = item.value;
				}
			});

			updateTree();

			setTimeout(() => {
				updatedProperties.value = [];
				updatesNodes = [];
				state.nodes.forEach(node => {
					node.isUpdate = false;
					node.isNew = false;
				})
				updateTree();
			}, 2000);
		}

		let updatedProperties = ref([]),
			isUserInteraction = false,
			tempUpdateProperties = [],
			{ compareNodes } = useWbsDocument(),
			updatesNodes = [],
			debounceUpdateNodesProperties = debounce(() => {
				updateNodesProperties();
				isUserInteraction = false;
			}, 10000);

		const updateNodes = async (channelKey) => {
			if (isImportRun.value && state.nodes.length == 0) {
				state.isLoading = true;
			}
			tempUpdateProperties = [];
			const fieldsMap = {
				jiraIssueStatus: "status",
			};

			let lastUpdateDate = store.getters["wbs/nodesMeta"]?.lastUpdateDate;
			if (!lastUpdateDate)
				lastUpdateDate = moment(new Date()).format("Y-MM-d H:m:s");
			const updatingList = await store.dispatch(
				"wbs/latestNodesChanges",
				{
					channelKey: channelKey,
					modelId: state.projectId,
					lastUpdateDate: lastUpdateDate,
				}
			);

			if (!updatingList.data) {
				state.isLoading = false;
				store.commit("wbs/SET_IMPORT_RUN", false);
				return;
			}

			store.commit('wbs/SET_NODES_META', updatingList.meta)

			for (let i = 0; i < updatingList.data.length; i++) {
				const updateNode = updatingList.data[i];
				const targetNode = state.nodes.find(
					(n) => n.id == updateNode.id
				);
				if (targetNode) {
					// delete node from document
					if (updateNode?.isDeleted == true) {
						state.nodes = state.nodes.filter(
							(n) => n.id !== updateNode.id
						);
						state.nodes.forEach((n) => {
							if (n.parentId == updateNode.id) n.parentId = null;
						});
					} else {
						// update properties
						const detectedChanges = compareNodes(
							targetNode,
							updateNode
						);

						targetNode.x = 0;
						targetNode.y = 0;
						targetNode.visible = true;

						Object.keys(detectedChanges).forEach((k) => {
							tempUpdateProperties.push({
								id: targetNode.id,
								name: fieldsMap[k] ? fieldsMap[k] : k,
							});
						});

						Object.keys(updateNode).forEach((k) => {
							if (!['listChildrenCollapsed', 'wbsChildrenCollapsed'].includes(k)) {
								// targetNode[k] = updateNode[k];
								updatesNodes.push({ id: updateNode.id, keyName: k, value: updateNode[k] })
							}
						});

						if (updateNode.syncError) {
							store.commit("wbs/ADD_ERROR_NODE", {
								id: updateNode.id,
								createdNode: updateNode,
								action: "create",
								error: updateNode.syncErrorMessage,
							});
						} else {
							store.commit(
								"wbs/DELETE_ERROR_NODE",
								updateNode.id
							);
						}
					}
				} else if (!updateNode?.isDeleted) {
					// add new node
					updateNode.x = 0;
					updateNode.y = 0;
					updateNode.visible = true;
					updateNode.isNew = true;
					state.nodes.push(updateNode);
					if (updateNode.syncError) {
						store.commit("wbs/ADD_ERROR_NODE", {
							id: updateNode.id,
							createdNode: updateNode,
							action: "create",
							error: updateNode.syncErrorMessage,
						});
					} else {
						store.commit("wbs/DELETE_ERROR_NODE", updateNode.id);
					}
				}
			}

			if (isUserInteraction)
				debounceUpdateNodesProperties()
			else
				updateNodesProperties()

			setTimeout(() => {
				state.isLoading = false;
				if (isImportRun.value) {
					store.commit("wbs/SET_IMPORT_RUN", false);
					notifications.value.push({
						name: "Import",
						text: "Import completed successfully",
						type: "success",
					});
					setTimeout(() => {
						notifications.value = notifications.value.filter(
							(e) => e.name !== "Import"
						);
					}, 3000);
				}
			}, 100);
		};

		const applyState = () => {
			const config = wbsState.value;
			isOrphanedCollapsed.value = config.orphanedChildrenCollapsed;
		};

		// project ready
		watch(
			() => wbsState.value,
			() => {
				applyState();
			}
		);

		watch(
			() => state.nodes,
			() => {
				subtaskNodes.value = useSubtaskNodes(
					store,
					state.nodes
				).subtaskNodes.value;
			},
			{
				deep: true,
			}
		);

		// set blocked nodes
		watch(
			() => subtaskNodes.value,
			(current) => {
				if (!current || !current.length) return;
				current.forEach((blocked) => {
					state.nodes.forEach((n) => {
						if (n.id == blocked) {
							n.subtask = true;
						}
					});
				});
			}
		);

		const getStatusColorText = useStatusColor().getStatusColorText;

		const makeRows = () => {
			let result = state.nodes
				.filter((n) => filteredNodes.value.some((id) => id == n.id))
				.map((node) => {
					let childrenCollapsed = node.listChildrenCollapsed;
					return {
						id: node.id,
						codeOfAccounts: node.codeOfAccounts,
						parentId: node.parentId,
						summary: node.summary,
						parentKey: "",
						jiraIssueKey: node.jiraIssueKey,
						jiraIssueUrl: node.jiraIssueUrl,
						jiraIssueType: node.jiraIssueType.name,
						subtask: node.jiraIssueType?.subtask,
						isSubtask: node.jiraIssueType?.subtask,
						jiraIssueUri: node.jiraIssueType?.iconUri || node.jiraIssueType?.iconUrl,
						assignee: node.assignee?.displayName,
						assigneeUri: node.assignee?.iconUri,
						status: node.jiraIssueStatus?.name,
						isUpdate: node.isUpdate,
						isNew: node.isNew,
						syncError: node.syncError,
						syncErrorMessage: node.syncErrorMessage,
						colorNode: node.colorNode,
						color: node.colorNode?.split(":")[0],
						aggregateProgress: node.aggregateProgress,
						timeSpentFormatted: node.timeSpentFormatted,
						remainingEstimateFormatted:
							node.remainingEstimateFormatted,
						originalEstimateFormatted:
							node.originalEstimateFormatted,
						originalEstimateInStoryPoints:
							node.originalEstimateInStoryPoints,
						progress: useCalcProgress(
							node.originalEstimateFormatted,
							node.timeSpentFormatted,
							node.remainingEstimateFormatted
						),
						childrenDisplayOption: node.childrenDisplayOption,
						childrenCount: node?.children?.length,
						childrenCollapsed: childrenCollapsed,
						displayChildren:
							childrenCollapsed == true ? false : true,
						statusColor: node?.jiraIssueStatus?.categoryColor,
						statusColorText: getStatusColorText(
							node?.jiraIssueStatus?.categoryColor
						),
						topNode: node.topNode,
						topNodeId: node.topNodeId,
						isOrphaned: node.isOrphaned,
						visible: true,
					};
				})
			result = result.map(node => {
				node.isOrphaned = false;
				const sourceNode = state.nodes.find(n => n.id == node.id);
				sourceNode.isOrphaned = false;
				sourceNode.isSubtask = node.isSubtask;
				if (!node.parentId && !node.topNode) {
					node.codeOfAccounts = '---';
					node.isOrphaned = true;
					const sourceNode = state.nodes.find(n => n.id == node.id);
					sourceNode.isOrphaned = node.isOrphaned;
					sourceNode.codeOfAccounts = node.codeOfAccounts;
					const children = getChildren(node.id, true, result);
					children.forEach(c => {
						const node = result.find(r => r.id == c.id);
						node.codeOfAccounts = '---';
						node.isOrphaned = true;
						const sourceNode = state.nodes.find(n => n.id == node.id);
						sourceNode.isOrphaned = node.isOrphaned;
						sourceNode.codeOfAccounts = node.codeOfAccounts;
					})
				}
				return node;
			})
			result.sort((a, b) => Number(a.isOrphaned) - Number(b.isOrphaned));
			result.sort(function (a, b) {
				let codeAccountA = a.codeOfAccounts?.toString();
				let codeAccountB = b.codeOfAccounts?.toString();

				if (codeAccountA && codeAccountB) {
					if (codeAccountA.split('.').length) {
						codeAccountA = codeAccountA.split(".").pop()
					} else {
						if (a.isOrphaned) {
							codeAccountA = "0"
						}
					}

					if (codeAccountB.split('.').length) {
						codeAccountB = codeAccountB.split(".").pop()
					} else {
						if (b.isOrphaned) {
							codeAccountB = "0"
						}
					}

					return parseInt(codeAccountA) - parseInt(codeAccountB);
				}
			})
			state.rows = result;
		};

		const foundCount = ref(0);
		const invisibleFilteredNodes = computed(() => {
			if (isFiltered.value === true && foundCount.value == 0) {
				return state.nodes.map((n) => n.id);
			}
			return (
				(isFiltered.value &&
					state.nodes.reduce((acc, node) => {
						acc = acc || [];
						if (
							!filteredNodes.value.includes(node.id) &&
							childrenCount.value.get(node.id) == 0
						)
							acc.push(node.id);
						return acc;
					}, [])) ||
				[]
			);
		});

		const filterNodes = (nodes) => {
			filteredNodes.value = [];
			foundCount.value = 0;
			const filters = wbsState.value.filters || {
				projectId: [],
				issueTypeId: [],
				statusId: [],
				assignee: [],
				categoryId: [],
			};

			if (!filters) return [];
			try {
				nodes.forEach((n) => {
					if (
						(filters.statusId.includes(n.jiraIssueStatus?.name) ||
							!filters.statusId.length) &&
						(filters.issueTypeId.includes(n.jiraIssueType?.id) ||
							!filters.issueTypeId.length) &&
						(filters.assignee.includes(n.assignee?.id) ||
							!filters.assignee.length) &&
						(filters.projectId.includes(n.jiraProject?.id) ||
							!filters.projectId.length) &&
						(filters.categoryId.includes(
							n.jiraIssueStatus?.statusCategory?.id
						) ||
							!filters.categoryId.length)
					) {
						filteredNodes.value.push(n.id);
						foundCount.value = filteredNodes.value.length;
					}
				});

				if (filteredNodes.value.length == 0) {
					filteredNodes.value = nodes.map((n) => n);

					foundCount.value = 0;
				}
			} catch {
				return nodes;
			}
		};

		const makeTree = () => {
			state.nodes = store.getters["wbs/nodes"];
			filterNodes(store.getters["wbs/nodes"]);
			updateTree();
		};

		const getNodes = async (hideLoader = false) => {
			if (!store.getters["wbs/nodes"].length) {
				const response = await store.dispatch("wbs/getNodes", {
					projectId: state.projectId,
					hideLoader,
				});
				makeTree();
				return response;
			} else {
				store.commit(
					"wbs/UPDATE_NODES",
					store.getters["wbs/cachedNodes"][state.projectId]
				);
			}

			makeTree();
		};

		const getHierarchy = async () => {
			if (!store.getters["settings/hierarchy"].length)
				await store.dispatch("settings/getNodesHierarchy");
		};

		const getIssueTypes = async () => {
			if (!store.getters["settings/issueTypes"].length)
				await store.dispatch("settings/getIssueTypes");
		};

		const loadInstance = async (id) => {
			state.projectId = parseInt(id);
			store.commit("wbs/CLEAR_CACHED_STATUS");
			await getWbsState(state.projectId);
			applyState();
			getHierarchy();
			getIssueTypes();
		};

		const setSessionState = async (value) => {
			if (state.source !== "projects") return;
			if (value == true) {
				if (!store.getters["wbs/projectParticipants"].length)
					await store.dispatch(
						"wbs/addProjectParticipants",
						state.projectId
					);
			} else {
				if (
					store.getters["wbs/projectParticipants"].length &&
					state.projectId
				)
					await store.dispatch(
						"wbs/deleteProjectParticipant",
						state.projectId
					);
			}
		};

		const closeNodeErrorPopup = () => {
			nodeErrorPosition.value = {
				display: "none",
			};
		};

		const dismissNodeError = async () => {
			closeNodeErrorPopup();
			const node = state.nodes.find((n) => n.id == currentNode.value.id);
			try {
				await store.dispatch('wbs/dismissWarning', { projectId: state.projectId, nodeId: node.id })	
			} catch (error) {
				toaster.error(`Request error`,{position: "top-right",});
			}			
		};

		const showNodeErrorPopup = ({ event, nodeError }) => {
			const node = errorNodes.value.find((n) => n.id == nodeError);
			currentNodeErrors.value = node;
			nodeErrorPosition.value = calcElementPosition({
				e: event,
				popupWidth: 300,
				container: wbsContainer.value,
			});
		};

		const retryNodeAction = async () => {
			if (!currentNode.value) return;
			closeNodeErrorPopup();

			const node = state.nodes.find((n) => n.id == currentNode.value.id);
			node.isUpdate = true;
			const error = errorNodes.value.find((n) => n.id == node.id);
			await store.dispatch("wbs/editNode", {
				projectId: state.projectId,
				nodeId: node.isTemporary ? error.createdNode.id : node.id,
				node: node.isTemporary ? error.createdNode : node,
			});
			node.isUpdate = false;
		};

		const addMouseUpEventListener = () => {
			allowToDrop.value = {};
			removeBlockedRow();
		};

		const runAutoImport = () => {
			if (isImportRun.value || isExistsImport.value) {
				return;
			} else {
				if (wbsState.value.nodesCount !== 0 || wbsState.value.jiraJql)
					launchImport();
			}
		};

		const setCalcHeight = () => {
			state.calcHeight =
				wbsContainer.value && wbsContainer.value.clientHeight - 240;
		};

		let unsubscribeAction;
		onMounted(async () => {
			setCalcHeight();
			const projectId = parseInt(router.currentRoute.value?.params?.id);
			const { readCookie } = useCookies();
			let source;
			switch (router.currentRoute.value.name) {
				case "wbs-project":
					store.commit("wbs/SET_WBS_SOURCE", "projects");
					source = "projects";
					break;
				case "wbs-project-list-view":
					store.commit("wbs/SET_WBS_SOURCE", "projects");
					source = "projects";
					break;
				default:
					store.commit("wbs/SET_WBS_SOURCE", "templates");
					source = "templates";
					break;
			}

			unsubscribeAction = store.subscribe((action, state) => {
				if (action.type == "wbs/UPDATE_LAST_UPDATE_MILLESECONDS") {
					isUserInteraction = true;
				}

				if (["wbs/SET_NODES", "wbs/UPDATE_NODE"].includes(action.type)) {
					makeTree();
				}

				if (action.type == "wbs/UPDATE_NODE") {
					if (action.payload.createdNode) {
						const createdNode = action.payload.createdNode;
						if (source == 'templates') {
							toaster.success(
								`Issue has been successfuly created`,
								{
									position: "top-right",
								}
							);
						} else {
							toaster.success(
								`Issue ${createdNode?.jiraIssueKey} - ${createdNode.summary} has been successfuly created`,
								{
									position: "top-right",
								}
							);
						}
					}
				}
			});

			// const viewStyle = await readCookie(`view-style-${projectId}`);
			// if (viewStyle == "displayAsTree") {
			// 	onPanelAction("displayAsTree");
			// 	return;
			// }

			await loadInstance(projectId);			
			await getNodes();
			socketsOn(store.getters["profile/tenantId"], state.projectId);
			document.body.style.overflowX = "hidden";
			document.body.style.overflowY = "hidden";

			setSessionState(true);
			window.addEventListener("beforeunload", () => {
				socketsOff();
				setSessionState(false);
			});
			document.addEventListener("mouseup", addMouseUpEventListener);
			window.addEventListener("resize", setCalcHeight);
			// run auto-import
			// if (source == "projects") {
			//   runAutoImport();
			// }
		});

		onUnmounted(() => {
			if (!window?.isTestRun)
				window?.source?.cancel("Operation canceled by the user.");
			document.removeEventListener("mouseup", addMouseUpEventListener);
			window.removeEventListener("resize", setCalcHeight);
			store.commit("wbs/SET_CACHED_NODES", {
				projectId: state.projectId,
				nodes: state.nodes,
			});

			const exists = [
				"wbs-project",
				"wbs-template",
				"wbs-template-list-view",
				"wbs-project-list-view",
				"wbs-schedule-management",
			].some((i) => i == router?.currentRoute?.value?.name);

			if (router?.currentRoute?.value.name && !exists) {
				store.commit("wbs/RESET_CACHED_NODES");
				store.commit("wbs/RESET_WBS_STATE");
				store.commit("wbs/SET_EXISTS_IMPORT", false);
				store.commit("wbs/SET_IMPORT_RUN", false);
				setSessionState(false);
			} else {
				if (router.currentRoute.value.params.id != state.projectId) {
					store.commit("wbs/RESET_CACHED_NODES");
					store.commit("wbs/RESET_WBS_STATE");
					setSessionState(false);
				}
			}

			document.body.style.overflow = "initial";
			unsubscribeAction();
			socketsOff();

			const channelKey = router.currentRoute.value?.name?.includes(
				"wbs-project",
				"wbs-project-list-view"
			)
				? "project"
				: "template";
			if (channelKey === "project") {
				window.removeEventListener("beforeunload", setSessionState);
			}
		});

		/**
		 * This is for orphaned nodes (drag & drop)
		 */
		let sourceDragNodeId = null;
		let targetDragNodeId = null;
		let dropDistantion = {};
		const selectedRows = ref([]);
		const allowToDrop = ref({});
		const onDragstart = (id) => {
			dropDistantion = {};
			sourceDragNodeId = id;
		};

		const onDragEnd = (e) => {
			dropDistantion = e;
		};
		const onDragEnter = (e) => {
			let nodeTo = state.nodes.find((n) => n.id == parseInt(e));
			let nodeFrom = state.nodes.find(
				(n) => n.id == parseInt(sourceDragNodeId)
			);
			if (nodeTo) targetDragNodeId = nodeTo.id;
			let targetNode = state.nodes.find((n) => n.id == targetDragNodeId);
			targetMoveNode.value = targetNode;

			let obj = {};
			allowToDrop.value = {};
			if (!nodeFrom) return;
			setCurrentNode(nodeFrom.id);
			if (!allowedDragNodes.value.some((n) => n == nodeFrom.id)) {
				obj[nodeTo.id] = { value: false };
				allowToDrop.value = obj;
			} else {
				obj[nodeTo.id] = { value: true };
				allowToDrop.value = obj;
			}
		};

		const onDragleave = (e) => { };

		const onDrop = (e) => {
			state.isLoading = true;
			function moveElement(array, initialIndex, finalIndex) {
				array.splice(
					finalIndex,
					0,
					array.splice(initialIndex, 1)[0]
				);
				return array;
			}
			setTimeout(() => {
				state.isLoading = false;
				for (const iterator in allowToDrop.value) {
					if (allowToDrop.value[iterator].value === false) {
						allowToDrop.value = {};
						return;
					}
				}

				let node = state.nodes.find((n) => n.id == sourceDragNodeId);

				if (!node) return;
				let targetNode = state.nodes.find(
					(n) => n.id == targetDragNodeId
				);
				if (!targetNode) return;
				// if accept new parent
				if (targetNode?.id == allowNewParentNode.value) {

					node.topNode = true;
					node.codeOfAccounts = '0';
					node.isOrphaned = false;

					targetNode.topNode = false;
					targetNode.codeOfAccounts = '--';
					if (targetNode.parentId && targetNode.parentId == node.id)
						targetNode.parentId = node.id;

					targetNode.topNodeId = node.id;
					makeRows()
					onChangeStructure({
						from: [node.id],
						to: targetNode.id,
						rootNode: targetNode,
					});
					recalcCodeOfAccounts(targetNode.parentId);
					recalcCodeOfAccounts(node.parentId);
				} else if (node.parentId == targetNode.parentId && !targetNode.topNode) {
					if (node.isOrphaned) return;
					let code1 = node.codeOfAccounts,
						code2 = targetNode.codeOfAccounts;
					if (
						node.codeOfAccounts.toString().split(".").length &&
						targetNode.codeOfAccounts.toString().split(".").length
					) {
						code1 = parseInt(node.codeOfAccounts.toString().split(".").pop());
						code2 = parseInt(
							targetNode.codeOfAccounts.toString().split(".").pop()
						);
					}

					if (
						dropDistantion?.orderDirection ==
						"order-direction-bottom"
					) {
						node.codeOfAccounts = targetNode.codeOfAccounts;
					} else if (code1 < code2) {
						node.codeOfAccounts = getPrevCodeOfAccount(
							targetNode.codeOfAccounts
						);
					} else {
						node.codeOfAccounts = targetNode.codeOfAccounts;
					}

					const fromIndex = state.nodes.findIndex(
						(r) => r.id == sourceDragNodeId
					),
						toIndex = state.nodes.findIndex(
							(r) => r.id == targetNode.id
						);
					if (
						dropDistantion?.orderDirection ==
						"order-direction-bottom"
					)
						moveElement(state.nodes, fromIndex, toIndex);
					else
						moveElement(
							state.nodes,
							fromIndex,
							fromIndex < toIndex ? toIndex - 1 : toIndex
						);
					changeOrderRequest(node);
					makeRows();
					recalcCodeOfAccounts(node.parentId);
				} else {
					// drag & drop
					node.parentId = targetNode.id;
					node.isOrphaned = false;
					node.isOrphanedChild = false;

					const children = getChildren(targetNode.id, false);
					if (children.length) {
						const fromIndex = state.nodes.findIndex(
							(r) => r.id == node.id
						),
							toIndex = state.nodes.findIndex(
								(r) => r.id == children[children.length - 1].id
							);
						moveElement(state.nodes, fromIndex, toIndex);
					}

					onChangeStructure({ from: [node.id], to: targetNode.id });
					recalcCodeOfAccounts(targetNode.parentId);
					recalcCodeOfAccounts(node.parentId);
				}
				allowToDrop.value = {};
			}, 100);
		};

		const changeOrderRequest = async (node) => {
			if (!node) return;

			await store.dispatch("wbs/updateCoa", {
				projectId: state.projectId,
				nodeId: node.id,
				node,
			});
		};

		/**
		 * Create new root node from orphaned to placeholder
		 */

		const dragStartPlaceHolder = (ev) => {
			ev.dataTransfer.setData("id", ev.target.id);
		};

		const dragEnterPlaceHolder = (e) => {
			isPlaceHolderHover.value = true;
		};

		const dragLeavePlaceHolder = (e) => {
			isPlaceHolderHover.value = false;
		};

		const dropPlaceHolder = (e) => {
			isPlaceHolderHover.value = false;
			const rootNode = state.nodes.find((n) => n.id == sourceDragNodeId);
			if (rootNode) {
				rootNode.topNode = true;
				rootNode.visible = true;
				rootNode.isOrphanedChild = false;
				rootNode.isOrphaned = false;
				const children = getChildren(rootNode.id);
				for (const child of children) {
					const childNode = state.nodes.find((n) => n.id == child.id);
					childNode.isOrphanedChild = false;
					childNode.isOrphaned = false;
				}
				makeRows();
				onChangeStructure({ from: null, to: null, rootNode: rootNode });
			}
		};

		const setExpand = (value) => {
			state.expandAll = null;
			nextTick(() => {
				state.expandAll = value;
				state.nodes.forEach((n) => {
					n.listChildrenCollapsed = state.expandAll ? false : true;
				});
				store.dispatch("wbs/setChildrenCollapsedAll", {
					projectId: state.projectId,
					viewOption: "list",
					childrenCollapsed: !value,
				});
			});
		};

		const addBlockedRow = (id) => {
			blockedRow.value = id;
		};

		const removeBlockedRow = () => {
			blockedRow.value = null;
		};

		return {
			state,
			...toRefs(state),
			openColorPicker,
			onShowChildren,
			cancelColorPicker,
			changeColorPicker,
			submitColorPicker,
			hideDropDowns,
			currentNode,
			setCurrentNode,
			isOpenPicker,
			openAssignDropDown,
			openStatusDropDown,
			onChangeStructure,
			nodeDelete,
			dropDownPosition,
			wbsContainer,
			onChangeDropDown,
			currentDropDown,
			onBeforeNodeDelete,
			confirmationPosition,
			closeConfirmation,
			addNewNode,
			editNode,
			openEstimationPopup,
			projectStatistic,
			onPanelAction,
			openFilter,
			isShowFilter,
			onCloseFilter,
			wbsState,
			setWbsState,
			allowedDragNodes,
			onDragHover,
			onCellClick,
			isHasRootNode,
			isHasOrphaned,
			errorNodes,
			nodeErrorPosition,
			showNodeErrorPopup,
			closeNodeErrorPopup,
			dismissNodeError,
			retryNodeAction,
			currentNodeErrors,
			openNewNodeModal,
			selectUser,
			notificationList,
			printInfo,
			onSubmitFilter,
			filteredNodes,
			invisibleFilteredNodes,
			filterComponent,
			isFiltered,
			childrenCount,
			foundCount,
			isImportError,
			rowStyle,
			renderRows,
			onDragEnter,
			onDrop,
			onDragleave,
			onDragstart,
			onDragEnd,
			selectedRows,
			allowToDrop,
			isShowPlaceHolder,
			isPlaceHolderHover,
			dragStartPlaceHolder,
			dragEnterPlaceHolder,
			dragLeavePlaceHolder,
			dropPlaceHolder,
			blockedRows,
			isOrphanedCollapsed,
			setExpand,
			isImportRun,
			rowElement,
			isJsLoading,
			addBlockedRow,
			removeBlockedRow,
			onChangeSummary,
			nodesCount,
			CellComponentList,
			updatedProperties,
		};
	},
};
</script>
<style>
.grid.full-screen {
	height: 100vh !important;
}
</style>
<style lang="scss" scoped>
.wbs-container {
	padding-top: 81px;
	height: 100vh;
}

.wbs-component {
	height: 100%;
}

.print-info {
	position: absolute;
	z-index: 1;
	top: 0;
	margin-top: 3px;
	margin-left: 15px;
}

.share {
	margin-left: 10px;
}

.info-panel {
	margin-top: 15px;
	background: rgba(255, 255, 255, 1) fff;
	border-radius: 8px;
	padding: 20px 30px;
	display: grid;
	align-items: center;
	grid-column-gap: 30px;
	grid-template-columns: 30px 1fr 15px;

	.icon {
		width: 31px;
		height: 31px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(42.62deg, #2684fe 0%, #2483ff 100%);
	}

	.text {
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: #a1a9ba;

		strong {
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			color: #363636;
		}

		p {
			margin: 0;
		}
	}

	.close {
		cursor: pointer;
	}
}

.list-view-component {
	padding: 15px;
	background: #fff;
	border-radius: 10px;
}

.filter-input {

	display: flex;
	gap: 15px;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 3px;

	.project-name {
		font-size: 21px;
		color: #0f8af9;
		flex-grow: 1;
	}

	.project-filter {
		img {
			width: 35px;
			height: 35px;
			object-fit: cover;
		}
	}
}

.cell-select {
	color: blue;
}

::v-deep(.actions.cell-value) {
	justify-content: flex-end;
}

.list-view-component {
	::v-deep(.columns) {
		grid-template-columns:
			75px 110px minmax(200px, 1fr) minmax(100px, 200px) 100px repeat(3, 75px) 89px 125px !important;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: #F0F0F0;
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: calc(100% - 1px);
			width: 100%;
			height: 1px;
			background: #F0F0F0;
		}

		.column {
			font-family: var(--font-family) !important;
			font-weight: 500;
			font-size: 12px;
			line-height: 15px;
			text-transform: none;
		}
	}

	::v-deep(.row-container) {
		.cell:nth-child(4) .cell-value {
			width: 100%;
		}

		grid-template-columns: 75px 110px minmax(200px, 1fr) minmax(100px, 200px) 100px repeat(3, 75px) 89px 125px !important;
	}

	::v-deep(.row-wrapper) {
		position: relative;

		.cell-value {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 500;
			font-size: 12px !important;
			line-height: 12px !important;
		}

		&:before {
			content: '';
			display: block;
			height: 100%;
			width: 15px;
			background: #fff;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 0;
		}
	}
}

.list-view-component {
	&.template {
		::v-deep(.columns) {
			grid-template-columns:
				75px 110px minmax(200px, 1fr) minmax(100px, 200px) 100px repeat(3, 75px) 137px 0 !important;
			color: #A0A5B0;
		}

		::v-deep(.row-container) {
			.cell:nth-child(3) .cell-value {
				width: 100%;
			}

			grid-template-columns: 75px 110px minmax(200px, 1fr) minmax(100px, 200px) 100px repeat(3, 75px) 137px 0 !important;
		}
	}
}

.actions-row {
	width: 136px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 5px;
	column-gap: 15px;

	::v-deep(svg) {
		position: relative;
		z-index: 1;
	}

	.action-btn {
		position: relative;

		&.disabled {
			pointer-events: none;
			opacity: 0.5;
		}

		&:hover {
			&:before {
				z-index: 0;
				content: "";
				position: absolute;
				background: #0f8af91a;
				transition: 0.5s;
				width: calc(100% + 10px);
				height: calc(100% + 10px);
				transform: translate(-5px, -5px);
			}
		}
	}
}

.cell .progress-bar {
	width: 130px;
	text-align: right;
	font-size: 12px;
}

.status {
	padding: 3px;
	border-radius: 4px;
	font-size: 12px;
}

.cell-bold {
	font-weight: 600;
}

.orphaned {
	position: relative;
}

.issue-list-container {
	display: flex;

	column-gap: 15px;
	justify-content: space-between;

	.issue-list {
		flex-grow: 1;
		min-width: 950px;
		position: relative;

		&.is-loading {

			//filter: blur(1.5px);
			&:before {
				content: "";
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				background: rgb(255 255 255 / 84%);
				z-index: 2;
			}
		}
	}

	.please-wait {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 99;
	}
}

.list-view-component {
	::v-deep(.cell) {
		word-break: break-word;
		max-height: 33px;
		overflow: hidden;
	}
}

.drag-node-placeholder {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px dashed #ccc;
	border-radius: 5px;
	transition: 0.3s;
	position: relative;
	z-index: 1;

	&.is-hover {
		border: 2px dashed #0f8af9;
	}

	svg {
		z-index: -1;
	}
}

.expand-collapse-all {
	display: flex;
	align-items: center;
	gap: 7px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #365674;
	cursor: pointer;
	position: relative;
	z-index: 1;
	top: 54px;
	left: -5px;
	border-top: 1px solid #F0F0F0;
	border-bottom: 1px solid #F0F0F0;
	height: 40px;
	margin-left: -15px;
	width: calc(100% + 30px);
	padding-left: 9px;
}

.nodes-count {
	flex-grow: 1;
	text-align: right;
	font-size: 16px;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	color: #A0A5B0;

	span {
		color: #000000;
	}
}

.search-control {
	display: flex;
	gap: 15px;
	align-items: center;
}

.highlight-property {
	background: rgb(255, 128, 0) !important;
}

.open-filter {
	width: 36px;
	height: 36px;
	display: flex;
	align-items: center;
	border: 1px solid #F2F2F2;
	border-radius: 4px;

	&.active {
		background: #0f8af9;
	}

	&.is-filtered {
		background: #2684fe;
		border-radius: 5px;

		position: relative;

		.badge {
			position: absolute;
			right: -6px;
			top: -3px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: #e55e5d;
			color: white;
			font-size: 12px;
		}
	}
}

.assignee-cell {
	display: flex;
	align-items: center;
	gap: 5px;
}

.add-root-node {
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	display: flex;
	gap: 5px;
	align-items: center;
	color: #136AFD;
	cursor: pointer;

	&[disabled="true"] {
		opacity: .5;
		pointer-events: none;
	}
}
</style>
