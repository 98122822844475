<template>
  <div class="session-container" :class="{ 'is-open': isOpen }">
    <div class="session" :class="{ 'is-open': isOpen }" @click="openClosePopup">
      <div class="session-item" :title="item.user.displayName" :key="i" v-for="(item, i) in shortList">
        <img :src="item.user.iconUri" />
      </div>
      <div class="session-item">
        <span v-if="activeUsers.length">{{ calcCount > 5 ? calcCount + "+" : "" }}</span>
      </div>
    </div>
    <div class="session-popup" v-click-outside="(e) => closePopup(e)">
      <div class="session-popup--header">
        {{ translate("wbs.currently_in_session") }}
      </div>
      <div class="session-popup--list">
        <ul>
          <li :key="i" v-for="(item, i) in activeUsers">
            <img :src="item.user.iconUri" /> {{ item.user.displayName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, onUnmounted, reactive, toRefs, watch } from "vue";
import { useStore } from "vuex";
import IconClose from "@/components/icons/IconClose.vue";
export default {
  components: {
    IconClose,
  },
  inject: ['translate'],
  setup() {
    const store = useStore(),
      state = reactive({
        shortList: computed(() => {
          if (store.getters["wbs/projectParticipants"]) {
            return store.getters["wbs/projectParticipants"].length > 5
              ? store.getters["wbs/projectParticipants"].slice(0, 5)
              : store.getters["wbs/projectParticipants"];
          } else {
            return [];
          }
        }),
        calcCount: computed(() => {
          return state.activeUsers.length > 0 ? state.activeUsers.length - 5 : 0;
        }),
        activeUsers: computed(() => {
          return store.getters["wbs/projectParticipants"];
        }),
        inactiveUsers: [],
        isOpen: false,
      });

    const openClosePopup = () => {
      if (!state.activeUsers.length) return;
      state.isOpen = !state.isOpen;
    };

    watch(
      () => state.isOpen,
      (value) => {
        if (value) {
          window.scrollTo(0, 0);
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      }
    );

    const closePopupDirectly = () => {
      state.isOpen = false;
    };

    const closePopup = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("session-popup") || p.classList.contains("session"))
          );
        })
      )
        return;

      closePopupDirectly();
    };
    const onkeydown = (e) => {
      if (e.key == "Escape") state.isOpen = false;
    };
    onMounted(() => {
      //store.dispatch("session/getSession");
      document.addEventListener("keydown", onkeydown);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", onkeydown);
    });
    return {
      ...toRefs(state),
      openClosePopup,
      closePopup,
      closePopupDirectly,
    };
  },
};
</script>
<style lang="scss" scoped>
.session-container {
  position: relative;
  justify-self: end;
}

.session {
  display: flex;
  //grid-template-columns: repeat(3, 30px) 40px 40px 22px;
  align-items: center;
  justify-self: end;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  /* #365674 */
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  color: #365674;

  .session-item+.session-item {
    margin-left: -15px;
  }

  .session-item:last-child {
    margin-left: 0;
  }

  .session-item:not(.session-item:last-child) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .session-item:not(.session-item:last-child) img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 1);
  }

  &.is-open~.session-popup {
    display: block;
  }
}

.session-popup {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 210px;
  background: white;
  z-index: 2;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 10px 30px #08122426;

  &--header {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #A0A5B0;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 30px;
  }

  ul {
    padding: 0;
    list-style: none;
    height: 220px;
    overflow: auto;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      height: 45px;
      font-size: 13px;
      line-height: 16px;

      color: #23252A;
      /* or 18px */

      display: flex;
      align-items: center;

      padding-left: 13px;
      padding-right: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 53px;
        height: 1px;
        background: #F0F0F0;
        width: calc(100% - 70px);
      }
    }

    li:last-child {
      &::after {
        display: none;
      }
    }

    li+li {
      margin-top: 10px;
    }

    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
      margin-right: 10px;
      border-radius: 50%;

      &.inactive {
        border: 0;
      }
    }
  }
}

.close-popup {
  cursor: pointer;
}
</style>
