<template>
  <div
    class="container-node"
    data-testid="container-node"
    :data-drag-direction="dragDirection"
    :class="{
      'is-not-filtered': isNotFiltered,
      'edited-node': isEdited,
      'need-jira-update': isUpdate,
      'has-error': hasError,
      'is-orphaned': isOrphaned,
      'is-orphaned-child': isOrphanedChild,
      'is-template-node': source == 'templates',
    }"
  >
    <div class="header">
      <div class="info-icon" v-if="isUpdate">
        <icon-jira-update :width="'28px'" :height="'28px'" :fill="'#FF8C4B'" />
      </div>
      <div class="info-icon" v-if="hasError" @click="(e) => showErrorPopup(e)">
        <icon-jira-error :width="'28px'" :height="'28px'" :fill="'#d81d1d'" />
      </div>
      <div class="numb">
        {{ codeOfAccounts }}
      </div>
      <i class="icon" v-if="jiraIssueType" :title="jiraIssueType.name">
        <img :src="jiraIssueType.iconUri" />
      </i>
      <div class="title" :title="jiraIssueKey">
        <a v-if="jiraIssueUrl" v-open-jira-issue-dialog="jiraIssueKey" target="_blank" :href="jiraIssueUrl">{{
          jiraIssueKey
        }}</a>
        <template v-else>
          <a
            target="_blank"
            v-tooltip="jiraProject.name"
            :href="jiraProject.jiraProjectUrl"
            >{{ jiraProject.key }} - {{ jiraIssueType.name }}</a
          >
        </template>
      </div>
      <div class="ava" v-if="source == 'templates'" @click="onChangeAssign">
        <img v-if="assignee" :src="assignee.iconUri" alt="" />
        <span v-else class="no-assignee">
          <icon-no-assignee :width="'24px'" :height="'24px'" :fill="'#fff'" />
        </span>
      </div>
      <div
        :style="{ background: statusColor }"
        v-if="source == 'projects'"
        class="status"
        @click="onChangeStatus"
        data-testid="change-status-node"
      >
        <span :style="{ color: statusColorText }">{{ status }}</span>
      </div>
    </div>
    <div class="body">
      <div class="time-line">
        <div class="name" :title="summary">
          {{ summary.length > 95 ? summary.substr(0, 95) + "..." : summary }}
        </div>
        <div
          class="ava"
          v-if="source == 'projects'"
          data-testid="change-assignee-node"
          @click="onChangeAssign"
        >
          <img
            v-if="assignee"
            data-testid="assignee-avatar"
            :src="assignee.iconUri"
            alt=""
            :title="assignee.displayName"
          />
          <span v-else class="no-assignee" data-testid="assignee-avatar">
            <icon-no-assignee :width="'24px'" :height="'24px'" :fill="'#fff'" />
          </span>
        </div>
        <div class="progress-container" @click="showLogged(id)">
          <div
            class="total"
            data-testid="edit-estimate"
            @click="showEstimates(id)"
          >
            <span>{{ translate("wbs.estimate") }}</span>
            {{
              estimationType == "story_points"
                ? originalEstimateInStoryPoints
                : originalEstimateInHours
            }}
          </div>
          <div class="progress-bar">
            <app-progress-bar
              :spent="progress.calcTimePerc"
              :overlog="progress.calcOverLog"
              :remaining="progress.calcRemaining"
            />
          </div>
          <div class="spent-time">
            <span>{{ translate("wbs.spent") }}:</span>
            {{ timeSpent }}
          </div>
          <div class="remaining-time">
            <span>{{ translate("wbs.remaining") }}:</span>
            {{ remainingEstimate }}
          </div>
        </div>
      </div>
    </div>
    <div class="edited" v-if="isEdited">
      <label
        ><icon-edit-node :width="'12px'" :height="'12px'" />Editing....</label
      >
      <span class="name"
        ><img :src="edited.iconUri" alt="" />
        <span :title="edited.name">{{ edited.name }}</span>
      </span>
      <div class="numb-children">
        <span>{{ childrenCount }}</span> {{ translate("wbs.children") }}
        <div
          class="arrow-down"
          :class="{ hidden: !showChildren }"
          @click="displayChildren"
        >
          <icon-arrow-down :width="'11px'" />
        </div>
      </div>
    </div>
    <div v-else class="show-children" :class="{ 'disable-edit': isOrphaned }">     
      <div class="panel">
        <ul>
          <li data-testid="edit-node" @click="editNode(id)">
            <icon-pencil :width="'14px'" />
          </li>
          <li
            data-testid="edit-color"
            @click.stop="(e) => onChangeColor({ id, e })"
          >
            <icon-circle :width="'14px'" />
          </li>
          <li
            data-testid="add-new-child-node"
            v-if="!isSubtask"
            @click="addNewNode(id)"
          >
            <icon-add-node :stroke="'#A1A9BA'" :width="'14px'" />
          </li>
        </ul>
      </div>
      <div
        v-if="!isSubtask"
        class="numb-children"
        :class="{ highlight: isApplyFilters && childrenCount > 0 }"
        @click="displayChildren"
      >
        <span>{{ childrenCount }}</span> {{ translate("wbs.children") }}
        <div class="arrow-down" :class="{ hidden: !showChildren }">
          <icon-arrow-down :width="'11px'" />
        </div>
      </div>
    </div>
    <div class="drag-line"></div>
  </div>
</template>

<script>
import { computed, toRefs, reactive, watch, ref } from "vue";
import { useCalcProgress } from "@/helpers/calcProgress";
import { useStatusColor } from "@/helpers/statusColor";
import IconAddNode from "@/components/icons/IconAddNode.vue";
import IconPencil from "@/components/icons/IconPencil.vue";
import IconCircle from "@/components/icons/IconCircle.vue";
import IconArrowDown from "@/components/icons/IconArrowDown.vue";
import IconJiraUpdate from "@/components/icons/IconJiraUpdate.vue";
import IconJiraError from "@/components/icons/IconJiraError.vue";
import IconEditNode from "@/components/icons/IconEditNode.vue";
import IconNoAssignee from "@/components/icons/IconNoAssignee.vue";
import AppProgressBar from "@/components/wbsProject/estimation/ProgressBar.vue";
import vOpenJiraIssueDialog from "@/directives/openJiraIssueDialog.js"

export default {
  components: {
    IconAddNode,
    IconPencil,
    IconCircle,
    IconArrowDown,
    IconJiraUpdate,
    IconJiraError,
    IconEditNode,
    AppProgressBar,
    IconNoAssignee,
  },
  directives: {
    'open-jira-issue-dialog': vOpenJiraIssueDialog
  },
  inject: ["translate"],
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    assignee: {
      type: Object,
      default: null,
    },
    summary: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    source: {
      type: String,
      default: "projects",
    },
    jiraProject: {
      type: Object,
      default: null,
    },
    jiraIssueKey: {
      type: String,
    },
    jiraIssueUrl: {
      type: String,
      default: null,
    },
    jiraIssueType: {
      type: Object,
      default: null,
    },
    status: {
      type: String,
      default: "",
    },
    statusColor: {
      type: String,
      default: "rgb(223, 225, 230)",
    },
    originalEstimateInHours: {
      type: String,
      default: "",
    },
    originalEstimateInStoryPoints: {
      type: [Number, String],
      default: "",
    },
    timeSpent: {
      type: String,
      default: "",
    },
    remainingEstimate: {
      type: [String, Number],
      default: "",
    },
    aggregateProgress: {
      type: Number,
      default: 0,
    },
    codeOfAccounts: {
      type: [Number, String],
    },
    childrenCount: {
      type: Number,
      default: 0,
    },
    edited: {
      type: Object,
      default: {},
    },
    syncedWithJira: {
      type: [Number, Boolean],
      default: null,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    showChildren: {
      type: Boolean,
      default: true,
    },
    isNotFiltered: {
      type: [Boolean, Number],
      default: null,
    },
    isApplyFilters: {
      type: [Boolean],
      default: false,
    },
    colorNode: {
      type: String,
      default: "white",
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    isOrphaned: {
      type: Boolean,
      default: false,
    },
    isOrphanedChild: {
      type: Boolean,
      default: false,
    },
    isSubtask: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Object,
      default: null,
    },
    estimationType: {
      type: String,
      default: null,
    },
    dragDirection: {
      type: String,
      default: null,
    },
  },
  emits: [
    "onChangeColor",
    "onShowChildren",
    "onChangeAssign",
    "onChangeStatus",
    "onAddNewNode",
    "onEditNode",
    "onShowEstimates",
    "onShowLogged",
    "onClickErrorIcon",
  ],
  setup(props, { emit }) {
    const state = reactive({
      showMenu: false,
      isEdited: computed(() => Object.keys(props.edited).length > 0),
      progress: computed(() => {
        return useCalcProgress(
          props.originalEstimateInHours,
          props.timeSpent,
          props.remainingEstimate
        );
      }),
      statusColorText: computed(() => {
        return useStatusColor().getStatusColorText(props.statusColor);
      }),
    });

    const backgroundColor = ref(null),
      fontColor = ref(null);

    watch(
      () => props.colorNode,
      (current) => {
        backgroundColor.value = current?.split(":")[0];
        fontColor.value = current?.split(":")[1];
      }, {
        immediate: true
      }
    );

    const isMenuOpen = computed(() => state.showMenu);

    const openMenu = () => {
      state.showMenu = !state.showMenu;
    };

    const onChangeColor = ({ id, e }) => {
      emit("onChangeColor", { id, e });
    };

    const displayChildren = () => {
      emit("onShowChildren", !props.showChildren);
    };
    const onChangeAssign = (e) => {
      emit("onChangeAssign", e);
    };
    const onChangeStatus = (e) => {
      emit("onChangeStatus", e);
    };
    const addNewNode = (nodeId) => {
      emit("onAddNewNode", nodeId);
    };
    const editNode = (nodeId) => {
      emit("onEditNode", nodeId);
    };
    const showEstimates = (id) => {
      emit("onShowEstimates", id);
    };
    const showLogged = (id) => {
      emit("onShowLogged", id);
    };

    const showErrorPopup = (e) => {
      emit("onClickErrorIcon", { event: e, nodeError: props.hasError });
    };

    return {
      ...toRefs(state),
      isMenuOpen,
      openMenu,
      onChangeColor,
      displayChildren,
      onChangeAssign,
      onChangeStatus,
      addNewNode,
      editNode,
      showEstimates,
      showLogged,
      showErrorPopup,
      backgroundColor,
      fontColor,
    };
  },
};
</script>
<style lang="scss" scoped>
.container-node {
  &.edited-node {
    .header,
    .body {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &.is-not-filtered {
    border: 1px dashed #2684fe;

    .header,
    .body {
      opacity: 0;
      pointer-events: none;
    }
    .body {
      border-top: 0;
    }
    .header {
      border-bottom: 0;
    }
  }

  &.is-orphaned-child {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      height: 100%;
      width: 3px;
      background: #03a9f4;
    }
  }
  &.need-jira-update {
    .header {
      border: 1px solid #ff8c4b;
      border-bottom: 0;
    }
    .body {
      border: 1px solid #ff8c4b;
      border-top: 0;
    }
  }

  &.has-error {
    .header {
      border: 1px solid #d81d1d;
      border-bottom: 0;
    }
    .body {
      border: 1px solid #d81d1d;
      border-top: 0;
    }
  }

  .info-panel {
    grid-area: InfoPanel;
    font-weight: 500;
    font-size: 9px;
    line-height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    color: #2684fe;
    background: rgba(38, 132, 254, 0.05);
    border: 1px solid rgba(38, 132, 254, 0.25);
    box-sizing: border-box;
    border-radius: 4px;
    align-self: end;
  }
  .numb {
    width: 32px;
    height: 30px;
    background: rgba(153, 118, 217, 0.1);
    border-radius: 4px;
    font-size: 12px;
    line-height: 18px;
    color: v-bind("fontColor || '#363636'");
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: Index;
  }
  &.hover-success {
    border: 1px solid #2684fe;
    transition: 0.5s;
    transform: scale(1.1) translate(-10px, -10px) !important;
  }
  &.accept-new-parent {
    border: 1px dashed #2684fe;
    .header,
    .body,
    .show-children {
      opacity: 0;
    }
  }
  .drag-line {
    display: none;
    position: absolute;
    height: 3px;
    bottom: -12px;
    background: lime;
    width: calc(100% + 20px);
    transform: translateX(-10px);
    left: 0;

    &:before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #4caf50;
    }
    &:after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: #4caf50;
    }
  }
  &[data-drag-direction="top"] {
    .drag-line {
      top: -12px;
    }
  }
  &[data-drag-direction="bottom"] {
    .drag-line {
      bottom: -12px;
    }
  }

  &[data-drag-direction="left"],
  &[data-drag-direction="right"] {
    .drag-line {
      width: 3px;
      height: calc(100% + 20px);
      transform: translateY(-10px);
      &:before {
        left: -4px;
        top: 0;
        transform: translateY(0);
      }
      &:after {
        right: -5px;
        top: auto;
        bottom: 0;
        transform: translateY(0);
      }
    }
  }
  &[data-drag-direction="left"] {
    .drag-line {
      left: -15px;
      right: auto;
    }
  }
  &[data-drag-direction="right"] {
    .drag-line {
      left: auto;
      right: -15px;
    }
  }

  &.change-order-success {
    transition: 0.5s;
    position: relative;
    .drag-line {
      display: block;
    }
  }
  &.hover-error {
    border: 1px solid #d81d1d;
    transition: 0.5s;
  }
  display: grid;
  border-radius: 8px;
  position: relative;
  grid-template-columns: 1fr;
  grid-template-areas:
    "Header"
    "Body"
    "Children";
  grid-template-rows: 42px 138px auto;
  &:hover {
    .panel {
      right: -45px;
      opacity: 1;
      //z-index: 1;
    }
  }
}
.header {
  transition: 0.3s;
  display: grid;
  grid-template-areas: "Index Icon Title Status";
  align-items: center;
  justify-content: start;
  grid-column-gap: 10px;
  grid-template-columns: 32px 15px 1fr auto;
  grid-area: Header;
  padding: 0 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;  
  color: v-bind("fontColor || '#363636'");
  background: v-bind("backgroundColor || '#ffffff'");
  .info-icon {
    position: absolute;
    left: -2px;
    top: 0;
    cursor: pointer;
  }
  .icon {
    grid-area: Icon;
    display: flex;
    align-items: center;
    img {
      width: 15px;
      height: 15px;
    }
  }
  .title {
    grid-area: Title;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;    
    color: v-bind("fontColor || '#363636'");
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.5s;
    display: inline-flex;
    height: 100%;
    align-items: center;

    a {
      display: flex;
      height: 100%;
      align-items: center;
      width: 100%;
      color: v-bind("fontColor || 'rgba(22, 0, 255, 1)'");
    }
    &:hover {
      background: #0f8af91a;
    }
  }
  .status {
    grid-area: Status;    
    color: v-bind("fontColor || '#363636'");
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 30px;
    background: rgba(195, 202, 217, 0.2);
    border-radius: 4px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #0f8af91a !important;
      span {
        color: #444 !important;
      }
    }
  }
}
.is-template-node {
  .header {
    grid-template-areas: "Index Icon Title Ava";
  }
  .ava {
    border: 0;
  }
  .time-line {
    grid-template-areas:
      "Name Name Name"
      "ProgressContainer ProgressContainer ProgressContainer";
  }
}
.body {
  grid-area: Body;
  transition: 0.3s;
  background: v-bind("backgroundColor || '#ffffff'");
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ava {
  grid-area: Ava;
  justify-self: center;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #f2f5f9;
  transition: 0.5s;
  &:hover {
    background: #0f8af91a;
  }
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
  }
}
.no-assignee {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8993a4;
}
.time-line {
  display: grid;
  padding: 0 10px;
  font-weight: 500;
  font-size: 9px;
  line-height: 12px;
  color: #01263c;
  height: 100%;
  align-items: center;
  grid-template-columns: 1fr 1fr 71px;
  grid-template-areas:
    "Name Name Ava"
    "ProgressContainer ProgressContainer ProgressContainer";
  .name {
    grid-area: Name;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: v-bind("fontColor || '#7b818e'");
    text-overflow: ellipsis;
    overflow: hidden;
    // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  .total {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: v-bind("fontColor || '#2684fe'");
    grid-area: Estimate;
    transition: 0.5s;
    height: 30px;
    &:hover {
      background: #0f8af91a;
    }
    span {
      margin-right: 5px;
      display: block;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: v-bind("fontColor || '#363636'");
    }
  }

  .progress-container {
    cursor: pointer;
    grid-area: ProgressContainer;
    position: relative;
    display: grid;
    grid-row-gap: 5px;
    grid-template-areas:
      "Estimate ."
      "Progress Progress"
      "Spent Remaining";
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;

    .time-label {
      grid-area: TimeLabel;
    }

    .progress-bar {
      grid-area: Progress;
      overflow: hidden;
    }

    .spent-time {
      grid-area: Spent;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: v-bind("fontColor || '#a1a9ba'");
      span {
        color: v-bind("fontColor || '#a1a9ba'");
      }
    }
    .remaining-time {
      grid-area: Remaining;
      justify-self: end;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: v-bind("fontColor || '#a1a9ba'");
      span {        
        color: v-bind("fontColor || '#a1a9ba'");
      }
    }
  }
}

.show-children {
  background: white;
  margin-top: 5px;
  border-radius: 8px;
  grid-area: Children;

  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.05);
  display: grid;

  //grid-template-columns: 40px 85px 1fr;
  //grid-template-areas: "Menu Panel NumbChildren";
  grid-template-columns: 1fr 0.5fr;
  grid-template-areas: "Panel NumbChildren";
  &.disable-edit {
    // opacity: 0.5;
    // pointer-events: none;
    .numb-children {
      display: none;
    }
  }
  .menu {
    cursor: pointer;
    grid-area: Menu;
    i {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > svg {
      z-index: 99;
    }
    &.show {
      ul {
        display: block;
      }
      i {
        background: rgba(38, 132, 254, 0.05);
        border: 1px solid #2684fe;
        border-radius: 4px;
      }
    }
    ul {
      display: none;
      background: #ffffff;
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      width: 185px;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 15px;
      padding: 0;
      margin-top: 5px;
      list-style: none;
      li {
        height: 40px;
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 20px;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
        justify-content: start;
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;
        color: #01263c;
        &:hover {
          background: #eef7ff;
        }
      }
      li:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      li:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &::before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 4px 8px 4px;
        border-color: transparent transparent #ffffff transparent;

        position: absolute;
        top: -7px;
        left: 15px;
      }
    }
  }
  .panel {
    grid-area: Panel;
    right: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;

    ul {
      width: 100%;
      padding: 0;
      list-style: none;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    li {
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
      &:hover {
        background: #0f8af91a;
      }
    }
  }
}
.numb-children {
  cursor: pointer;
  justify-self: end;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
  height: 100%;
  transition: 0.5s;
  width: 100%;
  justify-content: center;
  &:hover {
    background: #0f8af91a;
  }
  &.highlight {
    background: #0f8af91a;
  }
  span {
    color: #2684fe;
  }
}
.arrow-down {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  svg {
    transition: 0.3s;
  }
  &.hidden {
    svg {
      transform: rotate(90deg);
    }
  }
}
.edited {
  background: white;
  margin-top: 5px;
  border-radius: 8px;
  display: grid;
  grid-area: Children;
  align-items: center;
  padding: 0 15px;
  height: 35px;
  box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.05);
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-column-gap: 10px;
  border: 1px solid #ffd167;
  label {
    font-style: italic;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #a1a9ba;
    svg {
      margin-right: 5px;
    }
  }
  .name {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    color: #363636;
    justify-content: start;
    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
}
</style>
