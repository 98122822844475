import { createRouter, createWebHistory } from 'vue-router';
import DashboardMain from '@/components/dashboard/Main.vue';
import DashboardWelcome from '@/components/dashboard/Welcome.vue';
import AppGetStarted from "@/components/dashboard/GetStarted.vue";
import SettingsMain from '@/components/settings/Main';
import UserAccessWelcome from '@/components/userAccess/Welcome.vue';
import UserAccessMain from '@/components/userAccess/Main.vue';
import ProjectsMain from '@/components/projects/Main.vue';
import TemplatesMain from "@/components/templates/Main.vue";
import WbsProjectMain from "@/components/wbsProject/Main.vue";
import WbsProjectListViewMain from "@/components/wbsProject/ListView.vue";
import AppScheduleManagement from "@/components/wbsProject/schedule/ScheduleManagement.vue";
import NoAccess from "@/components/userAccess/NoAccess.vue";
import ReleaseNotes from "@/components/release-notes/ReleaseNotes.vue";
import AppHelpCenter from "@/components/help-center/HelpCenter.vue"
import AppOtherApps from "@/components/our-app/OurApp.vue"

import Axios from 'axios';
const routes = [
  {
    path: '/',
    name: 'index',
    redirect: { name: 'dashboard.welcome' }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardMain
  },
  {
    path: '/dashboard/get-started',
    name: 'dashboard.get-started',
    component: AppGetStarted
  },
  {
    path: '/dashboard/welcome',
    name: 'dashboard.welcome',
    component: DashboardWelcome
  },
  {
    path: '/user-access',
    name: 'user.access',
    component: UserAccessMain
  },
  {
    path: '/user-access/welcome',
    name: 'user.access.welcome',
    component: UserAccessWelcome
  },
  {
    path: '/projects/:openModal?',
    name: 'projects',
    component: ProjectsMain
  },
  {
    path: '/templates/:openModal?',
    name: 'templates',
    component: TemplatesMain
  },
  {
    path: '/wbs-project/:id',
    name: 'wbs-project',
    component: WbsProjectMain,
    meta: {
      requiresAccess: true,
    }
  },
  {
    path: '/wbs-template/:id',
    name: 'wbs-template',
    component: WbsProjectMain,
    meta: {
      requiresAccess: true,
    }
  },
  {
    path: '/help-center',
    name: 'help-center',
    component: AppHelpCenter,
  },
  {
    path: '/wbs-template-list-view/:id',
    name: 'wbs-template-list-view',
    component: WbsProjectListViewMain,
    meta: {
      requiresAccess: true,
    }
  },
  {
    path: '/wbs-project-list-view/:id',
    name: 'wbs-project-list-view',
    component: WbsProjectListViewMain,
    meta: {
      requiresAccess: true,
    }
  },
  {
    path: '/wbs-schedule-management/:id',
    name: 'wbs-schedule-management',
    component: AppScheduleManagement,
    meta: {
      requiresAccess: true,
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsMain
  },
  {
    path: '/no-access',
    name: 'no-access',
    component: NoAccess
  },
  {
    path: '/release-notes',
    name: 'release-notes',
    component: ReleaseNotes
  },
  {
    path: '/our-apps',
    name: 'other-apps',
    component: AppOtherApps
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (window.Chatra) {
    if (typeof window.Chatra?.setButtonPosition === "function") {
      window.Chatra.setButtonPosition("br");
    }
  }

  if (router.app?.store) {
    if (!router.app.store.getters["profile/conversionScreenUsed"] && to.name != 'dashboard.get-started') {
      next({ name: 'dashboard.get-started' })
      return;
    }
  }

  if (to.matched.some(record => record.meta.requiresAccess)) {
    const id = to.params.id;
    try {      
      const source = ['wbs-project', 'wbs-project-list-view', 'wbs-schedule-management'].includes(to.name) ? 'projects' : 'templates';
      
      if (router.app) {
        if (!Object.keys(router.app.store.getters["wbs/wbsState"]).length)
          await Axios.get(`/${source}/${id}`);
      }
      else
        await Axios.get(`/${source}/${id}`);
      next();
    } catch (error) {
      if (error.response.status == 403)
        next({ name: 'no-access' })
    }
    if (window.Chatra) {
      if (typeof window.Chatra?.setButtonPosition === "function") {
        window.Chatra.setButtonPosition("bl");
      }
    }
  } else {
    next()
  }
})


export default router;
