import * as types from '@/store/mutation-types';
export default {
  [types.SET_HIERARCHY](state, payload) {
    state.hierarchy = payload.data;
    state.meta = payload.meta;
  },
  [types.SET_ISSUE_TYPES](state, payload) {
    state.issueTypes = payload.data;
  },
  [types.SET_GENERAL_SETTINGS](state, payload) {
    state.estimationType = payload.estimationType;
  }
};
