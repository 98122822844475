<template>
  <div v-click-outside="clickOutSide" class="search-container" data-testid="search-input">
    <app-search-input v-model="searchText" :placeholder="placeholder ? placeholder : translate('search')"
      @keydown="onKeyDown" @focus="onFocus" />
    <ul :class="{ show: isShowList, 'is-empty': searchList.length == 0 }">
      <li v-for="(item, i) in searchList" :key="i">
        <router-link :to="item.path" @click="closeList">{{ item.name }}</router-link>
      </li>
      <li v-if="isNotFound">
        <a href="javascript:;">{{ translate("search_not_found") }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import AppSearchInput from "@/components/shared/SearchInput.vue";
import { computed, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import debounce from "lodash.debounce";
export default {
  components: { AppSearchInput },
  inject: ["translate"],
  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },
  setup() {
    const searchText = ref(""),
      isShow = ref(false),
      store = useStore(),
      searchList = computed(() => {
        return store.getters["app/searchList"].map((item) => {
          item.path = item.path
            .replace("/projects/", "/wbs-project-list-view/")
            .replace("/templates/", "/wbs-template-list-view/");
          return item;
        });
      }),
      wasRequest = ref(false),
      isNotFound = computed(
        () => wasRequest.value && searchText.value && !searchList.value.length
      ),
      isShowList = computed(() => isShow.value);
    const debounceSearch = debounce(async (searchStr) => {
      await store.dispatch("app/search", searchStr);
      wasRequest.value = true;
    }, 500);

    watch(
      () => searchText.value,
      (current) => {
        wasRequest.value = false;
        debounceSearch(current);
      }
    );

    const clickOutSide = () => {
      closeList();
    };

    const onFocus = () => {
      wasRequest.value = false;
      isShow.value = true;
    };

    const closeList = () => {
      isShow.value = false;
      store.commit("app/SET_SEARCH_INSTANCE", []);
    };

    const onKeyDown = (e) => {
      isShow.value = true;
      if (e.key == "Escape") {
        closeList();
      }
    };

    onUnmounted(() => store.commit("app/SET_SEARCH_INSTANCE", []));

    return {
      searchText,
      searchList,
      clickOutSide,
      isShowList,
      onFocus,
      onKeyDown,
      closeList,
      isNotFound,
      wasRequest,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-container {
  position: relative;
}

ul {
  position: absolute;
  overflow: hidden;
  border: 0;
  z-index: 2;
  box-shadow: -4px 4px 15px rgb(0 0 0 / 5%);
  border: 1px solid #dadcde;  
  &.is-empty {
    box-shadow: none;
    border: 0;
  }
  background: white;
  max-height: 300px;
  overflow: auto;
  top: calc(100% + 10px);
  left: 0;
  width: 300px;
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;

  &.show {
    opacity: 1;
    visibility: visible;
    z-index: 999;
  }

  li a {
    height: 40px;
    display: flex;
    align-items: center;
    background: white;
    text-decoration: none;
    padding: 10px;
    color: #363636;

    &:hover {
      background: #0f8af9;
      color: #ffffff;
    }
  }

  li+li a {
    border-top: 1px dashed #a1a9ba42;
  }
}
</style>
