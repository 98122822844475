<template>
  <svg :width="width" :height="height" :fill="fill" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.0605 0L0 1.0605L3.21975 4.28025L1.5 6H5.25C5.44891 6 5.63968 5.92098 5.78033 5.78033C5.92098 5.63968 6 5.44891 6 5.25V1.5L4.28025 3.21975L1.0605 0Z"
      fill="#23252A" />
    <path
      d="M16.5 12H12.75C12.5511 12 12.3603 12.079 12.2197 12.2197C12.079 12.3603 12 12.5511 12 12.75V16.5L13.7197 14.7803L16.9395 18L18 16.9395L14.7803 13.7197L16.5 12Z"
      fill="#23252A" />
    <path
      d="M12.75 6H16.5L14.7803 4.28025L18 1.0605L16.9395 0L13.7197 3.21975L12 1.5V5.25C12 5.44891 12.079 5.63968 12.2197 5.78033C12.3603 5.92098 12.5511 6 12.75 6Z"
      fill="#23252A" />
    <path
      d="M5.25 12H1.5L3.21975 13.7197L0 16.9395L1.0605 18L4.28025 14.7803L6 16.5V12.75C6 12.5511 5.92098 12.3603 5.78033 12.2197C5.63968 12.079 5.44891 12 5.25 12Z"
      fill="#23252A" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
      default: "transparent",
    },
  },
};
</script>
