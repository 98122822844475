export default {
  beforeMount: function (el, binding) {
    const ourClickEventHandler = (event) => {
      if (config.isLocal) {
        console.log('app is not running in Jira instance')
        return;
      }
      event.preventDefault();
      AP.jira.openIssueDialog(binding.value, async (jiraIssueKey) => {
        console.log(jiraIssueKey)
      });
    };

    el.__vueClickEventHandler__ = ourClickEventHandler;
    el.addEventListener("click", ourClickEventHandler);
  },
  unmounted: function (el) {
    document.removeEventListener("click", el.__vueClickEventHandler__);
  }
}