<template>
  <div class="drop-down" :style="position" data-testid="user-select-dropdown">
    <div class="select-user">
      <Multiselect ref="select$" v-model="userId" :options="userList" :searchable="true" :noResultsText="''"
        :loading="loadingSearch" :can-clear="false" :hideSelected="false" :close-on-select="false"
        :placeholder="translate('search_selectbox')" @change="onChangeItem" @search-change="handleInput"
        @close="onClose">
        <template #option="{ option }">
          <div class="option-container">
            <label>
              <img v-if="option.iconUri" :src="option.iconUri" alt="" />
              <icon-no-assignee v-else class="unassigned" :width="'24px'" :height="'24px'" :fill="'#ccc'" />
              {{ option.label }}
            </label>
          </div>
        </template>
        <template #beforelist>
          <div class="button-container">
            <div class="assign-to-me" @click="assigneeToMe">{{ translate('new_node.assign_to_me') }}</div>
          </div>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  onMounted,
  ref,
  watch,
  nextTick,
} from "vue";
import { useStore } from "vuex";
import debounce from "lodash.debounce";
import IconNoAssignee from "@/components/icons/IconNoAssignee.vue";
import Multiselect from '@vueform/multiselect';
export default {
  components: {
    Multiselect,
    IconNoAssignee,
  },
  inject: ["translate"],
  props: {
    position: {
      type: Object,
      default: () => { },
    },
    userData: {
      type: Object,
      default: () => { },
    },
  },
  emits: ['onChange'],
  setup(props, { emit }) {
    const store = useStore(),
      currentUser = store.getters["profile/user"],
      state = reactive({
        userId: null,
        selectedUser: null,
        userList: computed(() => {
          if (state.selectedUser) {
            const arr = [
              ...store.getters["users/userList"],
              state.selectedUser,
            ];
            return [
              {
                label: "Unassigned",
                value: -1,
                iconUri: null,
              },
              ...new Set([
                ...new Map(arr.map((item) => [item["value"], item])).values(),
              ]),
            ];
          } else
            return [
              {
                label: "Unassigned",
                value: -1,
                iconUri: null,
              },
              ...store.getters["users/userList"],
            ];
        }),
      });

    const select$ = ref();
    watch(
      () => props.position?.display,
      () => {
        nextTick(() => {
          setTimeout(() => {
            if (select$.value) {
              select$.value.open();
              select$.value.focus();
            }
          }, 100);
        });
      }
    );

    const assigneeToMe = () => {
      const item = {
        value: currentUser.id,
        label: currentUser.displayName,
        displayName: currentUser.displayName,
        iconUri: currentUser.iconUri,
      };
      emit("onChange", item);
    }

    const onClose = () => {
      emit("onChange", null);
    };

    const onChangeItem = (id) => {
      const item = state.userList.find((u) => u.value == id);
      if (item) {
        emit("onChange", item);
        getUserList({
          perPage: 100,
          hideLoader: true,
        });
      }
    };

    const getUserList = async (
      params = { perPage: 5, search: "", hideLoader: true }
    ) => {
      await store.dispatch("users/getUsers", params);
    };

    const loadingSearch = ref(false);
    const debounceSearch = debounce(async (str) => {
      loadingSearch.value = true;
      await getUserList({
        perPage: 100,
        search: str,
        hideLoader: true,
      });
      loadingSearch.value = false;
    }, 500);

    const handleInput = async (str) => {
      debounceSearch(str);
    };

    onMounted(async () => {
      state.loadingSearch = true;
      await getUserList({
        perPage: 100,
        hideLoader: true,
      });
      state.loadingSearch = false;
      if (select$.value) {
        select$.value.open();
        select$.value.focus();
      }
    });

    return {
      ...toRefs(state),
      onChangeItem,
      onClose,
      handleInput,
      assigneeToMe,
      loadingSearch,
      select$,
    };
  },
};
</script>
<style>
:root {
  --ms-max-height: 15rem;
  --ms-line-height: 2;
  --ms-option-bg-selected: #fff;
  --ms-option-bg-selected-pointed: #a283e5;
  --ms-option-color-selected: #444;
  --ms-option-color-selected-pointed: #ffffff;
  --ms-ring-color: #0f8cf939;
  --ms-option-bg-pointed: #a283e5;
  --ms-option-color-pointed: #ffffff;
}
</style>
<style src="@vueform/multiselect/themes/default.css">
</style>
<style lang="scss" scoped>
.drop-down {
  position: absolute;
  width: 200px;
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  ::v-deep(.vue-select-header) {
    width: 200px;
    border: 0;
  }

  ::v-deep(.vue-dropdown) {
    margin-top: 0;
    box-shadow: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  ::v-deep(.vue-dropdown-item) {
    position: relative;
  }

  .image-item {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr;
    height: 100%;
    position: absolute;
    left: 0;
    padding-left: 15px;
  }
}

::v-deep(.multiselect-option) {
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #d1d5db;
}

.option-container {
  width: 100%;
  padding: 5px;
}

.option-container {
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  ::v-deep(img) {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.button-container {
  position: sticky;
  top: 0;
  background: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 5px;

  .assign-to-me {
    width: 100%;
    color: #0052CC;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.selected-items {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  left: 10px;
  right: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
  justify-content: start;

  ul {
    padding: 0;
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    align-items: center;
  }

  li+li {
    margin-left: -15px;
  }

  li {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
</style>