<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1667 5.00002H18.3334V6.66669H16.6667V17.5C16.6667 17.721 16.5789 17.933 16.4226 18.0893C16.2663 18.2456 16.0544 18.3334 15.8334 18.3334H4.16669C3.94567 18.3334 3.73371 18.2456 3.57743 18.0893C3.42115 17.933 3.33335 17.721 3.33335 17.5V6.66669H1.66669V5.00002H5.83335V2.50002C5.83335 2.27901 5.92115 2.06704 6.07743 1.91076C6.23371 1.75448 6.44567 1.66669 6.66669 1.66669H13.3334C13.5544 1.66669 13.7663 1.75448 13.9226 1.91076C14.0789 2.06704 14.1667 2.27901 14.1667 2.50002V5.00002ZM15 6.66669H5.00002V16.6667H15V6.66669ZM7.50002 9.16669H9.16669V14.1667H7.50002V9.16669ZM10.8334 9.16669H12.5V14.1667H10.8334V9.16669ZM7.50002 3.33335V5.00002H12.5V3.33335H7.50002Z"
      fill="#A0A5B0" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
    stroke: {
      type: [String],
      default: "#FF7E7E",
    },
  },
};
</script>
