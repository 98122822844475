import { useStore } from "vuex"
export function useModalHelper() {
  const storeModal = useStore();

  const openModal = (component, props = {}) => {    
    storeModal.commit("modal/openModal", { component, props });
  }

  const closeModal = () => {    
    storeModal.commit("modal/closeModal");
    storeModal.commit("modal/setProps", {});
    storeModal.commit("modal/setData", null);
  }

  const submitModal = (data = {}) => {
    storeModal.commit("modal/setData", data);
    closeModal()
  }

  return { openModal, closeModal, submitModal, storeModal }
}