export function useLocation() {
  const getUrl = (id, source) => {
    return new Promise((resolve, reject) => {
      if (config.isLocal) {
        reject('app is not running in Jira instance')
        return;
      }
      AP.getLocation(function (location) {
        location = location
          .replace(/&wbsTemplate+.+/, "")
          .replace(/\?wbsTemplate+.+/, "")
          .replace(/&wbsProject+.+/, "")
          .replace(/\?wbsProject+.+/, "");
        const paramName = source == "templates" ? "wbsTemplate" : "wbsProject";
        location =
          location +
          (location.indexOf("?") !== -1 ? "&" : "?") +
          `${paramName}=` +
          btoa(id);

        resolve(location)
      });
    })
  }

  return {
    getUrl
  }
}