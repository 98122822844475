<template>
  <icon-download
    v-if="!isPrintRun"
    v-tooltip="{
      content: translate('wbs.download'),
      placement: 'bottom',
    }"
    :width="'18px'"
    :height="'18px'"
    class="print-popup-icon"
    @click="exportAsPng()"
  />

  <i class="loader" v-if="isPrintRun">
    <icon-refresh :stroke="'#0f8af9'" :width="'18px'" :height="'18px'" />
  </i>
  <ul
    class="print-popup"
    :class="{ 'is-open': isOpen }"
    @click="openClosePopup"
    v-click-outside="(e) => closePopup(e)"
  >
    <!-- <li @click="exportAsPdf()">Export as PDF</li> -->
    <li @click="exportAsPng()">{{ translate("wbs.export_as_png") }}</li>
    <!-- <li @click="printPdf()">Print</li> -->
  </ul>
</template>

<script>
import IconDownload from "@/components/icons/IconDownloadWbs.vue";
import { reactive, toRefs } from "@vue/reactivity";
import { computed, onMounted, onUnmounted } from "@vue/runtime-core";
import IconRefresh from "@/components/icons/IconRefresh.vue";
import { useStore } from "vuex";
export default {
  components: {
    IconDownload,
    IconRefresh,
  },
  emits: ["onPngExport", "onPdfExport", "onPrintPdf"],
  inject: ["translate"],
  setup(props, { emit }) {
    const store = useStore(),
      state = reactive({
        isOpen: false,
        isPrintRun: computed(() => store.getters["wbs/isPrintRun"]),
      });

    const openClosePopup = () => {
      state.isOpen = !state.isOpen;
    };

    const closePopup = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("print-popup") ||
              p.classList.contains("print-popup-icon"))
          );
        })
      )
        return;

      state.isOpen = false;
    };

    const onkeydown = (e) => {
      if (e.key == "Escape") state.isOpen = false;
    };

    const exportAsPng = () => {
      emit("onPngExport");
    };

    const exportAsPdf = () => {
      emit("onPdfExport");
    };

    const printPdf = () => {
      emit("onPrintPdf");
    };

    onMounted(() => {
      document.addEventListener("keydown", onkeydown);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", onkeydown);
    });
    return {
      ...toRefs(state),
      openClosePopup,
      closePopup,
      exportAsPng,
      exportAsPdf,
      printPdf,
    };
  },
};
</script>

<style lang="scss" scoped>
ul {
  transition: 0.3s;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  background: white;
  background: white;
  border: 5px solid rgba(39, 143, 247, 0.1);
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  box-shadow: -4px 4px 15px rgb(0 0 0 / 5%);
  border-radius: 8px;
  width: 200px;
  &.is-open {
    opacity: 1;
    z-index: 99;
    visibility: visible;
  }
}
li {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  transition: 0.3s;
  &:hover {
    background: #2684fe;
    color: white;
  }
}
svg {
  outline: 0;
}
.loader {
  display: flex;
  align-items: center;
  svg {
    animation: spin 1s linear infinite;
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
