export default {
  all: (state) => {
    return state.users || [];
  },
  userList: state => state.userList || [],
  meta: (state) => {
    return state.meta || {};
  },
  count: (state) => {
    return state.count;
  },
  countOnPage: (state) => {
    return state.countOnPage;
  },
};
