<template>
  <div class="import">
    <div class="header">
      {{ translate("import.title") }}
      <div class="close" @click="closeImport">
        <icon-close :width="'20px'" :height="'20px'" :fill="'#A0A3BD'" />
      </div>
    </div>
    <div class="content">
      <div class="import-container">
        <p class="text">{{ translate("import.text") }}</p>
        <div class="new-issues">
          <app-base-check-box
            v-model="isImportNewIssues"
            :disabled="countNodes > 0 && !jiraFilterId"
            >{{ translate("import.import_new_issue") }}</app-base-check-box
          >
        </div>
        <div class="filter-field">
          <label>
            {{ translate("import.select_jira_filter") }}
            <icon-attention
              v-tooltip="{
                html: true,
                content: translate('import.tooltip'),
                placement: 'top',
              }"
              :width="'20px'"
              :height="'20px'"
            />
          </label>
          <base-select-box
            v-model="filterValue"
            :options="filterList"
            :placeholder="translate('import.select_filter')"
            :selectedText="translate('selected')"
            label-by="name"
            value-by="id"
            :searchable="true"
            :min="1"
            :clear-on-close="true"
            :close-on-select="true"
            :small-font="true"
            @input="handleInput"
            :disabled="isDisabledSelect"
          >
            <template #icon>
              <div v-if="loadingSearch">
                <icon-loader :width="'21px'" :height="'21px'" />
              </div>
              <div v-if="!loadingSearch && !filterValue">
                <span class="icon arrow-downward"></span>
              </div>
              <div
                class="clear-search"
                @click="resetFilter"
                v-if="!loadingSearch && filterValue"
              >
                <icon-close :width="'21px'" :height="'21px'"></icon-close>
              </div>
            </template>
          </base-select-box>
        </div>
        <div class="actions">
          <app-button :color="'default'" type="button" @click="closeImport">{{
            translate("cancel")
          }}</app-button>
          <app-button
            :disabled="!isImportEnabled"
            :color="'primary'"
            type="button"
            @click="launchImport"
            >{{ translate("import.submit") }}</app-button
          >
        </div>
      </div>
      <hr />
      <div class="error-list">
        <ul>
          <li :key="i" v-for="(error, i) in backendErrors">{{ error.name }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, onMounted, reactive, toRefs, ref } from "vue";
import { useStore } from "vuex";
import errorToList from "@/helpers/errorToList";
import IconAttention from "@/components/icons/IconAttention.vue";
import IconClose from "@/components/icons/IconClose.vue";
import AppButton from "@/components/shared/Button.vue";
import AppBaseCheckBox from "@/components/forms/elements/BaseCheckBox.vue";
import BaseSelectBox from "vue-select-box";
import AppConfirm from "@/components/wbsProject/import/Confirm.vue";
import AppImportTickets from "@/components/wbsProject/import/ImportTickets.vue";
import AppCompleteImport from "@/components/wbsProject/import/CompleteImport.vue";
import debounce from "lodash.debounce";
import IconLoader from "@/components/icons/IconLoader.vue";

export default {
  components: {
    IconAttention,
    IconClose,
    BaseSelectBox,
    AppButton,
    AppConfirm,
    AppImportTickets,
    AppCompleteImport,
    AppBaseCheckBox,
    IconLoader,
  },
  inject: ["translate"],
  emits: ["onClose", "onSubmit"],
  setup(props, { emit }) {
    const store = useStore(),
      state = reactive({
        filterValue: null,
        filterList: computed(() => store.getters["wbs/filterList"]),
        importList: computed(() => store.getters["wbs/importList"]),
        project: computed(() => store.getters["wbs/wbsState"]),
        countNodes: computed(() => store.getters["wbs/nodes"].length),
        isImportEnabled: computed(() => {
          if (state.isImportNewIssues && state.filterValue) return true;
          if (!state.isImportNewIssues) return true;
          return false;
        }),
        isDisabledSelect: computed(() => {
          if (state.countNodes > 0 && !state.jiraFilterId) return true;
          if (state.jiraFilterId) return true;
          return false;
        }),
        isImportNewIssues: false,
        backendErrors: [],
        jiraFilterId: store.getters["wbs/wbsState"].jiraFilterId || null,
      });

    const closeImport = () => {
      emit("onClose");
    };

    const launchImport = async () => {
      const projectId = state.project.id,
        jiraFilterId = state.filterValue;
      try {
        state.backendErrors = [];
        await store.dispatch("wbs/launchImport", {
          projectId,
          jiraFilterId,
          importNewIssues: state.isImportNewIssues,
        });
        emit("onSubmit", true);
      } catch (error) {
        state.backendErrors = errorToList(error);
      }
    };

    const getFilterList = async (params = { perPage: 10, search: "" }) => {
      await store.dispatch("wbs/getFilterList", params);
    };

    const resetFilter = () => {
      state.filterValue = null;
      getFilterList();
    };

    const loadingSearch = ref(false);
    const debounceSearch = debounce(async (inputEvent) => {
      loadingSearch.value = true;
      await getFilterList({
        perPage: 5,
        search: inputEvent.target.value,
      });
      loadingSearch.value = false;
    }, 500);

    const handleInput = async (inputEvent) => {
      debounceSearch(inputEvent);
    };

    onMounted(async () => {
      await getFilterList();
      await store.dispatch("wbs/getImport", state.project.id);
      state.filterValue = store.getters["wbs/wbsState"].jiraFilterId || null;
    });
    return {
      ...toRefs(state),
      closeImport,
      launchImport,
      handleInput,
      loadingSearch,
      getFilterList,
      resetFilter,
    };
  },
};
</script>
<style lang="scss" scoped>
.import {
  background: white;
  width: 560px;
  position: relative;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(54, 54, 54, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  .popup-content {
    background: #ffffff;
    border: 3px solid rgba(37, 132, 255, 0.15);
    border-radius: 16px;
    padding: 15px;
    transform: translateY(-150px);
  }
}
.header {
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  /* identical to box height, or 34px */
  height: 40px;
  display: flex;
  align-items: center;

  justify-content: space-between;

  color: #363636;
  margin-bottom: 15px;
  .close {
    cursor: pointer;
  }
}

.import-container {
  .text {
    grid-area: Text;
  }
  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 17px */

    display: flex;
    align-items: center;

    /* #A1A9BA */
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #a1a9ba;
    margin-bottom: 10px;
  }
  .new-issues {
    grid-area: NewIssueCheckBox;
    label {
      justify-content: flex-start;
    }
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  align-items: center;
  grid-template-areas:
    "Text Text"
    "NewIssueCheckBox NewIssueCheckBox"
    "Filter Filter"
    "ImportBtn ImportBtn";
  .filter-field {
    grid-area: Filter;
  }
  ::v-deep(.vue-select.disabled) {
    opacity: 0.5;
    * {
      cursor: default;
    }
    .clear-search {
      pointer-events: none;
    }
  }
  .actions {
    width: 350px;
    justify-self: end;
    grid-area: ImportBtn;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 15px;
  }
  margin-bottom: 10px;
}
hr {
  height: 1px;
  border: 0;
  margin: 0;
  background: #f2f5f9;
}
.ticket-list {
  ul {
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow: auto;
  }
  li {
    display: grid;
    grid-template-columns: minmax(110px, max-content) 1fr minmax(110px, max-content);
    align-items: center;
    height: 60px;
    justify-content: space-between;
    .ticket-numb {
      display: grid;
      align-items: center;
      grid-auto-flow: column;
      img {
        width: 16px;
        height: 16px;
      }
      a {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        text-decoration: none;
        color: #2684fe;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .ticket-description {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;

      color: #7b818e;
    }
    .ticket-status {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      /* identical to box height */

      /* #363636 */

      color: #363636;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 15px;

      background: rgba(195, 202, 217, 0.2);
      border-radius: 4px;
    }
  }
  li {
    border-bottom: 1px solid #f2f5f9;
  }
}
.error-list {
  margin-top: 15px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    color: red;
  }
}
.arrow-download {
  border-left: 1px solid #c3cad9;
  width: 54px;
  height: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAICAYAAADJEc7MAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACCSURBVHgBjdA7DoAgDAZgHgmzM5NH6MJBPClHMM4McgQn4ujOgPyJJsYUQpeW0i80CFGDiCYxGHV2RlYojDG7c44GENXZFQ+pGONRSlmklL6HH+QxW82l0UwpndbaDRgZ5xYKIUT09HvZwhxCyP9KWBcYgzlnwSEWfjFqDnUDuPdZN5KiUuIFuIyJAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transition: 0.5s;
  }
}
.clear-search {
  position: relative;
  top: 2px;
}
</style>
