<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 11.25L13.4993 13.5H15.75V15H13.4993L13.5 17.25H12L11.9992 15H9.75V13.5H11.9992L12 11.25H13.5ZM8.25 13.5V15H2.25V13.5H8.25ZM15.75 8.25V9.75H2.25V8.25H15.75ZM15.75 3V4.5H2.25V3H15.75Z"
      fill="#136AFD" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
