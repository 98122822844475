import { computed } from 'vue';
export function useSubtaskNodes(store, nodes = []) {
  const subtaskNodes = computed(() => {
    const hierarchy = store.getters["settings/hierarchy"];
    if (!hierarchy.length || !nodes.length) return;
    if (!hierarchy.filter((n) => n.default == true).length) return;
    let subtask = hierarchy
      .filter((n) => n.default == true)
      .map((n) => {
        return n.issueTypes.filter((i) => i.subtask == 1);
      })
      .find((i) => i.length > 0);

    if (!subtask) return [];

    subtask = subtask.map((item) => {
      return item.jiraIssueTypeId;
    });

    const result = nodes.filter((n) =>
      subtask.includes(n.jiraIssueType.jiraIssueTypeId)
    );

    return result.length ? result.map((n) => n.id) : [];
  });

  return {
    subtaskNodes
  }
}