<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.25 0.666687V2.50002H16.3333L11.75 9.37502V17.1667H6.25V9.37502L1.66667 2.50002H0.75V0.666687H17.25ZM3.87033 2.50002L8.08333 8.81952V15.3334H9.91667V8.81952L14.1297 2.50002H3.87033Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
      default: "#23252A",
    },
  },
};
</script>
