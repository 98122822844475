<template>
  <svg :width="width" :height="height" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.58335 0.833313H14.4167C14.6598 0.833313 14.893 0.92989 15.0649 1.1018C15.2368 1.27371 15.3334 1.50686 15.3334 1.74998V19.2977C15.3335 19.3797 15.3116 19.4602 15.27 19.5308C15.2284 19.6014 15.1687 19.6596 15.097 19.6993C15.0253 19.739 14.9442 19.7587 14.8623 19.7565C14.7804 19.7542 14.7006 19.73 14.6312 19.6864L8.00002 15.5275L1.36885 19.6855C1.29952 19.729 1.21979 19.7532 1.13795 19.7555C1.0561 19.7578 0.975133 19.7382 0.903457 19.6986C0.831782 19.659 0.772018 19.601 0.73038 19.5305C0.688741 19.46 0.666748 19.3796 0.666687 19.2977V1.74998C0.666687 1.50686 0.763264 1.27371 0.935173 1.1018C1.10708 0.92989 1.34024 0.833313 1.58335 0.833313ZM13.5 2.66665H2.50002V16.8126L8.00002 13.3651L13.5 16.8126V2.66665Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
