<template>
  <label class="checkbox">
    <input type="checkbox" v-model="checked" @change="onChange" />
    <div class="icon">
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 5.00001L4.33333 8.33334L11 1.66667"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <slot />
  </label>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
export default {
  name: "CheckBoxComponent",
  props: {
    modelValue: Boolean,
    isChecked: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const state = reactive({
      checked: props.modelValue,
    });

    watch(
      () => props.modelValue,
      (current) => {
        state.checked = current;
      }
    );

    const onChange = () => {
      emit("update:modelValue", state.checked);
    };

    return {
      ...toRefs(state),
      onChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.checkbox[disabled="true"] {
  opacity: 0.5;
  pointer-events: none;
}
.checkbox {
  input {
    display: none;
  }
  display: inline-flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 200%;
  text-align: center;
  color: #363636;
  .icon {
    border-radius: 5px;
    width: 20px;
    height: 20px;
    background: #f2f5f9;
    border: 1px solid #0f8af9;
    display: block;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    svg {
      position: absolute;
      left: 3px;
      top: 3px;
      display: none;
    }
  }
  input:checked ~ .icon {
    background: #0f8af9;
  }
  input:checked ~ .icon svg {
    display: block;
  }
}
</style>
