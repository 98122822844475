import * as htmlToImage from "html-to-image";
//import { jsPDF } from "jspdf";
export function usePrintHelper() {

  const printToPng = (querySelector) => {
    return new Promise((resolve, reject) => {
      const canvasEl = document.querySelector(querySelector);
      htmlToImage
        .toPng(canvasEl)
        .then(function (dataUrl) {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.style.display = "none";
          link.setAttribute("download", "wbs-screen");
          link.textContent = "download";
          document.body.appendChild(link);
          link.click();
          link.remove();
          resolve(true)
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
          reject({
            name: "Export Error",
            text: "Export Error",
          })
        });
    })
  }

  const printToPdf = (querySelector, print = true, title = '') => {
    return new Promise((resolve, reject) => {
      const canvasEl = document.querySelector(querySelector);
      htmlToImage
        .toCanvas(canvasEl)
        .then(function (canvas) {
          let imgData = canvas.toDataURL("image/png"),
            imgWidth = 210,
            pageHeight = 295,
            imgHeight = (canvas.height * imgWidth) / canvas.width,
            heightLeft = imgHeight,
            doc = new jsPDF("p", "mm"),
            position = 5; // give some top padding to first page
          doc.setFontSize(10);
          doc.text(title, 1, 4);
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position += heightLeft - imgHeight; // top padding for other pages
            doc.addPage();
            doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }

          if (print) {
            doc.autoPrint({ variant: "non-conform" });
            doc.save("wbs-screen-print");
          } else doc.save("wbs-screen");
          resolve(true);
        })
        .catch(function (error) {
          reject(error)
        });
    })
  }
  return { printToPng, printToPdf }
}