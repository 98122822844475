<template>
  <svg :width="width" :height="height" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.16669 8.16669V4.83335H9.83335V8.16669H13.1667V9.83335H9.83335V13.1667H8.16669V9.83335H4.83335V8.16669H8.16669ZM9.00002 17.3334C4.39752 17.3334 0.666687 13.6025 0.666687 9.00002C0.666687 4.39752 4.39752 0.666687 9.00002 0.666687C13.6025 0.666687 17.3334 4.39752 17.3334 9.00002C17.3334 13.6025 13.6025 17.3334 9.00002 17.3334ZM9.00002 15.6667C10.7681 15.6667 12.4638 14.9643 13.7141 13.7141C14.9643 12.4638 15.6667 10.7681 15.6667 9.00002C15.6667 7.23191 14.9643 5.53622 13.7141 4.28598C12.4638 3.03573 10.7681 2.33335 9.00002 2.33335C7.23191 2.33335 5.53622 3.03573 4.28598 4.28598C3.03573 5.53622 2.33335 7.23191 2.33335 9.00002C2.33335 10.7681 3.03573 12.4638 4.28598 13.7141C5.53622 14.9643 7.23191 15.6667 9.00002 15.6667Z"
      fill="#A0A5B0" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "none",
    },
    stroke: {
      type: String,
      default: "white",
    },
  },
};
</script>
