<template>
  <div class="table-container">
    <div ref="tableEl" class="table">
      <!-- Table Header -->
      <div class="table-header">
        <slot name="header">
          <div class="icon">
            <slot name="icon" />
          </div>
          <div class="columns">
            <div class="column" v-for="(item, index) in header" :key="index">
              <span v-html="item" />
            </div>
          </div>
        </slot>
      </div>
      <!-- Table Body -->
      <div class="table-body">
        <!-- Slot for the row -->
        <slot name="row" />
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  props: {
    header: {
      type: [Array],
      default: [],
    },
  },
  emits: ["onChangeFilter"],
  components: {},
  setup(props, { emit }) {
    const changeFilter = (filterName) => {
      emit("onChangeFilter", filterName);
    };
    const resetFilters = (e) => {
      emit("onChangeFilter", "");
      e.preventDefault();
    };
    const tableEl = ref();

    return {
      changeFilter,
      resetFilters,
      tableEl,
    };
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  position: relative;
}

.table-header {
  display: flex;
  align-items: center;
  .icon {
    margin-right: 15px;
  }
  .columns {
    width: 100%;
    display: grid;
    height: 50px;
    align-items: center;
    grid-column-gap: 0;
    grid-template-columns: 1fr repeat(3, minmax(45px, 146px));
    @media (max-width: 1400px) {
      grid-template-columns: 1fr repeat(3, minmax(45px, 120px));
      grid-column-gap: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .column {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    /* #363636 */

    color: #363636;

    span ::v-deep(strong) {
      font-weight: 500;
      display: block;
      width: 100%;
      clear: both;
      color: #363636;
    }
  }

  @media (max-width: 1400px) {
    padding-left: 15px;
    padding-right: 15px;
    .column {
      font-size: 12px;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
