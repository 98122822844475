<template>
  <svg :width="width" :height="height" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.75 12.75L0.750001 9H3V0H4.5V9H6.75L3.75 12.75ZM7.5 1.5V0H15.75V1.5H7.5ZM7.5 6.75V5.25H15.75V6.75H7.5ZM9 12V10.5H15.75V12H9Z"
      fill="#23252A" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
