<template>
  <div class="share-btn-container" tabindex="0" v-click-outside="(e) => clickOutSide(e)">
    <button type="button" class="btn-share" @click="openMenu">
      <icon-share width="12px" height="13px" />
      <div class="title">Share</div>
      <div class="arrow">
        <icon-arrow-down-list width="8px" height="5px" :fill="'#3E5571'" />
      </div>
    </button>
    <ul class="actions" :class="{ 'is-show': isShowMenu }">
      <li v-if="id" @click="copyLink">
        <div class="icon">
          <icon-share-item width="18px" height="18px" stroke="#2684FE" fill="transparent" />
        </div>
        Copy Link
      </li>
      <li v-if="['projects'].includes(source)" @click="emit('getReportLink', 'google-sheets');closeMenu();">
        <div class="icon">
          <app-google-report-link />
        </div>
        Google Sheet
      </li>
      <li v-if="['projects'].includes(source)" @click="emit('getReportLink', 'excel');closeMenu();">
        <div class="icon">
          <icon-excel />
        </div>
        Excel
      </li>
    </ul>
  </div>
</template>
<script>

import IconShare from "@/components/icons/IconShare";
import IconShareItem from "@/components/icons/share.vue";
import IconArrowDownList from "@/components/icons/arrowDownList.vue";
import IconExcel from "@/components/icons/IconExcel.vue";
import IconCsvFile from "@/components/icons/IconCSVFile.vue";
import AppGoogleReportLink from "@/components/icons/GoogleReportLink";
import { createToaster } from "@meforma/vue-toaster";
import { reactive, onMounted, onUnmounted, toRefs } from "vue";
const toaster = createToaster({ position: "top-right" });
export default {
  components: {
    IconShare,
    IconArrowDownList,
    IconShareItem,
    IconExcel,
    IconCsvFile,
    AppGoogleReportLink,
  },
  props: {
    shareType: {
      requied: true,
    },
    id: {
      required: false,
    },
    source: {
      type: String,
      default: 'projects'
    }
  },
  setup(props, { emit }) {
    const data = reactive({
      copyUrl: false,
      isShowMenu: false,
    });
    const copyToClipboard = () => {
      AP.getLocation(function (location) {
        const input = document.createElement("input");
        const paramName = props.source == "templates" ? "wbsTemplate" : "wbsProject";
        location =
          location.replace(/&wbsTemplate+.+/, "").replace(/\?wbsTemplate+.+/, "").replace(/&wbsProject+.+/, "").replace(/\?wbsProject+.+/, "");

        input.setAttribute("value", location + (location.indexOf('?') !== -1 ? '&' : '?') + `${paramName}=` + btoa(props.id));
        document.body.appendChild(input);
        input.select();
        data.copyUrl = document.execCommand("copy");
        document.body.removeChild(input);
      });
    };
    const copyLink = async () => {
      let message = "Copied";
      try {
        data.copyUrl = await copyToClipboard();
        toaster.success(
          message,
          {
            position: "top-right",
          }
        );
      } catch (error) {
        toaster.success(
          'Copy to clipboard error',
          {
            position: "top-right",
          }
        );
      }
      closeMenu();
    }
    const openMenu = () => {
      data.isShowMenu = !data.isShowMenu;
    }
    const closeMenu = (e) => {
      data.isShowMenu = false;
    }
    const clickOutSide = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("share-btn-container") ||
              p.classList.contains("btn-share"))
          );
        })
      )
        return;

      closeMenu();
    }
    const onkeydown = (e) => {
      if (e.key == "Escape") {
        closeMenu();
      }
    }
    onMounted(() => {
      document.addEventListener("keydown", onkeydown);
    })

    onUnmounted(() => {
      document.removeEventListener("keydown", onkeydown);
    })
    return {
      openMenu,
      closeMenu,
      copyLink,
      clickOutSide,
      emit,
      ...toRefs(data),
    }
  }
};
</script>

<style scoped lang="scss">
.share-btn-container {
  position: relative;
}

.btn-share {
  border: 0;
  outline: none;
  height: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  border-radius: 4px;
  background: #3e55711b;

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #3e5571;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 165px;
  background: #ffffff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: none;
  z-index: 3;

  &.is-show {
    display: block;
  }

  li {
    padding: 8px 15px;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    gap: 15px;
    color: #3e5571;
    cursor: pointer;

    &:hover {
      background: #f1f3f7;
      border-radius: 10px;
    }
  }
}

.arrow {
  display: flex;
  align-items: center;
}
</style>
