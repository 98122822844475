import axios from 'axios';
import * as types from '@/store/mutation-types';
import errorToObject from "@/helpers/errorToObject";
export default {
  getSession({ commit }, params = { perPage: 10, search: '' }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`https://wbs-json-server.jira-helper.com/session`)        
        .then((response) => {
          commit(types.SET_SESSION, { data: response.data.data, meta: response.data.meta });
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
};
