<template>
  <g @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
    <path
      :d="config.treeView == 'horizontal' ? dAttrHorizontal : dAttrVertical"
      :style="pathStyle"
    ></path>
    <template v-if="showDirectionArrow">
      <a v-if="showDelete" @click="deleteLink">
        <text text-anchor="middle" :transform="arrowTransform" font-size="22"
          >&times;</text
        >
      </a>
      <path
        v-else
        d="M -1 -1 L 0 1 L 1 -1 z"
        :style="arrowStyle"
        :transform="arrowTransform"
      ></path>
    </template>
  </g>
</template>

<script>
import { computed, inject, reactive, toRefs } from "vue";
export default {
  props: {
    // start point position [x, y]
    start: {
      type: Array,
      default() {
        return [0, 0];
      },
    },
    // end point position [x, y]
    end: {
      type: Array,
      default() {
        return [0, 0];
      },
    },
    fromY: {
      type: Number,
      default: 0,
    },
    toY: {
      type: Number,
      default: 0,
    },
    scale: {
      type: Number,
      default: 1,
    },
    centerX: {
      type: Number,
      default: 0,
    },
    centerY: {
      type: Number,
      default: 0,
    },
    isLine: {
      type: Boolean,
      default: false,
    },
    isCurved: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    id: Number,
    showDelete: {
      type: Boolean,
      default: false,
    },
    showDirectionArrow: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["deleteLink"],
  setup(props, { emit }) {
    const config = inject("treeConfig");
    const data = reactive({
      show: {
        delete: false,
      },
    });
    const pathStyle = computed(() => {
      return {
        stroke: "#2684FE",
        strokeWidth: 1,
        fill: "none",
      };
    });
    const arrowStyle = computed(() => {
      return {
        stroke: "#2684FE",
        strokeWidth: 3.73205,
        fill: "none",
      };
    });

    const arrowTransform = computed(() => {
      const [arrowX, arrowY] = caculateCenterPoint();
      const degree = caculateRotation();
      return `translate(${arrowX}, ${arrowY}) rotate(${degree})`;
    });

    const dAttrHorizontal = computed(() => {
      let cx = props.start[0],
        cy = props.start[1],
        ex = props.end[0],
        ey = props.end[1];
      let x1 = cx + 35 * props.scale,
        y1 = cy,
        x2 = ex - 35 * props.scale,
        y2 = ey;

      if (props.isLast)
        return `M ${cx}, ${cy - 35 * props.scale} C ${x1 - 35 * props.scale}, ${y1}, ${
          x2 + 35 * props.scale
        }, ${y2}, ${ex}, ${ey}`;
      if (props.isCurved)
        return `M ${cx}, ${cy - 35 * props.scale} C ${x1 - 35 * props.scale}, ${y1}, ${
          x2 + 35 * props.scale
        }, ${y2}, ${ex}, ${ey}`;

      if (props.isLine) return `M ${cx} ${cy} l 0 ${ey}`;

      return `M ${cx}, ${cy} C ${x1}, ${y1}, ${x2}, ${y2}, ${ex}, ${ey}`;
    });

    const dAttrVertical = computed(() => {
      let cx = props.start[0],
        cy = props.start[1],
        ex = props.end[0],
        ey = props.end[1];
      let x1 = cx,
        y1 = cy + 35 * props.scale,
        x2 = ex,
        y2 = ey - 35 * props.scale;

      if (props.isLast)
        return `M ${cx - 35 * props.scale}, ${cy} C ${x1}, ${
          y1 - 35 * props.scale
        }, ${x2}, ${y2 + 35 * props.scale}, ${ex}, ${ey}`;
      if (props.isCurved)
        return `M ${cx - 35 * props.scale}, ${cy} C ${x1}, ${
          y1 - 35 * props.scale
        }, ${x2}, ${y2 + 35 * props.scale}, ${ex}, ${ey}`;

      if (props.isLine) return `M ${cx} ${cy} l ${ey} 0`;

      return `M ${cx}, ${cy} C ${x1}, ${y1}, ${x2}, ${y2}, ${ex}, ${ey}`;
    });

    const handleMouseOver = () => {
      if (props.id) {
        data.show.delete = true;
      }
    };
    const handleMouseLeave = () => {
      data.show.delete = false;
    };

    const caculateCenterPoint = () => {
      // caculate arrow position: the center point between start and end
      const dx = (props.end[0] - props.start[0]) / 2;
      const dy = (props.end[1] - props.start[1]) / 2;
      return [props.start[0] + dx, props.start[1] + dy];
    };

    const caculateRotation = () => {
      // caculate arrow rotation
      const angle = -Math.atan2(
        props.end[0] - props.start[0],
        props.end[1] - props.start[1]
      );
      const degree = (angle * 180) / Math.PI;
      return degree < 0 ? degree + 360 : degree;
    };

    const deleteLink = () => {
      emit("deleteLink");
    };

    return {
      ...toRefs(data),
      pathStyle,
      arrowStyle,
      arrowTransform,
      dAttrHorizontal,
      dAttrVertical,
      handleMouseOver,
      handleMouseLeave,
      caculateCenterPoint,
      caculateRotation,
      deleteLink,
      config,
    };
  },
};
</script>

<style scoped lang="scss">
g {
  cursor: pointer;
}
</style>
