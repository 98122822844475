<template>
  <svg
    :opacity="opacity"
    :width="width"
    :height="height"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M28 0L0 28V8C0 3.58172 3.58172 0 8 0H28Z" :fill="fill" />
    <path
      d="M10.3281 9.38525H6.93896V12.1567H10.876V13H5.90771V5.17969H10.8223V6.02832H6.93896V8.54199H10.3281V9.38525Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "transparent",
    },
    stroke: {
      type: [String],
      default: "white",
    },
  },
};
</script>
