import axios from 'axios';
import * as types from '@/store/mutation-types';
import objectToFormData from '@/helpers/objectToFormData';
import errorToObject from "@/helpers/errorToObject";
export default {
  getUserList({ commit, dispatch }, params = { perPage: 5, search: '' }) {
    return new Promise((resolve, reject) => {
      dispatch('users/getUsers', params, { root: true })
        .then((response) => {
          const data = response.data;
          commit(types.SET_USER_LIST, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  getTemplates({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      axios      
        .get(`/templates?${params._filter || ''}&sort=${params._sort || ''}&search=${params.phrase || ''}&page=${params?._page || 1}&perPage=${params?._limit || 7}`)
        .then((response) => {
          const data = response.data;
          commit(types.SET_TEMPLATES, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  searchTemplates({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/templates?${params._filter || ''}&sort=${params._sort || ''}&search=${params.search || ''}&page=${params?._page || 1}&perPage=${params?.perPage || 7}`)
        .then((response) => {
          const data = response.data;
          commit(types.SET_SEARCH_TEMPLATES, data);
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  removeTemplate({ commit }, templateId) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/templates/${templateId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  addTemplate({ commit }, payload) {
    const formData = objectToFormData(payload);
    return new Promise((resolve, reject) => {
      axios
        .post(`/templates`, formData, {
          'Content-Type': 'multipart/form-data'
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
  editTemplate({ commit }, payload) {
    const formData = objectToFormData(payload);
    formData.append('_method', 'PUT');
    return new Promise((resolve, reject) => {
      axios
        .post(`/templates/${payload.id}`, formData, {
          'Content-Type': 'multipart/form-data'
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  },
};
