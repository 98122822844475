<template>
  <div class="dashboard-start">
    <div class="title-intro">
      <div class="title">
        {{ translate("dashboard.welcome") }}<br />
        <span>{{ translate("dashboard.app_name") }}</span>
      </div>
    </div>
    <div class="steps">
      <h2 class="steps-title">{{ translate("dashboard.steps_title") }}</h2>
      <div class="video-placeholder">
        <a
          href="https://aneto.io/anetos-work-breakdown-structure-tutorial/"
          target="_blank"
        >
          <img src="@/assets/images/video_placeholder.png" alt="" />
        </a>
      </div>
    </div>
    <div class="buttons">
      <app-button @click="openDashboard" color="primary">{{
        translate("user_access.close")
      }}</app-button>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import IconDownload from "../icons/download";
import IconArrow from "@/components/icons/IconArrow";
import AppButton from "@/components/shared/Button";
import * as types from "@/store/mutation-types";
export default {
  components: {
    IconDownload,
    IconArrow,
    AppButton,
  },
  inject: ["translate"],
  setup() {
    const store = useStore(),
      showIntroScreen = computed(() => store.getters["profile/showMainIntroScreen"]),
      isLoading = computed(() => store.getters["loader/isLoading"]);
    const showWelcomeScreen = async () => {
      if (!showIntroScreen.value) {
        router.push({ name: "dashboard" });
      }
    };
    const openDashboard = async () => {
      store.commit(`profile/${types.SET_SHOW_MAIN_INTRO}`, false);
      router.push({ name: "dashboard" });
    };
    showWelcomeScreen();
    return {
      openDashboard,
      isLoading,
    };
  },
};
</script>
<style lang="scss" scoped>
.dashboard-start {
  background-color: #fff;
  border-radius: 15px;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 35px;
  padding-bottom: 25px;
  min-height: 740px;
  .wrapper {
    max-width: 790px;
  }
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 125%;
    /* or 45px */

    text-align: center;

    /* #363636 */

    color: #363636;
    span {
      font-weight: 300;
    }
  }
  .intro-image {
    margin-top: 15px;
    text-align: center;
    max-width: 630px;
    margin: 0 auto;
    img {
      width: 100%;
      @media (max-width: 1200px) {
        max-width: 730px;
      }
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 125%;
      text-align: center;
      @media (max-width: 1200px) {
        font-size: 18px;
        line-height: 125%;
      }
    }
    &-list {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(3, minmax(240px, 1fr));
      width: 100%;
    }
    .step {
      min-height: 120px;
      background: #e7f3fe;
      border-radius: 8px;
      display: grid;
      grid-template-columns: 1fr 100px;
      grid-column-gap: 10px;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      padding: 0 15px;
      text-decoration: none;
      color: #363636;
      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 21px;
      }
      .intro {
        font-size: 14px;
        line-height: 19px;
        grid-area: 2 / 1 / 2 / 3;
      }
      .square {
        min-width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #ffffff;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        .arrow {
          width: 7px;
          transform: rotate(180deg);
        }
      }
      &:hover {
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
      }
    }
  }
  .buttons {
    width: 155px;
    margin: 0 auto;
    margin-top: 30px;
  }
  @media (max-width: 1200px) {
    min-height: 685px;
    .steps {
      grid-column-gap: 15px;
    }
  }
  .video-placeholder {
    img {
      max-width: 760px;
      margin: 0 auto;
    }
  }
}
</style>
