export function useCookies() {

  const saveCookie = (name, value, days) => {
    if (window.isTestRun) {
      return;
    }
    if (!config?.isLocal) {
      AP.cookie.save(name, value, days);
      return;
    }
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value) + expires + "; path=/";
  };

  const readCookie = (name) => {
    return new Promise((resolve, reject) => {
      if (!config?.isLocal) {
        AP.cookie.read(name, (value) => {
          resolve(value)
        });
      } else {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) {
            resolve(c.substring(nameEQ.length, c.length));
            return;
          }
        }
        resolve(null);
      }

    })

  }

  const eraseCookie = (name) => {
    if (!config.isLocal) {
      AP.cookie.erase(name);
      return;
    }
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  return {
    saveCookie, readCookie, eraseCookie,
  }
}