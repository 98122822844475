<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.93652 28.236L1.69297e-05 30L1.76402 22.0635C0.602315 19.8906 -0.00369277 17.464 1.69297e-05 15C1.69297e-05 6.7155 6.71552 0 15 0C23.2845 0 30 6.7155 30 15C30 23.2845 23.2845 30 15 30C12.536 30.0037 10.1094 29.3977 7.93652 28.236ZM8.37152 25.0665L9.35102 25.5915C11.0888 26.5202 13.0296 27.0041 15 27C17.3734 27 19.6935 26.2962 21.6669 24.9776C23.6402 23.6591 25.1783 21.7849 26.0866 19.5922C26.9948 17.3995 27.2325 14.9867 26.7694 12.6589C26.3064 10.3311 25.1635 8.19295 23.4853 6.51472C21.8071 4.83649 19.6689 3.6936 17.3411 3.23058C15.0133 2.76755 12.6005 3.00519 10.4078 3.91345C8.2151 4.8217 6.34096 6.35977 5.02238 8.33316C3.7038 10.3065 3.00002 12.6266 3.00002 15C3.00002 17.001 3.48752 18.927 4.41002 20.649L4.93352 21.6285L3.95102 26.049L8.37152 25.0665ZM7.50002 15H10.5C10.5 16.1935 10.9741 17.3381 11.818 18.182C12.662 19.0259 13.8065 19.5 15 19.5C16.1935 19.5 17.3381 19.0259 18.182 18.182C19.0259 17.3381 19.5 16.1935 19.5 15H22.5C22.5 16.9891 21.7098 18.8968 20.3033 20.3033C18.8968 21.7098 16.9891 22.5 15 22.5C13.0109 22.5 11.1032 21.7098 9.69672 20.3033C8.29019 18.8968 7.50002 16.9891 7.50002 15Z"
      fill="#3E5571"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "none",
    },
    stroke: {
      type: String,
      default: "white",
    },
  },
};
</script>
