<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.5 11.0003C31.2956 11.0003 32.0587 11.3164 32.6213 11.879C33.1839 12.4416 33.5 13.2047 33.5 14.0003V20.0003C33.5 20.796 33.1839 21.559 32.6213 22.1217C32.0587 22.6843 31.2956 23.0003 30.5 23.0003H28.907C28.5413 25.9003 27.1299 28.5671 24.9376 30.5003C22.7453 32.4336 19.9229 33.5003 17 33.5003V30.5003C19.3869 30.5003 21.6761 29.5521 23.364 27.8643C25.0518 26.1765 26 23.8873 26 21.5003V12.5003C26 10.1134 25.0518 7.82421 23.364 6.13638C21.6761 4.44855 19.3869 3.50034 17 3.50034C14.6131 3.50034 12.3239 4.44855 10.636 6.13638C8.94821 7.82421 8 10.1134 8 12.5003V23.0003H3.5C2.70435 23.0003 1.94129 22.6843 1.37868 22.1217C0.816071 21.559 0.5 20.796 0.5 20.0003V14.0003C0.5 13.2047 0.816071 12.4416 1.37868 11.879C1.94129 11.3164 2.70435 11.0003 3.5 11.0003H5.093C5.45905 8.10068 6.87062 5.43425 9.06286 3.50135C11.2551 1.56846 14.0773 0.501953 17 0.501953C19.9227 0.501953 22.7449 1.56846 24.9371 3.50135C27.1294 5.43425 28.541 8.10068 28.907 11.0003H30.5ZM10.64 22.6778L12.23 20.1338C13.6596 21.0294 15.313 21.5031 17 21.5003C18.687 21.5031 20.3404 21.0294 21.77 20.1338L23.36 22.6778C21.4539 23.8721 19.2493 24.5038 17 24.5003C14.7507 24.5038 12.5461 23.8721 10.64 22.6778Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "none",
    },
    stroke: {
      type: String,
      default: "white",
    },
  },
};
</script>
