<template>
  <div class="tab" v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
import { watch, inject, ref, onBeforeMount } from "vue";
export default {
  props: {
    title: {
      type: String,
      default: "Tab",
    },
  },
  setup() {
    let { selectedIndex, tabsCount } = inject("TabsProvider");
    const isActive = ref(false),
      index = ref(0);

    watch(
      () => selectedIndex.value,
      (current) => {
        isActive.value = current === index.value;
      }
    );

    onBeforeMount(() => {
      index.value = tabsCount.value;
      tabsCount.value++;
      isActive.value = index.value === selectedIndex.value;
    });

    return {
      isActive,
      index,
    };
  },
};
</script>

<style lang="scss" scoped></style>
