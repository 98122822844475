<template>
  <svg
    :opacity="opacity"
    :width="width"
    :height="height"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.266 17.998H1.73301C1.55747 17.998 1.38503 17.9518 1.23302 17.864C1.081 17.7762 0.954766 17.65 0.867 17.498C0.779235 17.346 0.733031 17.1735 0.733032 16.998C0.733033 16.8224 0.77924 16.65 0.867007 16.498L10.133 0.497984C10.2209 0.346445 10.3471 0.220659 10.4989 0.133219C10.6507 0.0457798 10.8228 -0.000244141 10.998 -0.000244141C11.1732 -0.000244141 11.3453 0.0457798 11.4971 0.133219C11.6489 0.220659 11.7751 0.346445 11.863 0.497984L21.13 16.498C21.2177 16.6499 21.2639 16.8223 21.264 16.9977C21.264 17.1732 21.2179 17.3456 21.1303 17.4975C21.0426 17.6495 20.9165 17.7758 20.7646 17.8636C20.6128 17.9515 20.4405 17.9978 20.265 17.998H20.266ZM10 12.998V14.998H11.998V12.998H10ZM10 5.99798V10.998H12V5.99798H10Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "transparent",
    },
    stroke: {
      type: [String],
      default: "white",
    },
  },
};
</script>
