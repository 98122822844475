<template>
  <div class="node-content">
    <div class="icon">
      <slot name="icon" />
    </div>
    <div class="split"></div>
    <div class="name">
      <slot name="name" />
    </div>
    <div class="menu" :class="{ open: isOpenMenu }" v-click-outside="(e) => closeMenu()">
      <label> {{ translate("settings.issue_link") }}</label>
      <div class="value">{{ relation }}</div>
    </div>
    <div class="add-cutom-node">
      <slot name="add-custom-node"> </slot>
    </div>
    <div class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from "vue";
import IconArrowDown from "@/components/icons/IconArrowDown.vue";
import * as types from "@/components/settings/types.js";
export default {
  inject: ["translate"],
  emits: ["menuChange"],
  components: { IconArrowDown },
  props: {
    relation: {
      type: String,
      default: types.IS_PARENT_OF,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      isOpenMenu: false,
      listRelation: [types.IS_PARENT_OF, types.IS_CHILD_OF],
    });

    const closeMenu = () => {
      state.isOpenMenu = false;
    };

    const openCloseMenu = () => {
      state.isOpenMenu = !state.isOpenMenu;
    };

    const selectLink = (value) => {
      emit("menuChange", value);
      openCloseMenu();
    };

    return {
      ...toRefs(state),
      openCloseMenu,
      closeMenu,
      selectLink,
    };
  },
};
</script>
<style lang="scss" scoped>
.node-content {
  position: relative;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  display: grid;
grid-template-columns: minmax(161px, max-content) 20px minmax(min-content, 120px) minmax(min-content, 100px) 1fr 50px;
  align-items: center;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #c3cad9;
  border-radius: 4px;
  padding-right: 30px;
  .icon {
    display: flex;
    justify-content: center;
        justify-content: center;
    height: 100%;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .split {
    height: 40px;
    width: 1px;
    background: #c3cad9;
    justify-self: start;
  }
  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #363636;
  }

  .menu {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-column-gap: 10px;
    align-items: center;
    grid-template-areas:
      "Label Icon"
      "Value Icon";
    label {
      display: block;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #a1a9ba;
      grid-area: Label;
    }
    .value {
      font-size: 12px;
      line-height: 18px;
      color: #363636;
      grid-area: Value;
    }
    .icon-menu {
      grid-area: Icon;
      align-self: center;
      cursor: pointer;
      transition: 0.5s;
    }
    &.open {
      ul {
        display: block;
      }
      .icon-menu {
        transform: rotate(180deg);
      }
    }
    ul {
      display: none;
      position: absolute;
      top: 100%;
      z-index: 1;
      width: 180px;
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      overflow: hidden;
      li {
        padding: 10px 20px;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #363636;
        cursor: pointer;
      }
      li.active,
      li:hover {
        background: rgba(38, 132, 254, 0.1);
      }
    }
  }
  .actions {
    height: 100%;
    justify-self: end;
    align-self: center;
    display: flex;
    align-items: center;
    ::v-deep(svg) {
      cursor: pointer;
    }
  }
  .add-cutom-node {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #a1a9ba;
    grid-template-columns: 20px 1fr;
    justify-self: center;
    cursor: pointer;
  }
}
</style>
