<template>
  <div class="welcome-screen" v-show="!isLoading">
    <img class="flower" src="@/assets/images/flower.svg" alt="" />
    <img class="man" src="@/assets/images/man.svg" alt="" />
    <img class="clock" src="@/assets/images/clock.svg" alt="" />
    <div class="wrapper">
      <div class="header-text text-center">
        <h1>{{ translate("app_name") }}<br />{{ translate("user_access.header") }}</h1>
        <p>
          {{ translate("user_access.description1") }}
        </p>
        <p>
          {{ translate("user_access.description2") }}
        </p>
      </div>
      <div class="preview">
        <small></small>
        <img src="@/assets/images/user_access.svg" alt="" />
        <div class="buttons-number">
          <app-button color="purple" @click="openUserAccess">{{
            translate("user_access.close")
          }}</app-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import router from "@/router";
import { useLanguageHelper } from "@/helpers/languageHelper";
import IconAnetoLogo from "@/components/icons/IconAnetoLogo.vue";
import AppButton from "@/components/shared/Button";
import * as types from '@/store/mutation-types';
export default {
  components: {
    IconAnetoLogo,
    AppButton,
  },
  setup() {
    const store = useStore(),
      showUserAccessIntroScreen = computed(
        () => store.getters["profile/showUserAccessIntroScreen"]
      ),
      isLoading = computed(() => store.getters["loader/isLoading"]),
      instance = computed(() => store.getters["profile/instance"]);
    const showWelcomeScreen = () => {
      if (!showUserAccessIntroScreen.value) {
        router.push({ name: "user.access" });
      }
    };
    const openUserAccess = () => {
      store.commit(`profile/${types.SET_SHOW_USER_ACCESS_INTRO}`, false);
      router.push({ name: "user.access" });
    };
    showWelcomeScreen();
    return {
      openUserAccess,
      isLoading,
      ...useLanguageHelper(),
    };
  },
};
</script>
<style lang="scss" scoped>
.welcome-screen {
  background: white;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.clock,
.man,
.flower {
  position: absolute;
}

.flower {
  left: 0;
  top: 0;
}

.man {
  bottom: 45px;
  left: 25px;
}
.clock {
  right: 0;
  bottom: 0;
}

h1 {
  font-weight: 500;
  font-size: 24px;
  line-height: 125%;
}

.wrapper {
  max-width: 620px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 740px;
  @media (max-width: 1200px) {
    min-height: 650px;
  }
}
.header-text {
  margin-top: 32px;
  margin-bottom: 32px;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 200%;
  /* or 26px */
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 10px;
  }
}

.button {
  max-width: 160px;
}
.preview {
  img {
    margin-bottom: 35px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
}
.buttons-number {
  margin-bottom: 35px;
  display: flex;

  width: 165px;
  grid-template-columns: 1fr 40px;
  justify-content: center;
  align-items: center;
  justify-items: center;
  svg {
    margin-left: 5px;
    margin-right: 5px;
    flex-shrink: 0;
  }
  .number {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #727a81;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: white;
    border-radius: 50%;
    text-decoration: underline;
    flex-shrink: 0;
  }
}
@media (max-width: 1200px) {
  .flower {
    left: -78px;
    top: -45px;
    display: none;
  }
  .man {
    width: 150px;
  }
  .clock {
    width: 260px;
    height: 230px;
    bottom: 35px;
  }
}
</style>
