<template>
  <div class="wbs-container" data-testid="wbs-document">
    <app-top-panel
      :isShowFilter="isShowFilter"
      :zoomInd="zoomInd"
      :treeView="treeView"
      :isShowNewNode="!isHasRootNode"
      :projectStatistic="projectStatistic"
      :isFiltered="isFiltered"
      :filteredCount="foundCount"
      :isImportError="isImportError"
      :isImportRun="isImportRun"
      @onAction="onPanelAction"
    >
      <app-project-indicator :estimate="projectStatistic.estimateStr">
        <app-progress-bar
          :projectDoneOriginalEstimateInStoryPoints="
            projectStatistic.projectDoneOriginalEstimateInStoryPoints
          "
          :spent="projectStatistic.projectProgress"
          :overlog="projectStatistic.calcOverLog"
          :remaining="projectStatistic.calcRemaining"
          :isShowInfo="true"
          :projectProgressStr="projectStatistic.projectProgressStr"
          :spentStr="projectStatistic.spentStr"
          :remainingStr="projectStatistic.remainingStr"
          :estimateStr="projectStatistic.estimateStr"
          :background="'#fff'"
        />
      </app-project-indicator>
      <app-filter
        ref="filterComponent"
        :isShow="isShowFilter"
        :isFiltered="isFiltered"
        :total="foundCount"
        @onClose="onCloseFilter"
        @onSumbit="onSubmitFilter"
      />
    </app-top-panel>
    <app-notification :list="notificationList" />
    <div class="wbs-component" ref="wbsContainer">
      <div class="print-info" v-if="printInfo" v-html="printInfo"></div>
      <app-color-picker
        :color="currentNode?.colorNode"
        :scale="state.scale"
        :isOpenPicker="isOpenPicker"
        :nodeHeight="state.nodeHeight"
        :currentNode="currentNode"
        :centerX="state.centerX"
        :centerY="state.centerY"
        :position="dropDownPosition"
        :dependingColor="currentNode?.applyColorToChildren"
        @onCancel="cancelColorPicker"
        @onSubmit="submitColorPicker"
      />
      <app-select-status
        v-if="
          currentDropDown?.name == 'status' && currentNode?.jiraIssueStatusId
        "
        :status="currentNode?.jiraIssueStatusId"
        :position="dropDownPosition"
        :projectId="currentNode?.jiraProject?.id"
        :issueTypeId="currentNode?.jiraIssueType?.id"
        :nodeId="currentNode?.id"
        @onChange="onChangeDropDown"
      />
      <app-select-user
        v-if="currentDropDown?.name == 'assigned'"
        ref="selectUser"
        :userData="currentDropDown.value"
        :position="dropDownPosition"
        @onChange="onChangeDropDown"
      />
      <app-confirmation
        :position="confirmationPosition"
        :showDeleteFromJira="state.source == 'projects' ? true : false"
        @onSubmit="nodeDelete"
        @onCancel="closeConfirmation"
      />
      <app-error-node
        v-if="currentNodeErrors"
        :position="nodeErrorPosition"
        :nodeErrors="currentNodeErrors"
        @onRetry="retryNodeAction"
        @onClose="closeNodeErrorPopup"
      />

      <app-tree-component
        ref="tree"
        :scene="state"
        :defaultLocationX="state.defaultLocationX"
        :defaultLocationY="state.defaultLocationY"
        :treeView="treeView"
        :zoom="zoomInd"
        :isOrphanedCollapsed="isOrphanedCollapsed"
        :allowedDragNodes="allowedDragNodes"
        :filteredNodes="filteredNodes"
        :invisibleFilteredNodes="invisibleFilteredNodes"
        :allowNewParentNode="allowNewParentNode"
        @mousedown="treeMouseDown"
        @onNodeClick="setCurrentNode"
        @onChangeZoom="onChangeZoom"
        @onChangeStructure="onChangeStructure"
        @onChangeOrder="onChangeOrder"
        @onBeforeNodeDelete="onBeforeNodeDelete"
        @onBeforeNodeDeleteOrphaned="onBeforeNodeDeleteOrphaned"
        @nodeDelete="nodeDelete"
        @onScrollingEnd="setWbsState"
        @onCollapsedOrphaned="
          (e) => setWbsState({ orphanedChildrenCollapsed: e })
        "
        @onMoveNode="onMoveNode"
        @onAddNewNode="openNewNodeModal"
        data-testid="tree-view"
      >
        <template v-slot:default="{ node }">
          <app-node-content
            :class="{
              'hover-success': node.isSuccessHover === true,
              'change-order-success': node.isChangeOrder === true,
              'hover-error': node.isSuccessHover === false,
              'accept-new-parent': node.isAccepNewParent,
            }"
            :dragDirection="node?.dragDirection"
            :id="node.id"
            :assignee="node.assignee"
            :summary="node.summary"
            :description="node.description"
            :jiraIssueType="node.jiraIssueType"
            :jiraIssueKey="node.jiraIssueKey"
            :jiraIssueUrl="node.jiraIssueUrl"
            :source="state.source"
            :jiraProject="node.jiraProject"
            :status="node?.jiraIssueStatus?.name"
            :statusColor="node?.jiraIssueStatus?.categoryColor"
            :originalEstimateInHours="node.originalEstimateFormatted"
            :originalEstimateInStoryPoints="node.originalEstimateInStoryPoints"
            :estimationType="wbsState?.estimationType"
            :timeSpent="node.timeSpentFormatted"
            :remainingEstimate="node.remainingEstimateFormatted"
            :aggregateProgress="node.aggregateProgress"
            :codeOfAccounts="node.codeOfAccounts"
            :childrenCount="childrenCount.get(node.id)"
            :isApplyFilters="isFiltered"
            :edited="node.edited"
            :syncedWithJira="node.syncedWithJira"
            :visible="node.visible"
            :showChildren="node.showChildren"
            :colorNode="node.colorNode"
            :isOrphaned="node.isOrphaned"
            :isSubtask="node.subtask"
            :isNotFiltered="filteredNodes.length && !filteredNodes.some((n) => n === node.id)"
            :isUpdate="node.isUpdate"
            :hasError="errorNodes.find((n) => n.id == node.id)"
            :isOrphanedChild="node.isOrphanedChild"
            :nodeWidth="state.nodeWidth"
            :nodeHeight="state.nodeHeight"
            @onAddNewNode="addNewNode"
            @onEditNode="editNode"
            @onChangeColor="openColorPicker"
            @onChangeAssign="(e) => openAssignDropDown(node, e)"
            @onChangeStatus="(e) => openStatusDropDown(node, e)"
            @onShowChildren="(value) => onShowChildren(value, node)"
            @onShowEstimates="openEstimationPopup"
            @onShowLogged="(id) => openEstimationPopup(id, 1)"
            @onClickErrorIcon="(e) => showNodeErrorPopup(e)"
          />
        </template>
      </app-tree-component>
    </div>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  computed,
  onMounted,
  inject,
  watch,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { useCookies } from "@/helpers/cookies";
import router from "@/router";
import { useModalHelper } from "@/helpers/modalHelper";
import { usePositionHelper } from "@/helpers/positionHelper";
import { useDateHelper } from "@/helpers/dateHelper";
import { usePrintHelper } from "@/helpers/printHelper";
import { useAllowedIssueTypes } from "@/helpers/allowedIssueTypes";
import AppTreeComponent from "@/components/treeView/TreeComponent.vue";
import AppNodeContent from "@/components/wbsProject/NodeContent.vue";
import AppColorPicker from "@/components/wbsProject/ColorPicker.vue";
import AppSelectStatus from "@/components/wbsProject/SelectStatus.vue";
import AppSelectUser from "@/components/wbsProject/SelectUser.vue";
import AppConfirmation from "@/components/wbsProject/Confirmation.vue";
import AppErrorNode from "@/components/wbsProject/ErrorNode.vue";
import AppTopPanel from "@/components/wbsProject/TopPanel.vue";
import AppFilter from "@/components/wbsProject/Filter.vue";
import AppProjectIndicator from "@/components/wbsProject/ProjectIndicator.vue";
import AppProgressBar from "@/components/wbsProject/estimation/ProgressBar.vue";
import AppNotification from "@/components/wbsProject/Notification.vue";
import errorToList from "@/helpers/errorToList";
import debounce from "lodash.debounce";
import moment from "moment";
import * as wbsConst from "@/store/modules/wbs/const";
import { useStatistic } from "@/components/wbsProject/modules/statistic";
import { useSubtaskNodes } from "@/components/wbsProject/modules/subtaskNodes";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({ position: "top-right" });
export default {
  components: {
    AppTopPanel,
    AppTreeComponent,
    AppNodeContent,
    AppColorPicker,
    AppSelectStatus,
    AppSelectUser,
    AppConfirmation,
    AppErrorNode,
    AppFilter,
    AppProjectIndicator,
    AppProgressBar,
    AppNotification,
  },
  setup() {
    const { openModal, storeModal } = useModalHelper(),
      { calcElementPosition } = usePositionHelper(),
      translate = inject("translate"),
      store = useStore(),
      tree = ref(null),
      selectUser = ref(),
      currentNode = ref(null),
      isShowFilter = ref(false),
      printInfo = ref(null),
      state = reactive({
        centerX: wbsConst.LocationX,
        centerY: wbsConst.LocationY,
        defaultLocationX: wbsConst.LocationX,
        defaultLocationY: wbsConst.LocationY,
        scale: 1,
        nodeWidth: wbsConst.nodeWidth,
        nodeHeight: wbsConst.nodeHeight,
        nodes: [],
        links: [],
        projectId: null,
        source: computed(() => store.getters["wbs/source"]),
      }),
      backendErrors = ref([]),
      notifications = ref([]),
      notificationList = computed(() => {
        backendErrors.value.forEach((e) => {
          e.type = "error";
        });

        return [...backendErrors.value, ...notifications.value];
      }),
      isImportError = computed(() => {
        return store.getters["wbs/nodesModelMeta"]?.lastImportErrorMessage
          ? true
          : false;
      }),
      wbsState = computed(() => {
        return store.getters["wbs/wbsState"];
      }),
      errorNodes = computed(() => {
        store.getters["wbs/errorNodes"].forEach((item) => {
          const node = state.nodes.find((n) => n.id == item.id);
          if (node) node.isUpdate = false;
        });
        return store.getters["wbs/errorNodes"];
      }),
      currentNodeErrors = ref(null),
      treeView = ref("horizontal"),
      filterComponent = ref("filterComponent"),
      isOrphanedCollapsed = ref(false),
      { parseInput, humanTime } = useDateHelper(),
      { allowedIssueTypes, allowedIssueTypesBefore } = useAllowedIssueTypes(),
      isFiltered = computed(() => {
        let result = false;
        if (wbsState.value.filters) {
          Object.keys(wbsState.value.filters).forEach((k) => {
            if (wbsState.value.filters[k] && wbsState.value.filters[k].length) {
              result = true;
            }
          });
        }
        return result;
      }),
      isHasRootNode = computed(() => {
        const hierarchy = store.getters["settings/hierarchy"];
        if (hierarchy.length)
          return state.nodes.some(
            (n) => n.topNode && n.jiraIssueType.name == hierarchy[0].name
          );
        return false;
      }),
      isHasOrphaned = computed(
        () => state.nodes.filter((n) => !n.parentId && !n.topNode).length > 0
      ),
      subtaskNodes = computed(() => {
        return useSubtaskNodes(store, state.nodes).subtaskNodes.value;
      }),
      projectStatistic = computed(() => {
        return useStatistic().projectStatistic.value;
      }),
      allowNewParentNode = ref(null),
      allowedDragNodes = computed(() => {
        if (currentNode.value && targetMoveNode.value && tree.value) {
          allowNewParentNode.value = null;
          if (currentNode.value?.subtask && targetMoveNode.value?.subtask) {
            return [currentNode.value.id, targetMoveNode.value.id];
          }

          const isOrphaned =
            tree.value.orphanedNodes.length &&
            tree.value.orphanedNodes.some((n) => n.id == currentNode.value.id);
          if (
            isOrphaned &&
            //!targetMoveNode.value.parentId &&
            targetMoveNode.value.topNode &&
            !currentNode.value.parentId
          ) {
            const allowedTypes = allowedIssueTypesBefore(
              targetMoveNode.value.jiraIssueType.name
            );

            if (
              allowedTypes.length &&
              allowedTypes.some(
                (t) => t.name == currentNode.value.jiraIssueType.name
              )
            ) {
              allowNewParentNode.value = targetMoveNode.value.id;
              return [currentNode.value.id];
            }
          }

          if (
            isOrphaned &&
            targetMoveNode.value.topNode &&
            currentNode.value.jiraIssueType.jiraIssueTypeId ==
              targetMoveNode.value.jiraIssueType.jiraIssueTypeId
          ) {
            allowNewParentNode.value = targetMoveNode.value.id;
            return [currentNode.value.id];
          }

          if (
            !isOrphaned &&
            currentNode.value.parentId == targetMoveNode.value.parentId
          ) {
            return [currentNode.value.id, targetMoveNode.value.id];
          }

          if (!currentNode.value?.subtask) {
            const allowedTypes = allowedIssueTypes(
              parseInt(targetMoveNode.value.jiraIssueType.jiraIssueTypeId),
              parseInt(targetMoveNode.value.id)
            ).map((n) => n.id);

            return state.nodes
              .filter((n) => allowedTypes.some((t) => t == n.jiraIssueTypeId))
              .map((n) => n.id);
          }
        }
        return [];
      }),
      customFields = computed(() => {
        const customFileds = store.getters["wbs/nodesMeta"].customFields || [];
        return customFileds.map((f) => {
          return {
            id: f.jiraCustomFieldId,
            name: f.jiraCustomFieldName,
          };
        });
      }),
      filteredNodes = ref([]),
      childrenCount = computed(() => {
        return (
          (tree.value &&
            state.nodes.reduce((acc, node) => {
              acc = acc || new Map();
              let count = 0;
              if (isFiltered.value) {
                const children = tree.value.getChildren(node.id);
                children.forEach((c) => {
                  const node = state.nodes.find((n) => n.id == c.id);
                  if (filteredNodes.value.includes(node.id)) {
                    count++;
                  }
                });
              } else {
                const children = tree.value.getChildren(node.id, false);
                children.forEach((c) => {
                  count++;
                });
              }

              acc.set(node.id, count);
              return acc;
            }, new Map())) ||
          new Map()
        );
      }),
      isImportRun = computed(() => store.getters["wbs/isImportRun"]),
      isExistsImport = computed(() => store.getters["wbs/isExistsImport"]),
      canvasHeight = ref(document.body.scrollHeight + "px");

    const targetMoveNode = ref();
    const onMoveNode = (node) => {
      targetMoveNode.value = node;
    };
    const currentDropDown = ref(null),
      dropDownPosition = ref({
        position: "absolute",
        display: "none",
      }),
      confirmationPosition = ref({
        position: "absolute",
        display: "none",
      }),
      nodeErrorPosition = ref({
        position: "absolute",
        display: "none",
      }),
      wbsContainer = ref(),
      zoomInd = ref(8),
      isOpenPicker = ref(false);

    const zoomIn = () => {
      tree.value.zoomIn();
      setWbsState();
    };

    const zoomReset = () => {
      tree.value.zoomReset();
      setWbsState();
    };

    const zoomOut = () => {
      tree.value.zoomOut();
      setWbsState();
    };

    const bringToCenter = () => {
      tree.value.bringToCenter();
      window.scrollTo(0, 0);
      setWbsState();
    };

    const onShowChildren = async (value, node, showAnimate = true) => {
      tree.value.showChildren(node.id, value, showAnimate);

      const sameLevelNodes = state.nodes.filter(
        (n) => n.parentId == node.parentId
      );
      sameLevelNodes.forEach((n) => {
        n.showChildren = false;
        n.childrenCollapsed = true;
      });

      node.childrenCollapsed = !value;
      node.showChildren = value;
      node.topNodeId = null;

      await store.dispatch("wbs/setChildrenCollapsed", {
        projectId: state.projectId,
        nodeId: node.id,
        viewOption: "wbs",
        childrenCollapsed: node.childrenCollapsed,
      });
    };

    const onChangeZoom = (value) => {
      zoomInd.value = value;
      isOpenPicker.value = false;
      dropDownPosition.value = {
        display: "none",
      };
      closeConfirmation();
      setWbsState();
    };

    let oldNodesColor = [];
    const openColorPicker = ({ id, e }) => {
      isOpenPicker.value = true;
      oldNodesColor = [];
      const node = state.nodes.find((n) => n.id == id);
      currentNode.value = node;
      oldNodesColor.push({ id: node.id, color: node.colorNode });

      const children = tree.value.getChildren(currentNode.value.id);
      children.forEach((child) => {
        const node = state.nodes.find((n) => n.id === child.id);
        oldNodesColor.push({ id: node.id, color: node.colorNode });
      });
      currentDropDown.value = { name: "color-picker" };
      dropDownPosition.value = calcElementPosition({
        e,
        popupWidth: 250,
        container: wbsContainer.value,
      });
    };

    const cancelColorPicker = () => {
      isOpenPicker.value = false;
      if (!currentNode.value || !oldNodesColor.length) return;

      currentNode.value.colorNode = oldNodesColor.find(
        (c) => (c.id = currentNode.value.id)
      ).color;

      const children = tree.value.getChildren(currentNode.value.id);
      children.forEach((child) => {
        const node = state.nodes.find((n) => n.id === child.id);
        node.colorNode = oldNodesColor.find((c) => c.id === child.id).color;
      });
      oldNodesColor.values = [];
    };

    const changeColorPicker = ({ color, backgroundColor, depending }) => {
      if (currentNode.value) {
        currentNode.value.colorNode = backgroundColor + ":" + color;
        currentNode.value.depending = depending;
        currentNode.value.applyColorToChildren = depending;
        if (depending) {
          const children = tree.value.getChildren(currentNode.value.id);
          children.forEach((child) => {
            const node = state.nodes.find((n) => n.id === child.id);
            node.colorNode = backgroundColor + ":" + color;
          });
        } else {
          const children = tree.value.getChildren(currentNode.value.id);
          children.forEach((child) => {
            const node = state.nodes.find((n) => n.id === child.id);
            node.colorNode = oldNodesColor.find(
              (c) => c.id === child.id
            ).color;
          });
        }
      }
    };
    const closeConfirmation = () => {
      confirmationPosition.value = {
        display: "none",
      };
    };

    const closeDropDown = () => {
      if (!currentDropDown.value) return;
      if (currentDropDown.value.name == "status")
        currentDropDown.value.name = "";
      dropDownPosition.value = {
        display: "none",
      };
    };

    const treeMouseDown = () => {
      isOpenPicker.value = false;
      // cancelColorPicker();
      closeDropDown();
      closeConfirmation();
      closeNodeErrorPopup();
    };

    const submitColorPicker = async ({ color, backgroundColor, depending }) => {
      isOpenPicker.value = false;
      currentNode.value.isUpdate = true;
      changeColorPicker({ color, backgroundColor, depending })
      await store.dispatch("wbs/editNode", {
        projectId: state.projectId,
        nodeId: currentNode.value.id,
        node: currentNode.value,
      });
    };

    const setCurrentNode = (id) => {
      const node = state.nodes.find((n) => n.id == id);
      currentNode.value = node;
    };

    const openFilter = () => {
      isShowFilter.value = !isShowFilter.value;
    };

    const onCloseFilter = () => {
      isShowFilter.value = false;
    };

    const onSubmitFilter = () => {
      makeTree();
    };

    const openAssignDropDown = (node, e) => {
      currentNode.value = node;
      currentDropDown.value = { name: "assigned", value: node.assignee };
      dropDownPosition.value = calcElementPosition({
        e,
        popupWidth: 250,
        container: wbsContainer.value,
      });
      // if (selectUser.value) {
      //   selectUser.value.resetUserId();
      // }
    };

    const openStatusDropDown = (node, e) => {
      if (!node) return;
      currentNode.value = node;
      currentDropDown.value = {
        name: "status",
        value: node.jiraIssueStatus.id,
      };
      dropDownPosition.value = calcElementPosition({
        e,
        popupWidth: 250,
        container: wbsContainer.value,
      });
    };

    const onChangeDropDown = async (item) => {
      if (!item) {
        closeDropDown();
        return;
      }

      if (currentDropDown.value.name == "assigned") {
        const node = state.nodes.find((n) => n.id == currentNode.value.id);
        node.assigneeId = item.value == -1 ? null : item.value;
        node.isUpdate = true;
        node.assignee = item.value == -1 ? null : {
          id: item.value,
          displayName: item.displayName,
          iconUri: item.iconUri,
        };
        closeDropDown();
        await store.dispatch("wbs/editNode", {
          projectId: state.projectId,
          nodeId: node.id,
          node,
        });
      }

      if (currentDropDown.value.name == "status") {
        const node = state.nodes.find((n) => n.id == currentNode.value.id);
        node.isUpdate = true;
        if (!item || !item.name) return;
        node.jiraIssueStatusId = item.id;
        node.jiraIssueStatus = {
          id: item.id,
          name: item.name,
        };
        closeDropDown();
        await store.dispatch("wbs/editNode", {
          projectId: state.projectId,
          nodeId: node.id,
          node,
        });
      }
    };
    const onChangeOrder = async ({ node }) => {
      if (!node) return;
      node.isUpdate = true;
      updateTree();
      await store.dispatch("wbs/editNode", {
        projectId: state.projectId,
        nodeId: node.id,
        node,
      });
    };
    const onChangeStructure = async ({
      oldLinks,
      newLinks,
      rootNode = null,
    }) => {

      const moveNode = async (nodeId, node) => {
        node.isUpdate = true;
        updateTree();
        await store.dispatch("wbs/editNode", {
          projectId: state.projectId,
          nodeId,
          node,
        });
      };

      // it means create new root node from orphaned
      if (rootNode) {
        moveNode(rootNode.id, rootNode);
        rootNode.topNodeId = null;
        return;
      }
      if (newLinks.length > oldLinks.length) {
        const link = newLinks[newLinks.length - 1];
        const node = state.nodes.find((n) => n.id == link.to);
        node.parentId = link.from;
        moveNode(link.to, node);
        if (node.parent && tree.value) onShowChildren(true, node.parent, false);
        return;
      }
      for (let i = 0; i < newLinks.length; i++) {
        const link = newLinks[i];
        for (let j = 0; j < oldLinks.length; j++) {
          const oldLink = oldLinks[j];
          if (oldLink.id === link.id) {

            if (oldLink.from !== link.from || oldLink.to !== link.to) {
              const node = state.nodes.find((n) => n.id == link.to);
              node.parentId = link.from;

              if (node.parent && tree.value) {
                await onShowChildren(true, node.parent, false);
              }
              await moveNode(link.to, node);
              // uncomment break for multiple moving
              break;
            }
          }
        }
      }
    };

    const onBeforeNodeDelete = (id, e) => {
      confirmationPosition.value = calcElementPosition({
        e,
        popupWidth: 250,
        container: wbsContainer.value,
      });
    };

    const onBeforeNodeDeleteOrphaned = (id, e) => {
      const node = state.nodes.find((n) => n.id == id);
      if (!node) return;
      currentNode.value = node;
      confirmationPosition.value = calcElementPosition({
        e,
        popupWidth: 250,
        container: wbsContainer.value,
      });
    };

    const nodeDelete = async (deleteFromJira = false) => {
      if (!currentNode.value) return;
      closeConfirmation();
      const nodeList = [];		
			let offerNodes = [];
 
			tree.value.orphanedNodes.forEach((c) => {
				const node = state.nodes.find((n) => n.id == c.id);
				if (node) nodeList.push(node);
			});
    
      if (nodeList.length == 1) {
				offerNodes = nodeList;
			} else {
				const hierarchy = store.getters["settings/hierarchy"];
				const issueTypesList = store.getters["settings/issueTypes"];

				const custom = hierarchy
					.filter((n) => n.default == false).map(item => {
						const foundIssue = issueTypesList.find(l => l.name == item.name);
						return foundIssue
					});

				const epic = hierarchy
					.filter((n) => n.default == true && n.name == 'Epic').map(item => {
						const foundIssue = issueTypesList.find(l => l.name == item.name);
						return foundIssue
					});

				const standarts = hierarchy
					.filter((n) => n.default == true)
					.map((n) => {
						return n.issueTypes.filter((i) => i.subtask == 0 && i?.isSelected == true);
					})
					.find((i) => i.length > 0)
					.map((item) => {
						return item;
					});		

				offerNodes = nodeList.filter(n=> custom.some(c=>c.jiraIssueTypeId==n?.jiraIssueType?.jiraIssueTypeId));
				
				if (offerNodes.length == 0) {
					offerNodes = nodeList.filter(n=> epic.some(c=>c.jiraIssueTypeId==n?.jiraIssueType?.jiraIssueTypeId));
				}
				
				if (offerNodes.length == 0) {
					offerNodes = nodeList.filter(n=> standarts.some(c=>c.jiraIssueTypeId==n?.jiraIssueType?.jiraIssueTypeId));
				}				
			}
      if (currentNode.value.topNode && offerNodes.length == 1) {
        let node = state.nodes.find(
							(n) => n.id == currentNode.value.id
						);
					
				if (node) {
          let node = state.nodes.find((n) => n.id == currentNode.value.id);
            node.isUpdate = true;
            const children = tree.value.getChildren(currentNode.value.id);

            children.forEach((c) => {
              const node = state.nodes.find((n) => c.id == n.id);
              node.isUpdate = true;
            });
            tree.value.nodeDelete(currentNode.value.id);
            await store.dispatch("wbs/deleteNode", {
              projectId: state.projectId,
              nodeId: currentNode.value.id,
              deleteFromJira: deleteFromJira,
              nextParent: offerNodes[0].id,
            });
            store.commit("wbs/RESET_CACHED_NODES");
            await getNodes(false);
				}
      }
      else if (currentNode.value.topNode && offerNodes.length > 1) {
        openModal("SelectParentNode", {
          childrenList: offerNodes,
        });
        const unsubscribe = storeModal.subscribe(async (data) => {
          if (!data.type.startsWith("modal")) return;
          if (data.type === "modal/setData") {
            
            closeConfirmation();
            let node = state.nodes.find((n) => n.id == currentNode.value.id);
            node.isUpdate = true;
            const children = tree.value.getChildren(currentNode.value.id);

            children.forEach((c) => {
              const node = state.nodes.find((n) => c.id == n.id);
              node.isUpdate = true;
            });
            tree.value.nodeDelete(currentNode.value.id);            
            await store.dispatch("wbs/deleteNode", {
              projectId: state.projectId,
              nodeId: currentNode.value.id,
              deleteFromJira: deleteFromJira,
              nextParent: data.payload.nodeId,
            });
            store.commit("wbs/RESET_CACHED_NODES");
            await getNodes(false);
          }
          unsubscribe();
        });
      } else {
        closeConfirmation();
        let node = state.nodes.find((n) => n.id == currentNode.value.id);
        if (node) node.isUpdate = true;
        tree.value.nodeDelete(currentNode.value.id);
        try {
          await store.dispatch("wbs/deleteNode", {
            projectId: state.projectId,
            nodeId: currentNode.value.id,
            deleteFromJira: deleteFromJira,
          });
        } catch {
          //tree.value.nodeDelete(currentNode.value.id);
        }
      }
    };

    const updateTree = (orderCodeOfAccounts = false) => {
      let links = [];

      links = state.nodes
        .sort(function (a, b) {
          let codeAccountA = a.codeOfAccounts
            ? a.codeOfAccounts.toString()
            : "";
          let codeAccountB = b.codeOfAccounts
            ? b.codeOfAccounts.toString()
            : "";

          if (codeAccountA && codeAccountB) {
            codeAccountA =
              codeAccountA.indexOf(".") !== -1
                ? codeAccountA.split(".").slice(-1)[0]
                : codeAccountA;

            codeAccountB =
              codeAccountB.indexOf(".") !== -1
                ? codeAccountB.split(".").slice(-1)[0]
                : codeAccountB;
            return codeAccountA - codeAccountB;
          }
        })
        .reduce((acc, node, i) => {
          acc = acc || [];
          if (node.parentId) {
            acc.push({
              id: i,
              from: node.parentId,
              to: node.id,
            });
          }
          return acc;
        }, []);
      state.links = links;

      store.commit("wbs/UPDATE_NODES", state.nodes);
      store.commit("wbs/UPDATE_LINKS", state.links);

      if (tree.value) tree.value.arrangeNodes(orderCodeOfAccounts);
    };

    const addNewNode = async (id) => {
      const node = state.nodes.find((n) => n.id == id);
      if (node) {
        openModal("NewNode", {
          projectId: parseInt(state.projectId),
          jiraIssueTypeId: parseInt(node.jiraIssueType.jiraIssueTypeId),
          jiraProject: node.jiraProject,
          parentNode: node,
          parentId: id ? parseInt(id) : null,
        });
        const parent = state.nodes.find((n) => n.id == id);
        if (parent && tree.value) {
          const isOrphanedParent = tree.value.orphanedNodes.some(
            (n) => n.id == parent.id
          );
          if (!isOrphanedParent) onShowChildren(true, parent, false);
        }
      } else {
        if (!state.links.length) {
          const firstNode = state.nodes.length
            ? state.nodes.find((n) => n.topNode == true)
            : null;

          openModal("NewNode", {
            projectId: parseInt(state.projectId),
            isShowOnlyRootIssueType: !isHasRootNode.value,
            firstNode: isHasRootNode.value == false ? firstNode : null,
          });
        } else {
          const firstNode = state.nodes.find((n) => n.topNode == true);
          openModal("NewNode", {
            projectId: parseInt(state.projectId),
            isShowOnlyRootIssueType: !isHasRootNode.value,
            firstNode: isHasRootNode.value == false ? firstNode : null,
          });
        }
      }

      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          if (data.payload.topNodeId) {
            const firstNode = state.nodes.length
              ? state.nodes.find((n) => n.topNode == true)
              : null;
            if (firstNode) {
              firstNode.topNode = false;
              firstNode.x = 0;
              firstNode.y = 0;
              firstNode.parentId = data.payload.id;
              data.payload.showChildren = true;
              data.payload.childrenCollapsed = false;
              firstNode.visible = true;
            }
          }

          state.nodes.push(data.payload);
          updateTree();
          makeTree();
          setTimeout(() => {
            const target = document.getElementById(data.payload.id);
            if (target) target.scrollIntoView({ block: "end" });
          }, 100);
        }
        unsubscribe();
      });
    };

    const editNode = async (id) => {
      let node = state.nodes.find((n) => n.id == id);
      const parentNode = state.nodes.find((n) => n.id == node.parentId);

      if (node.jiraIssueKey && state.source == "projects") {
        AP.jira.openIssueDialog(
          `${node.jiraIssueKey}`,
          async (jiraIssueKey) => {
            const response = await AP.request(
              `/rest/api/3/issue/${jiraIssueKey}`
            );
            const parsedResponse = JSON.parse(response.body);
            let stotyPointEstimationJira = 0;
            const fieldName = customFields.value.find(
              (f) => f.name == "Story Points"
            );
            if (fieldName) {
              stotyPointEstimationJira =
                parsedResponse.fields[fieldName.id] || 0;
            }
            let originalEstimateJira = parseInput(
              parsedResponse.fields?.timetracking?.originalEstimate
            );
            let originalEstimateNode = parseFloat(
              node?.originalEstimateInHours
            );
            originalEstimateJira = originalEstimateJira
              ? originalEstimateJira.toFixed(2)
              : null;
            originalEstimateNode = originalEstimateNode
              ? originalEstimateNode.toFixed(2)
              : null;

            let remainingEstimateJira = parseInput(
              parsedResponse.fields?.timetracking?.remainingEstimate
            );

            let remainingEstimateNode = parseFloat(
              node?.remainingEstimate
            );

            remainingEstimateJira = remainingEstimateJira
              ? remainingEstimateJira.toFixed(2)
              : null;

            remainingEstimateNode = remainingEstimateNode
              ? remainingEstimateNode.toFixed(2)
              : null;

            let timeSpentNode = parseFloat(node?.timeSpent)
              ? parseFloat(node.timeSpent)
              : null,
              timeSpentJira = parsedResponse.fields?.timetracking?.timeSpentSeconds,
              timeSpentJiraFormatted = parsedResponse.fields?.timetracking?.timeSpent,
              remainingEstimateFormatted = parsedResponse.fields?.timetracking?.remainingEstimate;

            if (
              parsedResponse.fields.summary != node.summary ||
              parsedResponse.fields.status.name != node.jiraIssueStatus.name ||
              parsedResponse.fields.issuetype.name != node.jiraIssueType.name ||
              parsedResponse.fields.assignee?.displayName !=
                node.assignee?.displayName ||
              originalEstimateJira != originalEstimateNode ||
              stotyPointEstimationJira != node.originalEstimateInStoryPoints ||
              timeSpentNode != timeSpentJira
            ) {
              let assignee = null;
              if (parsedResponse.fields.assignee) {
                assignee = {
                  ...parsedResponse.fields.assignee,
                  ...{
                    iconUri: parsedResponse.fields.assignee.avatarUrls["24x24"],
                  },
                };
              }
              const nodeData = {
                projectId: state.projectId,
                nodeId: node.id,
                node: {
                  ...node,
                  ...{
                    descriptionObject: parsedResponse.fields.description,
                    assignee: assignee,
                    originalEstimateFormatted: parsedResponse.fields
                      .timetracking
                      ? parsedResponse.fields.timetracking.originalEstimate
                      : "",
                    assigneeId: parsedResponse.fields.assignee
                      ? parsedResponse.fields.assignee.accountId
                      : null,
                    originalEstimateInHours: parseInput(
                      parsedResponse.fields?.timetracking?.originalEstimate
                    ),
                    jiraIssueStatus: parsedResponse.fields.status,
                    jiraIssueStatusId: parseInt(
                      parsedResponse.fields.status.id
                    ),
                    jiraProjectId: parseInt(parsedResponse.fields.project.id),
                    jiraIssueType: parsedResponse.fields.issuetype,
                    jiraIssueTypeId: parseInt(
                      parsedResponse.fields.issuetype.id
                    ),
                    projectId: state.projectId,
                    jiraPriorityId: parsedResponse.fields.priority.id,
                    originalEstimateInStoryPoints: stotyPointEstimationJira,
                    summary: parsedResponse.fields.summary,
                    timeSpent: timeSpentJira,
                    timeSpentFormatted: timeSpentJiraFormatted,
                    childrenDisplayOption: "wbs",
                    remainingEstimate: parseInput(
                      remainingEstimateFormatted
                    ),
                    remainingEstimateFormatted: remainingEstimateFormatted,
                  },
                },
              };
              Object.keys(node).forEach((k) => {
                if (nodeData.node[k]) {
                  node[k] = nodeData.node[k];
                }
              });
              node.isUpdate = true;
              await store.dispatch("wbs/editNodeWithJiraIds", nodeData);
            }
          }
        );
        return;
      }

      openModal("NewNode", {
        title: node.jiraIssueType.name + "-" + node.id,
        projectId: parseInt(state.projectId),
        jiraIssueTypeId: parseInt(node.jiraIssueType.jiraIssueTypeId),
        jiraProject: node.jiraProject,
        parentId: null,
        currentNode: node,
        parentNode: parentNode,
      });

      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          Object.keys(node).forEach((k) => {
            node[k] = data.payload.node[k];
          });
          node.isUpdate = true;
        }
        unsubscribe();
      });
    };

    const openShareModal = () => {
      openModal("ShareWBS", {
        source: state.source,
        id: parseInt(state.projectId),
        viewStyle: 'displayAsTree',
        treeStyle: treeView.value,
      });
    };

    const openNewNodeModal = () => {
      addNewNode();
    };

    const launchImport = async () => {
      try {
        store.commit("wbs/SET_EXISTS_IMPORT", true);
        await store.dispatch("wbs/launchImport", {
          projectId: state.projectId,
        });
        store.commit("wbs/SET_IMPORT_RUN", true);
      } catch (error) {
        store.commit("wbs/SET_IMPORT_RUN", false);
        backendErrors.value = errorToList(error);
        setTimeout(() => {
          backendErrors.value = [];
        }, 3000);
      }
    };

    const openEstimationPopup = (id, tabIndex = 0) => {
      // disable loggin hours temporary
      if (tabIndex == 1) return;
      const node = state.nodes.find((n) => n.id == id);
      openModal("Estimation", {
        node,
        tabIndex,
        estimationType:
          wbsState.value.estimationType ||
          store.getters["profile/estimationType"],
      });

      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          node.originalEstimateInHours = data.payload?.estimate
            ? parseInput(data.payload.estimate)
            : "";

          node.originalEstimateFormatted = data.payload?.estimate;
          node.remainingEstimate = data.payload?.remaining;
          node.timeSpent = data.payload?.spent;
          node.pertBestCase = data.payload?.pertBestCase;
          node.pertDeviation = data.payload?.pertDeviation;
          node.pertProbableCase = data.payload?.pertProbableCase;
          node.pertWorstCase = data.payload?.pertWorstCase;
          node.originalEstimateInStoryPoints = data.payload?.storyPoint;
          node.isUpdate = true;
          await store.dispatch("wbs/editNode", {
            projectId: state.projectId,
            nodeId: node.id,
            node,
          });
        }

        unsubscribe();
      });
    };

    const getWbsState = async (projectId) => {
      if (Object.keys(store.getters["wbs/wbsState"]).length) return;

      try {
        backendErrors.value = [];
        store.commit("wbs/SET_IMPORT_RUN", false);
        await store.dispatch("wbs/getWbsState", projectId);
        store.commit("wbs/SET_IMPORT_RUN", wbsState.value.activeImport);
      } catch (error) {
        backendErrors.value = errorToList(error);
        setTimeout(() => {
          backendErrors.value = [];
        }, 3000);
      }
    };

    const debouncesetWbsState = debounce(async (data) => {
      try {
        await store.dispatch("wbs/setWbsState", {
          ...wbsState.value,
          ...data,
          ...{
            projectId: state.projectId,
            zoomLevel: zoomInd.value,
            locationX: state.centerX,
            locationY: state.centerY,
            displayTreeOption: treeView.value,
          },
        });
      } catch (error) {
        const list = errorToList(error);
        list.forEach((item) => {
          backendErrors.value.push(item);
        });

        setTimeout(() => {
          backendErrors.value = backendErrors.value.filter(
            (e) => !list.some((l) => l.name == e.name)
          );
        }, 3000);
      }
    }, 500);

    const setWbsState = async (data = {}) => {
      await debouncesetWbsState(data);
    };

    const displayTreeAs = async (mode) => {
      if (!tree.value) return;
      treeView.value = mode;
      await tree.value.changeView(mode);
      setWbsState();
    };

    const savePngWbs = async () => {
      if (!tree.value) return;
      store.commit("wbs/SET_PRINT_RUN", true);
      const { printToPng } = usePrintHelper();

      tree.value.calcHeight();
      tree.value.bringToCenter();
      const oldZoomValue = zoomInd.value;
      tree.value.setZoom(8);
      await displayTreeAs("horizontal");
      printInfo.value =
        "Aneto Work Breakdown Structure - " +
        moment().format("MMM DD, YYYY hh:mm A") +
        "<br>" +
        wbsState.value.name;
      try {
        await printToPng(".wbs-component");
        tree.value.setZoom(oldZoomValue);
        store.commit("wbs/SET_PRINT_RUN", false);
        printInfo.value = null;
      } catch (error) {
        printInfo.value = null;
        const list = [
          {
            name: "Error save to png",
            text: "Error save to png",
            type: "error",
          },
        ];

        backendErrors.value.push(...list);

        setTimeout(() => {
          backendErrors.value = backendErrors.value.filter(
            (e) => !list.some((l) => l.name == e.name)
          );
        }, 3000);
        tree.value.setZoom(oldZoomValue);
        store.commit("wbs/SET_PRINT_RUN", false);
      }
    };

    const savePrintPdfWbs = async (print = false) => {
      if (!tree.value) return;
      store.commit("wbs/SET_PRINT_RUN", true);
      const { printToPdf } = usePrintHelper();
      backendErrors.value = [];
      tree.value.bringToCenter();
      const oldZoomValue = zoomInd.value;
      tree.value.setZoom(8);
      tree.value.calcHeight();
      await displayTreeAs("horizontal");
      try {
        await printToPdf(
          ".wbs-component",
          true,
          store.getters["wbs/wbsState"].name +
            " - Aneto Work Breakdown Structure"
        );
        tree.value.setZoom(oldZoomValue);
        store.commit("wbs/SET_PRINT_RUN", false);
      } catch (error) {
        const list = [
          {
            name: "Error save to pdf",
            text: "Error save to pdf",
            type: "error",
          },
        ];

        backendErrors.value.push(...list);

        setTimeout(() => {
          backendErrors.value = backendErrors.value.filter(
            (e) => !list.some((l) => l.name == e.name)
          );
        }, 3000);
        store.commit("wbs/SET_PRINT_RUN", false);
      }
    };

    const launchSync = async () => {
      try {
        backendErrors.value = [];
        await store.dispatch("wbs/launchSync", wbsState.value.id);
      } catch (error) {
        backendErrors.value = errorToList(error);
        setTimeout(() => {
          backendErrors.value = [];
        }, 3000);
      }
    };

    const createProject = async () => {
      openModal("NewProject", {
        template: wbsState.value,
      });
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          unsubscribe();
          if (data.payload.templateId) {
            router.push({
              name: "wbs-project",
              params: { id: data.payload.id },
            });
          }
        }
        if (data.type === "modal/closeModal") {
          unsubscribe();
        }
      });
    };

    const openEditProject = async () => {      
      openModal("NewProject", {
        template: wbsState.value.template,
        project: {
          ...wbsState.value,
          ...{ lastImportErrorMessage: isImportError.value },
        },
        countNodes: state.nodes.length,
        title: wbsState.value.name,
      });
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          store.commit("wbs/RESET_CACHED_NODES");
          store.commit("wbs/RESET_WBS_STATE");
          loadInstance(router.currentRoute.value.params.id);
          getNodes();
          //runAutoImport();
          unsubscribe();
        }
        if (data.type === "modal/closeModal") {
          unsubscribe();
        }
      });
    };

    const openEditTemplate = async () => {
      openModal("NewTemplate", {
        template: wbsState.value,
        title: wbsState.value.name,
      });
      const unsubscribe = storeModal.subscribe(async (data) => {
        if (!data.type.startsWith("modal")) return;
        if (data.type === "modal/setData") {
          store.commit("wbs/RESET_CACHED_NODES");
          store.commit("wbs/RESET_WBS_STATE");
          loadInstance(router.currentRoute.value.params.id);
          getNodes();
          unsubscribe();
        }
        if (data.type === "modal/closeModal") {
          unsubscribe();
        }
      });
    };

    const onPanelAction = async (action) => {
      const { saveCookie } = useCookies();
      switch (action) {
        case "launchImport":
          launchImport();
          break;
        case "openShareModal":
          openShareModal();
          break;
        case "displayAsList":
          router.push({
            name: state.source.includes("project")
              ? "wbs-project-list-view"
              : "wbs-template-list-view",
            params: {
              id:
                state.projectId ||
                parseInt(router.currentRoute.value.params.id),
            },
          });
          saveCookie(`view-style-${state.projectId}`, "displayAsList");
          break;
        case "openFilter":
          openFilter();
          break;
        case "openNewNodeModal":
          openNewNodeModal();
          break;
        case "launch-synch":
          launchSync();
          break;
        case "createProject":
          createProject();
          break;
        case "editProject":
          openEditProject();
          break;
        case "editTemplate":
          openEditTemplate();
          break;
        case "resetFilter":
          filterComponent.value.resetFilter();
          break;
        case "displaySchedule":					
					router.push({
						name: "wbs-schedule-management",
						params: {
							id:
								state.projectId ||
								parseInt(router.currentRoute.value.params.id),
						},
					});
					saveCookie(
						`view-style-${state.projectId}`,
						"displaySchedule"
					);					
					break;	          
      }
      if (!tree.value) return;
      switch (action) {
        case "zoomOut":
          zoomOut();
          break;
        case "zoomReset":
          zoomReset();
          break;
        case "zoomIn":
          zoomIn();
          break;
        case "bringToCenter":
          bringToCenter();
          break;
        case "displayTreeAsHorizontal":
          displayTreeAs("horizontal");
          break;
        case "displayTreeAsVertical":
          displayTreeAs("vertical");
          break;
        case "save-png":
          await savePngWbs();
          break;
      }
    };

    const socketsOff = () => {
      if (window.isTestRun) {
        return;
      }
      const tenantId = store.getters["profile/tenantId"];
      const projectId = state.projectId;
      Echo.leave("addon." + tenantId + "." + projectId);
      Echo.leave("addon." + tenantId + ".template." + projectId);
      Echo.leave("addon." + tenantId + ".project." + projectId);
      Echo.leave(
        "addon." + tenantId + ".template." + projectId + ".participants"
      );
      Echo.leave(
        "addon." + tenantId + ".project." + projectId + ".participants"
      );
    };

    const socketsOn = (tenantId, projectId) => {
      if (window.isTestRun) {
        return;
      }
      Echo.private("addon." + tenantId + "." + projectId)
        .listen("JiraImportStarted", (response) => {
          notifications.value = [];
          store.commit("wbs/SET_IMPORT_RUN", true);
        })
        .listen("JiraImportCompleted", async (response) => {
          store.commit("wbs/SET_IMPORT_RUN", false);
          store.commit("wbs/RESET_CACHED_NODES");
          const responseNodes = await getNodes(false);
          store.commit("wbs/RESET_WBS_STATE");
          loadInstance(projectId);
          if (responseNodes.meta.modelMeta.lastImportErrorMessage) {
            notifications.value.push({
              name: "Import",
              text: "Import error",
              type: "error",
            });
          } else {
            notifications.value.push({
              name: "Import",
              text: "Import completed successfully",
              type: "success",
            });
            store.commit("wbs/SET_IMPORT_COMPLETE", true);
          }

          setTimeout(() => {
            notifications.value = notifications.value.filter(
              (e) => e.name !== "Import"
            );
          }, 3000);
        });

      const channelKey = router.currentRoute.value.name.includes(
        "wbs-project",
        "wbs-project-list-view"
      )
        ? "project"
        : "template";

      Echo.private(
        "addon." + tenantId + "." + channelKey + "." + projectId
      ).listen("FetchLatestData", async (response) => {
        updateNodes(channelKey);
      });

      if (channelKey === "project") {
        Echo.private(
          "addon." +
            tenantId +
            "." +
            channelKey +
            "." +
            projectId +
            ".participants"
        ).listen("FetchProjectParticipants", (response) => {
          store.dispatch("wbs/getProjectParticipants", state.projectId);
        });
      }
    };

    const updateNodes = async (channelKey) => {
      let lastUpdateDate = store.getters["wbs/nodesMeta"]?.lastUpdateDate;
      if (!lastUpdateDate)
        lastUpdateDate = moment(new Date()).format("Y-MM-d H:m:s");
      const updatingList = await store.dispatch("wbs/latestNodesChanges", {
        channelKey: channelKey,
        modelId: state.projectId,
        lastUpdateDate: lastUpdateDate,
      });

      if (!updatingList.data) return;
      for (let i = 0; i < updatingList.data.length; i++) {
        const updateNode = updatingList.data[i];
        const targetNode = state.nodes.find((n) => n.id == updateNode.id);
        if (targetNode) {
          if (updateNode?.isDeleted == true) {
            state.nodes = state.nodes.filter((n) => n.id !== updateNode.id);
            state.nodes.forEach((n) => {
              if (n.parentId == updateNode.id) n.parentId = null;
            });
          } else {
            Object.keys(updateNode).forEach((k) => {
              targetNode[k] = updateNode[k];
            });

            targetNode.x = 0;
            targetNode.y = 0;
            targetNode.isUpdate = true;
            targetNode.visible = true;

            if (targetNode.syncError) {
              store.commit("wbs/ADD_ERROR_NODE", {
                id: updateNode.id,
                createdNode: updateNode,
                action: "create",
                error: updateNode.syncErrorMessage,
              });
            } else {
              store.commit("wbs/DELETE_ERROR_NODE", updateNode.id);
            }
          }
        } else if (!updateNode?.isDeleted) {
          updateNode.isUpdate = true;
          updateNode.x = 0;
          updateNode.y = 0;
          updateNode.visible = true;
          state.nodes.push(updateNode);
          if (updateNode.syncError) {
            store.commit("wbs/ADD_ERROR_NODE", {
              id: updateNode.id,
              createdNode: updateNode,
              action: "create",
              error: updateNode.syncErrorMessage,
            });
          } else {
            store.commit("wbs/DELETE_ERROR_NODE", updateNode.id);
          }
        }
      }

      updateTree(false);
      makeTree();

      setTimeout(() => {
        state.nodes.forEach((node) => {
          node.isUpdate = false;
        });
      }, 2000);
    };

    const applyState = () => {
      const config = wbsState.value;
      zoomInd.value = parseInt(config.zoomLevel || 8);
      treeView.value = config.displayTreeOption || "horizontal";
      state.centerX = parseInt(config.locationX || 0);
      state.centerY = parseInt(config.locationY || 0);
      isOrphanedCollapsed.value = config.orphanedChildrenCollapsed;
    };

    // project ready
    watch(
      () => wbsState.value,
      () => {
        applyState();
      }
    );

    // set blocked nodes
    watch(
      () => subtaskNodes.value,
      (current) => {
        if (!current || !current.length) return;
        current.forEach((blocked) => {
          state.nodes.forEach((n) => {
            if (n.id == blocked) {
              //n.isDragBlocked = true;
              n.subtask = true;
            }
          });
        });
      }
    );

    const foundCount = ref(0);
    const invisibleFilteredNodes = computed(() => {
      if (isFiltered.value === true && foundCount.value == 0) {
        return state.nodes.map((n) => n.id);
      }
      return (
        (isFiltered.value &&
          state.nodes.reduce((acc, node) => {
            acc = acc || [];
            if (
              !filteredNodes.value.includes(node.id) &&
              childrenCount.value.get(node.id) == 0
            )
              acc.push(node.id);
            return acc;
          }, [])) ||
        []
      );
    });

    const makeTree = () => {
      const filterNodes = (nodes) => {
        filteredNodes.value = [];
        foundCount.value = 0;
        const filters = wbsState.value.filters || {
          projectId: [],
          issueTypeId: [],
          statusId: [],
          assignee: [],
          categoryId: [],
        };

        if (!filters) return [];
        try {
          nodes.forEach((n) => {
            if (
              ((n.jiraIssueStatusId &&
                filters.statusId.includes(n.jiraIssueStatus?.id)) ||
                !filters.statusId.length) &&
              ((n.jiraIssueTypeId &&
                filters.issueTypeId.includes(n.jiraIssueType?.id)) ||
                !filters.issueTypeId.length) &&
              ((n.assigneeId && filters.assignee.includes(n.assignee?.id)) ||
                !filters.assignee.length) &&
              ((n.jiraProjectId &&
                filters.projectId.includes(n.jiraProject?.id)) ||
                !filters.projectId.length) &&
              ((n.jiraIssueStatus?.statusCategory?.id &&
                filters.categoryId.includes(
                  n.jiraIssueStatus?.statusCategory?.id
                )) ||
                !filters.categoryId.length)
            ) {
              filteredNodes.value.push(n.id);
              foundCount.value = filteredNodes.value.length;
            }
          });

          if (filteredNodes.value.length == 0) {
            filteredNodes.value = nodes.map((n) => n);
            foundCount.value = 0;
          }
        } catch {
          return nodes;
        }
      };

      state.nodes = store.getters["wbs/nodes"];
      filterNodes(store.getters["wbs/nodes"]);

      state.links = store.getters["wbs/links"];
      updateTree();
      if (tree.value) tree.value.arrangeNodes();
    };

    const getNodes = async (hideLoader = false) => {
      if (!store.getters["wbs/nodes"].length) {
        const response = await store.dispatch("wbs/getNodes", {
          projectId: state.projectId,
          hideLoader,
        });
        makeTree();
        return response;
      } else {
        store.commit(
          "wbs/UPDATE_NODES",
          store.getters["wbs/cachedNodes"][state.projectId]
        );
      }
      makeTree();
    };

    const getHierarchy = async () => {
      if (!store.getters["settings/hierarchy"].length)
        await store.dispatch("settings/getNodesHierarchy");
    };

    const getIssueTypes = async () => {
      if (!store.getters["settings/issueTypes"].length)
        await store.dispatch("settings/getIssueTypes");
    };

    const loadInstance = async (id) => {
      state.projectId = parseInt(id);
      store.commit("wbs/CLEAR_CACHED_STATUS");
      await getWbsState(state.projectId);
      applyState();
      socketsOff();
      socketsOn(store.getters["profile/tenantId"], state.projectId);

      getHierarchy();
      getIssueTypes();
    };

    const setSessionState = async (value) => {
      if (state.source !== "projects") return;
      if (value == true) {
        if (!store.getters["wbs/projectParticipants"].length)
          await store.dispatch("wbs/addProjectParticipants", state.projectId);
      } else {
        if (store.getters["wbs/projectParticipants"].length && state.projectId)
          await store.dispatch("wbs/deleteProjectParticipant", state.projectId);
      }
    };

    const closeNodeErrorPopup = () => {
      nodeErrorPosition.value = {
        display: "none",
      };
    };

    const showNodeErrorPopup = ({ event, nodeError }) => {
      currentNodeErrors.value = nodeError;
      nodeErrorPosition.value = calcElementPosition({
        e: event,
        popupWidth: 0,
        container: wbsContainer.value,
      });
    };

    const retryNodeAction = async () => {
      if (!currentNode.value) return;
      closeNodeErrorPopup();

      const node = state.nodes.find((n) => n.id == currentNode.value.id);
      node.isUpdate = true;
      const error = errorNodes.value.find((n) => n.id == node.id);
      await store.dispatch("wbs/editNode", {
        projectId: state.projectId,
        nodeId: node.isTemporary ? error.createdNode.id : node.id,
        node: node.isTemporary ? error.createdNode : node,
      });
      node.isUpdate = false;
    };

    const runAutoImport = () => {
      if (isImportRun.value || isExistsImport.value) {
        return;
      } else {
        if (wbsState.value.nodesCount !== 0 || wbsState.value.jiraJql)
          launchImport();
      }
    };

    let unsubscribeAction;
    onMounted(async () => {      
      const projectId = parseInt(router.currentRoute.value?.params?.id);
      let source;
      switch (router.currentRoute.value.name) {
        case "wbs-project":
          store.commit("wbs/SET_WBS_SOURCE", "projects");
          source = "projects";
          break;
        case "wbs-project-list-view":
          store.commit("wbs/SET_WBS_SOURCE", "projects");
          source = "projects";
          break;
        default:
          store.commit("wbs/SET_WBS_SOURCE", "templates");
          source = "templates";
          break;
      }

      unsubscribeAction = store.subscribe((action, state) => {
        if (
          action.type == "wbs/SET_NODES" ||
          action.type == "wbs/UPDATE_NODE"
        ) {
          makeTree();
        }
        if (action.type == "wbs/UPDATE_NODE") {
          if (action.payload.createdNode) {
            const createdNode = action.payload.createdNode;
            toaster.success(
              `Issue ${createdNode.jiraIssueKey} - ${createdNode.summary} has been successfuly created`,
              {
                position: "top-right",
              }
            );
          }
        }
      });

      const { readCookie } = useCookies();
      const viewStyle = await readCookie(`view-style-${parseInt(projectId)}`);

      if (!window?.isTestRun && (!viewStyle || viewStyle == "displayAsList")) {
        onPanelAction("displayAsList");
        return;
      }

      await loadInstance(projectId);
      await getNodes();

      document.body.style.overflowX = "hidden";
      document.body.style.overflowY = "auto";
      const treeStyle = await readCookie(`tree-style-${parseInt(projectId)}`);
      if (treeStyle) {
        switch (treeStyle) {
          case "horizontal":
            displayTreeAs("horizontal");
            break;
          default:
            displayTreeAs("vertical");
            break;
        }
      }
      setSessionState(true);
      window.addEventListener("beforeunload", () => {
        socketsOff();
        setSessionState(false);
      });

      // run auto-import
      // if (source == "projects") {
      //   runAutoImport();
      // }
    });

    onUnmounted(() => {
      window?.source?.cancel('Operation canceled by the user.');
      store.commit("wbs/SET_CACHED_NODES", {
        projectId: state.projectId,
        nodes: state.nodes,
      });

      //store.commit("wbs/CLEAR_ERROR_NODES");
      const exists = [
        "wbs-project",
        "wbs-template",
        "wbs-template-list-view",
        "wbs-project-list-view",
      ].some((i) => i == router?.currentRoute?.value?.name);

      if (router?.currentRoute?.value?.name && !exists) {
        store.commit("wbs/RESET_CACHED_NODES");
        store.commit("wbs/RESET_WBS_STATE");
        store.commit("wbs/SET_EXISTS_IMPORT", false);
        setSessionState(false);
      } else {
        if (router.currentRoute.value.params.id != state.projectId) {
          store.commit("wbs/RESET_CACHED_NODES");
          store.commit("wbs/RESET_WBS_STATE");
          setSessionState(false);
        }
      }

      document.body.style.overflow = "initial";
      unsubscribeAction();
      socketsOff();

      const channelKey =
        router.currentRoute.value.name === "wbs-project"
          ? "project"
          : "template";
      if (channelKey === "project") {
        window.removeEventListener("beforeunload", setSessionState);
      }
    });

    return {
      state,
      openColorPicker,
      tree,
      onShowChildren,
      onChangeZoom,
      cancelColorPicker,
      changeColorPicker,
      submitColorPicker,
      treeMouseDown,
      currentNode,
      setCurrentNode,
      isOpenPicker,
      openAssignDropDown,
      openStatusDropDown,
      onChangeStructure,
      nodeDelete,
      dropDownPosition,
      wbsContainer,
      onChangeDropDown,
      currentDropDown,
      onBeforeNodeDelete,
      onBeforeNodeDeleteOrphaned,
      confirmationPosition,
      closeConfirmation,
      addNewNode,
      editNode,
      openEstimationPopup,
      projectStatistic,
      onPanelAction,
      zoomInd,
      treeView,
      canvasHeight,
      openFilter,
      isShowFilter,
      onCloseFilter,
      wbsState,
      setWbsState,
      isOrphanedCollapsed,
      allowedDragNodes,
      onMoveNode,
      isHasRootNode,
      isHasOrphaned,
      errorNodes,
      nodeErrorPosition,
      showNodeErrorPopup,
      closeNodeErrorPopup,
      retryNodeAction,
      currentNodeErrors,
      openNewNodeModal,
      selectUser,
      notificationList,
      onChangeOrder,
      printInfo,
      onSubmitFilter,
      filteredNodes,
      invisibleFilteredNodes,
      filterComponent,
      isFiltered,
      childrenCount,
      foundCount,
      isImportError,
      allowNewParentNode,
      isImportRun,
    };
  },
};
</script>
<style lang="scss" scoped>
.wbs-container {
  padding-top: 61px;
  height: 100%;
}

.wbs-component {
  height: 100%;
}

.print-info {
  position: absolute;
  z-index: 1;
  top: 0;
  margin-top: 3px;
  margin-left: 15px;
}

.share {
  margin-left: 10px;
}

.info-panel {
  margin-top: 15px;
  background: rgba(255, 255, 255, 1) fff;
  border-radius: 8px;
  padding: 20px 30px;
  display: grid;
  align-items: center;
  grid-column-gap: 30px;
  grid-template-columns: 30px 1fr 15px;

  .icon {
    width: 31px;
    height: 31px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(42.62deg, #2684fe 0%, #2483ff 100%);
  }

  .text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #a1a9ba;

    strong {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #363636;
    }

    p {
      margin: 0;
    }
  }

  .close {
    cursor: pointer;
  }
}
</style>
