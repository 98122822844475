import { ref, computed, nextTick } from "vue";
import { useStore } from "vuex";
import * as wbsConst from "@/store/modules/wbs/const";
export function useOrphaned({ nodes = [], scale = 1, isCompactView = true }) {
  const store = useStore();
  const wbsState = computed(() => {
    return store.getters["wbs/wbsState"];
  });

  const getChildren = (id, recursion = true) => {
    const children = [];
    function rec(id) {
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        if (node.parentId == id) {
          children.push({
            id: node.id,
            parent: id,
          });

          if (recursion) rec(node.id);
        }
      }
    }
    rec(id);
    return children;
  };

  const createTree = (dataset) => {
    const hashTable = Object.create(null);
    dataset.forEach((aData) => (hashTable[aData.id] = { ...aData, children: [] }));
    const dataTree = [];

    if (Object.keys(hashTable).length) {
      dataset.forEach((aData) => {
        if (aData.parent && hashTable[aData.parent])
          hashTable[aData.parent].children.push(hashTable[aData.id]);
        else dataTree.push(hashTable[aData.id]);
      });
    }
    return dataTree;
  };
  const filteredNodes = ref([]), foundCount = ref([]);
  const filterNodes = (nodes) => {
    filteredNodes.value = [];
    foundCount.value = 0;
    const filters = wbsState.value.filters || {
      projectId: [],
      issueTypeId: [],
      statusId: [],
      assignee: [],
      categoryId: [],
    };

    if (!filters) return [];
    try {
      nodes.forEach((n) => {
        if (
          ((n.jiraIssueStatusId && filters.statusId.includes(n.jiraIssueStatus?.id)) ||
            !filters.statusId.length) &&
          ((n.jiraIssueTypeId && filters.issueTypeId.includes(n.jiraIssueType?.id)) ||
            !filters.issueTypeId.length) &&
          ((n.assigneeId && filters.assignee.includes(n.assignee?.id)) ||
            !filters.assignee.length) &&
          ((n.jiraProjectId && filters.projectId.includes(n.jiraProject?.id)) ||
            !filters.projectId.length) &&
          ((n.jiraIssueStatus?.statusCategory?.id &&
            filters.categoryId.includes(n.jiraIssueStatus?.statusCategory?.id)) ||
            !filters.categoryId.length)
        ) {
          filteredNodes.value.push(n.id);
          foundCount.value = filteredNodes.value.length;
        }
      });

      if (filteredNodes.value.length == 0) {
        filteredNodes.value = nodes.map((n) => n);
        foundCount.value = 0;
      }
      return filteredNodes;
    } catch (error) {
      return nodes;
    }
  };

  const orphanedNodes = computed(() => {
    filterNodes(nodes);
    const resultNodes = nodes
      .filter((n) => (!n.parentId && !n.topNode) || n.isOrphanedChild)
      .reduce((acc, n, i) => {
        acc = acc || [];
        if (filteredNodes.value.includes(n.id)) {
          acc.push({
            ...n,
            isOrphaned: true,
            ...{
              numb: 0,
              x: 2,
            },
          });

          n.showChildren = false;
          const children = getChildren(n.id);
          nextTick(() => {
            for (let j = 0; j < children.length; j++) {
              const child = nodes.find((n) => n.id == children[j].id);
              const isParentOrphaned = orphanedNodes.value.some(
                (n) => n.id == child?.parent?.id
              );
              if (
                ((child?.parent && isParentOrphaned) || !child?.parent) &&
                child.jiraIssueType &&
                //child.jiraIssueType.subtask == 0 &&
                !child.topNode
              ) {
                child.isOrphanedChild = true;
              }
            }
          });
        }
        return acc;
      }, []);

    const tree = createTree(
      resultNodes.map((n) => {
        return {
          id: n.id,
          parent: n.parentId,
        };
      })
    );
    const result = [];
    const makeOrderList = (tree) => {
      for (const treeNode of tree) {
        const node = resultNodes.find((n) => n.id == treeNode.id);
        result.push(node);
        if (treeNode.children.length) makeOrderList(treeNode.children);
      }
    };
    makeOrderList(tree);

    return result.map((n, i) => {
      return {
        ...n,
        ...{
          y: isCompactView == true
            ? 50 * scale * (i + 1)
            : 50 * scale + wbsConst.nodeHeight * i * scale,
        },
      };
    });
  });

  return {
    orphanedNodes, filterNodes, createTree
  }
}