<template>
  <div class="modal-body">
    <div class="close" @click="cancel">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">Creating your new project from template {{ templateName }}</div>
    <div class="container">
      <div class="progress-bar">
        <div class="progress-bar-value" :style="{ width: `${progressValue}%` }"></div>
      </div>
      <small :key="i" v-for="(error, i) in backendErrors">{{ error.text }}</small>
      <div class="actions">
        <app-button color="default" @click="cancel">
          {{ translate("modal.cancel") }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import { useModalHelper } from "@/helpers/modalHelper";
import AppButton from "@/components/shared/Button";
import AppBaseCheckBox from "@/components/forms/elements/BaseCheckBox.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { onMounted, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import errorToList from "@/helpers/errorToList";
export default {
  components: { AppButton, IconClose, AppBaseCheckBox },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    templateName: {
      type: String,
      default: "",
    },
  },
  inject: ["translate"],
  setup(props) {
    const { closeModal, submitModal } = useModalHelper(),
      store = useStore(),
      state = reactive({
        progressValue: "50",
        backendErrors: [],
      });

    const cancel = () => {
      closeModal();
    };
    onMounted(async () => {
      let response;
      state.backendErrors = [];
      try {
        if (props.projectData.id) {
          response = await store.dispatch("projects/editProject", props.projectData);
        } else {
          response = await store.dispatch("projects/addProject", props.projectData);
        }
        submitModal({ ...props.projectData, ...{ id: response?.data?.data?.id } });
      } catch (error) {
        state.backendErrors = errorToList(error);
      }
    });

    return {
      ...toRefs(state),
      cancel,
      closeModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #0085ff;
      svg {
        fill: white;
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    font-size: 16px;
    line-height: 36px;
  }
  .progress-bar {
    position: relative;
    height: 15px;
    background: #f2f5f9;
    border-radius: 10px;
    overflow: hidden;
    .progress-bar-value {
      height: 100%;
      position: absolute;
      left: 0;
      background: #2684fe;
    }
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;
    .button {
      justify-self: end;
      height: 55px;
      width: 100px;
    }
  }
}
small {
  color: red;
  margin-top: 5px;
}
</style>
