<template>
  <div class="faq-content">
    <p>
      You can change the view of the WBS to vertical tree, horizontal tree
      or list view by clicking on the view options at the top menu
    </p>
    <p>
      <img src="@/assets/images/wbs-panel.png" @click="openImagePopup" />
    </p>
  </div>
</template>
<script>
import { useModalHelper } from '@/helpers/modalHelper';

export default {
  setup() {
    const { openModal } = useModalHelper();
    const openImagePopup = (e) => {
      openModal("ImagePreview", {
        src: e.target.src,
      });
    };
    return {
      openImagePopup,
    };
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 100%;
  cursor: zoom-in;
}

ul {
  list-style: auto;
  padding: 0;
  padding-left: 15px;
  margin: 0;
}

ul li+li {
  margin-top: 15px;
}
</style>
