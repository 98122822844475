<template></template>
<script>
import { watch } from "vue";
import { useModalHelper } from "@/helpers/modalHelper";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore(),
      { openModal } = useModalHelper();
    watch(
      () => store.getters["app/serverError"],
      (value) => {
        if (value) {
          openModal("ServerError");
        }
      }
    );
  },
};
</script>

<style lang="scss" scoped></style>
