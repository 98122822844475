<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.55605 10L6.72762 7.17157L8.14183 5.75736L10.9703 8.58579L13.7987 5.75736L15.2129 7.17157L12.3845 10L15.2129 12.8284L13.7987 14.2426L10.9703 11.4142L8.14183 14.2426L6.72762 12.8284L9.55605 10ZM18.0413 17.0711C14.136 20.9764 7.80454 20.9764 3.89919 17.0711C-0.00615783 13.1657 -0.00615783 6.83428 3.89919 2.92893C7.80454 -0.976418 14.136 -0.976418 18.0413 2.92893C21.9467 6.83428 21.9467 13.1657 18.0413 17.0711ZM16.6271 15.6569C18.1274 14.1566 18.9703 12.1217 18.9703 10C18.9703 7.87827 18.1274 5.84344 16.6271 4.34315C15.1268 2.84285 13.092 2 10.9703 2C8.84853 2 6.8137 2.84285 5.31341 4.34315C3.81312 5.84344 2.97026 7.87827 2.97026 10C2.97026 12.1217 3.81312 14.1566 5.31341 15.6569C6.8137 17.1571 8.84853 18 10.9703 18C13.092 18 15.1268 17.1571 16.6271 15.6569Z"
      fill="#3E5571"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "#fff",
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>