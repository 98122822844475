<template>
  <div class="app">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-main" ref="modal" tabindex="0">
          <div class="modal-body">   
            <div class="text">It seems like something went wrong. <br>Please press ok to load the app once again</div>
            <div class="actions">
              <app-button color="red" @click="submit">Ok</app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppButton from "@/components/shared/Button";
import IconClose from "@/components/icons/IconClose.vue";
export default {
  components: { AppButton, IconClose },
  setup() {
    const submit = () => {
      window.location.reload();
    };
    return {
      submit,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "assets/fonts/custom-fonts.css";
@import "assets/scss/tooltip.scss";
:root {
  --font-family: "Poppins";
}
.text-center {
  text-align: center;
}

.cl-orange {
  color: #e47676;
}

.cl-blue {
  color: #0f8af9;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  background-color: #f2f5f9;
  --carousel-color-primary: rgba(54, 54, 54, 0.9);
  --carousel-nav-width: 46px;
}

#app {
  font-family: var(--font-family);
  color: #363636;
  position: relative;
  min-height: 100vh;
}

.app {
  height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-mask {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  overflow: auto;

  &.confirm {
    padding-top: 10%;
  }
}

.modal-wrapper {
  border-radius: 8px;
  max-width: 1000px;
  min-width: 400px;
  background: #fff;
  padding: 1.5rem 2rem;
  position: relative;
  transform: translateY(-150px);
}
.modal-main {
  outline: none;
}
.modal-title {
  font-weight: 500;
  font-size: 16px;
  color: #365674;
}
.modal-body {
  position: relative;
  max-width: 420px;
  font-weight: 500;
  font-size: 16px;
  color: #365674;  
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #0085ff;
      svg {
        fill: white;
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .text {
    font-weight: normal;
    font-size: 16px;
    line-height: 200%;
    text-align: center;
    color: #363636;
  }
  .actions {
    display: grid;
    grid-template-columns: 100px;
    grid-column-gap: 10px;
    margin-top: 10px;
    justify-content: center;
    .button {
      height: 55px;
      width: 100%;
    }
  }
}
</style>
