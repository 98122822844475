import axios from 'axios';
import * as types from '@/store/mutation-types';
import errorToObject from "@/helpers/errorToObject";
export default {
  showSideBar({ commit }) {
    commit(types.APP_SHOW_SIDEBAR, true);
  },
  hideSideBar({ commit }) {
    commit(types.APP_SHOW_SIDEBAR, false);
  },
  setNavBarWidth({ commit }, payload) {
    commit(types.SET_NAVBAR_WIDTH, payload)
  },
  search({ commit }, searchStr = '') {
    return new Promise((resolve, reject) => {
      axios
        .get(`/instance/search?search=${searchStr}`)
        .then((response) => {
          commit(types.SET_SEARCH_INSTANCE, response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(errorToObject(error));
        });
    });
  }
};