<template>
  <svg :width="width" :height="height" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 6.5L6.5 1.5L1.5 6.5" stroke="#636569" stroke-width="1.5" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "#3E5571",
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>