import * as types from '@/store/mutation-types';
export default {
  [types.SET_PROFILE_INSTANCE](state, payload = {}) {
    state.instance = payload.data;    
    state.instance.estimationTypes = Object.keys(state.instance.estimationTypes).map((k) => {
      return { value: k, label: state.instance.estimationTypes[k] };
    });
  },
  [types.SET_LANGUAGE](state, payload = 'en') {    
    state.instance.systemLanguage = payload;            
  },
  [types.SET_SHOW_USER_ACCESS_INTRO](state, payload = false) {
    state.instance.showUserAccessIntroScreen = payload
  },
  [types.SET_SHOW_MAIN_INTRO](state, payload = false) {
    state.instance.showMainIntroScreen = payload
  },
  [types.SET_CONVERSION_SCREEN_USED](state, payload) {
    state.instance.conversionScreenUsed = payload;
  }
};
