<template>
  <div
    class="filter-container"
    data-testid="filter-popup"
    :class="{ show: isOpen, 'is-loading': isLoading }"
    v-click-outside="(e) => clickOutSide(e)"
  >
    <div class="close" @click="closeFilter">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="input-field">
      <label>{{ translate("wbs.project") }}</label>
      <base-select-box
        v-model="projectId"
        data-testid="filter-projects"
        :searchable="true"
        :clear-on-close="true"
        :options="jiraProjects"
        :multiple="true"
        :placeholder="translate('wbs.select_filter')"
        :selectedText="translate('selected')"
        label-by="name"
        value-by="id"
        :small-font="true"
      >
      </base-select-box>
    </div>
    <div class="input-field">
      <label>{{ translate("wbs.status") }}</label>
      <base-select-box
        v-model="statusId"
        data-testid="filter-status"
        :options="jiraStatuses"
        :searchable="true"
        :clear-on-close="true"
        :placeholder="translate('wbs.select_filter')"
        :selectedText="translate('selected')"
        label-by="name"
        value-by="name"
        :multiple="true"
        :small-font="true"
      >
      </base-select-box>
    </div>

    <div class="input-field">
      <label>{{ translate("wbs.status_category") }}</label>
      <base-select-box
        data-testid="filter-category"
        v-model="categoryId"
        :options="jiraCategories"
        :searchable="true"
        :clear-on-close="true"
        :placeholder="translate('wbs.select_filter')"
        :selectedText="translate('selected')"
        label-by="name"
        value-by="id"
        :multiple="true"
        :small-font="true"
      >
      </base-select-box>
    </div>
    <div class="input-field">
      <label>{{ translate("wbs.type") }}</label>
      <base-select-box
        data-testid="filter-issues"
        v-model="issueTypeId"
        :options="issueTypesList"
        :searchable="true"
        :clear-on-close="true"
        :placeholder="translate('wbs.select_filter')"
        :selectedText="translate('selected')"
        label-by="name"
        value-by="id"
        :multiple="true"
        :small-font="true"
      >
      </base-select-box>
    </div>
    <div class="input-field assignee">
      <label>{{ translate("wbs.assignee") }}</label>
      <base-select-box
        v-model="assignee"
        data-testid="filter-assignee"
        :options="userList"
        :placeholder="translate('select_user')"
        :search-placeholder="translate('search_selectbox')"
        :selectedText="translate('selected')"
        group-by="isGroup"
        label-by="label"
        value-by="value"
        :small-font="true"
        multiple
        :searchable="true"
        :selectedItems="selectedAssignee"
      >
        <template #selected-items>
          <base-selected-items :items="selectedAssignee" />
        </template>
        <template #apply-button>
          <app-button :color="'purple'" type="button" @click="applyUsersFilter">{{
            translate("apply")
          }}</app-button>
        </template>
        <template v-slot:default="item">
          <div class="image-item">
            <img
              :style="{ width: '30px', height: '30px' }"
              v-if="item.option.iconUri"
              :src="item.option.iconUri"
            />
            {{ item.option.label }}
          </div>
        </template>
      </base-select-box>
    </div>
    <div class="total">{{ translate("wbs.total") }}: {{ total }}</div>
    <div class="actions">
      <app-button color="default" @click="resetFilter">{{
        translate("wbs.reset")
      }}</app-button>
      <app-button color="primary" @click="setFilters">{{
        translate("wbs.filter")
      }}</app-button>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed, onMounted, onUnmounted, watch, ref } from "vue";
import { useStore } from "vuex";
import BaseSelectBox from "vue-select-box";
import IconPerson from "@/components/icons/IconPerson.vue";
import IconWrapper from "@/components/icons/IconWrapper.vue";
import BaseSelectedItems from "@/components/forms/elements/SelectedItems.vue";
import AppButton from "@/components/shared/Button.vue";
import IconClose from "@/components/icons/IconClose.vue";
import { useCookies } from "@/helpers/cookies";
export default {
  components: {
    BaseSelectBox,
    BaseSelectedItems,
    AppButton,
    IconWrapper,
    IconClose,
    IconPerson,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: null,
    },
  },
  emits: ["onSumbit", "onClose"],
  inject: ["translate"],
  setup(props, { emit }) {
    const store = useStore(),
      { saveCookie, readCookie } = useCookies(),
      state = reactive({
        isOpen: props.isShow,
        isReady: false,
        projectId: [],
        wbsState: computed(() => store.getters["wbs/wbsState"]),
        jiraProjects: computed(() => {
          const list = store.getters["wbs/nodes"].reduce((acc, node) => {
            acc = acc || [];
            if (node.jiraProject?.id) {
              if (!acc.some((p) => p.id == node.jiraProject.id))
                acc.push(node.jiraProject);
            }
            return acc;
          }, []);
          return list;
        }),
        statusId: [],
        jiraStatuses: computed(() => {          
          const list = store.getters["wbs/nodes"].reduce((acc, node) => {
            acc = acc || [];
            if (node.jiraIssueStatus?.name) {
              if (!acc.some((p) => p.name == node.jiraIssueStatus.name))
                acc.push(node.jiraIssueStatus);
            }
            return acc;
          }, []);
          return list;
        }),
        categoryId: [],
        jiraCategories: computed(() => {
          const list = store.getters["wbs/nodes"].reduce((acc, node) => {
            acc = acc || [];
            if (node.jiraIssueStatus?.id && node.jiraIssueStatus?.statusCategory?.id) {
              if (!acc.some((p) => p.id == node.jiraIssueStatus.statusCategory.id))
                acc.push(node.jiraIssueStatus.statusCategory);
            }
            return acc;
          }, []);
          return list;
        }),
        issueTypeId: [],
        issueTypesList: computed(() => {
          const list = store.getters["wbs/nodes"].reduce((acc, node) => {
            acc = acc || [];
            if (node.jiraIssueType?.id) {
              if (!acc.some((p) => p.id == node.jiraIssueType.id))
                acc.push(node.jiraIssueType);
            }
            return acc;
          }, []);
          return list;
        }),
        assignee: [],
        selectedAssignee: [],
        isLoading: computed(() => store.getters["loader/isLoading"]),
        userList: computed(() => {
          const list = store.getters["wbs/nodes"]
            .reduce((acc, node) => {
              acc = acc || [];
              if (node.assignee?.id) {
                if (!acc.some((p) => p.id == node.assignee.id)) acc.push(node.assignee);
              }
              return acc;
            }, [])
            .map((u) => {
              return {
                iconUri: u.iconUri,
                value: u.id,
                label: u.displayName,
              };
            });

          return list;
        }),
      });

    watch(
      () => props.isShow,
      (current) => {
        state.isOpen = current;
        if (state.isOpen) {
          state.isReady = true;
        }
      }
    );

    watch(
      () => state.isReady,
      (current) => {
        if (current === true) {
          clearFilter();
          loadData();
        }
      }
    );

    const loadData = async () => {
      const cookie = await readCookie(`filter-${state.wbsState.id}`);
      if (!cookie) return;

      let { projectId, issueTypeId, statusId, assignee, categoryId } = JSON.parse(
        cookie
      );
      if (isNaN(statusId[0])==false) {
        statusId = []
      }
      state.projectId = projectId;
      state.issueTypeId = issueTypeId;
      state.statusId = statusId;
      state.categoryId = categoryId;
      state.assignee = assignee;
      if (assignee.length) {
        state.selectedAssignee = state.assignee.map((u) => {
          return state.userList.find((ul) => ul.value == u);
        });
      }
    };

    const setFilters = async () => {
      closeFilter();
      const filter = {
        projectId: state.projectId,
        issueTypeId: state.issueTypeId,
        statusId: state.statusId,
        assignee: state.assignee,
        categoryId: state.categoryId,
      };
      saveCookie(`filter-${state.wbsState.id}`, JSON.stringify(filter), 360);
      store.commit("wbs/SET_PROJECT_FILTERS", { data: filter });
      emit("onSumbit");
    };

    const clearFilter = () => {
      state.projectId = [];
      state.issueTypeId = [];
      state.statusId = [];
      state.categoryId = [];
      state.userId = [];
      state.selectedUser = [];
      state.assignee = [];
      state.selectedAssignee = [];
    };

    const resetFilter = async () => {
      clearFilter();
      setFilters();
    };

    const closeFilter = () => {
      state.isOpen = false;
      emit("onClose");
    };

    const clickOutSide = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("filter-container") ||
              p.classList.contains("open-filter"))
          );
        })
      )
        return;

      closeFilter();
    };

    const applyUsersFilter = () => {
      state.selectedAssignee = state.assignee.map((u) => {
        return state.userList.find((ul) => ul.value == u);
      });
    };

    const onkeydown = (e) => {
      if (e.key == "Escape") {
        closeFilter();
      }
    };

    const mouseEnter = () => {
      state.isReady = true;
    };

    onMounted(() => {
      document.addEventListener("keydown", onkeydown);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", onkeydown);
    });
    return {
      ...toRefs(state),
      clickOutSide,
      mouseEnter,
      setFilters,
      resetFilter,
      closeFilter,
      applyUsersFilter,
    };
  },
};
</script>
<style lang="scss" scoped>
.filter-container {
  position: relative;
  &.is-loading {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background: white;
      z-index: 1;
    }
  }
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 10px;
  align-items: center;
  padding: 20px;
  background: white;
  width: 750px;
  background: #ffffff;
  border-radius: 8px;
  filter: drop-shadow(15px 15px 25px rgba(0, 0, 0, 0.1));
  position: absolute;
  z-index: -1;
  top: 110px;
  left: 50%;
  transform: translate(-60%, -300px);
  transition: 0.5s;
  &.show {
    transform: translate(-60%, 0);
  }
}
.assignee {
  ::v-deep(.selected-items li img) {
    width: 30px;
    height: 30px;
  }
}
.input-field {
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    /* #A1A9BA */

    color: #a1a9ba;
    display: block;
  }
  ::v-deep(.vue-select-header) {
    height: 35px;
  }
}
.total {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  grid-area: 3 / 3;
  justify-self: end;
}
.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  align-self: end;
  grid-area: 2 / 3;
  button {
    height: 35px;
  }
}
.close {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 8px;
  border: 1px solid #0085ff;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background: #0085ff;
    svg {
      fill: white;
    }
  }
}
</style>
