<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2763 7.33316H19.2498C19.7361 7.33316 20.2024 7.52631 20.5462 7.87013C20.89 8.21395 21.0832 8.68026 21.0832 9.16649V12.8332C21.0832 13.3194 20.89 13.7857 20.5462 14.1295C20.2024 14.4733 19.7361 14.6665 19.2498 14.6665H18.2763C18.0529 16.4387 17.1903 18.0684 15.8506 19.2498C14.5109 20.4312 12.7861 21.0831 10.9998 21.0832V19.2498C12.4585 19.2498 13.8575 18.6704 14.8889 17.6389C15.9204 16.6075 16.4998 15.2085 16.4998 13.7498V8.24983C16.4998 6.79114 15.9204 5.39219 14.8889 4.36074C13.8575 3.32929 12.4585 2.74983 10.9998 2.74983C9.54115 2.74983 8.1422 3.32929 7.11075 4.36074C6.0793 5.39219 5.49984 6.79114 5.49984 8.24983V14.6665H2.74984C2.26361 14.6665 1.79729 14.4733 1.45347 14.1295C1.10966 13.7857 0.916504 13.3194 0.916504 12.8332V9.16649C0.916504 8.68026 1.10966 8.21395 1.45347 7.87013C1.79729 7.52631 2.26361 7.33316 2.74984 7.33316H3.72334C3.94703 5.56115 4.80966 3.93166 6.14936 2.75045C7.48906 1.56923 9.21376 0.91748 10.9998 0.91748C12.7859 0.91748 14.5106 1.56923 15.8503 2.75045C17.19 3.93166 18.0526 5.56115 18.2763 7.33316ZM2.74984 9.16649V12.8332H3.6665V9.16649H2.74984ZM18.3332 9.16649V12.8332H19.2498V9.16649H18.3332ZM7.11317 14.4694L8.08484 12.9147C8.95849 13.462 9.96892 13.7515 10.9998 13.7498C12.0308 13.7515 13.0412 13.462 13.9148 12.9147L14.8865 14.4694C13.7217 15.1992 12.3744 15.5853 10.9998 15.5832C9.62526 15.5853 8.27802 15.1992 7.11317 14.4694Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "#fff",
    },
    secondaryFill: {
      type: String,
      default: null,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryFill || this.fill;
    },
  },
};
</script>