<template>
  <svg :width="width" :height="width" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="12" :fill="fill"/>
    <path d="M12.014 9.512C11.766 9.512 11.558 9.428 11.39 9.26C11.222 9.092 11.138 8.884 11.138 8.636C11.138 8.388 11.222 8.18 11.39 8.012C11.558 7.844 11.766 7.76 12.014 7.76C12.254 7.76 12.458 7.844 12.626 8.012C12.794 8.18 12.878 8.388 12.878 8.636C12.878 8.884 12.794 9.092 12.626 9.26C12.458 9.428 12.254 9.512 12.014 9.512ZM12.686 10.388V17H11.318V10.388H12.686Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "#C3CAD9",
    },    
  },
};
</script>