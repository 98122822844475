<template>
  <svg :width="width" :height="height" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_425_2232)">
      <path
        d="M14.6667 10.0832L7.33332 10.0832C6.60398 10.0832 5.9045 10.3729 5.38878 10.8887C4.87305 11.4044 4.58332 12.1039 4.58332 12.8332L4.58332 14.8224C3.97145 15.0386 3.45574 15.4642 3.12733 16.024C2.79892 16.5837 2.67896 17.2415 2.78866 17.8811C2.89837 18.5207 3.23066 19.101 3.72682 19.5193C4.22297 19.9376 4.85103 20.167 5.49999 20.167C6.14895 20.167 6.77701 19.9376 7.27316 19.5193C7.76932 19.101 8.10161 18.5207 8.21132 17.8811C8.32102 17.2415 8.20106 16.5837 7.87265 16.024C7.54424 15.4642 7.02853 15.0386 6.41666 14.8224L6.41666 12.8332C6.41666 12.5901 6.51323 12.3569 6.68514 12.185C6.85705 12.0131 7.09021 11.9165 7.33332 11.9165L14.6667 11.9165C15.396 11.9165 16.0955 11.6268 16.6112 11.1111C17.1269 10.5954 17.4167 9.89589 17.4167 9.16654L17.4167 6.41654L20.1667 6.41654L16.5 1.83321L12.8333 6.41655L15.5833 6.41654L15.5833 9.16654C15.5833 9.40966 15.4867 9.64282 15.3148 9.81473C15.1429 9.98664 14.9098 10.0832 14.6667 10.0832ZM4.58332 17.4165C4.58332 17.1734 4.6799 16.9403 4.85181 16.7684C5.02372 16.5965 5.25687 16.4999 5.49999 16.4999C5.74311 16.4999 5.97626 16.5965 6.14817 16.7684C6.32008 16.9403 6.41666 17.1734 6.41666 17.4165C6.41666 17.6597 6.32008 17.8928 6.14817 18.0647C5.97626 18.2366 5.74311 18.3332 5.49999 18.3332C5.25688 18.3332 5.02372 18.2366 4.85181 18.0647C4.6799 17.8928 4.58332 17.6597 4.58332 17.4165Z"
        :fill="fill" />
      <path
        d="M7.65426 4.5L9 3.15318L7.84682 2L6.5 3.34574L5.15318 2L4 3.15318L5.34573 4.5L4 5.84574L5.15318 7L6.5 5.65426L7.84574 7L9 5.84682L7.65426 4.5Z"
        :fill="fill" />
      <path
        d="M16.6543 17.5L18 16.1532L16.8468 15L15.5 16.3457L14.1532 15L13 16.1532L14.3457 17.5L13 18.8457L14.1532 20L15.5 18.6543L16.8457 20L18 18.8468L16.6543 17.5Z"
        :fill="fill" />
    </g>
    <defs>
      <clipPath id="clip0_425_2232">
        <rect width="22" height="22" fill="white" transform="matrix(-1.19249e-08 -1 -1 1.19249e-08 22 22)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
      default: '#23252A'
    },
  },
};
</script>
