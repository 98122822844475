<template>
  <svg
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.12396 12C3.28136 14.6489 5.92443 16.5 8.99996 16.5C12.8889 16.5 16.0866 13.54 16.4629 9.75"
      :stroke="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.25 12L1.95 12C1.70145 12 1.5 12.2015 1.5 12.45L1.5 15.75"
      :stroke="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8389 6C14.6815 3.35115 12.0384 1.5 8.96291 1.5C5.07394 1.5 1.87624 4.46002 1.49996 8.25"
      :stroke="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.7129 6L16.0129 6C16.2614 6 16.4629 5.79855 16.4629 5.55L16.4629 2.25"
      :stroke="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
      default: "transparent",
    },
    stroke: {
      type: [String],
      default: "white",
    },
  },
};
</script>
