<template>
  <div class="our-app">
    <header>
      <!-- <img class="header-bg" src="./img/header.png" alt="" /> -->
      <div class="wrapper">
        <div class="header-center">
          <div class="logo">
            <a target="_blank" href="https://aneto.io">
              <img src="./img/logo.svg" alt="" />
            </a>
          </div>          
          <h1>Apps</h1>
          <p>
            These essential productivity apps will maximise<br />
            your teams output fast.
          </p>
        </div>
      </div>
    </header>
    <section class="grey">
      <div class="wrapper">
        <div class="section-content">
          <div class="text">
            <div class="header-text">
              Aneto EVM - Earned Value management for Jira
            </div>
            <div class="descr">
              Aneto Earned Value Management is the perfect<br />
              solution for professional project managers<br />
              that manage their project in Jira. Get live<br />
              updates on the projects earned value<br />
              management and project KPIs. This is the<br />
              perfect solutions for PMI certified project<br />
              managers.
            </div>
            <a
              target="_blank"
              href="https://marketplace.atlassian.com/apps/1224077/aneto-project-accounting-system?tab=overview&hosting=cloud"
              >Learn more</a
            >
          </div>
          <div class="image overflow">
            <img
              src="./img/value-managment.png"
              class="value-managment"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
    <section class="white">
      <div class="wrapper">
        <div class="section-content">
          <div class="text">
            <div class="header-text">Work Breakdown Structure</div>
            <div class="descr">
              <p>
                Managing a project need not be stressful<br />
                when you have the right project management<br />
                tools within reach.
              </p>
              <p>
                Our Work Breakdown Structure (WBS) app is<br />
                especially useful for meeting tight project<br />
                deadlines.
              </p>
              <p>
                We leverage the WBS app and other<br />
                technological advancements to work side-by-<br />side with your
                team throughout each stage of<br />
                the process.
              </p>
            </div>
            <a
              target="_blank"
              href="https://marketplace.atlassian.com/apps/1219682/wbs-work-breakdown-structure-story-board?hosting=cloud&tab=overview"
              >Learn more</a
            >
          </div>
          <div class="image">
            <img src="./img/wbs.png" class="wbs" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="grey">
      <div class="wrapper">
        <div class="section-content">
          <div class="text">
            <div class="header-text">
              Automatic Sub-Tasks<br />
              Professional
            </div>
            <div class="descr">
              This app kicks productivity up a notch by<br />
              speeding up the planning and estimation<br />
              processes. Save your team time on activities<br />
              such as analysis, design, and validation when<br />
              using this app. This app is recommended for<br />
              advanced Jira admin users. A simpler version<br />
              of the app is also available with many of the<br />
              same features.
            </div>
            <a
              target="_blank"
              href="https://marketplace.atlassian.com/apps/1218418/automatic-subtasks-professional?hosting=cloud&tab=overview"
              >Start Free trial</a
            >
          </div>
          <div class="image">
            <img src="./img/subtask.png" class="subtask" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="white">
      <div class="wrapper">
        <div class="section-content">
          <div class="text">
            <div class="header-text">Hours Reports</div>
            <div class="descr">
              Get quick and easy access to timesheets categorized by employee,
              project, and issue type. The simple-touse interface allows more
              control over teams and projects. Users can establish hourly rates,
              extract timesheets, and track spending in Excel using this app.
            </div>
            <a target="_blank" href="https://marketplace.atlassian.com/apps/1219348/hours-reports-work-log-time-reports?hosting=cloud&tab=overview"
              >Start Free trial</a
            >
          </div>
          <div class="image">
            <img src="./img/hr.png" class="hr" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="grey">
      <div class="wrapper">
        <div class="section-content">
          <div class="text">
            <div class="header-text">Recurring Issues</div>
            <div class="descr">
              <p>
                The automatic Sub-tasks Professional ap is a<br />
                great addition to your workflow as it speeds up<br />
                the planning and estimating process.
              </p>
              <p>
                If you have common sub-tasks for your Scrum<br />
                stories such as peer review, QA, design, etc, or<br />
                common sub-tasks for your BAU activities such<br />
                as analysis, validation, design, etc, then this<br />
                app is going to save your team a great deal of<br />
                time in your work flow.
              </p>
              <p>
                This app is a more advanced version or our<br />
                simple automatic-subtasks app and it is<br />
                intended for a more advanced Jira admin user.
              </p>
            </div>
            <a
              target="_blank"
              href="https://marketplace.atlassian.com/apps/1217019/recurring-issues-jira-add-on?hosting=cloud&tab=overview"
              >Start Free trial</a
            >
          </div>
          <div class="image">
            <img src="./img/issues.png" class="issues" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="white">
      <div class="wrapper">
        <div class="section-content">
          <div class="text">
            <div class="header-text">Pert Calculator</div>
            <div class="descr">
              PERT (Program Evaluation and Review<br />
              Technique) uses a weighted average of 3<br />
              numbers to generate a final estimate. PERT is<br />
              superior in accuracy to story points and direct<br />
              estimation. Managers can use PERT to<br />
              estimate and prepare contingencies for best-<br />case,
              probable-case, and worst-case<br />
              scenarios. Calculations include a deviation<br />
              value for better identification of whether a<br />
              story carries a considerable risk of missing<br />
              deadlines.
            </div>
            <a
              target="_blank"
              href="https://marketplace.atlassian.com/apps/1218473/pert-calculator?hosting=cloud&tab=overview"
              >Start Free trial</a
            >
          </div>
          <div class="image">
            <img src="./img/pert.png" class="pert" alt="" />
          </div>
        </div>
      </div>
    </section>
    <section class="grey">
      <div class="wrapper">
        <div class="section-content">
          <div class="text">
            <div class="header-text">Automatic Sub-tasks</div>
            <div class="descr">
              Create automatic sub-tasks, customize fields,<br />
              and establish the order of sub-tasks, all with<br />
              this amazing app. Stop wasting valuable time<br />
              — and money — by effortlessly creating<br />
              multiple sub-tasks. Each automatically<br />
              generated subtask can contain up to 10<br />
              different pre-defined values, including<br />
              summary, description, original estimate, and<br />
              assignee.
            </div>
            <a
              target="_blank"
              href="https://marketplace.atlassian.com/apps/1216960/automatic-subtasks-add-on?hosting=cloud&tab=overview"
              >Start Free trial</a
            >
          </div>
          <div class="image">
            <img src="./img/auto-subtask.png" class="auto-subtask" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Plus+Jakarta+Sans:wght@600&family=Roboto&display=swap");
</style>
<style lang="scss" scoped>
.our-app {
  min-width: 1200px;
}
.wrapper {
  max-width: 1230px;
  min-width: 1200px;
  margin: 0 auto;
}
header {
  position: relative;
  overflow: hidden;
  background: #04072a;

  .wrapper {
    display: grid;
    grid-template-rows: 70px 328px;
    position: relative;
    grid-template-columns: 1fr 740px 1fr;
  }
  .header-center {
    justify-self: center;
    text-align: center;
    position: relative;
    background: url(./img/radial.png);
    background-repeat: no-repeat;
    grid-area: 1 / 2 / 3 / 2;
    background-position: 50%;
    width: 100%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    h1 {
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 70px;
      line-height: 85px;
      /* identical to box height, or 121% */

      text-align: center;

      color: #ffffff;
    }
    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      /* or 140% */
      margin-top: 25px;
      text-align: center;

      color: #ffffff;
    }
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 396px;
      left: 100%;
      top: 0;
      background: conic-gradient(
        from 0deg at 100% 53.79%,
        #558cf7 0deg,
        rgb(0 0 0) 360deg
      );
      mix-blend-mode: normal;
      opacity: 0.6;
      transform: rotate(180deg);
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 396px;
      right: 100%;
      top: 0;
      background: conic-gradient(
        from 0deg at 100% 44.79%,
        #000 180deg,
        #558cf787 360deg
      );
      mix-blend-mode: normal;
      opacity: 0.6;
      transform: rotate(0deg);
    }
  }
}
.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}
.logo {
  img {
    width: 71px;
    position: relative;
    z-index: 111;
  }
}
.overflow {
  overflow: hidden;
}
.menu {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 50px;
    align-items: center;
  }
  a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    color: #ffffff;
  }
}
.menu-btn {
  width: 40px;
  height: 40px;
  background: #ffffff26;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.white {
  background: #ffffff;
}
.grey {
  background: #e5e5e5;
}
section {
  .wrapper {
    padding: 100px 0;
  }
  &.white {
    .text {
      background: #f7f7f8;
      border-radius: 0 10px 10px 0;
    }
    .section-content {
      display: grid;
      grid-template-columns: 1fr 460px;
      .image {
        border-radius: 10px 0 0 10px;
        grid-area: 1 / 1;
      }
    }
  }

  &.contact-us {
    .section-content {
      position: relative;
      overflow: hidden;
      background: #040728;
      border-radius: 10px;
      height: 320px;
      padding: 50px;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      .header-text {
        font-family: "Plus Jakarta Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        /* identical to box height, or 123% */
        margin-bottom: 15px;
        color: #ffffff;
      }
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        color: #ffffff;
      }
      .contact-bg {
        position: absolute;
        right: -573px;
        width: 100%;
        top: -553px;
      }
    }
  }

  .text {
    background: #ffffff;
    padding: 50px;
    border-radius: 10px 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .descr {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      color: #656565;
    }
    .header-text {
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 32px;
      /* or 145% */
      margin-bottom: 15px;
      color: #04072a;
    }
  }
  .image {
    border-radius: 0 10px 10px 0;
    background: linear-gradient(90deg, #1541f6 0%, #3d85f7 100%);
    box-shadow: 0px 20px 30px rgba(162, 162, 162, 0.02);
    display: flex;
    align-items: center;
  }

  img {
    width: 100%;
  }

  .value-managment {
    margin-left: -10px;
    margin-bottom: -25px;
    width: 100%;
  }

  .wbs {
    margin-right: -39px;
    margin-left: -26px;
    margin-bottom: -41px;
    width: 100%;
  }
  .subtask {
    width: 96%;
    position: relative;
    left: 15px;
  }
  .auto-subtask {
    width: 100%;
    position: relative;
    right: 15px;
  }
  .hr {
    width: 100%;
    margin-bottom: -25px;
    margin-left: -21px;
  }
  .issues {
    width: 100%;
    position: relative;
    top: 27px;
  }
  .pert {
    width: 100%;
    display: table;
    margin: 0 auto;
  }
  .section-content {
    display: grid;
    grid-template-columns: 460px 1fr;
    height: 590px;

    a {
      background: #1b49f5;
      box-shadow: 0px 9px 24px rgba(85, 140, 246, 0.15);
      border-radius: 62px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      /* identical to box height */

      color: #ffffff;
      max-width: 170px;
      margin-top: 40px;
    }
  }
}
footer {
  background: linear-gradient(180deg, #040727 0%, #0b2069 100%);
  padding: 0 40px;
  .wrapper {
    display: grid;
    grid-template-columns: 360px repeat(3, 1fr);
    padding: 40px 0;
  }
  .logo {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .menu-name {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #ffffff;

    opacity: 0.5;
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-row-gap: 20px;
    a {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #ffffff;
    }
  }
  .logo {
    display: grid;
    align-items: center;
    align-self: start;
    grid-template-columns: 50px 1fr;
    gap: 15px;
    .header-text {
      font-family: "Plus Jakarta Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      /* identical to box height */

      color: #ffffff;
    }
    img {
      width: 50px;
      height: 50px;
    }
    .year {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      color: #ffffff;

      opacity: 0.5;
    }
  }
  .social {
    ul {
      grid-row-gap: 15px;
    }
    li a {
      display: grid;
      grid-template-columns: 21px auto;
      gap: 10px;
      align-items: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      color: #ffffff;
    }
  }
}
</style>