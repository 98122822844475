import axios from 'axios';
import * as types from '@/store/mutation-types';

export default {
  getInstance({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get('/instance')
        .then((response) => {
          const instance = response;
          commit(types.SET_PROFILE_INSTANCE, instance);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  setLanguage({ commit }, payload) {
    commit(types.SET_LANGUAGE, payload);
  },

  conversionScreenUsed({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put('/instance/conversion')
        .then((response) => {
          commit(types.SET_CONVERSION_SCREEN_USED, true);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};