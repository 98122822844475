<template>
  <div class="date-picker" ref="containerEl" :class="{ 'select-week': selectByWeek }">
    <div class="date-picker-input" :title="localDate">
      <i class="left-arrow" @click="prevPeriod" v-if="showNavigate">
        <icon-arrow :width="'4px'" :stroke="'#0F8AF9'" />
      </i>
      <div class="locale-date" v-if="localDate">
        {{ localDate }}
      </div>
      <input
        :style="{ opacity: localDate ? 0 : 1 }"
        id="picker"
        ref="pickerEl"
        type="text"
        readonly
        :class="className"
        v-model="range"
        :start-week="startWeekDay"
      />
      <i class="right-arrow" @click="nextPeriod" v-if="showNavigate">
        <icon-arrow :width="'4px'" :stroke="'#0F8AF9'" />
      </i>
      <div class="icon" @click="show">
        <icon-calendar :width="'16px'" :height="'16px'" />
      </div>
    </div>
  </div>
</template>

<script>
import "daterangepicker/daterangepicker";
import "daterangepicker/daterangepicker.css";
import $ from "jquery";
import { computed, onMounted, watch, ref, onBeforeUnmount, nextTick } from "vue";
import moment from "moment";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import IconArrow from "@/components/icons/IconArrow.vue";

export default {
  components: { IconCalendar, IconArrow },
  emits: ["update:modelValue", "change"],
  props: {
    modelValue: {
      type: [Array],
      default: [new Date(), new Date()],
    },
    dateValue: {
      type: [String, Number],
    },
    showNavigate: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selectByWeek: {
      type: Boolean,
      default: false,
    },
    startWeekDay: {
      type: String,
      default: "monday",
      validator: function (value) {
        return (
          [
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday",
          ].indexOf(value) !== -1
        );
      },
    },
    format: {
      type: String,
      default: "MMM Do YYYY",
    },
    className: {
      type: String,
      default: "",
    },
    language: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const range = ref(moment().format("ddd, MMM D, YYYY")),
      pickerEl = ref(),
      containerEl = ref(),
      customOptions = computed(() => {
        return {
          locale: {
            format: props.format,
          },
          ...{
            parentEl: containerEl.value,
          },
          ...props.options,
        };
      }),
      localDate = computed(() => {
        const el = $(pickerEl.value),
          picker = el.data("daterangepicker");
        if (picker && range.value && props.language) {
          const startDate = picker.startDate.lang(props.language).format(props.format),
            endDate = picker.endDate.lang(props.language).format(props.format);
          return startDate + " - " + endDate;
        }
      });

    const createPicker = () => {
      $(pickerEl.value).daterangepicker("destroy");
      const el = $(pickerEl.value);
      el.daterangepicker(customOptions.value);
      const picker = el.data("daterangepicker");

      if (props.modelValue.length) {
        picker.setStartDate(props.modelValue[0]);
        picker.setEndDate(props.modelValue[1]);
      }

      if (props.dateValue) {
        nextTick(() => {
          picker.setStartDate(new Date(props.dateValue));
          picker.setEndDate(new Date(props.dateValue));
        });
      }

      el.on("apply.daterangepicker", (event, picker) => {
        onChange(picker);
      });

      el.on("show.daterangepicker", (event, picker) => {
        $(pickerEl.value).blur();
      });

      let oldRow, oldMonth;
      if (props.selectByWeek) {
        el.on("showCalendar.daterangepicker", function (event, picker) {
          const title = $(picker.container).find("td.active").attr("data-title");
          if (!title) return;
          const row = title.substr(1, 1),
            col = title.substr(3, 1),
            date = picker.leftCalendar.calendar[row][col],
            startDate = picker.startDate.format(props.format),
            endDate = picker.endDate.format(props.format),
            month = $(".drp-calendar.left .month").text();

          if (month !== oldMonth && oldMonth) {
            oldMonth = month;
            return;
          }
          if (oldRow !== row || startDate === endDate) {
            oldRow = row;
            oldMonth = month;
            setPickerStartEndWeek(picker, date, $("#picker").attr("start-week"));
          }
        });
      }
      onChange(picker, false);
    };

    watch(
      () => props.options,
      () => {
        createPicker();
      }
    );

    watch(
      () => props.modelValue,
      (current) => {
        const picker = $(pickerEl.value).data("daterangepicker");
        picker.setStartDate(current[0]);
        picker.setEndDate(current[1]);
        onChange(picker, false);
      }
    );

    const show = () => {
      const el = $(pickerEl.value);
      $(el).data("daterangepicker").show();
    };

    const hide = () => {
      const el = $(pickerEl.value);
      $(el).data("daterangepicker").hide();
    };

    const applyChanges = (value) => {
      const el = $(pickerEl.value);
      el.data("daterangepicker").setStartDate(value[0]);
      el.data("daterangepicker").setEndDate(value[1]);
      el.trigger("apply.daterangepicker", el.data("daterangepicker"));
    };

    const prevPeriod = () => {
      const el = $(pickerEl.value);
      range.value = [
        moment(el.data("daterangepicker").startDate)
          .subtract(7, "days")
          .format(props.format),
        moment(el.data("daterangepicker").startDate).format(props.format),
      ];
      applyChanges(range.value);
    };

    const nextPeriod = () => {
      const el = $(pickerEl.value);
      range.value = [
        moment(el.data("daterangepicker").endDate).format(props.format),
        moment(el.data("daterangepicker").endDate).add(7, "days").format(props.format),
      ];
      applyChanges(range.value);
    };

    const setPickerStartEndWeek = (picker, when, startWeekDay = props.startWeekDay) => {
      const days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sun",
      ];
      const startWeekDayIndex = days.indexOf(startWeekDay) + 1,
        selectedDay = moment(when).day(),
        diff = Math.abs(selectedDay - startWeekDayIndex);
      let m = when == "now" ? moment() : moment(when),
        weekStart = m.startOf("isoweek"),
        weekEnd = m.clone().endOf("isoweek");

      if (selectedDay < startWeekDayIndex) {
        weekStart = moment(when).add(diff, "days");
        weekEnd = moment(when).add(diff, "days").add(6, "days");
      } else if (selectedDay > startWeekDayIndex) {
        weekStart = moment(when).subtract(diff, "days");
        weekEnd = moment(when).subtract(diff, "days").add(6, "days");
      } else {
        weekStart = moment(when);
        weekEnd = moment(when).add(6, "days");
      }
      picker.setStartDate(weekStart);
      picker.setEndDate(weekEnd);
      picker.updateView();
    };

    const onChange = (picker, emitResult = true) => {
      let startDate = picker.startDate.format(props.format),
        endDate = picker.endDate.format(props.format);
      if (props.selectByWeek) {
        setPickerStartEndWeek(picker, picker.startDate);
        startDate = picker.startDate.format(props.format);
        endDate = picker.endDate.format(props.format);
        range.value = startDate + " - " + endDate;
        //picker.element[0].value = range.value;
      }

      if (emitResult) {
        emit("update:modelValue", [startDate, endDate]);
        emit("change", [
          startDate,
          endDate,
          new Date(picker.startDate).getTime(),
          new Date(picker.endDate).getTime(),
        ]);
      }
    };

    onMounted(() => {
      createPicker();
    });

    onBeforeUnmount(() => {
      $(pickerEl.value).off("apply.daterangepicker");
      $(pickerEl.value).off("hide.daterangepicker");
      $(pickerEl.value).off("hideCalendar.daterangepicker");
      $(pickerEl.value).daterangepicker("destroy");
    });

    return {
      pickerEl,
      show,
      hide,
      prevPeriod,
      nextPeriod,
      range,
      containerEl,
      localDate,
    };
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  &.select-week {
    ::v-deep(.daterangepicker.show-calendar) {
      .calendar-table .table-condensed tbody tr:hover {
        .off {
          background-color: #edf6fe;
          color: #363636;
        }
        background-color: #edf6fe;
      }
    }
  }
  ::v-deep(.daterangepicker.show-calendar) {
    font-family: var(--font-family);
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
    padding: 25px;
    border-radius: 15px;
    border: 0;
    &:before {
      display: none;
    }

    .calendar-table thead tr:first-child {
      height: 50px;
    }
    .calendar-table thead tr:first-child th {
      vertical-align: top;
    }
    .calendar-table th {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #727a81;
    }

    .calendar-table th.month {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #363636;
    }
    .calendar-table th.prev,
    .calendar-table th.next {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #0f8af9;
      display: flex;
      flex-shrink: 0;
      min-width: 20px;
      align-items: center;
      justify-content: center;
      span {
        border-color: #0f8af9;
        padding: 2px;
      }
    }
    .calendar-table th.prev {
      span {
        position: relative;
        left: 1px;
      }
    }
    .calendar-table th.next {
      span {
        position: relative;
        right: 1px;
      }
    }
    .calendar-table td {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      width: 35px;
      height: 32px;

      text-align: center;
    }

    .calendar-table td:hover:hover,
    .calendar-table td.active {
      background-color: #0085ff;
      color: white;
    }
    .calendar-table td.today,
    .calendar-table td.in-range.today {
      background-color: #0085ff;
      color: white;
    }

    .calendar-table td.active.end-date:not(.today) {
      background: #edf6fe;
      color: #363636;
    }
    .calendar-table td.active.start-date:not(.today) {
      background: #edf6fe;
      color: #363636;
    }
    .calendar-table td.in-range {
      background-color: #edf6fe;
    }
    .calendar-table tbody {
      margin-top: 35px;
    }

    .drp-selected {
      display: none;
    }

    .drp-buttons {
      border: 0;
    }

    .drp-buttons .btn {
      background: #0085ff;
      border-radius: 7px;
      color: white;
      border: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      cursor: pointer;
      height: 40px;
      width: 85px;
      transition: 0.5s;
      &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      }
    }
    .drp-buttons .btn.cancelBtn {
      background: none;
      border: 1px solid #0085ff;
      color: #0085ff;
    }
  }

  .date-picker-input {
    position: relative;
    input {
      outline: 0;
      border: 0;
      height: 100%;
      width: 100%;
      text-align: center;
      color: #363636;
    }
    padding-left: 5px;
    height: 55px;
    border: 1px solid #c3cad9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    .icon {
      border-left: 1px solid #c3cad9;
      width: 55px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      cursor: pointer;
    }
    .locale-date {
      position: absolute;
      top: 18px;
      left: 0;
      width: 100%;
      padding-left: 50px;
      padding-right: 85px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .left-arrow,
  .right-arrow {
    width: 22px;
    height: 22px;
    border: 1px solid #0f8af9;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    cursor: pointer;
  }
  .left-arrow {
    margin-left: 10px;
  }
  .right-arrow {
    transform: rotate(180deg);
    margin-right: 10px;
  }
}
input {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}
</style>
