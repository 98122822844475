import * as types from '@/store/mutation-types';
export default {
  [types.SET_USER_LIST](state, payload) {
    state.userList = payload;
    state.userList = state.userList.map(u => {
      return {
        label: u.displayNameWithId,
        value: u.id,
        iconUri: u.iconUri
      }
    })
    state.userList.unshift({
      isGroup: true,
      label: "All",
      value: state.userList.map((option) => option.value),
    });    
  },
  [types.SET_TEMPLATES](state, payload) {
    state.data = payload.data;
    state.meta = payload.meta;
  },
  [types.SET_SEARCH_TEMPLATES](state, payload) {
    state.searchTemplates = payload.data;
  },
};
