<template>
  <div class="widget">
    <div class="widget-icon">
      <slot name="icon"></slot>
    </div>
    <div class="widget-line">
      <img src="@/assets/images/line.png" alt="" />
    </div>
    <div class="widget-value">
      <slot name="value"></slot>
      <div>
        <small>
          <slot name="descr"></slot>
        </small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.widget {
  background: linear-gradient(42.62deg, #2684fe 0%, #2483ff 100%);
  border-radius: 8px;
  padding: 25px 0;
  display: grid;
  grid-template-rows: 65px 1px 1fr;
  justify-content: center;
}
.widget-icon {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 0 auto;
  grid-area: 1 / 1;
}
.widget-line {
  grid-area: 2 / 1;
  img {
    display: block;
  }
}
.widget-value {
  grid-area: 3 / 1;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  text-align: center;

  justify-items: center;
  align-self: center;
  color: #ffffff;
  small {
    display: block;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    text-align: center;

    /* #FFFFFF */

    color: #ffffff;
  }
}
</style>
