<template>
  <div class="app-logo-name">
    <icon-app-logo :width="'125px'" :height="'40px'"  />
    <div class="app-name-date">
      <div class="app-name">Aneto Work Breakdown<br />Structure</div>
    </div>
  </div>
</template>
<script>
import IconAppLogo from "@/components/icons/appLogo";
import moment from "moment";
import { ref } from "vue";
export default {
  setup() {
    const today = ref(moment().format("dddd, Do of MMMM"));
    return {
      today,
    };
  },
  components: {
    IconAppLogo,
  },
};
</script>
<style scoped lang="scss">
.app-logo-name {
  width: 100%;
  max-width: 1370px;  
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15px;
  margin-bottom: 0;
  padding-top: 0;
}
.app-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;  
  color: #363636;
}
.date {
  font-size: 14px;
  color: #b4b4b4;
}
</style>
