<template>
  <svg
    viewBox="0 0 68 68"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
  >
    <g fill="none">
      <circle cx="34" cy="34" r="34" fill="#0085FF" />
      <path
        d="M46.996 38.407c-.114-.348-.281-.654-.511-.752-.592-.25-1.365.491-2.092.84h-.007c-.728.695-1.615.925-2.61.925a4.114 4.114 0 0 1-4.133-4.118c0-2.283 1.856-4.149 4.14-4.149 1.005 0 1.928.352 2.645.95l.003-.014c.714.564 1.473.947 2.064.697.227-.098.39-.38.505-.728-.143-4.532-.54-8.065-.623-8.754-1.236.132-4.03.404-7.508.536-.616-.101-1.267-.293-1.424-.665-.25-.592.136-1.34.696-2.054l-.014-.003a4.148 4.148 0 0 0-3.195-6.788c-2.284 0-4.16 1.845-4.16 4.132 0 .995.195 1.883.891 2.61v.007c.696.728 1.125 1.49.874 2.082-.157.372-.797.567-1.4.668-4.378-.177-7.675-.595-8.096-.65.014.11.48 3.888.637 8.876-.115.348-.275.63-.504.727-.592.25-1.351-.136-2.065-.696l-.003.014a4.107 4.107 0 0 0-2.646-.95 4.156 4.156 0 0 0-4.138 4.152 4.116 4.116 0 0 0 4.131 4.118c.996 0 1.884-.23 2.611-.926h.007c.728-.348 1.5-1.086 2.092-.839.23.098.393.407.512.752-.164 4.78-.63 8.445-.679 8.803.066-.01 19.33-.042 23.322-.01.052-.4.515-4.045.678-8.793z"
        fill="#FDFDFE"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
  },
};
</script>
