<template>
  <svg
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.39814 3.31547C6.39814 4.87102 5.11574 6.13184 3.53241 6.13184C1.95001 6.13184 0.666687 4.87102 0.666687 3.31547C0.666687 1.76082 1.95001 0.5 3.53241 0.5C5.11574 0.5 6.39814 1.76082 6.39814 3.31547ZM16.0778 2.08194C16.7704 2.08194 17.3334 2.63502 17.3334 3.31547C17.3334 3.99682 16.7704 4.54991 16.0778 4.54991H10.5982C9.90469 4.54991 9.34173 3.99682 9.34173 3.31547C9.34173 2.63502 9.90469 2.08194 10.5982 2.08194H16.0778ZM1.92316 11.2983H7.40276C8.09627 11.2983 8.65924 11.8514 8.65924 12.5327C8.65924 13.2132 8.09627 13.7672 7.40276 13.7672H1.92316C1.22965 13.7672 0.666687 13.2132 0.666687 12.5327C0.666687 11.8514 1.22965 11.2983 1.92316 11.2983ZM14.4676 15.3148C16.051 15.3148 17.3334 14.054 17.3334 12.4993C17.3334 10.9438 16.051 9.68297 14.4676 9.68297C12.8852 9.68297 11.6019 10.9438 11.6019 12.4993C11.6019 14.054 12.8852 15.3148 14.4676 15.3148Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
