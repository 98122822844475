<template>
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_647_2106)">
      <path
        d="M14.944 10.5159L5.53467 8.85547V21.1246C5.53467 21.685 5.98889 22.1392 6.54921 22.1392H22.7852C23.3456 22.1392 23.7998 21.685 23.7998 21.1246V16.6043L14.944 10.5159Z"
        fill="#185C37"
      />
      <path
        d="M14.944 0H6.54921C5.98889 0 5.53467 0.454224 5.53467 1.01454V5.53488L14.944 11.0698L19.9254 12.7302L23.7998 11.0698V5.53488L14.944 0Z"
        fill="#21A366"
      />
      <path d="M5.53467 5.53516H14.944V11.07H5.53467V5.53516Z" fill="#107C41" />
      <path
        opacity="0.1"
        d="M12.2689 4.42773H5.53467V18.2649H12.2689C12.8284 18.2631 13.2816 17.81 13.2834 17.2504V5.44228C13.2816 4.88272 12.8284 4.42955 12.2689 4.42773Z"
        fill="#3E5571"
      />
      <path
        opacity="0.2"
        d="M11.7155 4.98047H5.53467V18.8177H11.7155C12.275 18.8159 12.7282 18.3627 12.73 17.8031V5.99501C12.7282 5.43545 12.275 4.98229 11.7155 4.98047Z"
        fill="#3E5571"
      />
      <path
        opacity="0.2"
        d="M11.7155 4.98047H5.53467V17.7107H11.7155C12.275 17.7089 12.7282 17.2557 12.73 16.6962V5.99501C12.7282 5.43545 12.275 4.98229 11.7155 4.98047Z"
        fill="#3E5571"
      />
      <path
        opacity="0.2"
        d="M11.162 4.98047H5.53467V17.7107H11.162C11.7215 17.7089 12.1747 17.2557 12.1765 16.6962V5.99501C12.1747 5.43545 11.7215 4.98229 11.162 4.98047Z"
        fill="#3E5571"
      />
      <path
        d="M1.01454 4.98047H11.1622C11.7225 4.98047 12.1767 5.43469 12.1767 5.99501V16.1427C12.1767 16.703 11.7225 17.1572 11.1622 17.1572H1.01454C0.454224 17.1572 0 16.703 0 16.1427V5.99501C0 5.43469 0.454224 4.98047 1.01454 4.98047Z"
        fill="url(#paint0_linear_647_2106)"
      />
      <path
        d="M3.14209 14.3674L5.27634 11.0603L3.32087 7.77148H4.89388L5.961 9.87474C6.05953 10.0745 6.12705 10.2229 6.16359 10.3208H6.17742C6.24753 10.1614 6.32133 10.0067 6.39882 9.85647L7.53955 7.7726H8.9836L6.97831 11.0421L9.03453 14.3674H7.49805L6.26543 12.0588C6.20737 11.9606 6.1581 11.8574 6.1182 11.7505H6.09994C6.06383 11.8552 6.01593 11.9555 5.95714 12.0494L4.68799 14.3674H3.14209Z"
        fill="white"
      />
      <path
        d="M22.7856 0H14.9443V5.53488H23.8001V1.01454C23.8001 0.454224 23.3459 0 22.7856 0Z"
        fill="#33C481"
      />
      <path
        d="M14.9443 11.0703H23.8001V16.6052H14.9443V11.0703Z"
        fill="#107C41"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_647_2106"
        x1="2.11535"
        y1="4.18772"
        x2="10.0614"
        y2="17.95"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#18884F" />
        <stop offset="0.5" stop-color="#117E43" />
        <stop offset="1" stop-color="#0B6631" />
      </linearGradient>
      <clipPath id="clip0_647_2106">
        <rect width="23.8" height="22.1395" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>