<template>
  <svg
    :opacity="opacity"
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0612 7.93752C8.73979 6.6161 6.18206 6.51592 4.68342 8.01456C4.5059 8.19208 3.92076 8.77723 3.77429 8.9237C2.26799 10.43 2.2335 12.8377 3.69725 14.3015C4.95859 15.5628 6.92088 15.7117 8.4033 14.7649C8.64123 14.613 8.86673 14.4328 9.07504 14.2244"
      stroke="#2684FE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.93945 10.0589C9.26087 11.3803 11.8186 11.4805 13.3173 9.98182C13.4948 9.80432 14.0799 9.21915 14.2263 9.07273C15.7327 7.56638 15.7672 5.15868 14.3034 3.69491C13.0421 2.43358 11.0798 2.28474 9.59738 3.23153C9.35945 3.38349 9.1339 3.56365 8.92559 3.77197"
      stroke="#2684FE"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: String,
      default: "",
    },
  },
};
</script>
