<template>
  <svg
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 10 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.03328 12.8035V1.45535"
      stroke="#C3CAD9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.43106 9.39008L5.03291 12.804L1.63477 9.39008"
      stroke="#C3CAD9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
