import { useStore } from "vuex";
import { computed } from 'vue';
export function useStatistic() {
  const store = useStore(),
    wbsState = computed(() => {
      return store.getters["wbs/wbsState"];
    }),
    projectStatistic = computed(() => {
      const data = store.getters["wbs/nodesModelMeta"];
      if (!data) return {};
      const calcOverlogPercentage = (data) => {
        let result =
          100 - data.projectTimeSpentPercentage < 0
            ? Math.abs(100 - data.projectTimeSpentPercentage)
            : 0;

        return result > 100 ? 100 : result;
      };

      return {
        projectDoneOriginalEstimateInStoryPoints:
          data.projectDoneOriginalEstimateInStoryPoints,
        calcTimePerc: data.projectTimeSpentPercentage,
        spent: data.projectTimeSpent,
        projectProgress: data.projectProgress,
        spentStr: data.projectTimeSpentFormatted,
        calcOverLog: calcOverlogPercentage(data),
        calcRemaining:
          wbsState.value?.estimationType == "story_points"
            ? 0
            : data.projectRemainingEstimatePercentage,
        remaining: data.projectRemainingEstimate,
        remainingStr: data.projectRemainingEstimateFormatted,
        estimate: data.projectOriginalEstimate,
        estimateStr: data.projectOriginalEstimateFormatted,
        projectProgressStr: data.projectProgressFormatted,
      };
    });
  return {
    projectStatistic
  }
}