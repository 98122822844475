<template>
  <div v-click-outside="(e) => onClickOutSide(e)" class="confirmation" :style="position" data-testid="delete-node-confirmation">
    <p>{{ text ? text : translate("wbs.remove_node") }}</p>
    <div v-if="showDeleteFromJira">
      <base-check-box v-model="isDeleteFromJira">{{
          translate("delete_from_jira")
      }}</base-check-box>
    </div>
    <br />
    <div class="actions">
      <app-button :color="'default'" @click="cancel">{{ translate("no") }}</app-button>
      <app-button data-testid="delete-node-confirmation-submit" :color="'red'" @click="submit">{{ translate("yes") }}
      </app-button>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import AppButton from "@/components/shared/Button.vue";
import BaseCheckBox from "@/components/forms/elements/BaseCheckBox.vue";
export default {
  components: { AppButton, BaseCheckBox },
  inject: ["translate"],
  props: {
    text: {
      type: String,
      default: null,
    },
    showDeleteFromJira: {
      type: Boolean,
      default: true,
    },
    position: {
      type: Object,
      default: () => { },
    },
  },
  emits: ["onSubmit", "onCancel", "onClickOutSide"],
  setup(props, { emit }) {
    const isDeleteFromJira = ref(false);
    const submit = () => {
      emit("onSubmit", isDeleteFromJira.value);
    };
    const cancel = () => {
      emit("onCancel");
    };
    const onClickOutSide = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("confirmation") ||
              p.classList.contains("cell-value"))
          );
        })
      )
        return;

      emit('onClickOutSide')
    }
    return {
      isDeleteFromJira,
      submit,
      cancel,
      onClickOutSide,
    };
  },
};
</script>
<style lang="scss" scoped>
.confirmation {
  display: none;
  position: absolute;
  width: 250px;
  padding: 15px;
  border-radius: 4px;
  z-index: 99;
  background: white;
  box-shadow: 0px 1px 3px 1px #0000003d;

  .actions {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 5px;

    button {
      height: 40px;
    }
  }
}
</style>
