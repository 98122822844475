<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 3882 1156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle r="11" transform="matrix(-1 0 0 1 3571 779)" fill="#4BA9FF" />
    <rect x="3260" y="779" width="622" height="234" rx="40" fill="#FFC54A" />
    <circle cx="3333" cy="852" r="43" fill="#E4AF3E" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3348.44 838.265C3348.44 846.832 3341.57 853.701 3333 853.701C3324.43 853.701 3317.56 846.832 3317.56 838.265C3317.56 829.699 3324.43 822.833 3333 822.833C3341.57 822.833 3348.44 829.699 3348.44 838.265ZM3333 881.167C3320.35 881.167 3309.67 879.11 3309.67 871.177C3309.67 863.241 3320.42 861.257 3333 861.257C3345.65 861.257 3356.33 863.313 3356.33 871.247C3356.33 879.183 3345.58 881.167 3333 881.167Z"
      fill="#FFC54A"
    />
    <rect x="3433" y="829" width="399" height="37" rx="18.5" fill="#E4AF3E" />
    <rect x="3433" y="919" width="267" height="11" rx="5.5" fill="#E4AF3E" />
    <rect x="3433" y="943" width="176" height="11" rx="5.5" fill="#E4AF3E" />
    <rect x="2471" y="378" width="622" height="234" rx="40" fill="#BC98FF" />
    <circle cx="2544" cy="451" r="43" fill="#A37BED" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2559.44 437.265C2559.44 445.832 2552.57 452.701 2544 452.701C2535.43 452.701 2528.56 445.832 2528.56 437.265C2528.56 428.699 2535.43 421.833 2544 421.833C2552.57 421.833 2559.44 428.699 2559.44 437.265ZM2544 480.167C2531.35 480.167 2520.67 478.11 2520.67 470.177C2520.67 462.241 2531.42 460.257 2544 460.257C2556.65 460.257 2567.33 462.313 2567.33 470.247C2567.33 478.183 2556.58 480.167 2544 480.167Z"
      fill="#BC98FF"
    />
    <rect x="2644" y="428" width="399" height="37" rx="18.5" fill="#A37BED" />
    <rect x="2644" y="518" width="267" height="11" rx="5.5" fill="#A37BED" />
    <rect x="2644" y="542" width="176" height="11" rx="5.5" fill="#A37BED" />
    <circle r="20" transform="matrix(-1 0 0 1 2782 612)" fill="#4BA9FF" />
    <path
      d="M2782 611.5V611.5C2782 649.055 2812.44 679.5 2850 679.5H3477C3527.81 679.5 3569 720.69 3569 771.5V774"
      stroke="#4BA9FF"
      stroke-width="3"
    />
    <circle r="11" transform="matrix(-1 0 0 1 2782 779)" fill="#4BA9FF" />
    <rect x="2471" y="779" width="622" height="234" rx="40" fill="#FF9446" />
    <circle cx="2544" cy="852" r="43" fill="#EB8135" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2559.44 838.265C2559.44 846.832 2552.57 853.701 2544 853.701C2535.43 853.701 2528.56 846.832 2528.56 838.265C2528.56 829.699 2535.43 822.833 2544 822.833C2552.57 822.833 2559.44 829.699 2559.44 838.265ZM2544 881.167C2531.35 881.167 2520.67 879.11 2520.67 871.177C2520.67 863.241 2531.42 861.257 2544 861.257C2556.65 861.257 2567.33 863.313 2567.33 871.247C2567.33 879.183 2556.58 881.167 2544 881.167Z"
      fill="#FF9446"
    />
    <rect x="2644" y="829" width="399" height="37" rx="18.5" fill="#EB8135" />
    <rect x="2644" y="919" width="267" height="11" rx="5.5" fill="#EB8135" />
    <rect x="2644" y="943" width="176" height="11" rx="5.5" fill="#EB8135" />
    <g opacity="0.7">
      <path
        opacity="0.2"
        d="M248.446 1028.47L469.75 644.832L385.717 491.456L0 1155.87H771.304L697.318 1028.47H248.446Z"
        fill="#2374DD"
      />
      <path
        opacity="0.2"
        d="M1342.05 1156H1167.07L670.699 301.14L534.341 536.099L448.742 387.552L671.091 0L750.818 137.321L759.43 152.071L1037.23 630.866L1050.67 653.97L1342.05 1156Z"
        fill="#2374DD"
      />
      <path
        opacity="0.2"
        d="M1064.9 1156L672.918 480.493L586.145 632.564L890.047 1156H1064.9Z"
        fill="#2374DD"
      />
    </g>
    <path
      d="M1334.08 386.832L1063.17 1006.82H880.511L853.155 599.676L679.316 1006.82H496.651L449 386.832H610.486L620.192 838.132L809.916 386.832H976.696L1003.17 835.483L1171.71 386.832H1334.08Z"
      fill="#8465BE"
    />
    <path
      d="M1817.44 547.569C1810.97 584.073 1795.97 614.395 1772.44 638.535C1748.91 662.675 1720.67 679.456 1687.72 688.876C1717.14 695.353 1740.38 709.778 1757.44 732.152C1774.5 754.525 1783.03 780.726 1783.03 810.754C1783.03 820.174 1782.15 829.3 1780.38 838.132C1771.56 889.945 1746.26 931.16 1704.49 961.776C1662.72 991.804 1609.48 1006.82 1544.77 1006.82H1256.21L1365.64 386.832H1644.49C1700.37 386.832 1743.61 398.313 1774.2 421.275C1804.79 444.238 1820.09 476.032 1820.09 516.658C1820.09 526.667 1819.21 536.971 1817.44 547.569ZM1570.36 638.535C1595.66 638.535 1615.95 632.942 1631.25 621.755C1646.54 610.568 1655.96 594.377 1659.49 573.181C1660.08 569.648 1660.37 564.938 1660.37 559.05C1660.37 541.975 1654.78 529.022 1643.6 520.191C1632.43 510.77 1615.95 506.06 1594.19 506.06H1495.35L1472.41 638.535H1570.36ZM1630.37 817.819C1631.54 810.754 1632.13 805.455 1632.13 801.922C1632.13 784.847 1625.95 771.6 1613.6 762.179C1601.25 752.17 1584.19 747.166 1562.42 747.166H1453L1428.29 886.707H1539.48C1565.36 886.707 1585.95 880.819 1601.25 869.043C1616.54 857.268 1626.25 840.193 1630.37 817.819Z"
      fill="#8465BE"
    />
    <path
      d="M2039.97 1013C1972.91 1013 1918.2 998.575 1875.84 969.725C1834.07 940.286 1813.19 898.777 1813.19 845.198C1813.19 839.898 1813.78 831.95 1814.95 821.352H1972.03C1970.26 843.726 1975.56 861.389 1987.91 874.342C2000.27 887.295 2019.09 893.772 2044.39 893.772C2067.33 893.772 2085.27 888.767 2098.22 878.758C2111.75 868.749 2118.51 854.618 2118.51 836.366C2118.51 817.525 2109.98 802.217 2092.92 790.441C2076.45 778.665 2050.56 765.418 2015.27 750.698C1981.15 736.567 1953.2 723.32 1931.44 710.956C1910.26 698.002 1891.73 680.633 1875.84 658.848C1859.96 637.064 1852.02 609.685 1852.02 576.713C1851.43 536.088 1861.43 500.761 1882.02 470.733C1902.61 440.705 1931.14 417.743 1967.62 401.846C2004.09 385.949 2045.86 378 2092.92 378C2135.28 378 2172.93 384.771 2205.87 398.313C2238.82 411.266 2264.41 430.401 2282.64 455.719C2300.88 480.448 2310 509.887 2310 544.036C2310 552.868 2309.71 559.344 2309.12 563.466H2148.51C2149.1 561.111 2149.4 557.578 2149.4 552.868C2149.4 536.382 2143.51 523.134 2131.75 513.125C2120.57 502.527 2104.98 497.228 2084.98 497.228C2063.8 497.228 2046.45 502.527 2032.92 513.125C2019.97 523.134 2013.5 536.971 2013.5 554.634C2013.5 572.298 2021.74 587.312 2038.21 599.676C2054.68 611.452 2080.27 625.288 2114.98 641.185C2149.69 657.082 2177.93 671.802 2199.7 685.344C2222.05 698.885 2241.17 717.138 2257.05 740.1C2272.94 762.474 2280.88 790.147 2280.88 823.118C2280.88 859.623 2271.17 892.3 2251.76 921.15C2232.35 950 2204.4 972.669 2167.93 989.154C2131.45 1005.05 2088.8 1013 2039.97 1013Z"
      fill="#8465BE"
    />
    <path d="M2782 612V776.5" stroke="#4BA9FF" stroke-width="3" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
  },
};
</script>
