<template>
	<div v-click-outside="(e) => clickOutSide(e)" class="issue-popup-wrapper" :data-testid="`popup-${issue.jiraIssueKey}`" @click="openPopup">
		<slot />
		<div ref="container" class="issue-popup-container" :class="{ 'is-display-right': isDisplayRight }" :style="{
			left: left,
			top: top,
			display: isDisplay ? 'grid' : 'none',
		}" :data-testid="`popup-container-${issue.jiraIssueKey}`">
			<div class="issue-data">
				<div class="issue-type">
					<img :src="issue.jiraIssueUri" alt="" />
				</div>
				<div class="issue-key">
					<a v-open-jira-issue-dialog="issue.jiraIssueKey" target="_blank" :href="issue.jiraIssueUrl">{{
						 issue.jiraIssueKey 
						}}</a>
				</div>
				<div class="status" :style="{ background: issue.jiraIssueStatus.categoryColor }">
					<span :style="{
						color: getStatusColorText(
							issue.jiraIssueStatus.categoryColor
						)
					}">{{  issue.jiraIssueStatus?.name  }}</span>
				</div>
				<div class="estimation">
					{{  issue.overview.originalEstimate  }}
				</div>
			</div>
			<div class="progress-bar-wrapper">
				<div class="perc-value">50%</div>
				<div class="progress-bar">
					<div :style="{ width: '50%' }" class="progress-bar-value"></div>
				</div>
			</div>
			<div class="period">
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M2.16667 11.3333V16.3333H0.5V0.5H8.31833C8.47305 0.500083 8.62469 0.543236 8.75627 0.624627C8.88785 0.706018 8.99417 0.822433 9.06333 0.960833L9.66667 2.16667H14.6667C14.8877 2.16667 15.0996 2.25446 15.2559 2.41074C15.4122 2.56702 15.5 2.77899 15.5 3V12.1667C15.5 12.3877 15.4122 12.5996 15.2559 12.7559C15.0996 12.9122 14.8877 13 14.6667 13H9.34833C9.19361 12.9999 9.04197 12.9568 8.91039 12.8754C8.77882 12.794 8.67249 12.6776 8.60333 12.5392L8 11.3333H2.16667ZM2.16667 2.16667V9.66667H9.03L9.86333 11.3333H13.8333V3.83333H8.63667L7.80333 2.16667H2.16667Z"
						fill="#A0A5B0" />
				</svg>
				{{  issue?.overview?.startDate  }} -
				{{  issue?.overview?.dueDate  }}
			</div>
		</div>
	</div>
</template>
<script>
import { ref } from "vue";
import vClickOutside from "@/directives/outside";
import { useStatusColor } from "@/helpers/statusColor";
import vOpenJiraIssueDialog from "@/directives/openJiraIssueDialog.js"
export default {
	directives: {
		clickOutside: vClickOutside,
		'open-jira-issue-dialog': vOpenJiraIssueDialog,
	},
	props: {
		issue: {
			type: Object,
			default: () => { },
		},
	},
	setup() {
		const top = ref("0px"),
			left = ref("0px"),
			isDisplay = ref(false),
			container = ref(null),
			isDisplayRight = ref(false),
			getStatusColorText = useStatusColor().getStatusColorText;
		const openPopup = (e) => {
			const { clientX, clientY } = e;
			const containerHeight = 115;
			isDisplay.value = !isDisplay.value;
			const diff =
				clientY + containerHeight > window.innerHeight
					? window.innerHeight - (clientY + containerHeight)
					: 0;
			left.value = `${clientX - 20}px`;
			top.value = `${clientY - Math.abs(diff) + (Math.abs(diff) > 0 ? 0 : 20)
				}px`;
			isDisplayRight.value = Math.abs(diff) > 0;
		};
		const clickOutSide = (e) => {
			const path = e.path || (e.composedPath && e.composedPath());
			if (
				path.some((p) => {
					return (
						p.classList &&
						p.classList.contains("issue-popup-container")
					);
				})
			) {
				return;
			}
			isDisplay.value = false;
		};
		return {
			top,
			left,
			isDisplay,
			container,
			isDisplayRight,
			openPopup,
			clickOutSide,
			getStatusColorText,
		};
	},
};
</script>
<style lang="scss" scoped>
.issue-popup-container {
	min-width: 200px;
	display: grid;
	gap: 15px;
	padding: 12px;
	background: #ffffff;
	box-shadow: 0px 10px 30px rgba(11, 25, 49, 0.1);
	border-radius: 4px;
	position: fixed;
	z-index: 99;
	display: none;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;

	&:before {
		content: "";
		position: absolute;
		top: -9px;
		left: 15px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 7.5px 10px 7.5px;
		border-color: transparent transparent #ffffff transparent;
	}

	&.is-display-right {
		margin-left: 30px;

		&:before {
			display: none;
		}
	}
}

img {
	display: block;
}

.issue-data {
	display: grid;
	grid-template-columns: 20px 1fr auto auto;
	align-items: center;
	gap: 5px;

	.issue-key {
		a {
			color: #0f8af9;
		}		
	}

	.estimation {
		color: #a0a5b0;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		grid-area: 1 / 4;
	}
}

.progress-bar-wrapper {
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	/* identical to box height */
	color: #ffa72b;
	display: grid;
	gap: 5px;

	.progress-bar {
		height: 4px;
		background: #ffa72b1e;
		position: relative;
	}

	.progress-bar-value {
		position: absolute;
		left: 0;
		height: 100%;
		background: #ffa72b;
	}
}

.period {
	background: rgba(0, 0, 0, 0.03);
	border-radius: 0px 0px 5px 5px;
	padding: 7px 12px;
	display: flex;
	align-items: center;
	gap: 5px;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: #a0a5b0;
	margin: -12px;
	margin-top: 0;
}

.status {
	font-size: 12px;
	line-height: 14px;
	/* identical to box height */
	cursor: pointer;
	display: block;
	margin-right: 5px;
	color: #006644;
	background: #e3fcef;
	border-radius: 4px;
	padding: 5px;
	text-align: center;
}
</style>
