export default {
  userList: state => state.userList || [],
  userAccessList: state => state.userAccessList || [],
  accessTypeOptions: state => state.accessTypeOptions || [],
  columns: state => state.columns || [],
  state: state => state,
  meta: state => state.meta || {},
  count: state => state.count,
  countOnPage: state => state.countOnPage,
};
