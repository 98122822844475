<template>
  <div class="progress-bar">
    <div class="progress-bar-value" :style="{
      width: `${spentPercent}%`,
    }">
      <div class="progress-bar-value-overlog" :style="{ width: `${overlogPercent}%` }"></div>
      <div v-if="isShowInfo" class="icon" :class="{ 'position-right': spentPercent > 80 }">
        <div class="info-tooltip">
          <div class="info-tooltip--content">
            <div v-if="projectDoneOriginalEstimateInStoryPoints">
              {{ translate("wbs.completed_story_point") }}
              <span>{{ projectDoneOriginalEstimateInStoryPoints }}</span>
            </div>
            <div>
              {{ translate("wbs.project_progress") }} <span>{{ projectProgressStr }}</span>
            </div>
            <div>
              {{ translate("wbs.original_estimate") }} <span>{{ estimateStr }}</span>
            </div>
            <div>
              {{ translate("wbs.time_spent") }} <span>{{ spentStr }}</span>
            </div>
            <div>
              {{ translate("wbs.remaining_work") }} <span>{{ remainingStr }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="progress-bar-value-remaining" :style="{ width: `${remainingPercent}%` }"></div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
export default {
  inject: ["translate"],
  props: {
    spent: {
      type: [Number, String],
      default: 0,
    },
    overlog: {
      type: [Number, String],
      default: 0,
    },
    remaining: {
      type: [Number, String],
      default: 0,
    },
    spentStr: {
      type: String,
      default: "",
    },
    estimateStr: {
      type: String,
      default: "",
    },
    remainingStr: {
      type: String,
      default: "",
    },
    projectProgressStr: {
      type: String,
      default: "",
    },
    isShowInfo: {
      type: Boolean,
      default: false,
    },
    projectDoneOriginalEstimateInStoryPoints: {
      type: [Number, String],
      default: null,
    },
    background: {
      type: String,
      default: '#f2f5f9'
    }
  },
  setup(props) {
    const spentPercent = computed(() => (isNaN(props.spent) ? 0 : props.spent)),
      overlogPercent = computed(() => (isNaN(props.overlog) ? 0 : props.overlog)),
      remainingPercent = computed(() => (isNaN(props.remaining) ? 0 : props.remaining)),
      backgroundColor = ref(props.background);
    return {
      spentPercent,
      overlogPercent,
      remainingPercent,
      backgroundColor,
    };
  },
};
</script>
<style lang="scss" scoped>
.progress-bar {
  height: 7px;
  position: relative;
  background: v-bind(backgroundColor);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  .progress-bar-value {
    position: relative;
    height: 100%;
    width: 0%;
    background: #2684fe;

    .progress-bar-value-overlog {
      position: absolute;
      right: 0;
      height: 100%;
      background: rgb(255, 171, 0);
    }
  }

  .progress-bar-value-remaining {
    position: relative;
    height: 100%;
    width: 0%;
    background: v-bind(backgroundColor);
  }
}

.icon {
  position: absolute;
  width: 12px;
  height: 12px;
  right: -2px;
  top: -3px;
  border: 2px solid #1469d7;
  border-radius: 50%;
  z-index: 1;
  background: #fff;

  &.position-right .info-tooltip {
    left: 0;
    transform: translateX(-94%);

    &:before {
      top: -7px;
      left: calc(100% - 15px);
    }
  }

  &:hover {
    .info-tooltip {
      display: flex;
    }
  }
}

.info-tooltip {
  display: none;
  position: absolute;
  background: rgba(35, 39, 44, 1);
  border-radius: 12px;
  padding: 10px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  min-height: 65px;
  align-items: center;
  font-size: 12px;
  line-height: 140%;
  color: #ffffff;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent rgba(35, 39, 44, 1) transparent;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
  }

  &--content {
    width: 100%;

    div {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      span {
        text-align: right;
      }
    }

    div+div {
      margin-top: 10px;
    }
  }
}
</style>
