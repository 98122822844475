import { createJIRASchema, isSchemaWithMedia } from "@atlaskit/adf-schema";
import { DOMParser, DOMSerializer } from "prosemirror-model";
export function useJiraTransform() {
  const jiraSchema = createJIRASchema({
    allowLists: true,
    allowMentions: true,
    allowEmojis: true,
    allowLinks: true,
    allowAdvancedTextFormatting: true,
    allowCodeBlock: true,
    allowBlockQuote: true,
    allowSubSup: true,
    allowMedia: true,
    allowTextColor: true,
    allowTables: true,
  });
  
  const nodes2Html = (content) => {
    const doc = jiraSchema.nodeFromJSON(content);
    let fragment = DOMSerializer.fromSchema(jiraSchema).serializeFragment(
      doc.content
    );
    let tmp = document.createElement("div");
    tmp.appendChild(fragment);
    return tmp.innerHTML;
  }

  const html2Nodes = (content) => {
    const clearMeta = (node) => {
      node.content.forEach(c => {
        c.marks.forEach(m => {
          if (Object.keys(m.attrs).length) {
            delete m.attrs.__confluenceMetadata
          }
        });
        if (c.content) {
          clearMeta(c.content)
        }
      })
    }
    let domNode = document.createElement("div");
    domNode.innerHTML = content;
    let nodes = DOMParser.fromSchema(jiraSchema).parse(domNode);
    clearMeta(nodes);
    return nodes;
  }

  const html2JiraMarkup = (htmlString) => {
    if (!htmlString) return;
    let jiraString = htmlString;
    // replace anchor tags
    let pattern = /<a[\s]+([^>]+)>.+?<\/a>/gi;
    let resultChanged;
    let result = jiraString.match(pattern);

    if (result && result.length >= 0) {
      result.forEach((r) => {
        const tempNode = document.createElement("div");
        tempNode.innerHTML = r;
        const newAnchor = tempNode.querySelector("a");
        const href = newAnchor.getAttribute("href");
        const text = newAnchor.innerText;
        resultChanged = `[${text}|${href}]`;

        jiraString = jiraString.replaceAll(r, resultChanged);
      });
    }

    jiraString = jiraString.replaceAll("&nbsp;", " ");
    jiraString = jiraString.replace(/<code.+?>/, "{noformat}");
    jiraString = jiraString.replaceAll("</code>", " ");
    jiraString = jiraString.replaceAll("<h1>", "h1. ");
    jiraString = jiraString.replaceAll("<h2>", "h2. ");
    jiraString = jiraString.replaceAll("<h3>", "h3. ");
    jiraString = jiraString.replaceAll("<h4>", "h4. ");
    jiraString = jiraString.replaceAll("<h5>", "h5. ");
    jiraString = jiraString.replaceAll("<h6>", "h6. ");
    jiraString = jiraString.replaceAll("</h1>", "\r");
    jiraString = jiraString.replaceAll("</h2>", "\r");
    jiraString = jiraString.replaceAll("</h3>", "\r");
    jiraString = jiraString.replaceAll("</h4>", "\r");
    jiraString = jiraString.replaceAll("</h5>", "\r");
    jiraString = jiraString.replaceAll("</h6>", "\r");
    jiraString = jiraString.replaceAll("<p>", "\r");
    jiraString = jiraString.replaceAll("</p>", "\r");

    jiraString = jiraString.replaceAll("<i>", "_");
    jiraString = jiraString.replaceAll("</i>", "_");
    jiraString = jiraString.replaceAll("<em>", "_");
    jiraString = jiraString.replaceAll("</em>", "_");
    jiraString = jiraString.replaceAll("<b>", "*");
    jiraString = jiraString.replaceAll("</b>", "*");
    jiraString = jiraString.replaceAll("<strong>", "*");
    jiraString = jiraString.replaceAll("</strong>", "*");
    jiraString = jiraString.replaceAll("<u>", "+");
    jiraString = jiraString.replaceAll("</u>", "+");
    jiraString = jiraString.replaceAll("<ins>", "+");
    jiraString = jiraString.replaceAll("</ins>", "+");
    jiraString = jiraString.replaceAll("<sup>", "^");
    jiraString = jiraString.replaceAll("</sup>", "^");
    jiraString = jiraString.replaceAll("<sub>", "~");
    jiraString = jiraString.replaceAll("</sub>", "~");
    jiraString = jiraString.replaceAll("<tt>", "{{");
    jiraString = jiraString.replaceAll("</tt>", "}}");
    jiraString = jiraString.replaceAll("<blockquote>", "{quote}");
    jiraString = jiraString.replaceAll("</blockquote>", "{quote}");
    jiraString = jiraString.replaceAll("</br>", "\r");
    jiraString = jiraString.replaceAll("<br>", "\r");
    jiraString = jiraString.replaceAll("<br/>", "\r");
    jiraString = jiraString.replaceAll("<br />", "\r");
    jiraString = jiraString.replaceAll("<br />", "\r");
    //jiraString = jiraString.replaceAll("\n", "\\");
    jiraString = jiraString.replaceAll("<pre>", " ");
    jiraString = jiraString.replaceAll("</pre>", " ");
    jiraString = jiraString.replaceAll("<hr>", "----");
    jiraString = jiraString.replaceAll("<ul>", "");
    jiraString = jiraString.replaceAll("</ul>", "\r");
    jiraString = jiraString.replaceAll("<ol>", "");
    jiraString = jiraString.replaceAll("</ol>", "\r");

    jiraString = jiraString.replaceAll("<li>", "# ");
    jiraString = jiraString.replaceAll("</li>", "\r");

    pattern = "<ol>(.|\n)*?</ol>";
    result = jiraString.match(pattern);
    resultChanged = "";
    if (result && result.length >= 0) {
      result = result[0];
      resultChanged = result.replaceAll("<ol>", "");
      resultChanged = result.replaceAll("</ol>", "");
      resultChanged = result.replaceAll("<li>", "*");
      resultChanged = result.replaceAll("</li>", "");
      jiraString = jiraString.replaceAll(result, resultChanged);
    }

    pattern = "<ul>(.|\n)*?</ul>";
    result = jiraString.match(pattern);
    if (result && result.length >= 0) {
      result = result[0];
      resultChanged = result.replaceAll("<ul>", "");
      resultChanged = result.replaceAll("</ul>", "");
      resultChanged = result.replaceAll("<li>", "#");
      resultChanged = result.replaceAll("</li>", "");
      jiraString = jiraString.replaceAll(result, resultChanged);
    }

    pattern = "<tr>(.|\n)*?<th(.|\n)*?</tr>";
    result = jiraString.match(pattern);
    if (result && result.length >= 0) {
      result = result[0];
      resultChanged = result.replaceAll("<tr>", "");
      resultChanged = result.replaceAll("<th>", "||");
      resultChanged = result.replaceAll("</th>", "");
      resultChanged = result.replaceAll("</tr>", "||\\");
      jiraString = jiraString.replaceAll(result, resultChanged);
    }

    pattern = "<tr>(.|\n)*?<td(.|\n)*?</tr>";
    result = jiraString.match(pattern);
    if (result && result.length >= 0) {
      result = result[0];
      resultChanged = result.replaceAll("<tr>", "");
      resultChanged = result.replaceAll("<td>", "|");
      resultChanged = result.replaceAll("</td>", "");
      resultChanged = result.replaceAll("</tr>", "|\\");
      jiraString = jiraString.replaceAll(result, resultChanged);
    }
    return jiraString;
  };
  return { html2JiraMarkup, nodes2Html, html2Nodes }
}