<template>
  <div class="top-panel" data-testid="top-panel" :style="{ width: `calc(100% - ${navBarWidth}px` }">
    <div class="controls">
      <div class="title">
        <div class="project-icon">
          <img v-if="wbsState?.iconUri" :src="wbsState?.iconUri" alt="" />
          <icon-default v-else :width="'35px'" :height="'35px'" />
        </div>
        <div v-tooltip="{
          content: wbsState.name,
          placement: 'bottom'
        }" class="link">
          {{ wbsState.name }}
        </div>
        <div class="info-icons">
          <div class="info info-btn" :class="{ active: displayInfo }" v-click-outside="(e) => closeInfo(e)"
            @click="showInfo">
            <icon-error v-if="isImportError" :width="'28px'" :height="'28px'" :fill="'#d81d1d'" />
            <icon-info v-else :width="'17px'" :height="'17px'" />
          </div>
          <div :class="{ 'display-info': displayInfo }">
            <div class="info-popup">
              <app-import-error v-if="isImportError" @onClose="displayInfo = false" />
              <app-info v-else @onClose="displayInfo = false" />
            </div>
          </div>
        </div>
        <div v-tooltip="{ content: translate('wbs.edit_project'), placement: 'bottom', }" class="panel-btn"
          data-testid="edit-project" @click="
            callAction(source == 'projects' ? 'editProject' : 'editTemplate')
          ">
          <icon-pencil fill="transparent" :stroke="'#A0A5B0'" :width="'15px'" />
        </div>
      </div>
      <div class="actions">
        <div v-if="source == 'projects'" class="controls-frame">
          <div v-tooltip="{
              content: translate('wbs.schedule_management'),
              placement: 'bottom',
            }" class="panel-btn tree-horizontal" :class="{ active: ['schedule-critical-path', 'schedule'].includes(viewMode) }"
            data-testid="dispay-as-gantt"
            @click="callAction('displaySchedule')">
            <icon-schedule :fill="'#0f8af9'" :width="'18px'" :height="'18px'" />
          </div>
          <div v-if="['schedule-critical-path', 'schedule'].includes(viewMode)"
            v-tooltip="{
              content: translate('wbs.schedule_critical_path'),
              placement: 'bottom',
            }" class="panel-btn tree-horizontal" :class="{ active: viewMode == 'schedule-critical-path' }"
            @click="callAction('displayScheduleCriticalPath')">
            <icon-schedule-critical-path :fill="'#23252A'" :width="'18px'" :height="'18px'" />
          </div>          
        </div>
        <div v-if="['list', 'schedule', 'schedule-critical-path'].includes(viewMode) == false" class="controls-frame">
          <div v-tooltip="{
            content: translate('wbs.zoom_out'),
            placement: 'bottom',
          }" data-testid="zoom-out" class="panel-btn" :class="{ active: zoomInd < 8 }"
            @click="callAction('zoomOut')">
            <icon-zoom-out :width="'18px'" :height="'18px'" />
          </div>
          <div v-tooltip="{
            content: translate('wbs.zoom_reset'),
            placement: 'bottom',
          }" data-testid="zoom-reset" class="panel-btn"
            :class="{ active: zoomInd == 8 }" @click="callAction('zoomReset')">
            <icon-zoom-reset :width="'18px'" :height="'18px'" />
          </div>
          <div v-tooltip="{
            content: translate('wbs.zoom_in'),
            placement: 'bottom',
          }" data-testid="zoom-in" class="panel-btn" :class="{ active: zoomInd > 8 }"
            @click="callAction('zoomIn')">
            <icon-zoom-in :width="'18px'" :height="'18px'" />
          </div>
          <div v-tooltip="{
            content: translate('wbs.bring_to_center'),
            placement: 'bottom',
          }" data-testid="bring-to-center" class="panel-btn"
            @click="callAction('bringToCenter')">
            <icon-bring-to-center :width="'18px'" :height="'18px'" />
          </div>
          <div class="panel-btn project-filter">
            <div v-tooltip="{
              content: translate('wbs.filter'),
              placement: 'bottom',
            }" :class="{ active: isShowFilter, 'is-filtered': isFiltered }" class="panel-btn open-filter"
              data-testid="open-filter" @click="callAction('openFilter')">
              <span v-if="isFiltered" class="badge"></span>
              <icon-filter :width="'42px'" :height="'16px'" :fill="isShowFilter || isFiltered ? '#fff' : '#23252A'" />
            </div>
          </div>
          <div class="panel-btn print-download">
            <app-print-wbs @onPngExport="callAction('save-png')" @onPdfExport="callAction('save-pdf')"
              @onPrintPdf="callAction('print-pdf')" />
          </div>
        </div>
        <div class="controls-frame">
          <app-button v-if="source == 'projects'" v-tooltip="{
            content: translate('wbs.import_from_jira'),
            placement: 'bottom',
          }" :disabled="isImportRun" class="panel-btn" :class="{ 'loader': isImportRun }"
            @click="callAction('launchImport')">
            <icon-import :width="'50px'" :height="'20px'" />
          </app-button>
        </div>        
        <div class="controls-frame" style="opacity:0;pointer-events: none;z-index:-1;">
          <div v-tooltip="{
              content: translate('wbs.display_horizontal'),
              placement: 'bottom',
            }" class="panel-btn tree-horizontal" :class="{ active: viewMode == 'horizontal' }"
            @click="callAction('displayTreeAsHorizontal')">
            <icon-tree :fill="'#0f8af9'" :width="'18px'" :height="'18px'" />
          </div>
          <div v-tooltip="{
              content: translate('wbs.display_vertical'),
              placement: 'bottom',
            }" class="panel-btn" :class="{ active: treeView == 'vertical' }"
            @click="callAction('displayTreeAsVertical')">
            <icon-tree :fill="'#0f8af9'" :width="'18px'" :height="'18px'" />
          </div>
          <div v-tooltip="{
              content: translate(
                viewMode == 'list'
                  ? 'wbs.display_tree_view'
                  : 'wbs.display_list_view'
              ),
              placement: 'bottom',
            }" class="panel-btn" :disabled="disabledChangeView" :class="{ active: viewMode == 'list' }"
            data-testid="dispay-as-list"
            @click="callAction('displayAsList')">
            <icon-list-view :fill="'#0f8af9'" :width="'18px'" :height="'18px'" />
          </div>
        </div>
      </div>
      <div class="sessions-share">
        <app-session v-if="source == 'projects'" />
        <div class="share">
          <!-- <div class="share-btn" :color="'primary'" @click="callAction('openShareModal')">
            <icon-share width="19px" />
            {{ translate("wbs.share_button") }}
          </div> -->          
          <share-button :id="wbsState.id" :source="source" @getReportLink="getReportLink" />
        </div>
      </div>
    </div>

    <slot />
  </div>
</template>
<script>
import AppButton from "@/components/shared/Button.vue";
import AppSession from "@/components/wbsProject/Session.vue";

import IconBringToCenter from "@/components/icons/IconBringToCenter.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import IconZoomOut from "@/components/icons/IconZoomOut.vue";
import IconZoomReset from "@/components/icons/IconZoomReset.vue";
import IconZoomIn from "@/components/icons/IconZoomIn.vue";
import IconTree from "@/components/icons/IconTree.vue";
import IconListView from "@/components/icons/IconListView.vue";
import IconImport from "@/components/icons/IconImport.vue";
import IconPencil from "@/components/icons/IconPencil.vue";
import IconError from "@/components/icons/IconError.vue";
import IconDefault from "@/components/icons/IconDefault.vue";
import IconShare from "@/components/icons/IconShare.vue";
import IconFilter from "@/components/icons/IconFilter.vue";
import IconSchedule from "@/components/icons/IconSchedule.vue";
import IconScheduleCriticalPath from "@/components/icons/IconScheduleCriticalPath.vue";
import AppPrintWbs from "@/components/wbsProject/PrintWbs.vue";
import AppInfo from "@/components/wbsProject/Information.vue";
import AppImportError from "@/components/wbsProject/ImportError.vue";
import { computed, onMounted, onUnmounted, reactive, toRefs } from "vue";
import ShareButton from '@/components/shared/ShareButton.vue';
import { useStore } from "vuex";
import errorToList from "@/helpers/errorToList";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: "top-right" });
export default {
  components: {
    AppButton,
    IconInfo,
    IconZoomOut,
    IconZoomReset,
    IconZoomIn,
    IconTree,
    IconListView,
    IconBringToCenter,
    IconImport,
    IconPencil,
    IconError,
    IconDefault,
    IconShare,
    IconFilter,
    IconSchedule,
    IconScheduleCriticalPath,
    AppSession,
    AppPrintWbs,
    AppInfo,
    AppImportError,
    ShareButton,
  },
  inject: ["translate"],
  props: {
    viewMode: {
      type: String,
      default: "tree",
    },
    activeUndoRedo: {
      type: Object,
      default: () => { },
    },
    zoomInd: {
      type: Number,
    },
    treeView: {
      type: String,
      required: false,
    },
    isShowFilter: {
      type: Boolean,
      default: false,
    },
    isFiltered: {
      type: Boolean,
      default: false,
    },
    filteredCount: {
      type: Number,
      default: null,
    },
    isShowNewNode: {
      type: Boolean,
      default: true,
    },
    projectStatistic: {
      type: Object,
      default: null,
    },
    isImportError: {
      type: Boolean,
      default: false,
    },
    isImportRun: {
      type: Boolean,
      default: false,
    },
    disabledChangeView: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onAction"],
  setup(props, { emit }) {
    const store = useStore(),
      leftSideBar = computed(() => store.getters['app/isShowSideBar'] ? '231px' : '81px'),
      state = reactive({
        source: computed(() => store.getters["wbs/source"]),
        wbsState: computed(() => store.getters["wbs/wbsState"]),
        nodesCount: computed(() => store.getters["wbs/nodes"].length),
        displayInfo: false,
        navBarWidth: computed(() => store.getters["app/navBarWidth"]),
      });

    const callAction = (name) => {
      emit("onAction", name);
    };

    const showInfo = (e) => {
      state.displayInfo = !state.displayInfo;
    };

    const closeInfo = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("info-popup") ||
              p.classList.contains("info-btn") ||
              p.classList.contains("info-content"))
          );
        })
      )
        return;

      state.displayInfo = false;
    };
    const onkeydown = (e) => {
      if (e.key == "Escape") state.displayInfo = false;
    };
    const getReportLink = async (value) => {
      try {
        const response = await store.dispatch('wbs/getProjectsReportLink', { type:value, id: state.wbsState.id, })          
        if (response?.link)
          window.open(response.link, "_blank");
      } catch (error) {
				toaster.error( `Error report download`,{position: "top-right",});
      }          
    }
    onMounted(() => {
      document.addEventListener("keydown", onkeydown);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", onkeydown);
    });
    return {
      ...toRefs(state),
      callAction,
      showInfo,
      closeInfo,
      leftSideBar,
      getReportLink,
    };
  },
};
</script>
<style lang="scss" scoped>
.top-panel {
  position: fixed;
  z-index: 999;
  top: 0;

  left: v-bind(leftSideBar);

  &:before {
    content: "";
    width: 100%;
    height: 20px;
    top: -20px;
    background: #f2f5f9;
    position: absolute;
  }

  .controls {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 10px;
    background: rgba(255, 255, 255, 1);
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: auto auto 1fr;
    margin-bottom: 5px;
  }

  button {
    height: 41px;
    width: 145px;

    &.small-btn {
      width: 70px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 14px;
    display: grid;
    align-items: center;
    grid-column-gap: 5px;
    color: #363636;
    grid-template-columns: 40px auto 40px 40px;
    justify-content: start;

    .name {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */


      color: #23252A;
    }

    .link {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    strong {
      font-size: 19px;
      font-weight: 600;
      line-height: 19px;
    }

    a {
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      text-decoration: none;
      color: #0f8af9;
    }
  }

  .info {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active {
      background: #f2f5f9;
      border: 1px solid #2684fe;
      border-radius: 5px;
    }
  }



  .actions {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-column-gap: 15px;
    justify-self: center;

    .panel-btn {
      width: 54px;
      height: 34px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      background: #F3F6FF;

      &.active {
        background: #136AFD;

        svg {
          fill: white;

          ::v-deep(path) {
            fill: white;
          }
        }
      }

      &.is-filtered {
        background: #2684fe;
        border-radius: 5px;

        position: relative;

        .badge {
          position: absolute;
          right: 2px;
          top: 1px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #e55e5d;
          color: white;
          font-size: 12px;
        }
      }
    }

    .users {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      grid-column-gap: 5px;
      justify-self: end;

      .users-item {
        border: 2px solid #2684fe;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .users-item img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
  }

  .undo-redo-download {
    grid-column-gap: 10px;

    .panel-btn.undo,
    .panel-btn.redo {
      pointer-events: none;

      &.active {
        pointer-events: auto;
      }
    }
  }

  .tree-horizontal {
    svg {
      transform: rotate(-90deg);
    }
  }

  .share {
    justify-self: end;

    .share-btn {
      padding: 0 15px;
      height: 34px;
      cursor: pointer;
      background: #F2F2F2;
      border-radius: 31px;
      border: 0;
      display: flex;
      align-items: center;
      outline: none;
      gap: 5px;
      justify-content: center;
      font-size: 14px;
      line-height: 18px;
      color: #23252A;
    }
  }

  .loader {
    display: flex;
    align-items: center;

    svg {
      animation: spin 1s linear infinite;
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .info-btn {
    position: relative;

    &.active {
      .info-popup {
        display: block;
      }
    }
  }

  .info-popup {
    display: none;
    position: absolute;
    top: 9px;
    left: 9px;
    padding-top: 10px;
  }

  .display-info {
    position: relative;

    .info-popup {
      display: block;
    }
  }

  .nodes-count,
  .project-perc {
    font-weight: 400;
    font-size: 14px;
    color: #0f8af9;

    span {
      font-weight: 600;
    }
  }
}

.panel-btn {
  &[disabled="true"] {
    opacity: 0.5;
    pointer-events: none;
  }
}

.sessions-share {
  display: flex;
  align-items: center;
  justify-self: end;
}

.controls-frame {
  display: flex;
  background: #ffffff;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  gap: 2px;
}

.project-icon {
  img {
    width: 36px;
    height: 36px;
    object-fit: cover;
  }
}
</style>
