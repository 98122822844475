<template>
  <div class="modal-body" data-testid="select-new-top-node">
    <div class="close" @click="closeModal">
      <icon-close :width="'10px'" :height="'10px'" :fill="'#0085FF'" />
    </div>
    <div class="title">{{ translate("modal.select_parent_node") }}</div>
    <div class="text">
      {{ translate("modal.select_parent_node_description") }}
    </div>
    <app-select-box
      v-model="nodeId"
      :options="list"
      :placeholder="translate('modal.select_parent_node')"
      :selectedText="translate('selected')"
      label-by="name"
      value-by="id"
      :clear-on-close="true"
      :close-on-select="true"
      :small-font="true"
      :searchable="true"
      :min="1"
    >
      <template #selected-item>
        <div class="selected-item-value" v-if="nodeSelected">
          <img :src="nodeSelected.iconUrl" alt="" />{{ nodeSelected.name }}
        </div>
      </template>
      <template v-slot:default="item">
        <div class="image-item">
          <img v-if="item.option.iconUrl" :src="item.option.iconUrl" alt="" />
          {{ item.option.name }}
        </div>
      </template>
    </app-select-box>
    <div class="actions">
      <app-button color="default" @click="closeModal">
        {{ translate("modal.cancel") }}
      </app-button>
      <app-button :disabled="!isRequired" color="red" @click="submit">{{
        translate("modal.submit")
      }}</app-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from "vue";
import { useModalHelper } from "@/helpers/modalHelper";
import AppButton from "@/components/shared/Button";
import AppBaseCheckBox from "@/components/forms/elements/BaseCheckBox.vue";
import IconClose from "@/components/icons/IconClose.vue";
import AppSelectBox from "vue-select-box";
export default {
  components: { AppButton, IconClose, AppBaseCheckBox, AppSelectBox },
  props: {
    childrenList: {
      type: Array,
      default: [],
    },
  },
  inject: ["translate"],
  setup(props) {
    const { closeModal, submitModal } = useModalHelper(),
      state = reactive({
        nodeId: null,
        isRequired: computed(() => state.nodeId !== null),
        nodeSelected: computed(() => {
          const item = state.list.find((t) => t.id == state.nodeId);
          return item;
        }),
        list: computed(() =>
          props.childrenList.map((node) => {
            return {
              id: node.id,
              iconUrl: node?.jiraIssueType?.iconUri,
              name:
                (node.jiraIssueKey ? node.jiraIssueKey + " - " : "") +  node.summary,
            };
          })
        ),
      });

    const submit = () => {
      submitModal({
        nodeId: state.nodeId,
      });
      closeModal();
    };
    return {
      submit,
      closeModal,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
  max-width: 500px;
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -21px;
    top: -14px;
    border: 1px solid #0085ff;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #0085ff;
      svg {
        fill: white;
      }
    }
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .text {
    margin: 25px;
    font-weight: normal;
    font-size: 12px;
    line-height: 200%;
    text-align: center;
    color: #363636;
  }
  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    margin-top: 10px;
    .button {
      height: 55px;
      width: 100%;
    }
  }
}
.selected-item-value {
  width: 100%;
  height: 40px;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-column-gap: 15px;
  align-items: center;
  padding-left: 15px;
  padding-top: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  img {
    width: 16px;
    height: 16px;
  }
}
</style>
