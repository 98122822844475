<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3381 0.833496C18.3482 0.833496 19.1668 1.65666 19.1668 2.65766V17.3427C19.1668 18.3501 18.3482 19.1668 17.3381 19.1668H2.66683V15.5002H0.833496V13.6668H2.66683V10.9168H0.833496V9.0835H2.66683V6.3335H0.833496V4.50016H2.66683V0.833496H17.3381ZM6.3335 2.66683H4.50016V17.3335H6.3335V2.66683ZM17.3335 2.66683H8.16683V17.3335H17.3335V2.66683Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "#fff",
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>