<template>
  <div class="jira-filter">
    <div class="filter-select-box">
      <slot />
    </div>
    <div class="filter-string">      
      <div class="input-field-wrapper">
        <input
          type="text"
          :disabled="disabled"
          readonly
          @click="openJql"
          v-model="filterString"
        />
      </div>
      <div class="filter-error" v-if="filterError">
        {{ filterError }}
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, watch } from "vue";
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change", "onError", "update:modelValue"],
  setup(props, { emit }) {
    const state = reactive({
      filterString: props.modelValue,
      filterError: "",
    });

    watch(
      () => props.modelValue,
      (current) => {
        state.filterString = current;
        state.filterError = null;
      }
    );
    watch(
      () => state.filterString,
      (current) => {
        emit("update:modelValue", current);
      }
    );

    const openJql = () => {
      if (config.isLocal) return;

      const options = {
        jql: state.filterString,
        header: "JQL Filter",
        submitText: "Ok",
        cancelText: "Cancel",
      };

      const callback = (obj) => {
        state.filterError = null;

        AP.request({
          url: "/rest/api/latest/jql/parse",
          type: "POST",
          contentType: "application/json",
          data: JSON.stringify({
            queries: [obj.jql],
          }),
          success: function (responseText) {
            const response = JSON.parse(responseText);
            if (response.queries.length) {
              if (response.queries[0].errors && response.queries[0].errors.length) {
                state.filterError = response.queries[0].errors[0];
                emit("onError", state.filterError);
              } else {
                state.filterString = obj.jql;
                emit("change", state.filterString);
              }
            }
          },
          error: function (xhr, statusText, errorThrown) {
            state.filterError = "Something went wrong";
            emit("onError", state.filterError);
          },
        });
      };
      AP.jira.showJQLEditor(options, callback);
    };
    return {
      ...toRefs(state),
      openJql,
    };
  },
};
</script>
<style lang="scss" scoped>
.input-field-wrapper {
  margin-top: 5px;
  outline: none;
  display: flex;
  align-items: center;
  height: 45px;
  position: relative;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 8px;
  transition: 0.5s;
}
.filter-string {
  flex-grow: 1;
}
input {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  outline: 0;
  background: transparent;
  padding-left: 15px;
  font-size: 12px;
  line-height: 18px;
  position: relative;
  z-index: 1;
  border: 0;
  &[disabled] {
    opacity: .5;
  }
}
.jira-filter {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.filter-error {
  color: red;
  font-size: 14px;
}
</style>
