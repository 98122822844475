import axios from 'axios';
import * as types from '@/store/mutation-types';

export default {
  getDashboard({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/instance/dashboard`)
        .then((response) => {
          const data = response.data;
          commit(types.SET_DASHBOARD, data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
