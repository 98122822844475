<template>
  <button class="button" :class="color" @click="onClick">
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButtonComponent",
  emits: ["click"],
  props: {
    color: {
      type: String,
      default: "primary",
      validator: function (value) {
        return ["primary", "default", "purple", "red"].indexOf(value) !== -1;
      },
    },
  },
  setup(props, { emit }) {
    const onClick = (e) => {
      emit("click", e);
    };
    return {
      onClick,
    };
  },
};
</script>

<style lang="scss" scoped>
@mixin button-style {
  border-radius: 8px;
  transition: 0.5s;
  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.button {
  text-decoration: none;
  @include button-style();
  &.primary {
    background: #0f8af9;
    color: #ffffff;
  }
  &.purple {
    background: #a283e5;
    color: #ffffff;
  }
  &.default {
    background: transparent;
    border: 1px solid #0f8af9;
    color: #0f8af9;
    &.purple {
      background: transparent;
      color: #a283e5;
      border: 1px solid #a283e5;
    }
  }
  &.red {
    background: rgb(235, 117, 113);
    color: white;
    border: 0;
  }
  display: grid;
  grid-auto-flow: column;
  height: 45px;
  border: 0;
  outline: none;
  width: 100%;
  align-items: center;
  cursor: pointer;
  justify-items: center;
  justify-content: center;
  grid-gap: 15px;
  font-size: 14px;
  line-height: 21px;
  i {
    display: block;
    width: 12px;
    height: 12px;
  }
}
</style>
