<template>
  <div class="table-container">
    <div class="table">
      <!-- Table Header -->
      <div class="table-header">
        <slot name="header">
          <div class="columns">
            <div class="column" v-for="(item, index) in header" :key="index">
              {{ item }}
            </div>
          </div>
        </slot>
      </div>
      <!-- Table Body -->
      <div class="table-body">
        <!-- Slot for the row -->
        <slot name="row" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    header: {
      type: [Array],
      default: [],
    },
  },
  emits: ["onChangeFilter"],
  components: {},
  setup(props, { emit }) {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  position: relative;
}

.table-header {
  .columns {
    display: grid;
    align-items: end;
    grid-template-columns: 1fr repeat(2, 100px);
    height: 100%;
    grid-column-gap: 15px;
  }
  .column {
    font-weight: 500;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #727a81;
  }
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 15px;
  height: 65px;
  position: relative;
}

svg {
  cursor: pointer;
}
.table-body {
  max-height: 325px;
  overflow: auto;
}
</style>
