<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 8 7"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.61621 0.5V6.45891" :stroke="stroke" stroke-linecap="round" />
    <path d="M6.51074 3.47943L0.72209 3.47943" :stroke="stroke" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "",
    },
    stroke: {
      type: String,
      default: "white",
    },
  },
};
</script>
