<template>
  <div
    class="input-field-wrapper"
    :class="{ 'input-field-wrapper-icon': slots, focus: isFocus }"
  >
    <textarea
      type="text"
      v-model="value"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      @focus="onFocus"
      @blur="onBlur"
      @input="onChange"
    />
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
export default {
  props: {
    modelValue: String,
    placeholder: String,
    readonly: Boolean,
    required: Boolean,
  },
  emits: ["update:modelValue", "blur", "focus"],
  setup(props, { emit, slots }) {
    const state = reactive({
      value: props.modelValue,
      isFocus: false,
    });

    watch(
      () => props.modelValue,
      (current) => {
        state.value = current;
      }
    );

    const onFocus = () => {
      emit("focus");
      state.isFocus = true;
    };
    const onBlur = () => {
      emit("blur");
      state.isFocus = false;
    };

    const onChange = () => {
      emit("update:modelValue", state.value);
    };

    return {
      ...toRefs(state),
      emit,
      onChange,
      slots,
      onFocus,
      onBlur,
    };
  },
};
</script>

<style lang="scss" scoped>
.input-field-wrapper {
  outline: none;
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  border: 1px solid #e6e6e6;
  box-sizing: border-box;
  border-radius: 8px;
  transition: 0.5s;
  &.focus {
    border: 1px solid #0f8af9;
    box-shadow: 0px 0px 10px #0f8af9;
  }
}

label {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 12px;
  display: block;
  margin-left: 22px;
}

textarea {
  border-radius: 8px;
  height: 100%;
  width: 100%;
  outline: 0;
  background: transparent;
  padding-top: 15px;
  padding-left: 15px;
  font-size: 12px;
  line-height: 18px;
  position: relative;
  z-index: 1;
  border: 0;
  resize: none;  
}

i {
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 16px;
  margin-top: -4px;
}
</style>
