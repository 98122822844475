import { useStore } from "vuex"
export function useAllowedIssueTypes(store = useStore()) {
  if (!store) store = useStore();

  const allowedIssueTypesBefore = (issueTypeName) => {    
    let hierarchy = store.getters["settings/hierarchy"];

    hierarchy = hierarchy.map(node=>{
      if (node.name=='Epic' || node.default==false)
        node.issueTypes = []
      return node
    });
    
    let issueTypeList = store.getters["settings/issueTypes"];

    if (!hierarchy.length || !issueTypeList.length) return [];

    issueTypeList = issueTypeList.map((issueType) => {
      for (let i = 0; i < hierarchy.length; i++) {
        const h = hierarchy[i];
        if (
          h.issueTypes.length &&
          h.issueTypes.some((hi) => hi.name == issueType.name)
        ) {
          issueType.groupName = h.name;
        } else if (h.name == issueType.name) {
          issueType.groupName = h.name;
        }
      }

      return issueType;
    });
    
    const foundIssue = issueTypeList.find(
      (n) => n.name == issueTypeName
    );
    const foundHierarchyItem = hierarchy.find(
      (h) => h.name == foundIssue.groupName
    );
    
    if (foundHierarchyItem.parentId) {
      const item = hierarchy.find((h) => h.id == foundHierarchyItem.parentId);
      const list = issueTypeList.filter((i) => i.groupName == item.name);
      
      return list;
    } else {
      const list = issueTypeList.filter(
        (i) => i.groupName == foundHierarchyItem.name
      );

      return list;
    }

  }

  const allowedIssueTypes = (jiraIssueTypeId, parentId) => {
    const issueTypesList = store.getters["settings/issueTypes"];
    let hierarchy = store.getters["settings/hierarchy"];
    
    hierarchy = hierarchy.map(node=>{
      if (node.name=='Epic' || node.default==false)
        node.issueTypes = []
      return node
    });

    if (!issueTypesList.length || !hierarchy.length) return [];

    if (!parentId) {
      const custom = hierarchy
        .filter((n) => n.default == false).map(item => {
          const foundIssue = issueTypesList.find(l => l.name == item.name);
          return foundIssue
        });

      const epic = hierarchy
        .filter((n) => n.default == true && n.name == 'Epic').map(item => {
          const foundIssue = issueTypesList.find(l => l.name == item.name);
          return foundIssue
        });

      const standarts = hierarchy
        .filter((n) => n.default == true)
        .map((n) => {
          return n.issueTypes.filter((i) => i.subtask == 0 && i?.isSelected == true);
        })
        .find((i) => i.length > 0)
        .map((item) => {
          return item;
        });

      const result = [...custom, ...epic, ...standarts].filter(item => issueTypesList.some(issue => issue?.name == item?.name));
      return result;
    } else if (issueTypesList.length) {
      const item = issueTypesList.find(
        (i) => i.jiraIssueTypeId == jiraIssueTypeId
      );
      
      if (!item) return [];
      const hierarchy = store.getters["settings/hierarchy"];
      let hierarchyParent = hierarchy.find((n) => n.name == item.name);
      
      if (!hierarchyParent) {
        const issueType = hierarchy
          .filter((n) => n.default == true)
          .map((n) => n.issueTypes.filter((i) => i))
          .find((i) => i.length > 0)
          .find((n) => n.name == item.name);
          
        hierarchy.forEach((i) => {
          if (i.issueTypes.includes(issueType)) {
            hierarchyParent = i;
            return;
          }
        });
      }
      
      if (!hierarchyParent)
        return [];
      const beforeParentTypes = [];
      for (let i = 0; i < hierarchy.length; i++) {
        const h = hierarchy[i];
        if (h.name == hierarchyParent.name)
          break;
        beforeParentTypes.push(h.name);
      }
      
      const children = hierarchy.find((h) => h.parentId == hierarchyParent.id);      
      
      if (!children) return [];
      if (children.issueTypes.length) {
        return issueTypesList.filter((list) =>
          children.issueTypes.some((i) => i.isSelected == true && i.name == list.name && !beforeParentTypes.includes(i.name))
        );
      } else {
        return issueTypesList.filter((list) => list.name == children.name);
      }
    } else return [];
  }

  return { allowedIssueTypes, allowedIssueTypesBefore }
}