<template>
  <div
    class="icon-wrapper"
    :style="{
      background: fill,
      'border-radius': borderRadius,
      width: width,
      height: height,
    }"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#0085ff",
    },
    borderRadius: {
      type: String,
      default: "50%",
    },
    width: {
      type: String,
      default: "40px",
    },
    height: {
      type: String,
      default: "40px",
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
}
</style>
