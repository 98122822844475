<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.46775 2.59034L9 0.916504L16.5322 2.59034C16.7358 2.63559 16.9179 2.74889 17.0483 2.91154C17.1788 3.07419 17.25 3.27648 17.25 3.485V12.6398C17.2499 13.5452 17.0263 14.4366 16.5991 15.2348C16.1718 16.0331 15.554 16.7135 14.8007 17.2158L9 21.0832L3.19933 17.2158C2.44608 16.7136 1.82842 16.0333 1.40115 15.2353C0.973883 14.4372 0.750217 13.5459 0.75 12.6407V3.485C0.750036 3.27648 0.821168 3.07419 0.951657 2.91154C1.08215 2.74889 1.26419 2.63559 1.46775 2.59034ZM2.58333 4.22017V12.6398C2.58334 13.2433 2.73236 13.8376 3.01716 14.3698C3.30195 14.9019 3.71372 15.3556 4.21592 15.6904L9 18.8804L13.7841 15.6904C14.2861 15.3557 14.6978 14.9022 14.9826 14.3702C15.2674 13.8382 15.4165 13.2441 15.4167 12.6407V4.22017L9 2.79567L2.58333 4.22017ZM9 10.0832C8.39221 10.0832 7.80932 9.84173 7.37955 9.41196C6.94978 8.98219 6.70833 8.39929 6.70833 7.7915C6.70833 7.18372 6.94978 6.60082 7.37955 6.17105C7.80932 5.74128 8.39221 5.49984 9 5.49984C9.60779 5.49984 10.1907 5.74128 10.6205 6.17105C11.0502 6.60082 11.2917 7.18372 11.2917 7.7915C11.2917 8.39929 11.0502 8.98219 10.6205 9.41196C10.1907 9.84173 9.60779 10.0832 9 10.0832ZM4.89975 14.6665C5.01087 13.6567 5.49071 12.7234 6.24732 12.0455C7.00394 11.3676 7.98411 10.9927 9 10.9927C10.0159 10.9927 10.9961 11.3676 11.7527 12.0455C12.5093 12.7234 12.9891 13.6567 13.1003 14.6665H4.89975Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "#fff",
    },
    secondaryfill: {
      type: String,
    },
  },
  computed: {
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>