<template>
  <div class="select-box-container">
    <Multiselect ref="select$" v-model="innerModel" :options="renderOptions" :noResultsText="''" :loading="loading"
      mode="multiple" :can-clear="false" :hideSelected="false" :close-on-select="false" v-bind="$attrs"
      @open="openSelectBox" @close="closeSelectBox" @keyup="keyUp" @search-change="onSearch">
      <template #option="{ option }">
        <div class="option-container"
          :class="{ 'option-checked': checkedIds[option.value] == true, 'option-unchecked': checkedIds[option.value] == false }"
          @click="e => proxyClick(e)">
          <label>
            <input v-model="checkedIds[option.value]" type="checkbox" @change="e => onChangeSelected({ e, option })">
            <slot name="option" :option="option" />
          </label>
        </div>
      </template>
      <template #multiplelabel="{ values }">
        <div v-if="isOpen == false" class="selected-items">
          <ul>
            <li v-for="(item, i) in values.slice(0, maxItemsShow)" :key="i" :style="{ 'z-index': options.length - i }">
              <img :src="item.iconUri" :alt="item.value" />
            </li>
          </ul>
          <span v-show="maxItemsShow < innerModel.length">+{{  innerModel.length - maxItemsShow  }}</span>
        </div>
      </template>
      <template #afterlist>
        <div class="apply-button-container" @click="onChange">
          <div class="button-container" @click="applySelected">
            <slot name="apply-button" :items="innerModel" />
          </div>
        </div>
      </template>
    </Multiselect>
  </div>
</template>
<script setup>
import Multiselect from '@vueform/multiselect';
import { nextTick } from 'process';
import { ref, computed, watch } from 'vue';

const props = defineProps({
  modelValue: [Array],
  loading: {
    type: Boolean,
    value: false,
  },
  options: {
    type: [Array, Object]
  }
});

const emit = defineEmits(['onApply', 'update:modelValue', 'onSearch']);
let cachedOptions = [];
const checkedIds = ref({}),
  isOpen = ref(false),
  innerModel = ref(props.modelValue),
  select$ = ref(null),
  maxItemsShow = ref(4),
  selectedItems = ref([]),
  renderOptions = computed(() => {
    const array = [
      ...cachedOptions,
      ...props.options,
      ...selectedItems.value || [],
    ];
    return [
      ...new Set([...new Map(array.map((item) => [item["value"], item])).values()]),
    ];
  }),
  checkedOptions = computed(() => {
    const checked = Object.keys(checkedIds.value).filter(k => checkedIds.value[k] === true);
    return renderOptions.value.filter(item => checked.map(c => parseInt(c)).includes(item.value))
  });

const onChangeSelected = ({ e, option }) => {
  select$.value.clearSearch()
  select$.value.focus();
  if (e.target.checked)
    selectedItems.value.push(option)
  else
    selectedItems.value = selectedItems.value.filter(item => item.value !== option.value)
}, proxyClick = (e) => {
  e.stopPropagation()
}, applySelected = (closeDropBox = true) => {

  Object.keys(checkedIds.value).forEach(k => {
    select$.value.deselect(k)
    if (checkedIds.value[k] === true) {
      select$.value.select(k)
    }
  })

  emit("update:modelValue", innerModel.value);
  emit('onApply', checkedOptions.value);
  
  if (closeDropBox)
    select$.value.close();
}, closeSelectBox = () => {
  isOpen.value = false;
}, openSelectBox = () => {
  isOpen.value = true;

}, keyUp = (e) => {
  if (e.keyCode == 13) {
    applySelected(false);
  }
}, onSearch = (str) => {
  if (str !== '')
    emit('onSearch', { target: { value: str } });
};

watch(() => isOpen.value, (value) => {
  if (value == true) {
    checkedIds.value = {}
    innerModel.value.forEach(item => {
      checkedIds.value[item] = true;
    })
  } else {
    checkedIds.value = {}
    select$.value.close();
    select$.value.blur();
  }
})

watch(() => innerModel.value, (current) => {
  checkedIds.value = {}  
  current.forEach(item => {
    checkedIds.value[item] = true;
  })
});

watch(() => props.modelValue, current => {
  //if (!innerModel.value.length) 
  {
    nextTick(() => innerModel.value = current)
  }
})

watch(() => innerModel.value, (current) => {
  emit("update:modelValue", current);
})

watch(() => renderOptions.value, (current) => {
  cachedOptions.push(...current)
})

</script>
<style>
:root {
  --ms-max-height: 15rem;
  --ms-line-height: 2;
  --ms-option-bg-selected: #fff;
  --ms-option-bg-selected-pointed: #a283e5;
  --ms-option-color-selected: #444;
  --ms-option-color-selected-pointed: #ffffff;
  --ms-ring-color: #0f8cf939;
  --ms-option-bg-pointed: #a283e5;
  --ms-option-color-pointed: #ffffff;
}
</style>
<style src="@vueform/multiselect/themes/default.css">
</style>
<style lang="scss" scoped>
::v-deep(.multiselect-option) {
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #d1d5db;
}

.option-container {
  width: 100%;
  padding: 5px;
}

.option-container {
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  ::v-deep(img) {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.apply-button-container {
  position: sticky;
  bottom: 0;
  background: #ffffff;

  &.disabled {
    .button-container {
      opacity: .5;
      pointer-events: none;
    }
  }
}

.selected-items {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  left: 10px;
  right: 10px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
  justify-content: start;

  ul {
    padding: 0;
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0;
    align-items: center;
  }

  li+li {
    margin-left: -15px;
  }

  li {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
</style>