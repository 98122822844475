<template>
  <div class="import__google" @click="getReportLink">
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_487_398"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="24"
      >
        <path
          d="M11.1735 0.34375H1.93625C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V22.0676C0.306152 22.9419 1.0397 23.6572 1.93625 23.6572H16.0638C16.9604 23.6572 17.6939 22.9419 17.6939 22.0676V6.70196L11.1735 0.34375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_487_398)">
        <path
          d="M11.1735 0.34375H1.93625C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V22.0676C0.306152 22.9419 1.0397 23.6572 1.93625 23.6572H16.0638C16.9604 23.6572 17.6939 22.9419 17.6939 22.0676V6.70196L13.8903 4.05271L11.1735 0.34375Z"
          fill="#0F9D58"
        />
      </g>
      <mask
        id="mask1_487_398"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="24"
      >
        <path
          d="M11.1735 0.34375H1.93625C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V22.0676C0.306152 22.9419 1.0397 23.6572 1.93625 23.6572H16.0638C16.9604 23.6572 17.6939 22.9419 17.6939 22.0676V6.70196L11.1735 0.34375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_487_398)">
        <path
          d="M4.65332 11.7363V19.4192H13.3472V11.7363H4.65332ZM8.45689 18.3595H5.74006V17.0348H8.45689V18.3595ZM8.45689 16.2401H5.74006V14.9154H8.45689V16.2401ZM8.45689 14.1207H5.74006V12.796H8.45689V14.1207ZM12.2605 18.3595H9.54363V17.0348H12.2605V18.3595ZM12.2605 16.2401H9.54363V14.9154H12.2605V16.2401ZM12.2605 14.1207H9.54363V12.796H12.2605V14.1207Z"
          fill="#F1F1F1"
        />
      </g>
      <mask
        id="mask2_487_398"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="24"
      >
        <path
          d="M11.1735 0.34375H1.93625C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V22.0676C0.306152 22.9419 1.0397 23.6572 1.93625 23.6572H16.0638C16.9604 23.6572 17.6939 22.9419 17.6939 22.0676V6.70196L11.1735 0.34375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_487_398)">
        <path
          d="M11.6504 6.23633L17.694 12.1283V6.70127L11.6504 6.23633Z"
          fill="url(#paint0_linear_487_398)"
        />
      </g>
      <mask
        id="mask3_487_398"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="24"
      >
        <path
          d="M11.1735 0.34375H1.93625C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V22.0676C0.306152 22.9419 1.0397 23.6572 1.93625 23.6572H16.0638C16.9604 23.6572 17.6939 22.9419 17.6939 22.0676V6.70196L11.1735 0.34375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_487_398)">
        <path
          d="M11.1733 0.34375V5.11241C11.1733 5.99063 11.9028 6.70196 12.8034 6.70196H17.6938L11.1733 0.34375Z"
          fill="#87CEAC"
        />
      </g>
      <mask
        id="mask4_487_398"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="24"
      >
        <path
          d="M11.1735 0.34375H1.93625C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V22.0676C0.306152 22.9419 1.0397 23.6572 1.93625 23.6572H16.0638C16.9604 23.6572 17.6939 22.9419 17.6939 22.0676V6.70196L11.1735 0.34375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_487_398)">
        <path
          d="M1.93625 0.34375C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V2.06576C0.306152 1.19151 1.0397 0.476213 1.93625 0.476213H11.1735V0.34375H1.93625Z"
          fill="white"
          fill-opacity="0.2"
        />
      </g>
      <mask
        id="mask5_487_398"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="24"
      >
        <path
          d="M11.1735 0.34375H1.93625C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V22.0676C0.306152 22.9419 1.0397 23.6572 1.93625 23.6572H16.0638C16.9604 23.6572 17.6939 22.9419 17.6939 22.0676V6.70196L11.1735 0.34375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_487_398)">
        <path
          d="M16.0638 23.5251H1.93625C1.0397 23.5251 0.306152 22.8098 0.306152 21.9355V22.068C0.306152 22.9423 1.0397 23.6576 1.93625 23.6576H16.0638C16.9604 23.6576 17.6939 22.9423 17.6939 22.068V21.9355C17.6939 22.8098 16.9604 23.5251 16.0638 23.5251Z"
          fill="#263238"
          fill-opacity="0.2"
        />
      </g>
      <mask
        id="mask6_487_398"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="24"
      >
        <path
          d="M11.1735 0.34375H1.93625C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V22.0676C0.306152 22.9419 1.0397 23.6572 1.93625 23.6572H16.0638C16.9604 23.6572 17.6939 22.9419 17.6939 22.0676V6.70196L11.1735 0.34375Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_487_398)">
        <path
          d="M12.8034 6.70283C11.9028 6.70283 11.1733 5.99151 11.1733 5.11328V5.24574C11.1733 6.12397 11.9028 6.8353 12.8034 6.8353H17.6938V6.70283H12.8034Z"
          fill="#263238"
          fill-opacity="0.1"
        />
      </g>
      <path
        d="M11.1735 0.34375H1.93625C1.0397 0.34375 0.306152 1.05905 0.306152 1.9333V22.0676C0.306152 22.9419 1.0397 23.6572 1.93625 23.6572H16.0638C16.9604 23.6572 17.6939 22.9419 17.6939 22.0676V6.70196L11.1735 0.34375Z"
        fill="url(#paint1_radial_487_398)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_487_398"
          x1="14.6725"
          y1="6.74222"
          x2="14.6725"
          y2="12.1291"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#263238" stop-opacity="0.2" />
          <stop offset="1" stop-color="#263238" stop-opacity="0.02" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_487_398"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.857005 0.807153) scale(28.0375 27.3401)"
        >
          <stop stop-color="white" stop-opacity="0.1" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  methods: {
    getReportLink() {
      this.$emit("getReportLink", 1);
    },
  },
};
</script>

<style lang="scss">
.import {
  &__google {
    .icon {
      width: 15px;
      height: 25px;
      cursor: pointer;
    }
  }
}
</style>
