<template>
  <div class="row" ref="rowEl">
    <div class="cell">
      <div class="ava">
        <img v-if="row.iconUri" :src="row.iconUri" alt="" />
        <icon-default v-else :width="'35px'" :height="'35px'" />
        {{ row.name }}
      </div>
    </div>
    <div class="cell">
      <strong>{{ row.membersCount }}</strong>
    </div>
    <div class="cell">
      <strong>{{ row.nodesCount }}</strong>
    </div>
    <div class="cell open-project">
      <slot />
    </div>
  </div>
</template>
<script>
import IconDefault from "@/components/icons/IconDefault.vue";
import { reactive, toRefs, computed } from "vue";

export default {
  components: { IconDefault },
  props: {
    row: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const state = reactive({
      row: props.row,
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
.row {
  border-bottom: 1px solid #c3cad93d;
  display: grid;
  align-items: center;
  grid-column-gap: 0;
  grid-template-columns: 1fr repeat(3, minmax(45px, 144px));

  &:hover {
    background: #f5f8fe;
  }
  @media (max-width: 1400px) {
    padding-left: 20px;
    padding-right: 20px;
    grid-template-columns: 1fr repeat(3, minmax(45px, 115px));
  }
}

.cell {
  display: flex;
  align-items: center;
  height: 45px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #363636;
  position: relative;
  @media (max-width: 1400px) {
    font-size: 12px;
    line-height: 18px;
    height: 60px;
  }
  strong {
    font-weight: 500;
  }
}

.ava {
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    left: 7px;
    position: relative;
    margin-right: 15px;
    flex-shrink: 0;
  }
  img {
    flex-shrink: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
    left: 7px;
    @media (max-width: 1200px) {
      margin-right: 10px;
    }
  }
}

.members {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  ul li + li {
    margin-left: -10px;
  }
  .plus-one {
    margin-left: 0;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    /* #2684FE */

    color: #2684fe;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    border: 2px solid #ffffff;
  }
}
.open-project {
  justify-self: end;
  margin-right: 15px;
}
</style>
