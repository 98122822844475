export function useStatusColor() {
  const getStatusColorText = (statusColor) => {
    switch (statusColor) {
      case "#dfe1e6":
        return "#363636";
      case "#0052cc":
        return "#fff";
      case "#00875a":
        return "#fff";
      default:
        return "#363636";
    }
  };
  return {
    getStatusColorText
  }
}