export default (error) => {
  const result = []
  if (error?.errors) {
    Object.keys(error.errors).forEach((k) => {
      result.push({
        name: k,
        text: error.errors[k].length ? error.errors[k][0] : error.errors[k],
      });
    });
  } else {
    result.push({
      name: error?.error,
      text: error?.error,
    });
  }
  return result;
};
