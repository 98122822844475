<template>
  <div :style="{ width, height }" class="loader"></div>
</template>

<script>
export default {
  props: {
    width: {
      type: [String],
      default: "100%",
    },
    height: {
      type: [String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
