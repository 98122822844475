<template>
  <div v-click-outside="(e) => onClickOutSide(e)" class="color-picker" data-testid="color-picker" :class="{ 'compact-mode': compactMode }"
    :style="{ ...position, ...{ display: isOpenPicker && position?.display == 'block' ? 'block' : 'none' } }">
    <div class="colors">
      <div v-for="(item, index) in colorsDefault" :key="item" class="item"
        :class="{ 'active': backgroundColor == item }" :style="{ 'background-color': item }" @click="changeColor(item)">
        <span :style="{ color: fontColors[index] }">A</span>
      </div>
    </div>
    <div class="checkbox-container">
      <div class="reset-color">
        <a href="javascript:;" @click="resetColor">Reset</a>
      </div>
      <app-checkbox v-model="depending" data-testid="apply-to-children" type="checkbox" @change="changeDepending">
        Apply color to children
      </app-checkbox>
    </div>
    <div class="actions">
      <app-button :color="'primary'" @click="submit">Apply</app-button>
      <app-button :color="'default'" @click="cancel">Cancel</app-button>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, ref, watch, computed, onUnmounted } from "vue";
import AppButton from "@/components/shared/Button.vue";
import AppCheckbox from "@/components/forms/elements/BaseCheckBox.vue";
export default {
  components: {
    AppButton,
    AppCheckbox,
  },
  props: {
    position: {
      type: Object,
      default: function () {
        return { display: "none" };
      },
    },
    currentNode: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: "rgba(0, 0, 0, 1)",
    },
    background: {
      type: String,
      default: "rgba(255, 255, 255, 1)",
    },
    centerX: {
      type: Number,
      default: 0,
    },
    centerY: {
      type: Number,
      default: 0,
    },
    nodeHeight: {
      type: Number,
      default: 220,
    },
    scale: {
      type: Number,
      default: 1,
    },
    compactMode: {
      type: Boolean,
      default: true,
    },
    isOpenPicker: {
      type: Boolean,
      default: false,
    },
    defaultColor: {
      type: String,
      default: '#000000',
    },
    defaulBackgroundtColor: {
      type: String,
      default: '#ffffff',
    },
    dependingColor: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onCancel", "onSubmit", "onChange", "onClickOutSide"],
  setup(props, { emit }) {
    const state = reactive({
      tabIndex: 0,
      fontColor: props.color,
      backgroundColor: props.background,
      depending: props.dependingColor,
      colorsDefault: [
        "#5344ab",
        "#de360e",
        "#fb9b21",
        "#22885a",
        "#2eb8d9",
        "#0452cc",
        "#6d5fbf",
        "#f2552d",
        "#fbab47",
        "#31b577",
        "#38d9fe",
        "#0662f2",
        "#897ccb",
        "#f47352",
        "#fdbe70",
        "#3fdd91",
        "#3be0fe",
        "#2279fd",

        "#bcb4e7",
        "#ffb6a4",
        "#ffe1bc",
        "#9fffd1",
        "#afe9f4",
        "#96bfff",

        "#e2ddfe",
        "#f8cdc3",
        "#fbeede",
        "#d5fce9",
        "#d5f8ff",
        "#c3dafc",
      ],
      fontColors: [
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#ffffff",
        "#ffffff",
        "#ffffff",
        "#000000",
        "#000000",
        "#000000",
        "#ffffff",

        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",

        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
        "#000000",
      ]
    }),
      colorPicker = ref(null),
      colorPickerPosition = computed(() => {
        if (!props.currentNode) return;

        const { x, y } = props.currentNode;
        return {
          left: `${x * props.scale + props.centerX}px`,
          top: `${y * props.scale +
            props.centerY +
            props.nodeHeight * props.scale +
            65
            }px`,
        };
      });

    watch(
      () => props.color,
      (current) => {
        state.backgroundColor = current?.split(":")[0];
        state.fontColor = current?.split(":")[1];
        state.depending = props.dependingColor;
      },
      {
        immediate: true,
      }
    );

    watch(
      () => props.dependingColor,
      (current) => {
        state.depending = current;
      }
    );

    let sendEmit = true;

    const changeColor = (color) => {
      state.backgroundColor = color;
      state.fontColor = state.fontColors[state.colorsDefault.indexOf(color)];
      if (sendEmit)
        emit("onChange", {
          color: state.fontColor,
          backgroundColor: state.backgroundColor,
          depending: state.depending,
        });
      sendEmit = true;
    };

    const resetColor = () => {
      emit("onSubmit", {
        color: props.defaultColor,
        backgroundColor: props.defaulBackgroundtColor,
        depending: state.depending,
      });
    };

    const submit = () => {
      emit("onSubmit", {
        color: state.fontColor,
        backgroundColor: state.backgroundColor,
        depending: state.depending,
      });
      sendEmit = false;
    };

    const cancel = () => {
      sendEmit = false;
      emit("onCancel");
    };

    const changeDepending = () => {
      emit("onChange", {
        color: state.fontColor,
        backgroundColor: state.backgroundColor,
        depending: state.depending,
      });
    };

    const onClickOutSide = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            (p.classList.contains("color-picker") ||
            p.classList.contains("cell-value"))
          );
        })
      )
        return;

      emit('onClickOutSide')
    }

    return {
      ...toRefs(state),
      changeColor,
      cancel,
      submit,
      changeDepending,
      resetColor,
      colorPickerPosition,
      colorPicker,
      onClickOutSide,
    };
  },
};
</script>
<style lang="scss">
.hu-color-picker {
  padding: 0;
}

.hu-color-picker.light {
  background: #fff;
}

.hu-color-picker.light .color-show .sucker {
  background: #fff;
}

.hu-color-picker.light .color-type .name {
  background: #fff;
}

.hu-color-picker.light .color-type .value {
  color: #666;
  background: #eceef0;
}

.hu-color-picker.light .colors.history {
  border-top: 1px solid #eee;
}

.hu-color-picker canvas {
  vertical-align: top;
}

.hu-color-picker .color-set {
  display: flex;
}

.hu-color-picker .color-show {
  margin-top: 8px;
  display: flex;
}

.saturation {
  position: relative;
  cursor: pointer;
}

.saturation .slide {
  position: absolute;
  left: 100px;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.color-alpha {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}

.color-alpha .slide {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: 4px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.sucker {
  width: 30px;
  fill: #9099a4;
  background: #2e333a;
  cursor: pointer;
  transition: all 0.3s;
}

.sucker.active,
.sucker:hover {
  fill: #1593ff;
}

.hue {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}

.hue .slide {
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: 4px;
  background: #fff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.colors {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.colors.history {
  margin-top: 10px;
  border-top: 1px solid #2e333a;
}

.colors .item {
  position: relative;
  width: 29px;
  height: 25px;
  border-radius: 3px;
  box-sizing: border-box;
  vertical-align: top;
  display: inline-block;
  transition: all 0.1s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    outline: 1px solid #000;
  }
}

.colors .item:nth-child(8n + 1) {
  margin-left: 0;
}

.colors .item:hover {
  transform: scale(1.4);
  position: relative;
  z-index: 1;
}

.colors .item .alpha {
  height: 100%;
  border-radius: 4px;
}

.colors .item .color {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.color-type {
  display: flex;
  margin-top: 0px;
  font-size: 12px;
}

.color-type .name {
  width: 60px;
  height: 30px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  background: #252930;
}

.color-type .value {
  flex: 1;
  height: 30px;
  min-width: 100px;
  padding: 0 12px;
  border: 0;
  color: #fff;
  background: #2e333a;
  box-sizing: border-box;
}
</style>
<style lang="scss" scoped>
.color-picker {
  position: absolute;
  z-index: 999;
  display: none;
  background: #fff;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 1px #00000047;

  ::v-deep(.hu-color-picker) {
    width: 200px !important;
  }

  &.compact-mode {
    ::v-deep(*) {

      .color-show,
      .color-set,
      .color-type:nth-last-of-type(2) {
        display: none;
      }
    }
  }
}

.checkbox-container {
  margin-top: 5px;
  font-size: 14px;
}

.reset-color {
  text-align: right;

  a {
    font-size: 14px;
    color: #0f8af9;
    text-decoration: none;
  }
}

.actions {
  display: grid;
  grid-column-gap: 5px;
  padding: 0;
  margin-top: 5px;
  grid-template-columns: 1fr 1fr;

  button {
    height: 35px;
  }
}

.tabs {
  display: grid;
  grid-template-columns: 1fr 21px;
  grid-row-gap: 5px;

  .tabs-list {
    background: #fafafb;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    grid-area: 1 / 1 / 1 / 1;
  }

  .tabs-name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    opacity: 0.4;
    display: flex;
    height: 31px;
    align-items: center;
    justify-content: center;

    &.active {
      opacity: 1;
      background: #ffffff;
      box-shadow: 0px 2px 6px rgba(192, 192, 192, 0.25);
      border-radius: 4px;
    }
  }

  .tab-content {
    grid-area: 2 / 1 / 2 / 3;
  }

  .close {
    width: 12px;
    height: 12px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABmSURBVHgBrdLRDYAgDATQixM4wu2/DGzgKIoKxhiuR4hN+gP3GkIK3LWWJnyxZh+US28Gs2ZSw+1gD7DMRNgO7gUsUngI9bBEC34qYuKpxMTnRAF5Z6eqzLk+CeMrl/HZ1wi98YUO6b87iRPLAF4AAAAASUVORK5CYII=");
    grid-area: 1 / 2 / 1 / 2;
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: contain;
    opacity: 0.5;
    align-self: center;
    justify-self: center;
  }
}

.preview {
  display: grid;
  gap: 5px;
  grid-template-columns: 56px 1fr;
  text-align: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  grid-area: 3/1/3/3;
  color: #999;
  margin-top: 5px;

  .background {
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    border: 1px solid #efefef;
    border-radius: 2px;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
    flex-grow: 1;
  }

  grid-area: 3 / 1 / 3 / 3;
}
</style>
