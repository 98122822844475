import * as types from '@/store/mutation-types';
export default {
  [types.SET_USER_ACCESS_USERS](state, payload = []) {
    state.userList = payload;
    state.userList = state.userList.map(u => {
      return {
        label: u.displayNameWithId,
        value: u.id,
        iconUri: u.iconUri
      }
    })
  },
  [types.REMOVE_USER_ACCESS](state, payload = []) {
    state.userAccessList = state.userAccessList.filter(u => u.id !== payload);
  },
  [types.SET_USER_ACCESS_LIST](state, payload = { data: [], meta: {} }) {
    state.userAccessList = payload.data;
    state.meta = payload.meta;
  },
  [types.ADD_USER_ACCESS](state, payload) {
    state.userAccessList.push(payload)
    state.accessType = 0;
  },
};