<template>
  <div class="modal-body">
    <div class="close" @click="closeModal">
      <icon-close :width="'20px'" :height="'20px'" :fill="'#A0A3BD'" />
    </div>
    <div class="title">{{ translate("shared.get_link") }}</div>
    <div class="actions">
      <div class="text">{{ translate("shared.text") }}</div>
      <div v-if="copyUrl" class="copied">{{ translate("shared.copied") }}</div>
      <div class="copy-link" @click="copyLink">
        <icon-share
          :width="'18px'"
          :height="'18px'"
          :stroke="'#2684FE'"
          :fill="'transparent'"
        />
        {{ translate("shared.copy_link") }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useModalHelper } from "@/helpers/modalHelper";
import IconClose from "@/components/icons/IconClose.vue";
import IconShare from "@/components/icons/IconShare.vue";

export default {
  components: { IconClose, IconShare },
  inject: ["translate"],
  props: {
    id: {
      type: Number,
      required: true,
    },
    source: {
      type: String,
      validator: function (value) {
        return ["projects", "templates"].indexOf(value) !== -1;
      },
      default: "projects",
    },
    viewStyle: {
      type: String,
      validator: function (value) {
        return ["list", "tree"].indexOf(value) !== -1;
      },
      default: "list",      
    },
    treeStyle: {
      type: String,
      validator: function (value) {
        return ["horizontal", "vertical"].indexOf(value) !== -1;
      },
      default: null,   
    }
  },
  setup(props) {
    const { closeModal } = useModalHelper(),
      copyUrl = ref(null);

    const copyLink = () => {
      AP.getLocation(function (location) {
        const input = document.createElement("input");
        const paramName = props.source == "templates" ? "wbsTemplate" : "wbsProject";
        location = 
        location.replace(/&wbsTemplate+.+/, "").replace(/\?wbsTemplate+.+/, "").replace(/&wbsProject+.+/, "").replace(/\?wbsProject+.+/, "");
        let treeStyle = ''
        if (props.treeStyle) {
          treeStyle=`&treeStyle=${props.treeStyle}`
        }
        input.setAttribute("value", location + (location.indexOf('?') !== -1 ? '&' : '?') + `${paramName}=` + btoa(props.id)+`&viewStyle=${props.viewStyle}${treeStyle}`);
        document.body.appendChild(input);
        input.select();
        copyUrl.value = document.execCommand("copy");        
        document.body.removeChild(input);
      });
    };

    return {
      copyLink,
      closeModal,
      copyUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
  width: 400px;
  .close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -7px;
    top: -2px;
    cursor: pointer;
  }
  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #14142b;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(160, 163, 189, 0.15);
  }
  .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;
    color: #14142b;
  }
  .actions {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
    height: 35px;
    .copied {
      color: #4caf50;
      font-size: 14px;
    }
    .copy-link {
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      display: flex;
      align-items: center;
      color: #2684fe;
      svg {
        margin-right: 10px;
      }
    }
  }
}
</style>
