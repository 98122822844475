<template>
  <div :id="id" v-click-outside="(e) => clickOutSide(e)" class="summary-container">
    <input v-if="editMode" ref="inputEl" v-model="valueData" type="text" class="input" @keypress.enter="onChange"
      @keyup.esc="cancelEditMode" />
    <div class="slot-content" @click="setEditMode">
      <span v-if="!editMode" :id="id" class="inner">
        <slot></slot>
      </span>
      <div v-if="editMode" class="icon" :style="{ left, top }">
        <div class="apply" @click.stop="onChange">
          <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
            <path
              d="M6.735 12.322a1 1 0 0 0-1.47 1.356l3.612 3.919c.537.526 1.337.526 1.834.03l.364-.359a2335.638 2335.638 0 0 0 3.939-3.883l.04-.04a492.598 492.598 0 0 0 3.658-3.643 1 1 0 0 0-1.424-1.404 518.42 518.42 0 0 1-3.64 3.625l-.04.04a2049.114 2049.114 0 0 1-3.775 3.722l-3.098-3.363z"
              fill="currentColor"></path>
          </svg>
        </div>
        <div class="cancel" @click.stop="cancelEditMode">
          <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation">
            <path
              d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z"
              fill="currentColor"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
window.blockDraggableBlobal = (e) => {
  e.preventDefault();
};
import {
  reactive,
  toRefs,
  ref,
  nextTick,
  watch,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    value: {
      type: String,
      default: "",
    },
  },
  emits: ["onChange"],
  setup(props, { emit }) {
    const data = reactive({
      editMode: false,
      valueData: props.value,
      isDataChanged: false,
    });

    const inputEl = ref(null),
      left = computed(() => {
        if (inputEl.value) {
          const { left, width } = inputEl.value.getBoundingClientRect();
          return `${left + width - 50}px`;
        }
        return `0px`;
      }),
      top = computed(() => {
        if (inputEl.value) {
          const { top } = inputEl.value.getBoundingClientRect();
          return `${top + 22}px`;
        }
        return `0px`;
      });

    const setEditMode = () => {
      data.editMode = !data.editMode;
      if (data.editMode) {
        data.valueData = props.value;
        nextTick(() => {
          inputEl.value.focus();
          inputEl.value.select();
        });
      }
    };

    const cancelEditMode = () => {
      data.editMode = false;
      data.valueData = props.value;
    };

    const onChange = () => {
      if (
        data.valueData &&
        data.valueData !== props.value &&
        data.isDataChanged == false
      ) {
        emit("onChange", data.valueData);
        data.isDataChanged = true;
      }
      data.editMode = false;
    };

    const clickOutSide = (e) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (
        path.some((p) => {
          return (
            p.classList &&
            e.target.id == props.id &&
            ["icon", "slot-content"].some((i) => p.classList.contains(i))
          );
        })
      )
        return;

      onChange();
    };

    watch(
      () => props.value,
      () => {
        document.body.removeEventListener("dragstart", blockDraggableBlobal);
      }
    );

    watch(
      () => data.editMode,
      (current) => {
        if (current) {
          document.body.addEventListener("dragstart", blockDraggableBlobal);
        } else {
          document.body.removeEventListener("dragstart", blockDraggableBlobal);
        }
      }
    );

    onMounted(() => {
      window.addEventListener(
        "scroll",
        function (e) {
          if (e.target.scrollTop > 5) {
            cancelEditMode();
          }
        },
        true
      );
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", cancelEditMode, true);
      document.body.removeEventListener("dragstart", blockDraggableBlobal);
    });

    return {
      ...toRefs(data),
      inputEl,
      onChange,
      setEditMode,
      cancelEditMode,
      left,
      top,
      clickOutSide,
    };
  },
};
</script>
<style lang="scss" scoped>
.slot-content {
  display: grid;
  grid-column-gap: 5px;
  grid-template-columns: 1fr 12px;
  align-items: center;
  position: relative;

  .inner {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .icon {
    display: flex;
    gap: 5px;
    position: fixed;
    z-index: 2;
    color: #464644;
  }

  .apply,
  .cancel {
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    width: 21px;
    height: 21px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(9 30 66 / 25%) 0px 4px 8px -2px,
      rgb(9 30 66 / 31%) 0px 0px 1px;
  }
}

.input {
  width: 100%;

  height: 100%;
}
</style>