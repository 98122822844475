export default {
  meta: state => state.meta || {},
  hierarchy: state => state.hierarchy?.map(node => {
    let issueTypes = [];
    if (node.name == 'Epic' || node.default == false)
      issueTypes = []
    else  
      issueTypes = [...new Set([...new Map(node.issueTypes.map((item) => [item["name"], item])).values()])];
    return {
      ...node, ...{
        issueTypes
      }
    }
  }) || {},

  issueTypes: state => state.issueTypes || [],
  nodesSettings: state => state.nodesSettings || {},
  estimationType: state => state.estimationType
};
