<template>
  <div>
    <ul class="tabs__header">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.props.title"
        @click="selectTab(index)"
        :class="{ tab__selected: index == selectedIndex }"
      >
        {{ tab.props.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
import { ref, onMounted, provide, watch } from "vue";
export default {
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: ["change"],
  setup(props, { slots, emit }) {
    const selectedIndex = ref(0),
      tabs = ref([]);
    let tabsCount = ref(0);
    provide("TabsProvider", { selectedIndex, tabsCount });
    const selectTab = (i) => {
      selectedIndex.value = i;
      emit("change", i);
    };

    watch(
      () => slots.default(),
      (current) => {
        tabs.value = current;
      }
    );

    onMounted(() => {
      tabs.value = slots.default();
      selectTab(props.tabIndex);
    });

    return {
      tabs,
      selectedIndex,
      selectTab,
    };
  },
};
</script>

<style lang="scss" scoped>
ul.tabs__header {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 0 30px;
  border-bottom: 1px solid #c3cad95e;
  margin-bottom: 30px;
}

ul.tabs__header > li {
  position: relative;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  color: #363636;
  opacity: 0.5;
}
ul.tabs__header > li + li {
  margin-left: 40px;
}
ul.tabs__header > li.tab__selected {
  opacity: 1;
  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    background: #2684fe;
    height: 2px;
    width: 100%;
  }
}

.tab {
  display: inline-block;
  color: black;
  padding: 20px;
  min-width: 800px;
  border-radius: 10px;
  min-height: 400px;
}

.tabs__light .tab {
  background-color: #fff;
}

.tabs__light li {
  background-color: #ddd;
  color: #aaa;
}

.tabs__light li.tab__selected {
  background-color: #fff;
  color: #83ffb3;
}

.tabs__dark .tab {
  background-color: #555;
  color: #eee;
}

.tabs__dark li {
  background-color: #ddd;
  color: #aaa;
}

.tabs__dark li.tab__selected {
  background-color: #555;
  color: white;
}
</style>
