import { onMounted, onUnmounted, ref } from "vue"

export function useKeyboard() {
  let isCtrlDown = ref(false);
  const addKeyDownEventListener = (e) => {
    const isOSX = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    if (isOSX && e.metaKey) {
      isCtrlDown.value = true;
    }
    if (!isOSX && e.key == "Control") {
      isCtrlDown.value = true;
    }
  };

  const addKeyUpEventListener = () => {
    isCtrlDown.value = false;
  };

  const addDocumentBlurListener = () => {
    isCtrlDown.value = false;
  };

  onMounted(() => {
    document.addEventListener("keydown", addKeyDownEventListener);
    document.addEventListener("keyup", addKeyUpEventListener);
    window.addEventListener("blur", addDocumentBlurListener);
  })
  onUnmounted(() => {
    document.addEventListener("keydown", addKeyDownEventListener);
    document.addEventListener("keyup", addKeyUpEventListener);
    window.removeEventListener("blur", addDocumentBlurListener);
  })

  return {
    isCtrlDown
  }
}