<template>
  <svg :width="width" :height="height" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.08331 6.08337V0.583374H7.91665V6.08337H13.4166V7.91671H7.91665V13.4167H6.08331V7.91671H0.583313V6.08337H6.08331Z"
      :fill="fill" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: String,
      default: "",
    },
    stroke: {
      type: String,
      default: "white",
    },
  },
};
</script>
