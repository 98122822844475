export default {
  data: [],
  userList: [],
  searchTemplates: [],
  filterStatusList: [{
    value: 0,
    label: "All"
  },
  {
    value: 1,
    label: "Active",
  },
  {
    value: 2,
    label: "Closed",
  }],
  statusList: [
    {
      value: 1,
      label: "Active",
    },
    {
      value: 2,
      label: "Closed",
    },
  ],
};
