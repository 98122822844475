<template>
  <svg :width="width" :height="height" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.75 0.25L0.750001 4H3V13H4.5V4H6.75L3.75 0.25ZM7.5 11.5V13H15.75V11.5H7.5ZM7.5 6.25V7.75H15.75V6.25H7.5ZM9 1V2.5H15.75V1H9Z"
      fill="#23252A" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
    },
  },
};
</script>
