<template>
  <svg :width="width" :height="height" :fill="fill" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_115_660)">
      <path
        d="M17.7803 16.7197L13.3035 12.243C14.5235 10.7509 15.1233 8.84703 14.9789 6.92513C14.8345 5.00323 13.9569 3.21036 12.5277 1.91735C11.0985 0.624341 9.22695 -0.0698723 7.30024 -0.0216984C5.37352 0.0264755 3.53903 0.813351 2.17621 2.17617C0.813397 3.53899 0.0265212 5.37348 -0.0216526 7.30019C-0.0698265 9.2269 0.624387 11.0984 1.91739 12.5276C3.2104 13.9569 5.00328 14.8344 6.92518 14.9788C8.84707 15.1232 10.751 14.5234 12.243 13.3035L16.7198 17.7802C16.8612 17.9168 17.0507 17.9924 17.2473 17.9907C17.444 17.989 17.6321 17.9101 17.7711 17.7711C17.9102 17.632 17.9891 17.4439 17.9908 17.2473C17.9925 17.0506 17.9169 16.8612 17.7803 16.7197ZM7.5 13.5C6.31332 13.5 5.15328 13.1481 4.16658 12.4888C3.17989 11.8295 2.41085 10.8924 1.95673 9.79606C1.5026 8.6997 1.38378 7.4933 1.61529 6.32942C1.8468 5.16553 2.41825 4.09643 3.25736 3.25732C4.09648 2.4182 5.16558 1.84676 6.32946 1.61525C7.49335 1.38374 8.69975 1.50256 9.7961 1.95668C10.8925 2.41081 11.8295 3.17984 12.4888 4.16654C13.1481 5.15323 13.5 6.31327 13.5 7.49996C13.4982 9.09071 12.8655 10.6158 11.7407 11.7406C10.6158 12.8655 9.09075 13.4982 7.5 13.5Z"
        fill="#23252A" />
      <path
        d="M11.2331 9.62062C12.4055 7.59007 11.7098 4.99379 9.67925 3.82145C7.64871 2.64912 5.05242 3.34479 3.88009 5.37533C3.42669 6.16064 3.25273 7.03081 3.32714 7.86985L5.71835 6.43665L4.61539 5.79986C5.00682 5.12202 5.62037 4.60019 6.35226 4.32265C7.08414 4.04511 7.88943 4.0289 8.63189 4.27675C9.37436 4.52461 10.0084 5.02132 10.4268 5.68286C10.8452 6.3444 11.0222 7.13015 10.928 7.9072C10.8337 8.68425 10.4739 9.40488 9.90952 9.9472C9.34509 10.4895 8.61066 10.8202 7.83047 10.8834L7.87598 11.7317C9.22658 11.6286 10.5047 10.8823 11.2331 9.62062Z"
        fill="#23252A" />
    </g>
    <defs>
      <clipPath id="clip0_115_660">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
      default: "transparent",
    },
  },
};
</script>
