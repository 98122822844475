<template>
  <div class="modal-body">
    <app-import @onClose="closeModal" @onSubmit="submitModal"/>
  </div>
</template>
<script>
import { useModalHelper } from "@/helpers/modalHelper";
import AppImport from "@/components/wbsProject/import/Import.vue";
export default {
  components: {
    AppImport,
  },

  setup(props) {
    const { closeModal, submitModal } = useModalHelper();
    return {
      closeModal,
      submitModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  position: relative;
}
</style>
