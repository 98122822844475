<template>
  <svg
    :opacity="opacity"
    :width="width"
    :height="height"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M28 0L0 28V8C0 3.58172 3.58172 0 8 0H28Z" :fill="fill" />
    <path
      d="M9.57632 7.15263C9.41316 7.15263 9.27632 7.09737 9.16579 6.98684C9.05526 6.87632 9 6.73947 9 6.57632C9 6.41316 9.05526 6.27632 9.16579 6.16579C9.27632 6.05526 9.41316 6 9.57632 6C9.73421 6 9.86842 6.05526 9.97895 6.16579C10.0895 6.27632 10.1447 6.41316 10.1447 6.57632C10.1447 6.73947 10.0895 6.87632 9.97895 6.98684C9.86842 7.09737 9.73421 7.15263 9.57632 7.15263ZM10.0184 7.72895V12.0789H9.11842V7.72895H10.0184Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "transparent",
    },
    stroke: {
      type: [String],
      default: "white",
    },
  },
};
</script>
