<template>
  <svg
    :width="width"
    :height="height"
    :fill="fill"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 16H14" stroke="#23252A" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M11 12L7.5 8.5M11 5V12V5ZM11 12L14.5 8.5L11 12Z"
      stroke="#23252A"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke="#23252A"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    fill: {
      type: [String],
      default: "transparent",
    },
  },
};
</script>
