<template>
  <svg
    viewBox="0 0 17 18"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
  >
    <g fill="none">
      <path
        d="M4.322 4.983l8.427 8.427"
        :stroke="stroke"
        stroke-linecap="round"
      />
      <path
        d="M12.749 4.983L4.322 13.41"
        :stroke="stroke"
        stroke-linecap="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    stroke: {
      type: String,
      default: "#fff",
    },
  },
};
</script>