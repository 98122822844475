<template>
  <svg
    :opacity="opacity"
    :width="width"
    :height="height"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0H2C0.89543 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H12C13.1046 14 14 13.1046 14 12V2C14 0.89543 13.1046 0 12 0Z"
      fill="#63BA3C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 3H5C4.448 3 4 3.448 4 4V10.5C4 10.776 4.224 11 4.5 11C4.675 11 4.821 10.905 4.91 10.769L4.914 10.77L6.84 8.54C6.92 8.434 7.08 8.434 7.16 8.54L9.086 10.77L9.09 10.769C9.179 10.905 9.325 11 9.5 11C9.776 11 10 10.776 10 10.5V4C10 3.448 9.552 3 9 3Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "100%",
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    opacity: {
      type: [Number],
      default: 1,
    },
    fill: {
      type: [String],
      default: "#0F8AF9",
    },
  },
};
</script>
