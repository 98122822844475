<template>
  <div class="info-content">
    <app-baloon :position="'top'">
      <template v-slot:title>
        <strong class="title">
          <icon-attention :fill="'rgb(0, 133, 255)'" :width="'20px'" :height="'20px'" />
          {{ translate("wbs.info_title") }}
          <div class="close" @click="onClose">
            <icon-close :width="'20px'" :height="'20px'" :fill="'#444'" />
          </div>
        </strong>
      </template>
      <template v-slot:content>
        <p>
          <span class="header"> {{ translate("wbs.new_node_button") }} </span>
          {{ translate("wbs.new_node_button_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.hierarchy_nodes") }}</span>
          {{ translate("wbs.hierarchy_nodes_description") }}
          <strong>{{ translate("wbs.hierachy_nodes_description_bold") }} </strong>
        </p>
        <p>
          <span class="header">{{ translate("wbs.pulling_changes") }}</span>
          {{ translate("wbs.pulling_changes_description") }}
          <span class="icon-import">
            <icon-import :width="'18px'" :height="'18px'" />
          </span>
          {{ translate("wbs.pulling_changes_description_2") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.zoom_in_out") }}</span>
          {{ translate("wbs.zoom_in_out_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.root_node") }}</span>
          {{ translate("wbs.root_node_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.drag_and_drop") }}</span>
          {{ translate("wbs.drag_and_drop_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.change_assignee_status") }}</span>
          {{ translate("wbs.change_assignee_status_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.estimation") }}</span>
          {{ translate("wbs.estimation_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.user_access") }}</span>
          {{ translate("wbs.user_access_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.color_nodes") }}</span>
          {{ translate("wbs.color_nodes_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.edit_nodes") }}</span>
          {{ translate("wbs.edit_nodes_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.child_nodes") }}</span>
          {{ translate("wbs.child_nodes_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.share") }}</span>
          {{ translate("wbs.share_description") }}
        </p>
        <p>
          <span class="header">{{ translate("wbs.new_features") }}</span>
          {{ translate("wbs.new_features_description") }}
        </p>
      </template>
    </app-baloon>
  </div>
</template>
<script>
import AppBaloon from "@/components/shared/baloon/Baloon";
import IconAttention from "@/components/icons/IconAttention.vue";
import IconImport from "@/components/icons/IconImport.vue";
import IconClose from "@/components/icons/IconClose.vue";
export default {
  components: {
    AppBaloon,
    IconAttention,
    IconImport,
    IconClose,
  },
  inject: ["translate"],
  emits: ["onClose"],
  setup(props, { emit }) {
    const onClose = () => {
      emit("onClose");
    };
    return { onClose };
  },
};
</script>

<style lang="scss" scoped>
.header {
  text-decoration: underline;
  display: block;
  margin-bottom: 5px;
}

p {
  font-size: 14px;
  line-height: 21px;
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #363636;
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  justify-content: space-between;
  align-items: center;

  strong {
    font-weight: 600;
  }

  .close {
    cursor: pointer;
    justify-self: end;
    align-self: self-start;
  }
}

::v-deep(.baloon-container) {
  .content {
    height: 400px;
    overflow: auto;
  }
}

.icon-import {
  width: 54px;
  height: 34px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background: #F3F6FF;
  border-radius: 16px;
}
</style>
