<template>
  <form @submit.prevent="submit" data-testid="general-tab-content">
    <div class="hierarchy">
      <label>
        {{ translate("general_settings.language") }}
      </label>
      <base-select-box
        data-testid="select-language"
        v-model="systemLanguage"
        :options="languageList"
        :placeholder="translate('general_settings.select_language')"
        :selectedText="translate('selected')"
        label-by="label"
        value-by="value"
        :min="1"
        :close-on-select="true"
        :small-font="true"
      />
      <br />
      <label>
        {{ translate("general_settings.estimation_style") }}
      </label>
      <base-select-box
        data-testid="select-estimation"
        v-model="estimationType"
        :options="estimationStyleList"
        :placeholder="translate('general_settings.select_estimation')"
        :selectedText="translate('selected')"
        label-by="label"
        value-by="value"
        :min="1"
        :close-on-select="true"
        :small-font="true"
      />
    </div>
    <div class="error-list">
      <ul>
        <li :key="i" v-for="(error, i) in backendErrors">
          {{ error.text }}
        </li>
      </ul>
    </div>
    <div class="actions">
      <app-button
        data-testid="submit-settings"
        :color="'primary'"
        :disabled="!isFormValid"
        type="submit"
      >
        <span>{{ translate("modal.save") }}</span>
      </app-button>
    </div>
  </form>
</template>
<script>
import { reactive, toRefs, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import BaseSelectBox from "vue-select-box";
import AppButton from "@/components/shared/Button";
import IconInformation from "@/components/icons/IconInformation.vue";
import errorToList from "@/helpers/errorToList";
import { useLanguageHelper } from "@/helpers/languageHelper";
export default {
  inject: ["translate"],
  components: {
    BaseSelectBox,
    AppButton,
    IconInformation
  },
  setup() {
    const store = useStore(),
      state = reactive({
        systemLanguage: "en",
        languageList: [
          {
            value: "en",
            label: "English",
          },
          {
            value: "es",
            label: "Spanish",
          },
          {
            value: "ru",
            label: "Russian",
          },
        ],
        estimationType: null,
        estimationStyleList: [],
        isFormValid: computed(() => state.systemLanguage != null),
        backendErrors: [],
      });
    watch(
      () => store.getters["profile/language"],
      (current) => {
        state.systemLanguage = current;
      }
    );

    onMounted(async () => {
      await store.dispatch("settings/getGeneralSettings");
      state.systemLanguage = store.getters["profile/language"];
      state.estimationType = store.getters["settings/estimationType"];
      state.estimationStyleList = store.getters["profile/estimationTypes"];
    });
    const { setLanguage } = useLanguageHelper(store);
    const submit = async () => {
      try {
        state.backendErrors = [];
        setLanguage(state.systemLanguage);

        await store.dispatch("settings/setGeneralSettings", {
          systemLanguage: state.systemLanguage,
          estimationType: state.estimationType
        });
        store.dispatch("profile/getInstance");
      } catch (error) {
        state.backendErrors = errorToList(error);
      }
    };
    return {
      ...toRefs(state),
      submit,
    };
  },
};
</script>
<style lang="scss" scoped>
form {
  max-width: 300px;
  div + div {
    margin-top: 30px;
  }
}

label {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
  color: #a1a9ba;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  svg {
    cursor: pointer;
  }
  svg:hover ~ .baloon-info {
    display: block;
  }
}
.actions {
  margin-top: 20px;
  max-width: 200px;
  button {
    height: 55px;
  }
}
.baloon-info {
  display: none;
  position: absolute;
  z-index: 999;
  left: calc(100% + 20px);
  top: -14px;
  div {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
  }
  img {
    border-radius: 3px;
    margin-top: 15px;
    height: 260px;
    width: 100%;
  }
}
.error-list {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      color: rgb(250, 89, 89);
    }
  }
}
</style>
