<template>
  <div class="input-field">
    <label>{{ translate("wbs.best_case") }}<span>*</span></label>
    <app-base-input ref="bestCaseEl" data-testid="base-case-estimate-input" v-model="optimistic" @input="onChange"
      :placeholder="translate('wbs.estimate_placeholder')" />
    <small>{{ translate("wbs.best_case_description") }} </small>
    <div class="error-text">
      {{ isOptimisticValid.errorText }}
    </div>
  </div>
  <div class="input-field">
    <label>{{ translate("wbs.probable_case") }} <span>*</span></label>
    <app-base-input data-testid="probable-case-estimate-input" v-model="nominal" @input="onChange"
      :placeholder="translate('wbs.estimate_placeholder')" />
    <small> {{ translate("wbs.probable_case_description") }} </small>
    <div class="error-text">
      {{ isNominalValid.errorText }}
    </div>
  </div>
  <div class="input-field">
    <label>{{ translate("wbs.worst_case") }}<span>*</span></label>
    <app-base-input data-testid="worst-case-estimate-input" v-model="pessimistic" @input="onChange"
      :placeholder="translate('wbs.estimate_placeholder')" />
    <small>{{ translate("wbs.worst_case_description") }}</small>
    <div class="error-text">
      {{ isPessimisticValid.errorText }}
    </div>
  </div>
  <div>
    <label class="deviation_estimation_label">
      <input v-model="deviationEstimation" type="checkbox" @change="onChangeDeviationEstimation">
      Add deviation to estimation 
    </label>
  </div>
  <div class="input-field">
    <label>PERT</label>
    <app-base-input :readonly="true" v-model="pertResult" :disabled="true" />
  </div>
  <br />
  <div class="input-field">
    <label>{{ translate("wbs.deviation") }}<span>*</span></label>
    <app-base-input :readonly="true" v-model="pertDeviation" :disabled="true" />
  </div>
</template>
<script>
import { reactive, toRefs, computed, watch, onMounted, ref } from "vue";
import AppBaseInput from "@/components/forms/elements/BaseInput.vue";
import { useDateHelper } from "@/helpers/dateHelper";
import { useCookies } from "@/helpers/cookies";
export default {
  components: {
    AppBaseInput,
  },
  inject: ["translate"],
  emits: ["onFormValid", "onChange"],
  props: {
    pertBestCase: {
      type: String,
      default: null,
    },
    pertProbableCase: {
      type: String,
      default: null,
    },
    pertWorstCase: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { parseInput, pertCalculation, checkValidationStr } = useDateHelper(),
      bestCaseEl = ref(),
      state = reactive({
        optimistic: props.pertBestCase,
        nominal: props.pertProbableCase,
        pessimistic: props.pertWorstCase,
        deviationEstimation: false,
        pertResult: computed(() => {
          return pertCalculation(
            parseInput(state.pessimistic),
            parseInput(state.nominal),
            parseInput(state.optimistic),
            state.deviationEstimation
          ).pert;
        }),
        pertDeviation: computed(() => {
          return pertCalculation(
            parseInput(state.pessimistic),
            parseInput(state.nominal),
            parseInput(state.optimistic)
          ).dev;
        }),
        isOptimisticValid: computed(() => {
          let errorText = "";
          let result = true;
          if (state.optimistic && !checkValidationStr(state.optimistic)) {
            errorText = "Specified format is not valid.";
          }

          if (
            parseInput(state.optimistic) > parseInput(state.nominal) ||
            parseInput(state.optimistic) > parseInput(state.pessimistic)
          ) {
            errorText = "Best case should be less than probable or worst cases.";
          }

          if (errorText) {
            result = false;
          }

          return {
            errorText,
            result,
          };
        }),
        isNominalValid: computed(() => {
          let errorText = "";
          let result = true;
          if (state.nominal && !checkValidationStr(state.nominal)) {
            errorText = "Specified format is not valid.";
          }

          if (parseInput(state.nominal) > parseInput(state.pessimistic)) {
            errorText = "Probable case should be smaller than the worst case.";
          }

          if (errorText) {
            result = false;
          }

          return {
            errorText,
            result,
          };
        }),
        isPessimisticValid: computed(() => {
          let errorText = "";
          let result = true;

          if (state.pessimistic && !checkValidationStr(state.pessimistic)) {
            errorText = "Specified format is not valid.";
          }

          if (errorText) {
            result = false;
          }

          return {
            errorText,
            result,
          };
        }),
        isFormValid: computed(
          () =>
            state.isOptimisticValid.result &&
            state.isNominalValid.result &&
            state.isPessimisticValid.result
        ),
      });

    watch(
      () => state.isFormValid,
      (current) => {
        emit("onFormValid", current);
      }
    );

    const onChange = () => {           
      if (state.isFormValid)
        emit("onChange", {
          estimate: state.pertResult?.trim(),
          pertBestCase: state.optimistic,
          pertProbableCase: state.nominal,
          pertWorstCase: state.pessimistic,
          pertDeviation: state.pertDeviation,
        });
    };

    const onChangeDeviationEstimation = (e) => {
      const { saveCookie } = useCookies();
      saveCookie('deviation_estimation', e.target.checked)
      onChange();
    }

    onMounted(async () => {
      emit("onFormValid", state.isFormValid);
      bestCaseEl.value.focus();
      bestCaseEl.value.el.select();
      onChange();
      const { readCookie } = useCookies();
      const deviationEstimation = await readCookie('deviation_estimation');
      state.deviationEstimation = deviationEstimation == 'true' ? true : false;
    });

    return {
      ...toRefs(state),
      onChange,
      bestCaseEl,
      onChangeDeviationEstimation,
    };
  },
};
</script>
<style lang="scss" scoped>
.input-field {
  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #363636;
    margin-bottom: 5px;
    display: block;

    span {
      color: red;
    }
  }

  small {
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 13px;
    color: #c3cad9;
  }

  .error-text {
    color: #de350b;
    font-size: 12px;
    margin-top: 5px;
  }
}
.deviation_estimation_label {
  font-size: 14px;
}
</style>
